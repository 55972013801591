import { OToastManager, OTypography } from "@maestro/react";
import { helpers } from "@maestro/utils";
import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { service } from "services";
import { GetApiAdminContractSignersResponse } from "services/hubloan/models";
import { loanIrisActs } from "../utils/loan-iris-acts.utils";
import { loanAdditionalSignerFieldsGrid as loanAdditionalSignerGrid } from "./loan-additional-signer.grid";
import { LoanContractsTemplatesMultiselect } from "./loan-contracts-templates-multiselect.component";
import { LoanContractsTemplatesUpload } from "./loan-contracts-templates-upload.component";
import { ContractTemplate } from "./loan-contracts-templates.types";
import { contractSignerTypes } from "./loan-contracts-templates.utils";

export const loanContractsTemplatesFormGrid = (
  initialContractTemplates: ContractTemplate[],
  onChange: (contractTemplates: ContractTemplate[]) => void,
  signers: GetApiAdminContractSignersResponse | undefined,
  config?: { edit?: boolean; detailsLoanContractsConfigId?: string },
) => {
  return {
    datagrid: {
      noDataText: "Nenhum template de contrato.",
      dataSource: initialContractTemplates ?? [],
      editing: {
        mode: "form",
        allowAdding: true,
        allowUpdating: !config?.detailsLoanContractsConfigId,
        allowDeleting: !config?.detailsLoanContractsConfigId,
        confirmDelete: true,
      },
      onRowInserted: async (row) => {
        if (config?.detailsLoanContractsConfigId) {
          try {
            const { data: response } =
              await service.hubLoan.addContractTemplateConfig({
                loanContractsConfigId: +config.detailsLoanContractsConfigId,
                ...row.data,
                contractAdditionalSigners:
                  row.data.contractAdditionalSigners?.map((ca) => ({
                    ...ca,
                    partyCompanyTaxId: ca.contractSigner.partyCompany.taxId,
                    signerCompanyTaxId: ca.contractSigner.signerCompany.taxId,
                  })) ?? [],
              });

            const e = row.data;
            e.id = response.id;
            e.updatedDate = response.updatedDate;
            OToastManager.success(
              "Configuração de template de contrato adicionada com sucesso.",
            );
          } catch {
            OToastManager.danger(
              "Não foi possível adicionar configuração de template de contrato.",
            );
          }
        }
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowUpdated: async (row) => {
        if (config?.detailsLoanContractsConfigId) {
          try {
            if (!row.data.id) throw new Error("No Id");

            await service.hubLoan.updateContractTemplateConfig({
              id: row.data.id,
              ...row.data,
              contractAdditionalSigners:
                row.data.contractAdditionalSigners?.map((ca) => ({
                  ...ca,
                  partyCompanyTaxId: ca.contractSigner.partyCompany.taxId,
                  signerCompanyTaxId: ca.contractSigner.signerCompany.taxId,
                })) ?? [],
            });
            OToastManager.success(
              "Configuração de template de contrato atualizada com sucesso.",
            );
          } catch {
            OToastManager.danger(
              "Não foi possível atualizar configuração de template de contrato.",
            );
          }
        }
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowRemoved: async (row) => {
        if (config?.detailsLoanContractsConfigId) {
          try {
            if (!row.data.id) throw new Error("No Id");

            await service.hubLoan.deleteContractTemplateConfig(row.data.id);
            OToastManager.success(
              "Configuração de template de contrato deletada com sucesso.",
            );
          } catch {
            OToastManager.danger(
              "Não foi possível deletar configuração de template de contrato.",
            );
          }
        }
        onChange?.(
          removeKeyFromDataSourceItems(row.component.getDataSource().items()),
        );
      },
      onRowValidating: (e) => {
        if (!e.newData?.template && !e.oldData?.template) {
          e.errorText = "Necessário anexar um documento";
          e.isValid = false;
        }
      },
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
        visible: !!config?.edit || !!config?.detailsLoanContractsConfigId,
        allowEditing: false,
        formItem: {
          visible: !!config?.edit || !!config?.detailsLoanContractsConfigId,
        },
      },
      {
        dataType: "datetime",
        dataField: "updatedDate",
        caption: "Última atualização",
        visible: !!config?.edit || !!config?.detailsLoanContractsConfigId,
        allowEditing: false,
        formItem: {
          visible: !!config?.edit || !!config?.detailsLoanContractsConfigId,
        },
      },
      {
        dataField: "type",
        caption: "Tipo de contrato",
        lookup: {
          dataSource: [
            "CCB",
            "CPR",
            "CAC",
            "AF_IMOVEL",
            "AF_AERONAVE",
            "CCG",
            "ICF",
            "CT",
            "NOTA_PROMISSORIA",
            "TERMO_CARTAO",
          ],
        },
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "contractSigners",
        caption: "Partes signatárias do contrato",
        editCellRender: (prop) => (
          <LoanContractsTemplatesMultiselect
            editCellData={prop}
            options={contractSignerTypes}
          />
        ),
      },
      {
        dataField: "signatureOrigin",
        caption: "Origem da assinatura",
        lookup: {
          dataSource: ["PaperClip", "ClickSign", "DocuSign"],
        },
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "irisAct",
        caption: "Ato na IRIS",
        validationRules: [{ type: "required" }],
        lookup: {
          displayExpr: "label",
          valueExpr: "value",
          dataSource: loanIrisActs,
        },
      },
      {
        dataField: "template",
        caption: "Guardar fileKey do arquivo de template",
        visible: false,
        showEditorAlways: false,
        formItem: { visible: false },
      },
      {
        dataField: "template-edit",
        caption: "Arquivo do template",
        editCellRender: (prop) => <LoanContractsTemplatesUpload {...prop} />,
        cellRender: ({ data }) => data?.template,
        formItem: {
          colSpan: 2,
        },
      },
      {
        caption: " ",
        dataField: "contractAdditionalSigners",
        dataType: "object",
        visible: false,
        editCellRender: ({ data, setValue }) => (
          <>
            <OTypography>Signatários adicionais</OTypography>
            <ODataGridGenerator
              grid={loanAdditionalSignerGrid(
                signers,
                (contractAdditionalSigners) => {
                  setValue(contractAdditionalSigners);
                },
              )}
              dataSource={data?.contractAdditionalSigners ?? []}
            />
          </>
        ),
        formItem: {
          colSpan: 2,
        },
      },
      {
        visible: !!config?.detailsLoanContractsConfigId,
        formItem: { visible: false },
        cellRender: ({ data, component, rowIndex }) => {
          return (
            <DataGridAction
              actions={[
                {
                  label: "Baixar",
                  icon: { category: "fal", icon: "fa-download" },
                  onClick: async () => {
                    try {
                      OToastManager.info("Enviando arquivo...");

                      const { data: blob } = await service.hubLoan.download({
                        key: data.template,
                      });

                      helpers.downloadBlobFile(
                        data.template.split("/")[1],
                        blob,
                      );
                      OToastManager.destroy();
                    } catch (err) {
                      OToastManager.danger("Não foi possível baixar arquivo.");
                    }
                  },
                },
                {
                  label: "Editar",
                  icon: { category: "fal", icon: "fa-edit" },
                  onClick: () => {
                    component.editRow(rowIndex);
                  },
                },
                {
                  label: "Excluir",
                  icon: { category: "fal", icon: "fa-trash" },
                  onClick: () => {
                    component.deleteRow(rowIndex);
                  },
                },
              ]}
            />
          );
        },
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    ContractTemplate & { "template-edit"?: unknown }
  >;
};
