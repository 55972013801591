import { PixKeySearchCard } from "components/pix-key-search-card";
import { RedirectWithRoles } from "components/redirect-with-roles";
import { ContactProvider } from "contexts/contact/contact.component";
import { Navigate } from "react-router-dom";
import { Routes } from "routes/routes.types";
import { cadastroRouteParams } from "../../cadastro/routes/cadastro.route-params";
import {
  BankingManager,
  BankslipPayments,
  LimiteMaisWorkingTime,
  PaymentsAndTransfers,
  PaymentsAndTransfersDetails,
  PaymentsAndTransfersProvider,
  ReconciliationDetails,
  Reconciliations,
  RegisterBankingAccount,
  RegisterNewStatement,
  ReprocessFile,
  StatementHistory,
  StatementHistoryDetails,
  Transfers,
} from "../pages";
import {
  CNABAgreement,
  CNABProvider,
  UpsertLayoutPage,
} from "../pages/cliente/arquivos-e-transmissoes";
import {
  Bankslip,
  CollectionBeneficiary,
  CollectionBeneficiaryProvider,
  CollectionsAgreement,
  CollectionsAgreementProvider,
  PixKeys,
  UpsertCollectionAgreement,
} from "../pages/cliente/cobrancas";
import { Accounts } from "../pages/cliente/contas";
import { AccountStatement } from "../pages/cliente/contas/extrato";
import { StatementProvider } from "../pages/cliente/contas/extrato/statement.context";
import { AccountAdminCorporateTax } from "../pages/cliente/contas/informe-rendimento";
import { BalanceYieldStatement } from "../pages/cliente/contas/remunera-mais";
import { DailyBalance } from "../pages/cliente/contas/saldo-extrato/saldo-diario";
import { ContactsFavored } from "../pages/cliente/contatos";
import { ContactsFavoredCreate } from "../pages/cliente/contatos/cadastrar";
import { ContactsFavoredEdit } from "../pages/cliente/contatos/editar";
import { OverdraftGuarantors } from "../pages/cliente/limite-mais/avalistas";
import { OverdraftDetails } from "../pages/cliente/limite-mais/detalhes";
import { OverdraftInstallments } from "../pages/cliente/limite-mais/parcelamentos";
import { SupplierPayments } from "../pages/cliente/pagamentos-ao-fornecedor";
import { BankslipSearch } from "../pages/produto/boletos";
import { UpdateIntegrationPage } from "../pages/produto/cnab/integracoes";
import { IntegrationDetailsProvider } from "../pages/produto/cnab/integracoes/compose/context/integration.context";
import { bankingRoles } from "../roles/banking.roles";
import { bankingRouteParams } from "./banking.route-params";

export const bankingRoutes = {
  product: {
    name: "Banking",
    path: "/banking/produto",
    element: <RedirectWithRoles />,
    children: {
      bankingManager: {
        name: "Banking manager",
        path: "/banking/produto/banking-manager",
        element: <BankingManager />,
        role: bankingRoles.product.bankingManagerPage.role,
        children: {
          register: {
            name: "Ativação da Empresa",
            path: "/banking/produto/banking-manager/cadastro",
            element: <RegisterBankingAccount />,
            role: bankingRoles.product.bankingManagerRegisterPage.role,
          },
        },
      },
      cnab: {
        name: "Extrato eletrônico",
        path: "/banking/produto/extrato-eletronico",
        element: (
          <Navigate to="/banking/produto/extrato-eletronico/cadastro" replace />
        ),
        hasSubItems: true,
        children: {
          register: {
            name: "Cadastro",
            path: "/banking/produto/extrato-eletronico/cadastro",
            element: <RegisterNewStatement />,
            role: bankingRoles.product.cnabRegisterPage.role,
          },
          search: {
            name: "Histórico",
            path: "/banking/produto/extrato-eletronico/historico",
            element: <StatementHistory />,
            role: bankingRoles.product.cnabSearchPage.role,
            children: {
              details: {
                name: "Detalhes",
                path: "/banking/produto/extrato-eletronico/historico/detalhes",
                element: <StatementHistoryDetails />,
                role: bankingRoles.product.cnabSearchDetailsPage.role,
              },
            },
          },
          reprocessFiles: {
            name: "Reprocessar arquivos",
            path: "/banking/produto/extrato-eletronico/reprocessar-arquivos",
            element: <ReprocessFile />,
            role: bankingRoles.product.cnabReprocessFilesPage.role,
          },
        },
      },
      integrations: {
        name: "Arquivos e Transmissões",
        path: "/banking/produto/integracoes/listar",
        hasSubItems: true,
        children: {
          register: {
            name: "Integrações",
            path: "/banking/produto/integracoes/listar",
            element: (
              <IntegrationDetailsProvider>
                <UpdateIntegrationPage />
              </IntegrationDetailsProvider>
            ),
            role: bankingRoles.product.cnabRegisterPage.role,
          },
        },
      },
      reconciliations: {
        name: "Reconciliações",
        path: "/banking/produto/reconciliacoes",
        hasSubItems: true,
        children: {
          cardSupplierPayments: {
            name: "PagForCard",
            path: "/banking/produto/reconciliacoes/pagfor-cartoes",
            element: <Reconciliations product="PagForCard" />,
            role: bankingRoles.product.reconciliationsPage.role,
            children: {
              details: {
                name: "Detalhes",
                path: `/banking/produto/reconciliacoes/pagfor-cartoes/:${bankingRouteParams.reconResultExternalId}/detalhes`,
                element: <ReconciliationDetails />,
                role: bankingRoles.product.reconciliationsDetailsPage.role,
              },
            },
          },
          billing: {
            name: "BillingPME",
            path: "/banking/produto/reconciliacoes/billing-pme",
            element: <Reconciliations product="BillingPME" />,
            role: bankingRoles.product.reconciliationsPage.role,
            children: {
              details: {
                name: "Detalhes",
                path: `/banking/produto/reconciliacoes/billing-pme/:${bankingRouteParams.reconResultExternalId}/detalhes`,
                element: <ReconciliationDetails />,
                role: bankingRoles.product.reconciliationsDetailsPage.role,
              },
            },
          },
        },
      },
      overdraft: {
        name: "Limite da conta",
        path: "/banking/produto/limite-mais",
        element: <LimiteMaisWorkingTime />,
        role: bankingRoles.product.overdraftPage.role,
      },
      pixKeys: {
        name: "Consulta de chave Pix",
        path: "/banking/produto/consulta-chave-pix",
        element: <PixKeySearchCard />,
        role: bankingRoles.product.pixKeySearchPage.role,
      },
      bankslip: {
        name: "Consulta de boletos",
        path: "/banking/produto/consulta-boletos",
        element: <BankslipSearch />,
        role: bankingRoles.product.bankslipSearchPage.role,
      },
    },
  },
  customer: {
    name: "",
    path: "/banking/cliente",
    element: <RedirectWithRoles />,
    children: {
      accounts: {
        name: "Contas",
        path: "/banking/cliente/contas",
        role: bankingRoles.customer.accountsPage.role,
        element: <Accounts />,
        children: {
          account: {
            name: "",
            path: `/banking/cliente/contas/:${bankingRouteParams.account}`,
            children: {
              agency: {
                name: "Detalhes da Conta",
                path: `/banking/cliente/contas/:${bankingRouteParams.account}/:${bankingRouteParams.agency}`,
                showChildren: true,
                children: {
                  dailyBalance: {
                    name: "Saldo final do dia",
                    path: `/banking/cliente/contas/:${bankingRouteParams.account}/:${bankingRouteParams.agency}/saldo-diario`,
                    element: <DailyBalance />,
                    role: bankingRoles.customer
                      .accountsAccountAgencyDailyBalancePage.role,
                  },
                  statement: {
                    name: "Extrato",
                    path: `/banking/cliente/contas/:${bankingRouteParams.account}/:${bankingRouteParams.agency}/extrato`,
                    element: (
                      <StatementProvider>
                        <AccountStatement />
                      </StatementProvider>
                    ),
                    role: bankingRoles.customer
                      .accountsAccountAgencyStatementPage.role,
                  },
                  balanceYield: {
                    name: "Remunera+",
                    path: `/banking/cliente/contas/:${bankingRouteParams.account}/:${bankingRouteParams.agency}/remunera-mais`,
                    element: <BalanceYieldStatement />,
                    role: bankingRoles.customer
                      .accountsAccountAgencyStatementPage.role,
                  },
                  corporateTax: {
                    name: "Informe de rendimentos",
                    path: `/banking/cliente/contas/:${bankingRouteParams.account}/:${bankingRouteParams.agency}/informe-rendimentos`,
                    role: bankingRoles.customer
                      .btgAccountsAccountAdminCorporateTaxPage.role,
                    element: <AccountAdminCorporateTax />,
                  },
                },
              },
            },
          },
        },
      },
      contacts: {
        name: "Contatos",
        path: "/banking/cliente/contatos",
        role: bankingRoles.customer.contactsFavoredPage.role,
        contextProvider: ContactProvider,
        element: <ContactsFavored />,
        children: {
          create: {
            name: "Cadastrar",
            path: "/banking/cliente/contatos/cadastrar",
            role: bankingRoles.customer.contactsFavoredCreatePage.role,
            element: <ContactsFavoredCreate />,
          },
          edit: {
            name: "Editar",
            path: `/banking/cliente/contatos/:${cadastroRouteParams.uuid}`,
            role: bankingRoles.customer.contactsFavoredEditPage.role,
            element: <ContactsFavoredEdit />,
          },
        },
      },
      cnab: {
        name: "Arquivos e transmissões",
        path: "/banking/cliente/arquivos-e-transmissoes",
        contextProvider: CNABProvider,
        hasSubItems: true,
        children: {
          agreement: {
            name: "Convênio",
            path: "/banking/cliente/arquivos-e-transmissoes/convenio",
            role: bankingRoles.customer.cnabAgreementListPage.role,
            element: <CNABAgreement />,
          },
          upsertModel: {
            name: "Configurar modelos",
            path: "/banking/cliente/arquivos-e-transmissoes/modelos",
            role: bankingRoles.customer.cnabUpsertModelPage.role,
            element: <UpsertLayoutPage />,
          },
        },
      },
      collection: {
        name: "Cobranças",
        path: "/banking/cliente/cobrancas",
        hasSubItems: true,
        children: {
          beneficiary: {
            name: "Beneficiário",
            path: "/banking/cliente/cobrancas/beneficiario",
            role: bankingRoles.customer.collectionBeneficiaryPage.role,
            contextProvider: CollectionBeneficiaryProvider,
            element: <CollectionBeneficiary />,
          },
          bankslip: {
            name: "Boletos",
            path: "/banking/cliente/cobrancas/boletos",
            role: bankingRoles.customer.bankslipListPage.role,
            element: <Bankslip />,
          },
          agreement: {
            name: "Convênio",
            path: "/banking/cliente/cobrancas/convenio",
            role: bankingRoles.customer.collectionsAgreementListPage.role,
            element: <CollectionsAgreement />,
            contextProvider: CollectionsAgreementProvider,
            children: {
              create: {
                name: "Cadastrar",
                path: "/banking/cliente/cobrancas/convenio/cadastrar",
                role: bankingRoles.customer.collectionsAgreementListPage.role,
                element: <UpsertCollectionAgreement />,
              },
              edit: {
                name: "Editar",
                path: "/banking/cliente/cobrancas/convenio/editar",
                role: bankingRoles.customer.collectionsAgreementListPage.role,
                element: <UpsertCollectionAgreement />,
              },
            },
          },
          pixKeys: {
            name: "Chaves Pix",
            path: "/banking/cliente/cobrancas/chaves-pix",
            role: bankingRoles.customer.pixKeysListPage.role,
            element: <PixKeys />,
          },
        },
      },
      overdraft: {
        name: "Limite da conta",
        path: "/banking/cliente/limite-mais",
        role: bankingRoles.customer.overdraftPage.role,
        element: <OverdraftDetails />,
        children: {
          installments: {
            name: "Parcelamentos",
            path: `/banking/cliente/limite-mais/:${bankingRouteParams.ownerId}/parcelamentos`,
            role: bankingRoles.customer.overdraftInstallmentsPage.role,
            element: <OverdraftInstallments />,
          },
          guarantors: {
            name: "Avalistas",
            path: "/banking/cliente/limite-mais/avalistas",
            role: bankingRoles.customer.overdraftGuarantorsPage.role,
            element: <OverdraftGuarantors />,
          },
        },
      },
      transfers: {
        name: "Transferências",
        path: "/banking/cliente/transferencias",
        hasSubItems: true,
        children: {
          scheduled: {
            name: "Agendadas",
            path: "/banking/cliente/transferencias/agendadas",
            role: bankingRoles.customer.transfersScheduledPage.role,
            element: <Transfers status="SCHEDULED" />,
          },
          sent: {
            name: "Realizadas",
            path: "/banking/cliente/transferencias/realizadas",
            role: bankingRoles.customer.transfersSentPage.role,
            element: <Transfers status="SENT" />,
          },
        },
      },
      payments: {
        name: "Pagamentos",
        path: "/banking/cliente/pagamentos",
        hasSubItems: true,
        children: {
          scheduled: {
            name: "Agendados",
            path: "/banking/cliente/pagamentos/agendados",
            role: bankingRoles.customer.paymentsScheduledPage.role,
            element: <BankslipPayments status="scheduled" />,
          },
          sent: {
            name: "Realizados",
            path: "/banking/cliente/pagamentos/realizados",
            role: bankingRoles.customer.paymentsSentPage.role,
            element: <BankslipPayments status="sent" />,
          },
        },
      },
      paymentsAndTransfers: {
        name: "Pagamentos e transferências",
        path: "/banking/cliente/pagamentos-e-transferencias",
        element: <PaymentsAndTransfers />,
        role: bankingRoles.customer.paymentsAndTransfersPage.role,
        contextProvider: PaymentsAndTransfersProvider,
        children: {
          details: {
            name: "Detalhes",
            path: "/banking/cliente/pagamentos-e-transferencias/detalhes",
            element: <PaymentsAndTransfersDetails />,
            role: bankingRoles.customer.paymentsAndTransfersPage.role,
          },
        },
      },
      supplierPayments: {
        name: "Pagamentos ao fornecedor",
        path: "/banking/cliente/pagamentos-ao-fornecedor",
        element: <SupplierPayments />,
        role: bankingRoles.customer.supplierPaymentsPage.role,
      },
    },
  },
} as const satisfies Routes;
