import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";

export const useEnergyCustomer = () => {
  const { tradeId } = useParams();

  const { callService, loading, hasError, value, error } = useServiceCall(
    service.hubEnergy.getTradeDetails,
  );

  const getEntity = useCallback(
    () => tradeId && callService(tradeId),
    [callService, tradeId],
  );

  useEffect(() => {
    getEntity();
  }, [getEntity]);

  return {
    error,
    getEntity,
    hasError,
    loading,
    value,
    tradeId,
  };
};
