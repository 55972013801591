import { ResourceList, ScopeList } from "pages/developers/pages/cliente/consent/consent.types";
import { client } from "../client";
import { developersEndpoints } from "./developers.endpoints";
import { PendingApps, appStatusObservation } from "./models";

export const developersService = {
  getPendingApps() {
    return client.get<PendingApps>(developersEndpoints.apps.pending);
  },
  patchApproveApp(
    appId: string,
    version: number | string,
    tenantId: string,
    body: appStatusObservation,
  ) {
    return client.patch(
      developersEndpoints.apps.verifyApp(appId, version, tenantId),
      body,
    );
  },
  patchRejectApp(
    appId: string,
    version: number | string,
    tenantId: string,
    body: appStatusObservation,
  ) {
    return client.patch(
      developersEndpoints.apps.rejectApp(appId, version, tenantId),
      body,
    );
  },
  getFilteredApps(filter: string) {
    return client.get<PendingApps>(
      developersEndpoints.apps.filteredApp(filter ? `?${filter}` : ""),
    );
  },
  getWebhooksByApp(appId: string, tenantId: string) {
    return client.get(developersEndpoints.webhooks.webhooksByApp(appId, tenantId))
  },
  getWebhookDetails(appId: string, tenantId: string, webhookId: string) {
    return client.get(developersEndpoints.webhooks.webhooksByWebhookId(appId, tenantId, webhookId))
  },
  getAppDetails(appId: string, tenantId: string) {
    return client.get(developersEndpoints.apps.detailsApp(appId, tenantId));
  },
  getFilteredResources(filter: string) {
    return client.get<ResourceList>(
      developersEndpoints.apps.filteredResource(filter ? `?${filter}` : ""),
    );
  },
  getFilteredScopes(filter: string) {
    return client.get<ScopeList>(
      developersEndpoints.apps.filteredScope(filter ? `?${filter}` : ""),
    );
  },
  RevokeConsentScope(id: string){
    return client.delete(developersEndpoints.apps.revokeConsentScope(id));
  },
  RevokeConsentResource(id: string){
    return client.delete(developersEndpoints.apps.revokeConsentResource(id));
  },
};
