import {
  OCard,
  OCardBody,
  OCheckbox,
  OCol,
  OLabel,
  OOption,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { SelectSearchMultiple } from "components/form";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { SelectSearchField } from "components/select-search/field/select-search-field.component";
import { FormProvider } from "react-hook-form";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { orqIcons } from "../../../../configuracoes/workflow-configuration.utils";
import { useEditWorkflowConfigMainData } from "./edit-workflow-config-main-data.hook";
import {
  backButtonActionTypes,
  defaultUserNotificationTypeData,
  summaryTypes,
} from "./edit-workflow-config-main-data.utils";

export const EditWorkflowConfigMainData = () => {
  const {
    books,
    creditLines,
    detailsPageTypeData,
    form,
    hasError,
    loading,
    loadingEditWorkflowConfig,
    minimumCriteriaOptions,
    workflowConfigsOptions,
    workflowConfig,
    getAllData,
    updateWorkflow,
  } = useEditWorkflowConfigMainData();

  const { handleSubmit, setValue } = form;

  const iconOptions = orqIcons.map((icon) => ({
    value: icon,
    label: icon,
    onSelect: (value: string) => setValue("icon", value),
  }));

  const configOptions =
    workflowConfigsOptions?.map((config) => ({
      value: String(config.id),
      label: config.name,
    })) ?? [];

  const configRedirectOptions =
    workflowConfigsOptions?.map((config) => ({
      value: config.identification,
      label: config.name,
    })) ?? [];

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title="Editar configuração de workflow - Dados principais" />
      }
      actions={
        <LoadingButton
          loading={loadingEditWorkflowConfig}
          onClick={handleSubmit(updateWorkflow)}
          disabled={hasError}
        >
          Salvar
        </LoadingButton>
      }
    >
      <ContentTemplate
        hasError={hasError}
        loading={loading}
        noValue
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar detalhes do processador"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={getAllData} />
          </ErrorComponent>
        }
        render={() => (
          <FormProvider {...form}>
            <OCard className="justify-content-between my-3">
              <OCardBody>
                <ORow>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <OTypography size="lg">Tela Inicial</OTypography>
                  </div>
                  <OCol sm={3} className="d-flex align-items-right">
                    <ORFieldInput
                      id="name"
                      name="name"
                      tag="text"
                      label="Nome"
                      labelSize="md"
                      tooltip="Label utilizado como título na listagem dos cards e na home do workflow."
                      tooltipPosition="top-right"
                    />
                  </OCol>
                  <OCol sm={6}>
                    <ORFieldInput
                      id="description"
                      name="description"
                      tag="text"
                      label="Descrição"
                      labelSize="md"
                      tooltip="Descrição utilizada na listagem dos cards e na home do workflow."
                      tooltipPosition="top-right"
                    />
                  </OCol>
                  <OCol sm={3}>
                    <ORFieldInput
                      id="gridTitle"
                      name="gridTitle"
                      tag="text"
                      label="Título da Grid"
                      labelSize="md"
                      tooltip="Título que aparece acima da grid. Exemplo: clientes, propostas, etc."
                      tooltipPosition="top-right"
                    />
                  </OCol>
                  <OCol sm={3}>
                    <ORFieldInput
                      id="tabTitle"
                      name="tabTitle"
                      tag="text"
                      label="Subtítulo"
                      labelSize="md"
                      tooltip="Título de apoio que aparece em cima da barra de busca."
                      tooltipPosition="top-right"
                    />
                  </OCol>
                  <OCol sm={6}>
                    <ORFieldInput
                      id="tabDescription"
                      name="tabDescription"
                      tag="text"
                      label="Descrição Subtítulo"
                      labelSize="md"
                      tooltip="Descrição que aparece abaixo do título de apoio."
                      tooltipPosition="top-right"
                    />
                  </OCol>
                  <OCol sm={3}>
                    <SelectSearchField
                      id="icon"
                      name="icon"
                      label="Ícone"
                      labelSize="md"
                      tooltip="Ícone utilizado na listagem dos cards."
                      tooltipPosition="top-right"
                      placeholder="Selecionar"
                      options={iconOptions}
                      value={workflowConfig?.icon ?? ""}
                      onClear={() => setValue("icon", "")}
                      maxOptions={50}
                    />
                  </OCol>
                  <OCol sm={3}>
                    <ORFieldInput
                      id="preWorkflowPageName"
                      name="preWorkflowPageName"
                      tag="text"
                      label="Nome da página antes do workflow"
                      labelSize="md"
                      tooltip="Default: Catálogo de produtos"
                      tooltipPosition="top-right"
                    />
                  </OCol>
                </ORow>
              </OCardBody>
            </OCard>
            <OCard className="justify-content-between my-3">
              <OCardBody>
                <ORow>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <OTypography size="lg">Workflow reutilizáveis</OTypography>
                  </div>

                  <OCol sm={4}>
                    <SelectSearchMultiple
                      id="reusableWorkflowConfigs"
                      name="reusableWorkflowConfigs"
                      label="Workflows Reutilizáveis"
                      labelSize="md"
                      tooltip="Workflows cujos leads podem ser reutilizados"
                      tooltipPosition="top-right"
                      options={configOptions}
                      key={workflowConfigsOptions?.map((r) => r.id).join("_")}
                    />
                  </OCol>
                  <OCol sm={4}>
                    <SelectSearchField
                      id="redirectWorkflow"
                      name="redirectWorkflow"
                      label="Workflow para redirecionar"
                      labelSize="md"
                      tooltip="Workflow que será redirecionado na tela de sucesso"
                      tooltipPosition="top-right"
                      options={configRedirectOptions}
                      key={workflowConfigsOptions
                        ?.map((r) => r.identification)
                        .join("_")}
                    />
                  </OCol>
                  <OCol sm={4}>
                    <SelectSearchMultiple
                      id="workflowConfigIncompatibleIds"
                      name="workflowConfigIncompatibleIds"
                      label="Workflows Incompatíveis"
                      labelSize="md"
                      tooltip="Workflows que não podem ter leads simultâneos com este em questão"
                      tooltipPosition="top-right"
                      options={configOptions}
                      key={workflowConfigsOptions?.map((r) => r.id).join("_")}
                    />
                  </OCol>
                </ORow>
              </OCardBody>
            </OCard>
            <OCard className="justify-content-between my-3">
              <OCardBody>
                <ORow>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <OTypography size="lg">Outros</OTypography>
                  </div>
                  <OCol sm={4}>
                    <SelectSearchMultiple
                      id="minimumCriteria"
                      name="minimumCriteria"
                      label="Critérios mínimos"
                      labelSize="md"
                      tooltip="Critérios visíveis no pré-formulário e alguns com validação que pode ser antes ou após iniciado o lead."
                      tooltipPosition="top-right"
                      options={
                        minimumCriteriaOptions?.map((config) => ({
                          value: String(config.id),
                          label: config.title,
                        })) ?? []
                      }
                      key={minimumCriteriaOptions?.map((x) => x.id).join("_")}
                    />
                  </OCol>
                  <OCol sm={4}>
                    <SelectSearchMultiple
                      id="defaultCreditLines"
                      name="defaultCreditLines"
                      label="Linhas de crédito padrão"
                      labelSize="md"
                      tooltip="Linha de crédito para ser adicionada automaticamente nas linhas de créditos desejadas do lead (WorflowLeadCreditLine)."
                      tooltipPosition="top-right"
                      options={
                        creditLines?.map((config) => ({
                          value: config.identification,
                          label: config.identification,
                        })) ?? []
                      }
                      key={creditLines
                        ?.map((cl) => cl.identification)
                        .join("_")}
                    />
                  </OCol>
                  <OCol sm={4}>
                    <SelectSearchField
                      id="bookCr"
                      name="bookCr"
                      label="Book"
                      labelSize="md"
                      tooltip="Book da linha de crédito principal."
                      tooltipPosition="top-right"
                      options={
                        books?.map((config) => ({
                          value: config.bookCr,
                          label: config.bookName,
                        })) ?? []
                      }
                      key={books?.map((book) => book.bookCr).join("_")}
                    />
                  </OCol>
                  <OCol sm={4}>
                    <ORFieldInput
                      id="nameId"
                      name="nameId"
                      tag="text"
                      label="Name Id"
                      labelSize="md"
                      tooltip="Nome sistêmico do Workflow, utilizado para definir Roles necessários para visualização e utilizado em integrações."
                      tooltipPosition="top-right"
                    />
                  </OCol>
                  <OCol sm={4}>
                    <ORFieldInput
                      id="uniqueNameId"
                      name="uniqueNameId"
                      tag="text"
                      label="Unique Name Id"
                      labelSize="md"
                      tooltip="Nome único sistêmico do Workflow."
                      tooltipPosition="top-right"
                    />
                  </OCol>
                  <OCol sm={4}>
                    <ORFieldInput
                      id="identification"
                      name="identification"
                      tag="text"
                      label="Identificação do WF"
                      labelSize="md"
                      tooltip="Utilizado em integrações para identificar o workflow."
                      tooltipPosition="top-right"
                      readonly
                      disabled
                    />
                  </OCol>
                  <OCol sm={4}>
                    <SelectSearchField
                      id="detailsPageType"
                      name="detailsPageType"
                      label="Tipo da página de detalhes"
                      labelSize="md"
                      options={
                        detailsPageTypeData?.map((type) => ({
                          value: type,
                          label: type,
                        })) ?? []
                      }
                      key={detailsPageTypeData?.join("_")}
                    />
                  </OCol>
                  <OCol sm={4}>
                    <ORFieldSelect
                      id="defaultUserNotificationType"
                      name="defaultUserNotificationType"
                      label="Tipo de notificação padrão"
                      labelSize="md"
                      tooltip={`Opção "Nenhum" decide se manda por Whatsapp ou e-mail`}
                      tooltipPosition="top-right"
                      key={defaultUserNotificationTypeData?.join("_")}
                    >
                      {defaultUserNotificationTypeData?.map(
                        ({ value, label }) => (
                          <OOption
                            value={value}
                            key={`details-page-type-${label}`}
                          >
                            <OTypography>{label}</OTypography>
                          </OOption>
                        ),
                      )}
                    </ORFieldSelect>
                  </OCol>
                  <OCol sm={4}>
                    <ORFieldSelect
                      id="backButtonActionType"
                      name="backButtonActionType"
                      label="Comportamento do botão de voltar"
                      labelSize="md"
                      key={backButtonActionTypes?.join("_")}
                    >
                      {backButtonActionTypes?.map((backButtonType) => (
                        <OOption
                          value={backButtonType.value}
                          key={`details-page-type-${backButtonType}`}
                        >
                          <OTypography>{backButtonType.label}</OTypography>
                        </OOption>
                      ))}
                    </ORFieldSelect>
                  </OCol>
                  <OCol>
                    <ORFieldCheckboxGroup
                      id="finishPreviousBigNumberOnStartNewOne"
                      name="finishPreviousBigNumberOnStartNewOne"
                      label="Etapas"
                      labelSize="md"
                      tooltip="Se selecionado, as etapas e status existentes em um lead serão encerrados assim que o lead entrar em outra etapa."
                      tooltipPosition="top-right"
                    >
                      <div className="d-flex flex-row gap-2 align-items-center">
                        <OCheckbox
                          size="xs"
                          id="finishable-status-checkbox"
                          value="true"
                        />
                        <OLabel htmlFor="finishable-status-checkbox">
                          Etapas síncronas?
                        </OLabel>
                      </div>
                    </ORFieldCheckboxGroup>
                  </OCol>
                  <OCol>
                    <ORFieldCheckboxGroup
                      id="hideDetailsAction"
                      name="hideDetailsAction"
                      label="Oculta ação ver detalhes"
                      labelSize="md"
                      tooltip="Se selecionado, a ação de ver detalhes não será mostrada no grid"
                      tooltipPosition="top-right"
                    >
                      <div className="d-flex flex-row gap-2 align-items-center">
                        <OCheckbox
                          size="xs"
                          id="hide-details-action-checkbox"
                          value="true"
                        />
                        <OLabel htmlFor="hide-details-action-checkbox">
                          Ocultar ação detalhes?
                        </OLabel>
                      </div>
                    </ORFieldCheckboxGroup>
                  </OCol>
                </ORow>
                <ORow>
                  <OCol md={2} sm={4}>
                    <ORFieldCheckboxGroup
                      id="hideSummary"
                      name="hideSummary"
                      label="Oculta resumo"
                      labelSize="md"
                      tooltip="Se selecionado, o resumo não será mostrado"
                      tooltipPosition="top-right"
                    >
                      <div className="d-flex flex-row gap-2 align-items-center">
                        <OCheckbox
                          size="xs"
                          id="hide-summary-checkbox"
                          value="true"
                        />
                        <OLabel htmlFor="hide-summary-checkbox">
                          Ocultar resumo?
                        </OLabel>
                      </div>
                    </ORFieldCheckboxGroup>
                  </OCol>
                  <OCol md={2} sm={4}>
                    <ORFieldSelect
                      id="summaryType"
                      name="summaryType"
                      label="Tipo do resumo"
                      labelSize="md"
                      key={summaryTypes?.join("_")}
                    >
                      {summaryTypes?.map((summaryType) => (
                        <OOption
                          value={summaryType.value}
                          key={`details-page-type-${summaryType.value}`}
                        >
                          <OTypography>{summaryType.label}</OTypography>
                        </OOption>
                      ))}
                    </ORFieldSelect>
                  </OCol>
                  <OCol md={2} sm={4}>
                    <ORFieldCheckboxGroup
                      id="checkDenyList"
                      name="checkDenyList"
                      label="Verificar na lista de bloqueio?"
                      labelSize="md"
                      tooltip="Se selecionado, irá verificar lead na lista de bloqueio"
                      tooltipPosition="top-right"
                    >
                      <div className="d-flex flex-row gap-2 align-items-center">
                        <OCheckbox
                          size="xs"
                          id="deny-list-checkbox"
                          value="true"
                        />
                        <OLabel htmlFor="deny-list-checkbox">
                          Verificar na lista de bloqueio?
                        </OLabel>
                      </div>
                    </ORFieldCheckboxGroup>
                  </OCol>
                </ORow>
              </OCardBody>
            </OCard>
          </FormProvider>
        )}
      />
    </FormTemplate>
  );
};
