import { yupResolver } from "@hookform/resolvers/yup";
import { OCol, ORFieldInput, ORow, OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { PutOriginatorsSubtypeRequest } from "services/hubpartners/models/requests/put-originators-subtype.request";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { HubPartners } from "services/hubpartners/models/types/admin";
import { EditDeskFormFields, editDeskSchema } from "./edit-desk.form";

export const EditDesk = () => {
  const { value: originatorSubtype } =
    useParseFromSearchParams<PutOriginatorsSubtypeRequest>("data");
  const [loading, setLoading] = useState(false);

  const form = useForm<EditDeskFormFields>({
    resolver: yupResolver(editDeskSchema),
  });
  const { handleSubmit, reset } = form;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.hubPartners.putDesk(values);

      OToastManager.success("Mesa editada com sucesso.");
    } catch (err) {
      if (isAxiosError<HubPartners.Admin.ErrorResponse>(err)) {
        err.response?.data?.failures?.forEach((failure) => {
          failure.message && OToastManager.danger(failure.message);
        });
      } else {
        OToastManager.danger("Não foi possível editar a mesa.");
      }
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (originatorSubtype) {
      reset({
        name: originatorSubtype.name,
        code: originatorSubtype.code,
        uuid: originatorSubtype.uuid,
      });
    }
  }, [originatorSubtype, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar mesa" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Editar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <ORow>
            <OCol>
              <ORFieldInput
                tag="text"
                label="Código"
                name="code"
                id="code"
                required
              />
            </OCol>
            <OCol>
              <ORFieldInput
                tag="text"
                label="Nome"
                name="name"
                id="name"
                required
              />
            </OCol>
          </ORow>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
