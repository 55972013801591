import { OField } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { loanContractsTemplatesFormGrid } from "./loan-contracts-templates-form.grid";
import { ContractTemplate } from "./loan-contracts-templates.types";

export interface LoanContractsTemplatesFormValues {
  contractTemplates: ContractTemplate[];
}

interface LoanContractsTemplatesFormProps {
  edit?: boolean;
  detailsLoanContractsConfigId?: string;
}

export const LoanContractsTemplatesForm = ({
  edit,
  detailsLoanContractsConfigId,
}: LoanContractsTemplatesFormProps) => {
  const {
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<LoanContractsTemplatesFormValues>();

  const watchContractTemplates = watch("contractTemplates");

  const {
    value: signers,
    callService: getSigners,
    loading,
  } = useServiceCall(service.hubLoan.getAllContractSigners);

  const grid = useMemo(
    () =>
      loanContractsTemplatesFormGrid(
        watchContractTemplates,
        (contractTemplates) => {
          setValue("contractTemplates", contractTemplates);
        },
        signers,
        { edit, detailsLoanContractsConfigId },
      ),
    [
      watchContractTemplates,
      signers,
      edit,
      detailsLoanContractsConfigId,
      setValue,
    ],
  );

  useEffect(() => {
    getSigners();
  }, [getSigners]);

  return (
    <>
      {errors?.contractTemplates?.message && (
        <OField error message={errors.contractTemplates.message} />
      )}
      <ODataGridGenerator grid={grid} loading={loading} />
    </>
  );
};
