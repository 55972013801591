import { OCard, OCardBody } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useRoles } from "hooks/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { ValidationErrorComponent } from "../../../../../components/validation-error-component";
import { energiaRoles } from "../../../../../roles/energia.roles";
import {
  ForceRecreateDiligenceButton,
  GoToCustomerButton,
  GoToTradeButton,
} from "./_compose";
import {
  consumerUnitBasicDataFields,
  consumerUnitCustomerFields,
  consumerUnitEnergyFields,
  consumerUnitTradeFields,
} from "./consumer-unit-details.details";
import { useConsumerUnitDetails } from "./consumer-unit-details.hook";

export const ConsumerUnitDetailsPage = () => {
  const { hasRole } = useRoles();
  const {
    consumerUnitId,
    error,
    getConsumerUnitDetails,
    hasError,
    loading,
    value,
  } = useConsumerUnitDetails();

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Detalhes" />}
      actions={
        value && (
          <>
            <GoToCustomerButton
              tradeId={value.response?.currentTrade.id}
              customerTaxId={value.response?.customer.taxId}
            />
            {hasRole(energiaRoles.product.tradesDetails.role) && (
              <GoToTradeButton tradeId={value.response?.currentTrade.id} />
            )}
            {hasRole(energiaRoles.customer.recreateMigration.role) && (
              <ForceRecreateDiligenceButton consumerUnitId={consumerUnitId} />
            )}
            <RouterButton
              href={corporateRouter.routes.energia.customer.consumerUnit.details.edit.path(
                { consumerUnitId },
              )}
              role={energiaRoles.customer.consumerUnitsCreate.role}
            >
              Editar
            </RouterButton>
          </>
        )
      }
    >
      <div className="d-flex flex-column gap-4 position-relative">
        <ContentTemplate
          loading={loading}
          hasError={hasError}
          errorComponent={
            <OCard>
              <OCardBody>
                <ValidationErrorComponent
                  error={error}
                  title="Erro ao buscar a unidade consumidora"
                  callback={getConsumerUnitDetails}
                />
              </OCardBody>
            </OCard>
          }
          value={value?.response}
          render={(_value) => (
            <>
              <DetailsCard
                fields={consumerUnitBasicDataFields}
                value={_value}
              />
              <DetailsCard fields={consumerUnitCustomerFields} value={_value} />
              <DetailsCard fields={consumerUnitEnergyFields} value={_value} />
              <DetailsCard fields={consumerUnitTradeFields} value={_value} />
            </>
          )}
        />
      </div>
    </DetailsTemplate>
  );
};
