import { yupResolver } from "@hookform/resolvers/yup";
import { OCol, ORFieldInput, OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { HubPartners } from "services/hubpartners/models/types/admin";
import { AddProductFormFields, addProductSchema } from "./add-product.form";

export const AddProduct = () => {
  const [loading, setLoading] = useState(false);

  const form = useForm<AddProductFormFields>({
    resolver: yupResolver(addProductSchema),
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.hubPartners.postProducts(values);

      OToastManager.success("Produto adicionado com sucesso.");
    } catch (err) {
      if (isAxiosError<HubPartners.Admin.ErrorResponse>(err)) {
        err.response?.data?.failures?.forEach((failure) => {
          failure.message && OToastManager.danger(failure.message);
        });
      } else {
        OToastManager.danger("Não foi possível cadastrar o produto.");
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar produto" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OCol>
            <ORFieldInput
              tag="text"
              label="Nome"
              name="name"
              id="name"
              required
            />
          </OCol>
          <OCol>
            <ORFieldInput
              tag="text"
              label="Descrição"
              name="description"
              id="description"
              required
            />
          </OCol>
          <OCol>
            <ORFieldInput
              tag="text"
              label="Código"
              name="code"
              id="code"
              required
            />
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
