import { env } from "utils/environments";

export const bankinghubEndpoints = {
  statement: {
    accounts: `${env.API_BANKINGHUB}/api/v3/Statement/208/accounts`,
    balance: (account: string | number, agency: string | number) =>
      `${env.API_BANKINGHUB}/api/v3/Statement/208/${account}/${agency}/balance`,
    movement: (account: string | number, agency: string | number) =>
      `${env.API_BANKINGHUB}/api/v3/Statement/208/${account}/${agency}/movement`,
    file: (account: string, agency: string, file: string) =>
      `${env.API_BANKINGHUB}/api/v3/Statement/208/${account}/${agency}/${file}`,
  },
  balanceYield: {
    balanceYield: (accountNumber: string, agency: number) =>
      `${env.API_BANKINGHUB}/api/v3/balanceYieldsStatus/${agency}/${accountNumber}`,
    fullYield: (balanceYieldId: string): string =>
      `${env.API_BANKINGHUB}/api/v3/balanceYields/${balanceYieldId}/fullYieldStatement`,
  },
  banking: {
    addBankAccount: `${env.API_BANKINGHUB}/Contact`,
  },
  corporateTax: {
    corporateTax: `${env.API_BANKINGHUB}/api/v3/CorporateTax`,
  },
  bankslip: {
    payments: `${env.API_BANKINGHUB}/api/v3/Bankslip/payments`,
  },
  overdrafts: {
    limits: `${env.API_BANKINGHUB}/api/v3/overdrafts/limits`,
    analysis: {
      document: () => `${env.API_BANKINGHUB}/api/v3/overdrafts/analysis`,
    },
    owner: {
      installmentPlans: (ownerId: string) =>
        `${env.API_BANKINGHUB}/api/v1/overdrafts/owner/${ownerId}/installmentPlans`,
    },
  },
  payment: `${env.API_BANKINGHUB}/api/v3/Payment`,
  cards: {
    endpoint: `${env.API_BANKINGHUB}/api/v1/Cards`,
    accounts: {
      endpoint: `${env.API_BANKINGHUB}/api/v1/Cards/accounts`,
      proposals: (accountId: string) =>
        `${env.API_BANKINGHUB}/api/v1/Cards/accounts/${accountId}/proposals`,
      invoices: (accountId: string) =>
        `${env.API_BANKINGHUB}/api/v1/Cards/accounts/${accountId}/invoices`,
    },
    offers: {
      endpoint: `${env.API_BANKINGHUB}/api/v1/Cards/offers`,
      byId: {
        guarantors: (offerId: string) =>
          `${env.API_BANKINGHUB}/api/v1/Cards/offers/${offerId}/guarantors`,
      },
    },
    owners: {
      endpoint: `${env.API_BANKINGHUB}/api/v1/Cards/owners`,
      byId: {
        addresses: (ownerId: string) =>
          `${env.API_BANKINGHUB}/api/v1/Cards/owners/${ownerId}/addresses`,
        address: {
          byId: (ownerId: string, addressId: string) =>
            `${env.API_BANKINGHUB}/api/v1/Cards/owners/${ownerId}/addresses/${addressId}`,
        },
      },
    },
    companies: {
      users: `${env.API_BANKINGHUB}/api/v1/Cards/companies/users`,
    },
    debitCardOwners: `${env.API_BANKINGHUB}/api/v1/Cards/debitCardOwners`,
    contestations: `${env.API_BANKINGHUB}/api/v1/Cards/contestations`,
    dollarPrice: `${env.API_BANKINGHUB}/api/v1/Cards/dollarPrice`,
    internal: {
      accounts: {
        timelines: (accountId: string) =>
          `${env.API_BANKINGHUB}/api/v1/Cards/internal/accounts/${accountId}/timelines`,
      },
      walletProvisions: {
        byCardId: (cardId: string) =>
          `${env.API_BANKINGHUB}/api/v1/Cards/internal/${cardId}/walletProvisions`,
      },
    },
    trackings: (cardId: string, trackingId: string) =>
      `${env.API_BANKINGHUB}/api/v1/Cards/${cardId}/trackings/${trackingId}`,
    wallets: {
      requests: `${env.API_BANKINGHUB}/api/v1/Cards/wallets/requests`,
    },
    payments: {
      byAccountId: (accountId: string) =>
        `${env.API_BANKINGHUB}/api/v1/Cards/payments/${accountId}`,
      installments: {
        byId: {
          cancel: (installmentId: string) =>
            `${env.API_BANKINGHUB}/api/v1/Cards/payments/installments/${installmentId}`,
          pay: (installmentId: string) =>
            `${env.API_BANKINGHUB}/api/v1/Cards/payments/installments/${installmentId}/pay`,
        },
      },
    },
  },
};
