import { OIcon } from "@maestro/react";
import { useLinkNewTabHandler } from "hooks/link-new-tab-handler";
import { useTranslation } from "react-i18next";
import { useLinkClickHandler } from "react-router-dom";
import { GoBackLink } from "./go-back.styles";

interface GoBackProps {
  link: string;
  onClick?: () => void;
}

export const GoBack = ({ link, onClick }: GoBackProps) => {
  const handler = useLinkClickHandler(link);
  const { t } = useTranslation("menu", { keyPrefix: "goBack" });

  return (
    <GoBackLink
      href={link}
      onClick={(evt) => {
        if (onClick) {
          evt.preventDefault();
          evt.stopPropagation();
          onClick();

          return;
        }
        handler(evt);
      }}
      onAuxClick={useLinkNewTabHandler(link)}
    >
      <OIcon category="orq" icon="orq-chevron-left" size="md" type="info" />
      {t("iconGoBack")}
    </GoBackLink>
  );
};
