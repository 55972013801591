export const cartoesRouteParams = {
  offerId: "offerId",
  taxId: "taxId",
  offerGuarantorUuid: "offerGuarantorUuid",
  creditCardPaymentId: "creditCardPaymentId",
} as const;

type CartoesParams = Record<keyof typeof cartoesRouteParams, string>;

export type CartoesProdutoMotorDeOfertasPropostasDeOfertaByOfferIdByTaxIdParams =
  Pick<CartoesParams, "offerId" | "taxId">;

export type CartoesClienteByOfferGuarantorUuidRouteParams = Pick<
  CartoesParams,
  "offerGuarantorUuid"
>;
