import { OButton } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { contractSignersGrid } from "./contract-signer.grid";

export const ContractSignerPage = () => {
  const gridRef = useGridRef();
  return (
    <GridTemplate
      pageTitle={<PageTitle title="Configurações de linhas de crédito" />}
      actions={
        <OButton
          onClick={() => {
            gridRef.current?.instance.addRow();
          }}
          outline
        >
          Adicionar
        </OButton>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
    >
      <ODataGridGenerator gridRef={gridRef} grid={contractSignersGrid} />
    </GridTemplate>
  );
};
