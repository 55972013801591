import {
  dataSourceODataStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RouterButton } from "components/router-button";
import { useGridRef } from "hooks/grid-ref";
import { useTranslation } from "react-i18next";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { endpoints } from "services/endpoints";
import { SacadosCompaniesViewValueItem } from "services/quickfin/models";
import { GridTemplate } from "templates/grid-template";
import { grid } from "./withdraw.grid";

const dataSource = dataSourceODataStoreGenerator<SacadosCompaniesViewValueItem>(
  endpoints.quickfin.odata.sacadosCompaniesView,
);

export const Withdraw = () => {
  const { t } = useTranslation("receivables", { keyPrefix: "drawee" });
  const { routes } = corporateRouter;
  const gridRef = useGridRef();

  return (
    <GridTemplate
      pageTitle={
        <PageTitle title={t("title")} description={t("description")} />
      }
      actions={
        <RouterButton
          dataAction="sacados:botao:adicionar"
          dataLabel="adicionar"
          role={roles.recebiveis.product.withdrawAddPage.role}
          href={routes.recebiveis.product.withdraw.add.path}
        >
          {t("grid.button")}
        </RouterButton>
      }
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={grid(t)}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
