import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import i18n from "../../../i18n";
import { OperationDetails } from "../../antecipacao-de-cartoes/pages/produto/operacoes/empresa/[companyId]/operacao/[operationId]/detalhes";
import {
  Approvals,
  Canceling,
  CommercialStrategies,
  FillPendingComplementsPage,
  PendingComplementsPage,
  Reprocess,
} from "../pages";
import { CustomerSignatures } from "../pages/cliente/assinaturas";
import { NewSignatureRule } from "../pages/cliente/assinaturas/criar";
import { AddSignatureGroup } from "../pages/cliente/assinaturas/[ruleId]/adicionar";
import { SignatureGroupDetails } from "../pages/cliente/assinaturas/[ruleId]/[groupId]/detalhes";
import { EditSignatureGroup } from "../pages/cliente/assinaturas/[ruleId]/[groupId]/editar";
import { CompanyCedentes } from "../pages/cliente/cedentes";
import { CedenteDetails } from "../pages/cliente/cedentes/detalhes";
import { UploadCedentes } from "../pages/cliente/cedentes/importar";
import { EstrategiaComercialPage } from "../pages/cliente/estrategia-comercial";
import { EstrategiaComercialRelationship } from "../pages/cliente/estrategia-comercial/[estrategiaId]/cadastro-relacionamento";
import { AddCompanyEstrategia } from "../pages/cliente/estrategia-comercial/[estrategiaId]/estrategia/adicionar";
import { CompanyEstrategiaDetails } from "../pages/cliente/estrategia-comercial/[estrategiaId]/estrategia/detalhes";
import { TaxasCalculator } from "../pages/cliente/estrategia-comercial/[estrategiaId]/taxas/calculadora";
import { ConfigureTaxas } from "../pages/cliente/estrategia-comercial/[estrategiaId]/taxas/configurar";
import { ViewTaxas } from "../pages/cliente/estrategia-comercial/[estrategiaId]/taxas/visualizar";
import { FeesSacado } from "../pages/cliente/fees-sacado";
import { RegisterFeesSacado } from "../pages/cliente/fees-sacado/cadastrar-fees";
import { CompanyInfo } from "../pages/cliente/informacoes";
import { BatchUploadReceivables } from "../pages/cliente/recebiveis";
import { CompanySacados } from "../pages/cliente/sacados";
import { SacadoDetails } from "../pages/cliente/sacados/detalhes";
import { UploadSacados } from "../pages/cliente/sacados/importar";
import { CompanyContractTemplates } from "../pages/cliente/templates-contrato";
import { AddCompanyContractTemplate } from "../pages/cliente/templates-contrato/adicionar";
import { EditCompanyContractTemplate } from "../pages/cliente/templates-contrato/[templateId]";
import { CalendarsPage } from "../pages/produto/calendarios";
import { AddCalendarPage } from "../pages/produto/calendarios/adicionar";
import { CalendarDetailsPage } from "../pages/produto/calendarios/[calendarId]";
import { Assignors } from "../pages/produto/cedentes";
import { RateCurve } from "../pages/produto/curva-taxas";
import { AddRateCurve } from "../pages/produto/curva-taxas/adicionar";
import { DashboardOnboardingsPage } from "../pages/produto/dashboard-onboardings/dashboard-onboardings.page";
import { DashboardOnboardingPage } from "../pages/produto/dashboard-onboardings/[cedenteId]/[cedenteType]/dashboard-onboarding.page";
import { SettlementStrategies } from "../pages/produto/estrategias-de-liquidacao";
import { AddSettlementStrategy } from "../pages/produto/estrategias-de-liquidacao/adicionar";
import { EditSettlementStrategy } from "../pages/produto/estrategias-de-liquidacao/editar";
import { OnboardingStrategies } from "../pages/produto/estrategias-de-onboarding";
import { AddOnboardingStrategies } from "../pages/produto/estrategias-de-onboarding/adicionar";
import { EditOnboardingStrategies } from "../pages/produto/estrategias-de-onboarding/editar";
import { TCOStrategies } from "../pages/produto/estrategias-de-tco";
import { AddTCOStrategies } from "../pages/produto/estrategias-de-tco/adicionar";
import { EditTCOStrategies } from "../pages/produto/estrategias-de-tco/editar";
import { RestrictionsList } from "../pages/produto/lista-de-restricoes";
import { AddRestrictionsList } from "../pages/produto/lista-de-restricoes/adicionar";
import { Assignments } from "../pages/produto/operacoes/assignments";
import { AssignmentDetails } from "../pages/produto/operacoes/assignments/details";
import { Settlements } from "../pages/produto/operacoes/settlements";
import { SettlementDetails } from "../pages/produto/operacoes/settlements/details";
import { Operations } from "../pages/produto/operacoes/trades";
import { OverduePositions } from "../pages/produto/posicoes-em-atraso";
import { Relationships } from "../pages/produto/relacionamentos";
import { BatchReportsRequested } from "../pages/produto/relatorios-em-lote/relatorios-solicitados";
import { BatchRequestForReports } from "../pages/produto/relatorios-em-lote/solicitacao-de-relatorios";
import { Withdraw } from "../pages/produto/sacados";
import { AddWithdraw } from "../pages/produto/sacados/adicionar";
import { AddContractTemplate } from "../pages/produto/templates-contrato/adicionar/add-contract-template.page";
import { ContractTemplatesPage } from "../pages/produto/templates-contrato/contract-templates.page";
import { EditContractTemplate } from "../pages/produto/templates-contrato/[templateId]/edit-contract-template.page";
import { EstrategiaTemplates } from "../pages/produto/templates-estrategia";
import { EstrategiaTemplatesRelationship } from "../pages/produto/templates-estrategia/[estrategiaId]/cadastro-relacionamento";
import { AddEstrategiaTemplate } from "../pages/produto/templates-estrategia/[estrategiaId]/estrategia/adicionar";
import { EstrategiaTemplateDetails } from "../pages/produto/templates-estrategia/[estrategiaId]/estrategia/detalhes";
import { EditTaxas } from "../pages/produto/templates-estrategia/[estrategiaId]/taxas";
import { Schedules } from "../pages/produto/titulos/agendamentos";
import { TitleTradesCorrection } from "../pages/produto/titulos/correcao-trades";
import { TitleImportCorrections } from "../pages/produto/titulos/correcao-trades/importar-correcoes";
import { TitleImportDetails } from "../pages/produto/titulos/detalhe-importacao";
import { AdditionsDetails } from "../pages/produto/titulos/detalhes/aditamentos";
import { AdditionDetail } from "../pages/produto/titulos/detalhes/aditamentos/detalhe/detail.page";
import { EventDetails } from "../pages/produto/titulos/detalhes/eventos";
import { GroupDetails } from "../pages/produto/titulos/detalhes/grupo";
import { StateMachineDetails } from "../pages/produto/titulos/detalhes/maquina-estados";
import { ReceivableDetails } from "../pages/produto/titulos/detalhes/recebivel";
import { TradeDetails } from "../pages/produto/titulos/detalhes/trade";
import { BatchUploadBorderos } from "../pages/produto/titulos/entuba";
import { TitleImportedBatches } from "../pages/produto/titulos/lotes-importados";
import { TitleNoteNote } from "../pages/produto/titulos/nota-a-nota";
import { TitleNoteNoteLegacy } from "../pages/produto/titulos/nota-a-nota-legacy";
import { recebiveisRoles } from "../roles/recebiveis.roles";
import { recebiveisRouteParams } from "./recebiveis.route-params";

const t = i18n.getFixedT(null, "receivables", "routes");

export const recebiveisRoutes = {
  customer: {
    name: "",
    path: "/recebiveis/cliente",
    element: <RedirectWithRoles />,
    children: {
      info: {
        i18nKey: "receivables:routes.customer.information",
        name: t("customer.information"),
        path: `/recebiveis/cliente/informacoes`,
        element: <CompanyInfo />,
        role: recebiveisRoles.customer.infoPage.role,
      },
      uploadReceivables: {
        i18nKey: "receivables:routes.customer.receivables",
        name: t("customer.receivables"),
        path: `/recebiveis/cliente/recebiveis`,
        element: <BatchUploadReceivables />,
        role: recebiveisRoles.customer.uploadReceivablesPage.role,
      },
      cedentes: {
        i18nKey: "receivables:routes.customer.payees",
        name: t("customer.payees"),
        path: `/recebiveis/cliente/cedentes`,
        element: <CompanyCedentes />,
        role: recebiveisRoles.customer.cedentesPage.role,
        children: {
          addCedente: {
            i18nKey: "receivables:routes.customer.addPayee",
            name: t("customer.addPayee"),
            path: `/recebiveis/cliente/cedentes/adicionar`,
            element: <CedenteDetails />,
            role: recebiveisRoles.customer.cedentesAddCedentePage.role,
          },
          details: {
            i18nKey: "receivables:routes.customer.payeeDetails",
            name: t("customer.payeeDetails"),
            path: `/recebiveis/cliente/cedentes/detalhes/:${recebiveisRouteParams.cedenteId}/:${recebiveisRouteParams.strategyId}`,
            element: <CedenteDetails />,
            role: recebiveisRoles.customer.cedentesDetailsPage.role,
          },
          batchImport: {
            i18nKey: "receivables:routes.customer.payeeBatchImporting",
            name: t("customer.payeeBatchImporting"),
            path: `/recebiveis/cliente/cedentes/importar`,
            element: <UploadCedentes />,
            role: recebiveisRoles.customer.cedentesBatchImportPage.role,
          },
        },
      },
      sacados: {
        i18nKey: "receivables:routes.customer.payers",
        name: t("customer.payers"),
        path: `/recebiveis/cliente/sacados`,
        element: <CompanySacados />,
        role: recebiveisRoles.customer.sacadosPage.role,
        children: {
          addSacado: {
            i18nKey: "receivables:routes.customer.addPayer",
            name: t("customer.addPayer"),
            path: `/recebiveis/cliente/sacados/adicionar`,
            element: <SacadoDetails />,
            role: recebiveisRoles.customer.sacadosAddSacadoPage.role,
          },
          details: {
            i18nKey: "receivables:routes.customer.payerDetails",
            name: t("customer.payerDetails"),
            path: `/recebiveis/cliente/sacados/detalhes/:${recebiveisRouteParams.sacadoId}/:${recebiveisRouteParams.strategyId}`,
            element: <SacadoDetails />,
            role: recebiveisRoles.customer.sacadosDetailsPage.role,
          },
          batchImport: {
            i18nKey: "receivables:routes.customer.payerBatchImporting",
            name: t("customer.payerBatchImporting"),
            path: `/recebiveis/cliente/sacados/importar`,
            element: <UploadSacados />,
            role: recebiveisRoles.customer.sacadosBatchImportPage.role,
          },
        },
      },
      estrategiaComercial: {
        i18nKey: "receivables:routes.customer.comercialStrategy",
        name: t("customer.comercialStrategy"),
        path: `/recebiveis/cliente/estrategia-comercial`,
        element: <EstrategiaComercialPage />,
        role: recebiveisRoles.customer.estrategiaComercialPage.role,
        children: {
          addEstrategia: {
            i18nKey: "receivables:routes.customer.addCommercialStrategy",
            name: t("customer.addCommercialStrategy"),
            path: `/recebiveis/cliente/estrategia-comercial/adicionar`,
            element: <AddCompanyEstrategia />,
            role: recebiveisRoles.customer.estrategiaComercialAddEstrategiaPage
              .role,
          },
          byEstrategiaId: {
            i18nKey: "receivables:routes.customer.byCommercialStrategy",
            name: t("customer.byCommercialStrategy"),
            path: `/recebiveis/cliente/estrategia-comercial/:${recebiveisRouteParams.estrategiaId}`,
            children: {
              taxas: {
                i18nKey: "receivables:routes.customer.strategyTaxes",
                name: t("customer.strategyTaxes"),
                path: `/recebiveis/cliente/estrategia-comercial/:${recebiveisRouteParams.estrategiaId}/taxas`,
                showChildren: true,
                children: {
                  view: {
                    i18nKey: "receivables:routes.customer.viewStrategyTaxes",
                    name: t("customer.viewStrategyTaxes"),
                    path: `/recebiveis/cliente/estrategia-comercial/:${recebiveisRouteParams.estrategiaId}/taxas/visualizar`,
                    element: <ViewTaxas />,
                    role: recebiveisRoles.customer
                      .estrategiaComercialByEstrategiaIdTaxasViewPage.role,
                  },
                  configure: {
                    i18nKey: "receivables:routes.customer.setStrategyTaxes",
                    name: t("customer.setStrategyTaxes"),
                    path: `/recebiveis/cliente/estrategia-comercial/:${recebiveisRouteParams.estrategiaId}/taxas/configurar`,
                    element: <ConfigureTaxas />,
                    role: recebiveisRoles.customer
                      .estrategiaComercialByEstrategiaIdTaxasConfigurePage.role,
                  },
                  calculator: {
                    i18nKey: "receivables:routes.customer.strategyCalculator",
                    name: t("customer.strategyCalculator"),
                    path: `/recebiveis/cliente/estrategia-comercial/:${recebiveisRouteParams.estrategiaId}/taxas/calculadora`,
                    element: <TaxasCalculator />,
                    role: recebiveisRoles.customer
                      .estrategiaComercialByEstrategiaIdTaxasCalculatorPage
                      .role,
                  },
                },
              },
              onboardingRelationshipSettings: {
                i18nKey: "receivables:routes.customer.relationship",
                name: t("customer.relationship"),
                path: `/recebiveis/cliente/estrategia-comercial/:${recebiveisRouteParams.estrategiaId}/cadastro-relacionamento`,
                element: <EstrategiaComercialRelationship />,
                role: recebiveisRoles.customer
                  .estrategiaComercialByEstrategiaIdOnboardingRelationshipSettingsPage
                  .role,
              },
              estrategiaDetails: {
                i18nKey: "receivables:routes.customer.strategyDetails",
                name: t("customer.strategyDetails"),
                path: `/recebiveis/cliente/estrategia-comercial/:${recebiveisRouteParams.estrategiaId}/detalhes`,
                element: <CompanyEstrategiaDetails />,
                role: recebiveisRoles.customer
                  .estrategiaComercialByEstrategiaIdEstrategiaDetailsPage.role,
              },
            },
          },
        },
      },
      contractTemplates: {
        i18nKey: "receivables:routes.customer.contractTemplates",
        name: t("customer.contractTemplates"),
        path: "/recebiveis/cliente/templates-contrato",
        element: <CompanyContractTemplates />,
        role: recebiveisRoles.customer.contractTemplatesPage.role,
        children: {
          add: {
            i18nKey: "receivables:routes.customer.addContractTemplates",
            name: t("customer.addContractTemplates"),
            path: `/recebiveis/cliente/templates-contrato/adicionar`,
            element: <AddCompanyContractTemplate />,
            role: recebiveisRoles.customer.contractTemplatesAddPage.role,
          },
          byTemplateId: {
            i18nKey: "receivables:routes.customer.editContractTemplates",
            name: t("customer.editContractTemplates"),
            path: `/recebiveis/cliente/templates-contrato/:${recebiveisRouteParams.templateId}`,
            element: <EditCompanyContractTemplate />,
            role: recebiveisRoles.customer.contractTemplatesByTemplateIdPage
              .role,
          },
        },
      },
      signatures: {
        i18nKey: "receivables:routes.customer.signatures",
        name: t("customer.signatures"),
        path: `/recebiveis/cliente/assinaturas`,
        element: <CustomerSignatures />,
        role: recebiveisRoles.customer.signaturesPage.role,
        children: {
          newRule: {
            i18nKey: "receivables:routes.customer.addSignatures",
            name: t("customer.addSignatures"),
            path: `/recebiveis/cliente/assinaturas/criar`,
            element: <NewSignatureRule />,
            role: recebiveisRoles.customer.signaturesNewRulePage.role,
          },
          byRuleId: {
            name: "",
            path: `/recebiveis/cliente/assinaturas/:${recebiveisRouteParams.ruleId}`,
            children: {
              add: {
                i18nKey: "receivables:routes.customer.addSignaturesGroup",
                name: t("customer.addSignaturesGroup"),
                path: `/recebiveis/cliente/assinaturas/:${recebiveisRouteParams.ruleId}/adicionar`,
                element: <AddSignatureGroup />,
                role: recebiveisRoles.customer.signaturesByRuleIdAddPage.role,
              },
              byGroupId: {
                i18nKey: "receivables:routes.customer.signaturesGroup",
                name: t("customer.signaturesGroup"),
                path: `/recebiveis/cliente/assinaturas/:${recebiveisRouteParams.ruleId}/:${recebiveisRouteParams.groupId}`,
                children: {
                  details: {
                    i18nKey: "receivables:routes.customer.signaturesDetails",
                    name: t("customer.signaturesDetails"),
                    path: `/recebiveis/cliente/assinaturas/:${recebiveisRouteParams.ruleId}/:${recebiveisRouteParams.groupId}/detalhes`,
                    element: <SignatureGroupDetails />,
                    role: recebiveisRoles.customer
                      .signaturesByRuleIdByGroupIdDetailsPage.role,
                  },
                  edit: {
                    i18nKey: "receivables:routes.customer.editSignaturesGroup",
                    name: t("customer.editSignaturesGroup"),
                    path: `/recebiveis/cliente/assinaturas/:${recebiveisRouteParams.ruleId}/:${recebiveisRouteParams.groupId}/editar`,
                    element: <EditSignatureGroup />,
                    role: recebiveisRoles.customer
                      .signaturesByRuleIdByGroupIdEditPage.role,
                  },
                },
              },
            },
          },
        },
      },
      feeSacado: {
        i18nKey: "receivables:routes.customer.fees",
        name: t("customer.fees"),
        path: "/recebiveis/cliente/fees",
        role: recebiveisRoles.customer.feeSacadoPage.role,
        element: <FeesSacado />,
        children: {
          feesRegister: {
            i18nKey: "receivables:routes.customer.addFees",
            name: t("customer.addFees"),
            path: "/recebiveis/cliente/fees/adicionar",
            role: recebiveisRoles.customer.feesRegisterPage.role,
            element: <RegisterFeesSacado />,
          },
        },
      },
    },
  },
  product: {
    name: "",
    path: "/recebiveis/produto",
    element: <RedirectWithRoles />,
    children: {
      approvals: {
        i18nKey: "receivables:routes.product.approvals",
        name: t("product.approvals"),
        path: "/recebiveis/produto/aprovacoes",
        role: recebiveisRoles.product.approvalsPage.role,
        element: <Approvals />,
      },
      contractsPendingComplement: {
        i18nKey: "receivables:routes.product.pendingContracts",
        name: t("product.pendingContracts"),
        path: "/recebiveis/produto/contratos-complemento-pendente",
        role: recebiveisRoles.product.complementContractPage.role,
        element: <PendingComplementsPage />,
        children: {
          fillComplements: {
            i18nKey: "receivables:routes.product.fillout",
            name: t("product.fillout"),
            role: recebiveisRoles.product.fillComplementContractPage.role,
            path: `/recebiveis/produto/contratos-complemento-pendente/:${recebiveisRouteParams.sacadoId}/:${recebiveisRouteParams.cedenteId}/preencher/:${recebiveisRouteParams.contractId}`,
            element: <FillPendingComplementsPage />,
          },
        },
      },
      cancelling: {
        i18nKey: "receivables:routes.product.withdraw",
        name: t("product.withdraw"),
        path: "/recebiveis/produto/cancelamento",
        role: recebiveisRoles.product.cancellingPage.role,
        element: <Canceling />,
      },
      reprocess: {
        i18nKey: "receivables:routes.product.reprocess",
        name: t("product.reprocess"),
        path: "/recebiveis/produto/reprocessamento",
        role: recebiveisRoles.product.cancellingPage.role,
        element: <Reprocess />,
      },
      rateCurve: {
        i18nKey: "receivables:routes.product.feesCurve",
        name: t("product.feesCurve"),
        path: "/recebiveis/produto/curva-taxas",
        role: recebiveisRoles.product.rateCurvePage.role,
        element: <RateCurve />,
        children: {
          add: {
            i18nKey: "receivables:routes.product.addFeesCurve",
            name: t("product.addFeesCurve"),
            role: recebiveisRoles.product.rateCurveAddPage.role,
            path: "/recebiveis/produto/curva-taxas/adicionar",
            element: <AddRateCurve />,
          },
        },
      },
      operations: {
        i18nKey: "receivables:routes.product.operations",
        name: t("product.operations"),
        path: "/recebiveis/produto/operacoes",
        hasSubItems: true,
        children: {
          trades: {
            i18nKey: "receivables:routes.product.tradeOperations",
            name: t("product.tradeOperations"),
            role: recebiveisRoles.product.operationsPage.role,
            path: "/recebiveis/produto/operacoes/trades",
            element: <Operations />,
            showChildren: false,
            children: {
              detalhes: {
                i18nKey: "receivables:routes.product.operationDetails",
                name: t("product.operationDetails"),
                role: recebiveisRoles.product.operationsDetalhesPage.role,
                path: `/recebiveis/produto/operacoes/trades/empresa/:${recebiveisRouteParams.companyId}/operacao/:${recebiveisRouteParams.operationId}/detalhes`,
                element: <OperationDetails />,
              },
            },
          },
          assignments: {
            i18nKey: "receivables:routes.product.assignments",
            name: t("product.assignments"),
            role: recebiveisRoles.product.operationsPage.role,
            path: "/recebiveis/produto/operacoes/cessoes",
            element: <Assignments />,
            showChildren: false,
            children: {
              detalhes: {
                i18nKey: "receivables:routes.product.assignmentsDetails",
                name: t("product.assignmentsDetails"),
                role: recebiveisRoles.product.operationsDetalhesPage.role,
                path: `/recebiveis/produto/operacoes/cessoes/empresa/:${recebiveisRouteParams.companyId}/operacao/:${recebiveisRouteParams.operationId}/detalhes`,
                element: <AssignmentDetails />,
              },
            },
          },
          settlements: {
            i18nKey: "receivables:routes.product.settlement",
            name: t("product.settlement"),
            role: recebiveisRoles.product.settlementsPage.role,
            path: "/recebiveis/produto/operacoes/liquidacoes",
            element: <Settlements />,
            showChildren: false,
            children: {
              details: {
                i18nKey: "receivables:routes.product.settlementDetails",
                name: t("product.settlementDetails"),
                role: recebiveisRoles.product.settlementDetailsPage.role,
                path: `/recebiveis/produto/operacoes/liquidacoes/:${recebiveisRouteParams.settlementId}/detalhes`,
                element: <SettlementDetails />,
              },
            },
          },
        },
      },
      titles: {
        i18nKey: "receivables:routes.product.invoices",
        name: t("product.invoices"),
        path: "/recebiveis/produto/titulo",
        hasSubItems: true,
        children: {
          upload: {
            i18nKey: "receivables:routes.product.importingInvoices",
            name: t("product.importingInvoices"),
            path: `/recebiveis/produto/titulo/entuba`,
            role: recebiveisRoles.product.titlesUploadPage.role,
            element: <BatchUploadBorderos />,
          },
          schedules: {
            i18nKey: "receivables:routes.product.invoiceSchedules",
            name: t("product.invoiceSchedules"),
            path: `/recebiveis/produto/titulo/agendamentos`,
            role: recebiveisRoles.product.titlesSchedulesPage.role,
            element: <Schedules />,
          },
          importedBatches: {
            i18nKey: "receivables:routes.product.importedBatch",
            name: t("product.importedBatch"),
            path: `/recebiveis/produto/titulo/lotes-importados`,
            role: recebiveisRoles.product.titlesImportedBatchesPage.role,
            element: <TitleImportedBatches />,
            children: {
              details: {
                i18nKey: "receivables:routes.product.importingDetails",
                name: t("product.importingDetails"),
                path: `/recebiveis/produto/titulo/lotes-importados/:${recebiveisRouteParams.batcheId}`,
                role: recebiveisRoles.product.titlesImportedBatchesDetailsPage
                  .role,
                element: <TitleImportDetails />,
              },
            },
          },
          noteNote: {
            i18nKey: "receivables:routes.product.invoiceByInvoice",
            name: t("product.invoiceByInvoice"),
            path: `/recebiveis/produto/titulo/nota-nota`,
            role: recebiveisRoles.product.titlesNoteNotePage.role,
            element: <TitleNoteNote />,
            children: {
              details: {
                i18nKey: "receivables:routes.product.invoiceByInvoiceDetails",
                name: t("product.invoiceByInvoiceDetails"),
                path: `/recebiveis/produto/titulo/nota-nota/:${recebiveisRouteParams.receivableId}`,
                showChildren: true,
                children: {
                  receivable: {
                    i18nKey: "receivables:routes.product.invoice",
                    name: t("product.invoice"),
                    path: `/recebiveis/produto/titulo/nota-nota/:${recebiveisRouteParams.receivableId}/recebivel`,
                    role: recebiveisRoles.product
                      .titlesNoteNoteDetailsReceivablePage.role,
                    element: <ReceivableDetails />,
                  },
                  stateMachine: {
                    i18nKey: "receivables:routes.product.invoiceStateMachine",
                    name: t("product.invoiceStateMachine"),
                    path: `/recebiveis/produto/titulo/nota-nota/:${recebiveisRouteParams.receivableId}/maquina-estados`,
                    role: recebiveisRoles.product
                      .titlesNoteNoteDetailsStateMachinePage.role,
                    element: <StateMachineDetails />,
                  },
                  trade: {
                    i18nKey: "receivables:routes.product.invoiceTrade",
                    name: t("product.invoiceTrade"),
                    path: `/recebiveis/produto/titulo/nota-nota/:${recebiveisRouteParams.receivableId}/trade`,
                    role: recebiveisRoles.product.titlesNoteNoteDetailsTradePage
                      .role,
                    element: <TradeDetails />,
                  },
                  event: {
                    i18nKey: "receivables:routes.product.invoiceEvents",
                    name: t("product.invoiceEvents"),
                    path: `/recebiveis/produto/titulo/nota-nota/:${recebiveisRouteParams.receivableId}/evento`,
                    role: recebiveisRoles.product.titlesNoteNoteDetailsEventPage
                      .role,
                    element: <EventDetails />,
                  },
                  group: {
                    i18nKey: "receivables:routes.product.invoiceGroup",
                    name: t("product.invoiceGroup"),
                    path: `/recebiveis/produto/titulo/nota-nota/:${recebiveisRouteParams.receivableId}/grupo`,
                    role: recebiveisRoles.product.titlesNoteNoteDetailsGroupPage
                      .role,
                    element: <GroupDetails />,
                  },
                  additions: {
                    i18nKey: "receivables:routes.product.addendums",
                    name: t("product.addendums"),
                    path: `/recebiveis/produto/titulo/nota-nota/:${recebiveisRouteParams.receivableId}/aditamentos`,
                    role: recebiveisRoles.product
                      .titlesNoteNoteDetailsAdditionsPage.role,
                    element: <AdditionsDetails />,
                    children: {
                      detail: {
                        i18nKey: "receivables:routes.product.addendumsDetails",
                        name: t("product.addendumsDetails"),
                        path: `/recebiveis/produto/titulo/nota-nota/:${recebiveisRouteParams.receivableId}/aditamentos/:${recebiveisRouteParams.aditamentoId}`,
                        role: recebiveisRoles.product
                          .titlesNoteNoteDetailsAdditionsDetailPage.role,
                        element: <AdditionDetail />,
                      },
                    },
                  },
                },
              },
            },
          },
          noteNoteLegacy: {
            i18nKey: "receivables:routes.product.invoiceByInvoiceLegacy",
            name: t("product.invoiceByInvoiceLegacy"),
            path: `/recebiveis/produto/titulo/nota-nota-antiga`,
            role: recebiveisRoles.product.titlesNoteNoteLegacyPage.role,
            element: <TitleNoteNoteLegacy />,
            children: {
              details: {
                i18nKey:
                  "receivables:routes.product.invoiceByInvoiceLegacyDetails",
                name: t("product.invoiceByInvoiceLegacyDetails"),
                path: `/recebiveis/produto/titulo/nota-nota-antiga/:${recebiveisRouteParams.receivableId}`,
                showChildren: true,
                children: {
                  receivable: {
                    i18nKey:
                      "receivables:routes.product.invoiceByInvoiceLegacyOne",
                    name: t("product.invoiceByInvoiceLegacyOne"),
                    path: `/recebiveis/produto/titulo/nota-nota-antiga/:${recebiveisRouteParams.receivableId}/recebivel`,
                    role: recebiveisRoles.product
                      .titlesNoteNoteLegacyDetailsReceivablePage.role,
                    element: <ReceivableDetails />,
                  },
                  stateMachine: {
                    i18nKey:
                      "receivables:routes.product.invoiceByInvoiceLegacyStatesMachine",
                    name: t("product.invoiceByInvoiceLegacyStatesMachine"),
                    path: `/recebiveis/produto/titulo/nota-nota-antiga/:${recebiveisRouteParams.receivableId}/maquina-estados`,
                    role: recebiveisRoles.product
                      .titlesNoteNoteLegacyDetailsStateMachinePage.role,
                    element: <StateMachineDetails />,
                  },
                  trade: {
                    i18nKey:
                      "receivables:routes.product.invoiceByInvoiceLegacyTrade",
                    name: t("product.invoiceByInvoiceLegacyTrade"),
                    path: `/recebiveis/produto/titulo/nota-nota-antiga/:${recebiveisRouteParams.receivableId}/trade`,
                    role: recebiveisRoles.product
                      .titlesNoteNoteLegacyDetailsTradePage.role,
                    element: <TradeDetails />,
                  },
                  event: {
                    i18nKey:
                      "receivables:routes.product.invoiceByInvoiceLegacyEvents",
                    name: t("product.invoiceByInvoiceLegacyEvents"),
                    path: `/recebiveis/produto/titulo/nota-nota-antiga/:${recebiveisRouteParams.receivableId}/evento`,
                    role: recebiveisRoles.product
                      .titlesNoteNoteLegacyDetailsEventPage.role,
                    element: <EventDetails />,
                  },
                  group: {
                    i18nKey:
                      "receivables:routes.product.invoiceByInvoiceLegacyGroup",
                    name: t("product.invoiceByInvoiceLegacyGroup"),
                    path: `/recebiveis/produto/titulo/nota-nota-antiga/:${recebiveisRouteParams.receivableId}/grupo`,
                    role: recebiveisRoles.product
                      .titlesNoteNoteLegacyDetailsGroupPage.role,
                    element: <GroupDetails />,
                  },
                  additions: {
                    i18nKey:
                      "receivables:routes.product.invoiceByInvoiceLegacyAddendums",
                    name: t("product.invoiceByInvoiceLegacyAddendums"),
                    path: `/recebiveis/produto/titulo/nota-nota-antiga/:${recebiveisRouteParams.receivableId}/aditamentos`,
                    role: recebiveisRoles.product
                      .titlesNoteNoteLegacyDetailsAdditionsPage.role,
                    element: <AdditionsDetails />,
                    children: {
                      detail: {
                        name: t(
                          "product.invoiceByInvoiceLegacyAddendumsDetails",
                        ),
                        path: `/recebiveis/produto/titulo/nota-nota-antiga/:${recebiveisRouteParams.receivableId}/aditamentos/:${recebiveisRouteParams.aditamentoId}`,
                        role: recebiveisRoles.product
                          .titlesNoteNoteLegacyDetailsAdditionsDetailPage.role,
                        element: <AdditionDetail />,
                      },
                    },
                  },
                },
              },
            },
          },
          tradesCorrection: {
            i18nKey: "receivables:routes.product.tradesCorrection",
            name: t("product.tradesCorrection"),
            path: `/recebiveis/produto/titulo/correcao-trades`,
            role: recebiveisRoles.product.titlesTradesCorrectionPage.role,
            element: <TitleTradesCorrection />,
            children: {
              importCorrections: {
                i18nKey: "receivables:routes.product.tradesCorrectionImporting",
                name: t("product.tradesCorrectionImporting"),
                path: `/recebiveis/produto/titulo/correcao-trades/importar-correcoes`,
                role: recebiveisRoles.product
                  .titlesTradesCorrectionImportCorrectionsPage.role,
                element: <TitleImportCorrections />,
              },
            },
          },
        },
      },
      commercialStrategies: {
        i18nKey: "receivables:routes.product.commercialStrategies",
        name: t("product.commercialStrategies"),
        path: "/recebiveis/produto/estrategias-comerciais",
        role: recebiveisRoles.product.commercialStrategiesPage.role,
        element: <CommercialStrategies />,
      },
      calendars: {
        i18nKey: "receivables:routes.product.calendar",
        name: t("product.calendar"),
        path: "/recebiveis/produto/calendarios",
        role: recebiveisRoles.product.calendarsPage.role,
        element: <CalendarsPage />,
        children: {
          add: {
            i18nKey: "receivables:routes.product.addCalendar",
            name: t("product.addCalendar"),
            role: recebiveisRoles.product.calendarsAddPage.role,
            path: "/recebiveis/produto/calendarios/adicionar",
            element: <AddCalendarPage />,
          },
          details: {
            i18nKey: "receivables:routes.product.calendarDetails",
            name: t("product.calendarDetails"),
            path: `/recebiveis/produto/calendarios/:${recebiveisRouteParams.calendarId}`,
            element: <CalendarDetailsPage />,
            role: recebiveisRoles.product.calendarsDetailsPage.role,
          },
        },
      },
      batchReports: {
        i18nKey: "receivables:routes.product.batchReports",
        name: t("product.batchReports"),
        path: "/recebiveis/produto/relatorios-em-lote",
        hasSubItems: true,
        children: {
          requestForReports: {
            i18nKey: "receivables:routes.product.batchReportsRequest",
            name: t("product.batchReportsRequest"),
            path: `/recebiveis/produto/relatorios-em-lote/solicitacao-de-relatorios`,
            role: recebiveisRoles.product.batchReportsRequestForReportsPage
              .role,
            element: <BatchRequestForReports />,
          },
          reportsRequested: {
            i18nKey: "receivables:routes.product.batchReportsRequested",
            name: t("product.batchReportsRequested"),
            path: `/recebiveis/produto/relatorios-em-lote/relatorios-solicitados`,
            role: recebiveisRoles.product.batchReportsReportsRequestedPage.role,
            element: <BatchReportsRequested />,
          },
        },
      },
      settlementStrategies: {
        i18nKey: "receivables:routes.product.settlementsStrategies",
        name: t("product.settlementsStrategies"),
        path: "/recebiveis/produto/estrategias-liquidacao",
        role: recebiveisRoles.product.settlementStrategiesPage.role,
        element: <SettlementStrategies />,
        children: {
          add: {
            i18nKey: "receivables:routes.product.addSettlementsStrategies",
            name: t("product.addSettlementsStrategies"),
            role: recebiveisRoles.product.settlementStrategiesAddPage.role,
            path: "/recebiveis/produto/estrategias-liquidacao/adicionar",
            element: <AddSettlementStrategy />,
          },
          edit: {
            i18nKey: "receivables:routes.product.editSettlementsStrategies",
            name: t("product.editSettlementsStrategies"),
            role: recebiveisRoles.product.settlementStrategiesEditPage.role,
            path: `/recebiveis/produto/estrategias-liquidacao/editar/:${recebiveisRouteParams.settlementId}`,
            element: <EditSettlementStrategy />,
          },
        },
      },

      assignors: {
        i18nKey: "receivables:routes.product.payees",
        name: t("product.payees"),
        path: "/recebiveis/produto/cedentes",
        role: recebiveisRoles.product.assignorsPage.role,
        element: <Assignors />,
      },
      relationships: {
        i18nKey: "receivables:routes.product.relationships",
        name: t("product.relationships"),
        path: "/recebiveis/produto/relacionamento-risco-sacado",
        role: recebiveisRoles.product.relationshipsPage.role,
        element: <Relationships />,
      },
      dashboard: {
        i18nKey: "receivables:routes.product.onboardingDashboard",
        name: t("product.onboardingDashboard"),
        path: "/recebiveis/produto/dashboard-onboardings",
        element: <DashboardOnboardingsPage />,
        role: recebiveisRoles.product.dashboardPage.role,
        children: {
          onboarding: {
            i18nKey: "receivables:routes.product.onboardingDashboardSCF",
            name: t("product.onboardingDashboardSCF"),
            path: `/recebiveis/produto/dashboard-onboardings/:${recebiveisRouteParams.cedenteId}`,
            children: {
              details: {
                i18nKey:
                  "receivables:routes.product.onboardingDashboardSCFDetails",
                name: t("product.onboardingDashboardSCFDetails"),
                path: `/recebiveis/produto/dashboard-onboardings/:${recebiveisRouteParams.cedenteId}/:${recebiveisRouteParams.cedenteType}`,
                element: <DashboardOnboardingPage />,
                role: recebiveisRoles.product.dashboardOnboardingDetailsPage
                  .role,
              },
            },
          },
        },
      },
      withdraw: {
        i18nKey: "receivables:routes.product.payers",
        name: t("product.payers"),
        path: "/recebiveis/produto/sacados",
        role: recebiveisRoles.product.withdrawPage.role,
        element: <Withdraw />,
        children: {
          add: {
            i18nKey: "receivables:routes.product.addPayers",
            name: t("product.addPayers"),
            role: recebiveisRoles.product.withdrawAddPage.role,
            path: "/recebiveis/produto/sacados/adicionar",
            element: <AddWithdraw />,
          },
        },
      },
      overduePositions: {
        i18nKey: "receivables:routes.product.delayedPositions",
        name: t("product.delayedPositions"),
        path: "/recebiveis/produto/posicoes-em-atraso",
        role: recebiveisRoles.product.overduePositionsPage.role,
        element: <OverduePositions />,
      },
      contractTemplates: {
        i18nKey: "receivables:routes.product.contractTemplates",
        name: t("product.contractTemplates"),
        path: "/recebiveis/produto/templates-contrato",
        element: <ContractTemplatesPage />,
        role: recebiveisRoles.product.contractTemplatesPage.role,
        children: {
          add: {
            i18nKey: "receivables:routes.product.addContractTemplates",
            name: t("product.addContractTemplates"),
            path: "/recebiveis/produto/templates-contrato/adicionar",
            element: <AddContractTemplate />,
            role: recebiveisRoles.product.contractTemplatesAddPage.role,
          },
          edit: {
            i18nKey: "receivables:routes.product.editContractTemplates",
            name: t("product.editContractTemplates"),
            path: `/recebiveis/produto/templates-contrato/:${recebiveisRouteParams.templateId}`,
            element: <EditContractTemplate />,
            role: recebiveisRoles.product.contractTemplatesEditPage.role,
          },
        },
      },
      estrategiaTemplates: {
        i18nKey: "receivables:routes.product.strategyTemplates",
        name: t("product.strategyTemplates"),
        path: "/recebiveis/produto/templates-estrategia",
        element: <EstrategiaTemplates />,
        role: recebiveisRoles.product.estrategiaTemplatesPage.role,
        children: {
          addEstrategia: {
            i18nKey: "receivables:routes.product.addStrategyTemplates",
            name: t("product.addStrategyTemplates"),
            path: `/recebiveis/produto/templates-estrategia/adicionar`,
            element: <AddEstrategiaTemplate />,
            role: recebiveisRoles.product.estrategiaTemplatesAddEstrategiaPage
              .role,
          },
          byEstrategiaId: {
            i18nKey: "receivables:routes.product.strategyTemplatesOne",
            name: t("product.strategyTemplatesOne"),
            path: `/recebiveis/produto/templates-estrategia/:${recebiveisRouteParams.estrategiaId}`,
            children: {
              taxas: {
                i18nKey: "receivables:routes.product.strategyTemplatesOneFees",
                name: t("product.strategyTemplatesOneFees"),
                path: `/recebiveis/produto/templates-estrategia/:${recebiveisRouteParams.estrategiaId}/taxas`,
                element: <EditTaxas />,
                role: recebiveisRoles.product
                  .estrategiaTemplatesByEstrategiaIdTaxasPage.role,
              },
              onboardingRelationshipSettings: {
                i18nKey:
                  "receivables:routes.product.strategyTemplatesOneRelationship",
                name: t("product.strategyTemplatesOneRelationship"),
                path: `/recebiveis/produto/templates-estrategia/:${recebiveisRouteParams.estrategiaId}/cadastro-relacionamento`,
                element: <EstrategiaTemplatesRelationship />,
                role: recebiveisRoles.product
                  .estrategiaTemplatesByEstrategiaIdOnboardingRelationshipSettingsPage
                  .role,
              },
              estrategiaDetails: {
                i18nKey:
                  "receivables:routes.product.strategyTemplatesOneDetails",
                name: t("product.strategyTemplatesOneDetails"),
                path: `/recebiveis/produto/templates-estrategia/:${recebiveisRouteParams.estrategiaId}/detalhes`,
                element: <EstrategiaTemplateDetails />,
                role: recebiveisRoles.product
                  .estrategiaTemplatesByEstrategiaIdEstrategiaDetailsPage.role,
              },
            },
          },
        },
      },
      restrictionsList: {
        i18nKey: "receivables:routes.product.restrictionList",
        name: t("product.restrictionList"),
        path: "/recebiveis/produto/lista-de-restricoes",
        role: recebiveisRoles.product.restrictionsListPage.role,
        element: <RestrictionsList />,
        children: {
          add: {
            i18nKey: "receivables:routes.product.addRestrictionList",
            name: t("product.addRestrictionList"),
            role: recebiveisRoles.product.restrictionsListAddPage.role,
            path: "/recebiveis/produto/lista-de-restricoes/adicionar",
            element: <AddRestrictionsList />,
          },
        },
      },
      onboardingStrategies: {
        i18nKey: "receivables:routes.product.onboardingStrategies",
        name: t("product.onboardingStrategies"),
        path: "/recebiveis/produto/estrategias-de-onboarding",
        role: recebiveisRoles.product.onboardingStrategiesPage.role,
        element: <OnboardingStrategies />,
        children: {
          add: {
            i18nKey: "receivables:routes.product.addOnboardingStrategies",
            name: t("product.addOnboardingStrategies"),
            role: recebiveisRoles.product.onboardingStrategiesAddPage.role,
            path: "/recebiveis/produto/estrategias-de-onboarding/adicionar",
            element: <AddOnboardingStrategies />,
          },
          edit: {
            i18nKey: "receivables:routes.product.editOnboardingStrategies",
            name: t("product.editOnboardingStrategies"),
            role: recebiveisRoles.product.onboardingStrategiesEditPage.role,
            path: `/recebiveis/produto/estrategias-de-onboarding/editar/:${recebiveisRouteParams.strategyId}`,
            element: <EditOnboardingStrategies />,
          },
        },
      },
      tcoStrategies: {
        i18nKey: "receivables:routes.product.TCOStrategies",
        name: t("product.TCOStrategies"),
        path: "/recebiveis/produto/estrategias-tco",
        role: recebiveisRoles.product.tcoStrategiesPage.role,
        element: <TCOStrategies />,
        children: {
          add: {
            i18nKey: "receivables:routes.product.addTCOStrategies",
            name: t("product.addTCOStrategies"),
            role: recebiveisRoles.product.tcoStrategiesAddPage.role,
            path: "/recebiveis/produto/estrategias-tco/adicionar",
            element: <AddTCOStrategies />,
          },
          edit: {
            i18nKey: "receivables:routes.product.editTCOStrategies",
            name: t("product.editTCOStrategies"),
            role: recebiveisRoles.product.tcoStrategiesEditPage.role,
            path: `/recebiveis/produto/estrategias-tco/editar/:${recebiveisRouteParams.strategyId}`,
            element: <EditTCOStrategies />,
          },
        },
      },
    },
  },
} as const satisfies Routes;
