/* eslint-disable filename-rules/match */
import { createRoot } from "react-dom/client";
import {
  enrichGlobalContext,
  initDatadog,
  isDatadogEnabled,
  trackDOMEvents,
  trackRequests,
  trackUser,
} from "utils/datadog";
import { App } from "./App";
import "./i18n";

if (isDatadogEnabled) {
  initDatadog();
  trackRequests();
  trackDOMEvents();
  trackUser();
  enrichGlobalContext();
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById("root")!).render(<App />);
