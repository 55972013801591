import { IType, OBadge, OButton, OIcon, OTypography } from "@maestro/react";
import { helpers, masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";
import { endpoints } from "services/endpoints";
import { invoiceStatusMap } from "../../workflow-leads.utils";

type FinancedAsset = Awaited<
  ReturnType<
    typeof service.hubCreditManager.odata.getWorkflowLeadFinancedAssets
  >
>["data"]["value"][number];

export const creditLineFinancedAssetsGrid = (workflowLeadId: string | number) =>
  ({
    datagrid: {
      noDataText: "Nenhum ativo financiado disponível",
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubCreditManager.odata.workflowLeadFinancedAssetsAdmin(
          workflowLeadId,
        ),
      ),
      columnAutoWidth: true,
    },
    columns: [
      {
        dataField: "Id",
        caption: "Id do ativo",
      },
      {
        dataField: "Type",
        caption: "Tipo",
      },
      {
        dataField: "Amount",
        caption: "Valor financiado",
        cellRender: ({ data }) => masks.currency(data.Amount, "R$"),
      },
      {
        dataField: "InvoiceId",
        caption: "Id da invoice",
      },
      {
        dataField: "InvoiceType",
        caption: "Tipo de invoice",
        cellRender: ({ data }) => data.InvoiceType || "-",
      },
      {
        dataField: "InvoiceDataOrigin",
        caption: "Origem",
      },
      {
        dataField: "InvoiceAmount",
        caption: "Valor da invoice",
        cellRender: ({ data }) =>
          data.InvoiceAmount ? masks.currency(data.InvoiceAmount, "R$") : "-",
      },
      {
        dataField: "InvoiceStatus",
        caption: "Status da invoice",
        cellRender: ({ data }) => {
          const invoiceStatus = invoiceStatusMap[data.InvoiceStatus];

          if (!invoiceStatus) return "-";

          return (
            <>
              {invoiceStatus ? (
                <OBadge
                  size="sm"
                  type={`${invoiceStatus.color}-light` as IType}
                  rounded
                >
                  <OIcon
                    category="orq"
                    icon={invoiceStatus.iconName}
                    type={invoiceStatus.color}
                    size="lg"
                  />
                  {invoiceStatus.name}
                </OBadge>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        dataField: "InvoicePayeeTaxId",
        caption: "Emitente",
        cellRender: ({ data }) => (
          <div className="d-flex flex-column">
            <OTypography size="sm">{data.InvoicePayeeName}</OTypography>
            <OTypography size="sm">
              {masks.cpfCnpj(data.InvoicePayeeTaxId)}
            </OTypography>
          </div>
        ),
      },
      {
        dataField: "InvoicePayerTaxId",
        caption: "Pagador",
        cellRender: ({ data }) => (
          <div className="d-flex flex-column">
            <OTypography size="sm">{data.InvoicePayerName}</OTypography>
            <OTypography size="sm">
              {masks.cpfCnpj(data.InvoicePayerTaxId)}
            </OTypography>
          </div>
        ),
      },
      {
        caption: "Arquivo",
        cellRender: ({ data }) =>
          !!data.InvoiceFileKey && (
            <OButton
              size="sm"
              onClick={async () => {
                const { data: response } =
                  await service.hubCreditManager.download({
                    key: data.InvoiceFileKey,
                  });

                helpers.downloadBlobFile(data.InvoiceFileName, response);
              }}
            >
              <OIcon category="orq" icon="orq-download" size="sm" />
            </OButton>
          ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<FinancedAsset>);
