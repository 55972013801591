import { ODropdownItem } from "@maestro/react";
import styled from "styled-components";

export const FlagButton = styled.div.attrs({
  className: "d-flex gap-2 cursor-pointer",
})`
  cursor: pointer;
  o-icon > i {
    transition: all 0.5s ease-in-out;
    color: var(--theme-dark);
  }
  .o-floater__anchor-element:has(.o-floater__floating-element.visible) & {
    o-icon > i {
      transform: rotateX(180deg);
      color: var(--theme-tertiary);
    }
  }
`;

export const FlagItem = styled(ODropdownItem)<{ active: boolean }>`
  &.o-dropdown-item {
    ${({ active }) =>
      active && `background-color: var(--theme-tertiary); pointer-events: none`}
  }
`;
