import * as yup from "yup";

export interface ChangeLoginEmailFormValues {
  currentEmail: string;
  newEmail: string;
  reason: string;
}

export const changeLoginEmailValidationSchema: yup.ObjectSchema<ChangeLoginEmailFormValues> =
  yup.object({
    currentEmail: yup.string().required("Este campo é obrigatório").email(),
    newEmail: yup.string().required("Este campo é obrigatório").email(),
    reason: yup.string().required("Este campo é obrigatório"),
  });
