import { maskIdentification } from "utils/mask";
import { CopyButton } from "components/copy-button";
import { DetailsFields } from "components/details-card";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { PostAdminCompaniesGetByTaxIdResponse } from "services/clerk";
import { DesksList, ProductsList } from "./_compose";

export const buildCompanyDetailsFields = (
  customer: PostAdminCompaniesGetByTaxIdResponse,
): DetailsFields => ({
  info: {
    items: [
      {
        label: "Razão Social",
        value: customer.officialName,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "CPF/CNPJ/Offshore",
        value: (
          <div className="d-flex align-items-center gap-2">
            {maskIdentification(customer.identification)}{" "}
            <CopyButton
              action="detalhes_do_cliente:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={customer.identification}
              toasterMessage="Identificação copiada."
            />
          </div>
        ),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Natureza",
        value: customer.legalNature,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Data de constituição",
        value: dayjs(customer.birthDate).format("DD/MM/YYYY"),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Data da próxima revisão cadastral",
        visible: !!customer.dataReviewDate,
        value: dayjs(customer.dataReviewDate).format("DD/MM/YYYY"),
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  contact: {
    title: "Contato",
    items: [
      {
        label: "E-mail",
        value: customer.email,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  products: {
    title: "Relacionamentos",
    items: [
      {
        label: "Produtos",
        value: <ProductsList products={customer.products} />,
        role: roles.cadastro.customer.detailsProductsDetailField.role,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Segmentos",
        value: <DesksList desks={customer.desks} />,
        role: roles.cadastro.customer.detailsDesksListDetailField.role,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Franquia",
        value: customer.franchise?.name || "N/A",
        role: roles.cadastro.customer.detailsOriginFranchiseField.role,
        grid: { md: 4, xl: 3 },
      },
    ],
  },
});
