import { OToastManager, modalManager } from "@maestro/core";
import { OConfirmationModal } from "@maestro/react";
import { DataGridActionV2 } from "components/datagrid-action";
import { useCallback, useState } from "react";
import { service } from "services";
import { CreditCardPaymentStatus } from "services/bankinghub/models/types/cards";
import { useCreditCardPaymentsContext } from "../../../../../../../contexts/cliente/credit-card-payments.context";
import { InstallmentActionButtonsProps } from "./installment-action-buttons.types";

export const InstallmentActionButtons = ({
  installmentId,
  reload,
}: InstallmentActionButtonsProps) => {
  const { selectedPayment } = useCreditCardPaymentsContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const payCreditCardPaymentInstallmentModalId = `pay-credit-card-payment-installment-${installmentId}-modal`;

  const handlePayCreditCardPaymentInstallment = useCallback(async () => {
    try {
      setIsLoading(true);
      await service.bankinghub.patchPayCreditCardPaymentInstallment(
        installmentId,
      );
      OToastManager.success("Solicitação de pagamento enviada com sucesso");
      reload();
    } catch (error) {
      OToastManager.danger(
        "Não foi possível enviar a solicitação de pagamento",
      );
    } finally {
      setIsLoading(false);
      modalManager.hide(payCreditCardPaymentInstallmentModalId);
    }
  }, [installmentId, reload, payCreditCardPaymentInstallmentModalId]);

  if (selectedPayment?.status !== CreditCardPaymentStatus.Confirmed)
    return null;

  return (
    <>
      <DataGridActionV2
        actions={[
          {
            icon: { category: "orq", icon: "orq-coin" },
            label: "Pagar",
            onClick: () => {
              modalManager.show(payCreditCardPaymentInstallmentModalId);
            },
          },
        ]}
      />
      <OConfirmationModal
        modalId={payCreditCardPaymentInstallmentModalId}
        title="Deseja pagar a parcela?"
        confirmLabel="Confirmar"
        closeLabel="Voltar"
        divider={false}
        size="xs"
        loading={isLoading}
        onConfirm={async () => {
          await handlePayCreditCardPaymentInstallment();
        }}
      />
    </>
  );
};
