import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { getProcessorPropertyFieldId } from "../../../utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";

export const useGenericPropertyField = ({
  processorConfigProperty: { processorConfigId, value, name },
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { watch, setValue } = useFormContext();

  const processorPropertyFieldId = getProcessorPropertyFieldId(fieldId);

  const fieldWatch = watch(processorPropertyFieldId);

  useEffect(() => {
    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [value, setValue, processorPropertyFieldId, name]);

  return {
    fieldWatch,
    processorConfigId,
    processorPropertyFieldId,
  };
};
