import { useFormContext } from "react-hook-form";
import { useBanner } from "../../banner-management.hook";
import { BannerContent } from "./banner-content/banner-content.component";
import { BannerPreviewProps } from "./banner-preview.types";
import { getDescription } from "./banner-preview.utils";
import { BannerSkeleton } from "./banner-skeleton/banner-skeleton.component";

export const BannerPreview = ({
  image,
  data,
  bannerTemplate,
}: BannerPreviewProps) => {
  const { watch } = useFormContext() || {};

  const { imageFile } = bannerTemplate || data?.bannerTemplate || {};
  const { key, name } = imageFile ?? {};

  const { imageUrl } = useBanner({ fileKey: key, fileName: name });

  if (!imageUrl && !image) return <BannerSkeleton />;

  if (image) return <BannerContent image={image} />;

  if (data) {
    const {
      title,
      badge,
      variables,
      bannerTemplate: bannerTemplateData,
    } = data;

    const parsedVariables = JSON.parse(variables ?? "{}") as Record<
      string,
      string
    >;
    const description = getDescription(
      parsedVariables,
      bannerTemplateData?.description,
    );

    return (
      <BannerContent
        description={description}
        title={title || "[Nome do evento]"}
        image={imageUrl}
        badge={badge}
        bannerTemplate={bannerTemplateData}
      />
    );
  }

  const watchBadge = watch("badge");
  const watchTitle = watch("title");

  const watchMainTheme = watch("temaPrincipal");
  const watchDate = watch("data");
  const watchHour = watch("hora");

  const description = getDescription(
    {
      temaPrincipal: String(watchMainTheme),
      data: String(watchDate),
      hora: String(watchHour),
    },
    bannerTemplate?.description,
  );

  return (
    <BannerContent
      description={description}
      title={watchTitle || "[Nome do evento]"}
      image={imageUrl}
      badge={watchBadge}
      bannerTemplate={bannerTemplate}
    />
  );
};
