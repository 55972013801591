export const scfDevelopersOnboardingStatusMap: Record<
  ScfDevelopers.OnboardingStatus,
  string
> = {
  CADASTRO_INICIADO: "Cadastro iniciado",
  DADOS_ENRIQUECIDOS: "Dados enriquecidos",
  ENVIADO_PARA_ANALISE: "Enviado para análise",
  SOLICITANDO_ANALISE: "Solicitando análise",
  ANALISE_INICIADA: "Análise iniciada",
  ANALISE_RISCO_SOLICITADA: "Análise de risco solicitada",
  ANALISE_RISCO_APROVADO: "Análise de risco aprovada",
  CONCLUIDO: "Concluído",
  PENDENTE_AJUSTE: "Pendente ajuste",
  REPROVADO: "Reprovado",
  ERRO: "Erro",
  BAIXADO_NA_RECEITA: "Baixado na receita",
  NAO_ENCONTRADO_NA_RECEITA: "Não encontrado na receita",
  VALIDANDO: "Validando",
  INDEFINIDO: "Indefinido",
  INAPTA_NA_RECEITA: "Inapta na receita",
};
