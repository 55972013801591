import { OToastManager, modalManager } from "@maestro/core";
import {
  OConfirmationModal,
  OField,
  OInputCurrency,
  OInputText,
  OOption,
  OSelect,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { DataGridActionV2 } from "components/datagrid-action";
import { useCallback, useEffect, useState } from "react";
import { service } from "services";
import {
  MapperRevertCreditCardPaymentType,
  RevertCreditCardPaymentType,
  RevertCreditCardPaymentChargeBackAmountType,
} from "services/bankinghub/models/types/cards";
import { useCardsCustomerContext } from "../../../../../../contexts";
import {
  CreditCardPaymentActionButtonsProps,
  ErrorsMessage,
} from "./credit-card-payment-action-buttons.types";

export const CreditCardPaymentActionButtons = ({
  installmentId,
  totalAmount,
  reload,
}: CreditCardPaymentActionButtonsProps) => {
  const { account } = useCardsCustomerContext();

  const [reason, setReason] = useState<
    RevertCreditCardPaymentType | undefined
  >();
  const [description, setDescription] = useState<string>("");
  const [amount, setAmount] = useState<number>(totalAmount);

  const [errorsMessage, setErrorsMessage] = useState<
    ErrorsMessage | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cancelCreditCardPaymentRequestStatusModalId = `cancel-credit-card-payment-${installmentId}-modal`;

  const loadErrorsMessage = useCallback((): ErrorsMessage => {
    return {
      reason: !reason ? "Este campo é obrigatório" : undefined,
      amount:
        amount > totalAmount
          ? "Valor precisa ser menor que o valor total do pagamento!"
          : amount === 0
          ? "Valor precisa ser maior que 0!"
          : undefined,
    };
  }, [reason, totalAmount, amount]);

  const handleCancelCreditCardPayment = useCallback(async () => {
    try {
      setIsLoading(true);

      const errors = loadErrorsMessage();
      setErrorsMessage(errors);

      if (errors.reason || errors.amount) {
        OToastManager.warning(
          "Por favor, revise os campos antes de confirmar a solicitação de cancelamento",
        );
        return;
      }

      if (!account) return;

      if (amount === totalAmount)
        await service.bankinghub.deleteCreditCardPayment({
          installmentId,
          companyDocument: account.company.document,
          reasonType: reason,
          description,
          chargeBackAmountType:
            amount === totalAmount
              ? RevertCreditCardPaymentChargeBackAmountType.Total
              : RevertCreditCardPaymentChargeBackAmountType.Partial,
        });
      OToastManager.success("Solicitação de cancelamento enviada com sucesso");
      reload();
    } catch (error) {
      OToastManager.danger(
        "Não foi possível confirmar a solicitação de cancelamento",
      );
    } finally {
      setIsLoading(false);
      modalManager.hide(cancelCreditCardPaymentRequestStatusModalId);
    }
  }, [
    loadErrorsMessage,
    account,
    amount,
    totalAmount,
    installmentId,
    reason,
    description,
    reload,
    cancelCreditCardPaymentRequestStatusModalId,
  ]);

  useEffect(() => {
    const closeCleanup = modalManager.on(
      cancelCreditCardPaymentRequestStatusModalId,
      "modalClose",
      () => {
        setReason(undefined);
        setDescription("");
        setErrorsMessage(undefined);
      },
    );

    return () => {
      closeCleanup();
    };
  }, [cancelCreditCardPaymentRequestStatusModalId]);

  return (
    <>
      <DataGridActionV2
        actions={[
          {
            icon: { category: "orq", icon: "orq-close" },
            label: "Cancelar",
            onClick: () => {
              modalManager.show(cancelCreditCardPaymentRequestStatusModalId);
            },
          },
        ]}
      />
      <OConfirmationModal
        modalId={cancelCreditCardPaymentRequestStatusModalId}
        title="Cancelar transação de cartão de crédito?"
        confirmLabel="Confirmar"
        closeLabel="Voltar"
        divider={false}
        size="xs"
        loading={isLoading}
        onConfirm={async () => {
          await handleCancelCreditCardPayment();
        }}
      >
        <div className="d-flex flex-column gap-3">
          <OField
            label="Qual é o motivo do cancelamento?"
            htmlFor="reason"
            required
            error={!!errorsMessage?.reason}
            message={errorsMessage?.reason}
          >
            <OSelect
              id="reason"
              placeholder="Selecione o motivo"
              value={reason}
            >
              {Object.values(RevertCreditCardPaymentType).map((item) => (
                <OOption
                  key={`select-modal-option-${item}`}
                  value={item}
                  onClick={() => setReason(item)}
                >
                  {MapperRevertCreditCardPaymentType[item]}
                </OOption>
              ))}
            </OSelect>
          </OField>
          <OField label="Observações" htmlFor="description">
            <OInputText
              id="description"
              name="description"
              placeholder="Digite..."
              value={description}
              onInput={(e) => {
                if (!e || !e.currentTarget || !e.currentTarget.value) return;
                setDescription(e.currentTarget.value.toString());
              }}
            />
          </OField>
          <OField
            label="Valor:"
            description={`Valor máximo: ${masks.currency(
              totalAmount,
              "R$",
              ",",
            )}`}
            htmlFor="amount"
            required
            disabled
            error={!!errorsMessage?.amount}
            message={errorsMessage?.amount}
          >
            <OInputCurrency
              id="amount"
              name="amount"
              value={amount}
              symbol="R$"
              onInput={(e) => {
                if (!e || !e.currentTarget || !e.currentTarget.value) return;
                setAmount(+e.currentTarget.value);
              }}
            />
          </OField>
        </div>
      </OConfirmationModal>
    </>
  );
};
