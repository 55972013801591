import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { endpoints } from "services/endpoints";
import { HubPartners } from "services/hubpartners/models/types/admin";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const accontsRelationshipsODataGrid: ODataGridGeneratorConfig<HubPartners.Admin.Relationship> =
  {
    datagrid: {
      noDataText: "Nenhuma conta encontrada",
      filterRow: { visible: true },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar contas.";
      },
      dataSource: dataSourceODataStoreGenerator(
        endpoints.hubPartners.admin.odata.adminGetODataAccountRelationships,
        {
          dataSourceOptions: {
            sort: [
              {
                desc: true,
                selector: "createdDate",
              },
            ],
          },
        },
      ),
    },
    columns: [
      {
        dataField: "isActive",
        caption: "Vínculo ativo",
      },
      {
        dataField: "number",
        caption: "Conta",
      },
      {
        dataField: "branch",
        caption: "Agência",
      },
      {
        dataField: "officialName",
        caption: "Nome",
        width: 240,
      },
      {
        dataField: "identification",
        caption: "CPF/CNPJ",
        width: 160,
        format: (value) => masks.cpfCnpj(value),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "isMainLink",
        caption: "Vínculo principal",
      },
      {
        dataField: "originatorName",
        caption: "Originador",
        allowEditing: false,
        width: 240,
        calculateFilterExpression: (filterValue) => [
          ["originatorName", "contains", filterValue],
          "or",
          [
            "originatorIdentification",
            "contains",
            filterValue.replace(/[^\w]/g, ""),
          ],
        ],
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{data.originatorName?.toUpperCase()}</OTypography>
            <OTypography type="default">
              {masks.cpfCnpj(data.originatorIdentification)}
            </OTypography>
          </div>
        ),
      },
      {
        dataField: "operatorName",
        caption: "Operador",
        allowEditing: false,
        width: 240,
        calculateFilterExpression: (filterValue) => [
          ["operatorName", "contains", filterValue],
          "or",
          [
            "operatorIdentification",
            "contains",
            filterValue.replace(/[^\w]/g, ""),
          ],
        ],
        cellRender: ({ data }) => (
          <div className="d-flex flex-column gap-2">
            <OTypography>{data.operatorName?.toUpperCase() || "-"}</OTypography>
            <OTypography type="default">
              {masks.cpfCnpj(data.operatorIdentification)}
            </OTypography>
          </div>
        ),
      },
      {
        dataField: "partnershipType",
        caption: "Tipo de Parceria",
      },
      {
        dataField: "mainOriginatorName",
        caption: "Instituição Responsável",
        width: 240,
      },
      {
        dataField: "createdDate",
        caption: "Data do vínculo",
      },
      {
        dataField: "createdBy",
        caption: "Criado por",
        width: 160,
      },
      {
        dataField: "deactivatedAt",
        caption: "Data da desativação",
      },
    ],
  };
