import { masks, validators } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { GetApiAdminContractSignersResponse } from "services/hubloan/models";
import { ContractTemplate } from "./loan-contracts-templates.types";

type AdditionalSigner = ContractTemplate["contractAdditionalSigners"][number];

export const loanAdditionalSignerFieldsGrid = (
  signers: GetApiAdminContractSignersResponse | undefined,
  onChange?: (additionalSigners: AdditionalSigner[]) => void,
) => {
  const signerOptions =
    signers?.map((s) => ({
      label: `Nome: ${s.signerCompany.name} - Empresa: ${masks.cnpj(
        s.partyCompany.taxId,
      )}`,
      value: s.id,
    })) ?? [];

  return {
    datagrid: {
      noDataText: "Nenhum registro adicionado.",
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
      },
      onRowInserted: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
      onRowUpdated: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
      onRowRemoved: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
    },
    columns: [
      {
        dataField: "contractSigner.id",
        caption: "Signatário",
        lookup: {
          dataSource: signerOptions,
          valueExpr: "value",
          displayExpr: "label",
        },
        setCellValue(data, value: number, currentRowData) {
          const signerData = signers?.find((s) => s.id === value);

          if (!data.contractSigner) {
            // eslint-disable-next-line no-param-reassign
            data.contractSigner = {
              email: "",
              signerCompany: {
                taxId: "",
                name: "",
              },
              partyCompany: {
                taxId: "",
              },
            };
          }

          // eslint-disable-next-line no-param-reassign
          data.contractSigner.email = signerData?.email;

          if (data.contractSigner.partyCompany)
            // eslint-disable-next-line no-param-reassign
            data.contractSigner.partyCompany.taxId =
              signerData?.partyCompany?.taxId;

          if (data.contractSigner.signerCompany) {
            // eslint-disable-next-line no-param-reassign
            data.contractSigner.signerCompany.taxId =
              signerData?.signerCompany?.taxId;
            // eslint-disable-next-line no-param-reassign
            data.contractSigner.signerCompany.name =
              signerData?.signerCompany?.name;
          }

          this.defaultSetCellValue?.(data, value, currentRowData);
        },
      },
      {
        dataField: "contractSigner.partyCompany.taxId",
        caption: "Parte",
        allowEditing: false,
        format: (value) => masks.cnpj(value),
        validationRules: [
          { type: "required" },
          {
            type: "custom",
            message: "CNPJ inválido",
            validationCallback: (option) => {
              return validators.cnpj(option.value);
            },
          },
        ],
        setCellValue(newData, value) {
          if (newData.contractSigner)
            // eslint-disable-next-line no-param-reassign
            newData.contractSigner.partyCompany = {
              ...newData.contractSigner?.partyCompany,
              taxId: value?.replace(/\D/g, ""),
            };
        },
      },
      {
        dataField: "contractSigner.signerCompany.taxId",
        caption: "CPF",
        allowEditing: false,
        format: (value) => masks.cpf(value),
        validationRules: [
          { type: "required" },
          {
            type: "custom",
            message: "CPF inválido",
            validationCallback: (option) => {
              return validators.cpf(option.value);
            },
          },
        ],
        setCellValue(newData, value) {
          if (newData.contractSigner)
            // eslint-disable-next-line no-param-reassign
            newData.contractSigner.signerCompany = {
              ...newData.contractSigner.signerCompany,
              taxId: value?.replace(/\D/g, ""),
            };
        },
      },
      {
        dataField: "contractSigner.signerCompany.name",
        caption: "Nome",
        allowEditing: false,
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "contractSigner.email",
        caption: "E-mail",
        allowEditing: false,
        cellRender: ({ data }) =>
          signers?.find((s) => s.id === data?.contractSigner?.id)?.email,
        editCellRender: (prop) =>
          signers?.find((s) => s.id === prop.data?.contractSigner?.id)?.email,
        validationRules: [{ type: "required" }, { type: "email" }],
      },
      {
        dataField: "nature",
        caption: "Natureza",
        lookup: {
          dataSource: ["AVALISTA", "INTERVENIENTE", "PROCURADOR", "TESTEMUNHA"],
        },
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "signWithBiometry",
        caption: "Assina via biometria?",
        dataType: "boolean",
      },
      {
        dataField: "automaticSignature",
        caption: "Assinatura automática?",
        dataType: "boolean",
      },
    ],
  } satisfies ODataGridGeneratorConfig<AdditionalSigner>;
};
