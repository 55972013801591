import * as yup from "yup";
import { InternationalUserForm } from "./add-international-user.type";

const requiredFieldMessage = "Campo obrigatório";
const requiredField = () => yup.string().required(requiredFieldMessage);

const isAlphanumeric = (str: string) => /^[a-zA-Z0-9]+$/.test(str);

export const addInternationalUserValidationSchema: yup.ObjectSchema<InternationalUserForm> =
  yup.object({
    email: requiredField().email("Campo deve ser um e-mail"),
    phoneNumber: requiredField(),
    identification: requiredField().test(
      "Is alphanumeric only",
      "Identificação deve conter apenas letras e/ou números sem espaço",
      isAlphanumeric,
    ),
    name: requiredField(),
    countryCode: requiredField(),
    profiles: yup
      .array()
      .required(requiredFieldMessage)
      .min(1, "É necessário selecionar ao menos um profile."),
  });
