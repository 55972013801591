import { yupResolver } from "@hookform/resolvers/yup";
import { OCol, ORFieldInput, ORow, OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { HubPartners } from "services/hubpartners/models/types/admin";
import { useProfiles } from "../../../../../hooks";
import {
  AddPartnershipTypeFormFields,
  addPartnershipTypeSchema,
} from "./add-partnership-type.form";

export const AddPartnershipType = () => {
  const [loading, setLoading] = useState(false);

  const { allProfiles, getProfiles, getProfilesHasError, getProfilesLoading } =
    useProfiles();

  const form = useForm<AddPartnershipTypeFormFields>({
    resolver: yupResolver(addPartnershipTypeSchema),
  });
  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.hubPartners.postPartnershipType(values);

      OToastManager.success("Tipo de parceria adicionado com sucesso.");
    } catch (err) {
      if (isAxiosError<HubPartners.Admin.ErrorResponse>(err)) {
        err.response?.data?.failures?.forEach((failure) => {
          failure.message && OToastManager.danger(failure.message);
        });
      } else {
        OToastManager.danger("Não foi possível cadastrar o tipo de parceria.");
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar tipo de parceria" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Adicionar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={getProfilesLoading}
          hasError={getProfilesHasError}
          value={!!allProfiles}
          errorComponent={
            <DefaultErrorComponent
              title="Não foi possível buscar os profiles"
              callback={getProfiles}
            />
          }
          render={() => (
            <FormProvider {...form}>
              <ORow>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    label="Código"
                    name="code"
                    id="code"
                    required
                  />
                </OCol>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    label="Nome"
                    name="name"
                    id="name"
                    required
                  />
                </OCol>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    label="Código de segmento de filtro"
                    name="segmentFilterCode"
                    id="segmentFilterCode"
                    required
                  />
                </OCol>
              </ORow>
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
