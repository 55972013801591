import { OToastManager } from "@maestro/react";
import { useCustomer } from "contexts/customer";
import { useRoles } from "hooks/roles";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HubPartners } from "services/hubpartners/models/types/admin";
import { service } from "services/service";

export const useActivePartners = () => {
  const navigate = useNavigate();
  const { hasRole } = useRoles();
  const { customer } = useCustomer();

  const [loading, setLoading] = useState(false);
  const [relationships, setRelationships] =
    useState<HubPartners.Admin.ActiveRelationship[]>();

  const fetchEntities = useCallback(async () => {
    setLoading(true);

    try {
      const payload = {
        identification: customer.identification,
      };
      const { data } = await service.hubPartners.getActivePartners(payload);
      setRelationships(
        data.data.filter((relationship) => relationship.isActive),
      );
    } catch {
      OToastManager.danger("Falha ao obter relacionamentos");
    } finally {
      setLoading(false);
    }
  }, [customer]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  return {
    hasRole,
    loading,
    navigate,
    relationships,
  };
};
