import { OToastManager } from "@maestro/core";
import { useMemo } from "react";
import {
  ODataGridGenerator,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { useCardsCustomerContext } from "../../../../../contexts/cliente/cards-customer.context";
import { NoAccountRegisteredTemplate } from "../../../components/no-account-registered-template";
import { assembleGetCreditCardPaymentsParams } from "./credit-card-payments-grid.filter-utils";
import { creditCardPaymentGrid } from "./credit-card-payments-grid.grid";
import { useCreditCardPaymentsContext } from "../../../../../contexts/cliente/credit-card-payments.context";

export const CreditCardPaymentGrid = () => {
  const { account } = useCardsCustomerContext();
  const { fetchPayments } = useCreditCardPaymentsContext();

  const gridRef = useGridRef();

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator<BankingHubCards.CreditCardPayment>(
        (loadOptions) => {
          if (!account) {
            const errorMessage = "Erro ao carregar dados da conta";
            OToastManager.danger(errorMessage);
            throw new Error(errorMessage);
          }

          return service.bankinghub
            .getCreditCardPayments(
              account.id,
              assembleGetCreditCardPaymentsParams(loadOptions),
            )
            .then(({ data }) => {
              fetchPayments(data.items);
              return {
                data: data.items,
                totalCount: data.totalItems,
              };
            })
            .catch(() => {
              const errorMessage =
                "Falha ao buscar pagamentos e transferências por cartão de crédito";
              OToastManager.danger(errorMessage);
              throw new Error(errorMessage);
            });
        },
      ),
    [account, fetchPayments],
  );

  return (
    <NoAccountRegisteredTemplate
      pageTitleProps={{
        title: "Pagamentos e transferências por cartão de crédito",
      }}
      children={
        <GridTemplate
          gridRef={gridRef}
          showRefreshButton
          showClearFiltersButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={creditCardPaymentGrid}
            dataSource={dataSource}
          />
        </GridTemplate>
      }
    />
  );
};
