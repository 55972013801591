import * as yup from "yup";

export interface ChangeUserEmailFormValues {
  newUserEmail: string;
  oldUserEmail: string;
  updatingReason: string;
}

export const changeUserEmailValidationSchema: yup.ObjectSchema<ChangeUserEmailFormValues> =
  yup.object({
    newUserEmail: yup.string().required("Este campo é obrigatório").email(),
    oldUserEmail: yup.string().required("Este campo é obrigatório").email(),
    updatingReason: yup.string().required("Este campo é obrigatório"),
  });
