import { client } from "../client";
import { clerkAdminService } from "./admin";
import { clerkEndpoints } from "./clerk.endpoints";
import {
  ApiStrategiesByGuidResponse,
  DeleteApiEmailConfigsDeleteByIdBody,
  DeleteApiEmailConfigsTypeDeleteByIdBody,
  GetApiBankAccountsResponse,
  GetApiDesksResponse,
  GetApiStrategiesByIdResponse,
  GetApiStrategiesEntityByIdResponse,
  GetApiStrategiesResponse,
  GetApiSystemsResponse,
  GetPmeAccountsResponse,
  GetPmeAcountsParams,
  GetUsersCompleteSelfResponse,
  PatchApiEmailConfigsTypeUpdateByIdBody,
  PatchApiEmailConfigsUpdateByIdBody,
  PatchApiStrategiesBody,
  PatchApiStrategiesResponse,
  PatchApiUsersUpdateByEmailBody,
  PatchApiUsersUpdateByEmailResponse,
  PatchEntitiesRelationshipByUseridByCompanyIdBody,
  PostAdminOdataDesksAllUsersSelfBody,
  PostAdminOdataDesksAllUsersSelfResponse,
  PostAdminOdataDesksB2BUsersSelfParams,
  PostAdminOdataDesksB2BUsersSelfResponse,
  PostApiDesksGetEntityDesksBody,
  PostApiDesksGetEntityDesksResponse,
  PostApiDesksRemoveDesksFromEntityBody,
  PostApiDesksUpsertEntityBody,
  PostApiDesksUpsertEntityResponse,
  PostApiEmailConfigsCreateBody,
  PostApiEmailConfigsGetByIdBody,
  PostApiEmailConfigsTypeCreateBody,
  PostApiEmailConfigsTypeGetByIdBody,
  PostApiFranchiseBody,
  PostApiFranchiseOdataBody,
  PostApiProductByIdentificationBody,
  PostApiSystemsByNameBody,
  PostApiSystemsByNameResponse,
  PostApiUsersGetLoginInfoBody,
  PostCustomerInvitesGetCompanyWorkflowDetailsBody,
  PostCustomerInvitesGetCompanyWorkflowsBody,
  PostCustomerInvitesGetWorkflowEventsBody,
  PostCustomerInvitesResendWorkflowNotificationBody,
  PostCustomerInvitesUpdateWorkflowBody,
  PostCustomerPlansGetFromCompanyBody,
  PostCustomerPlansGetFromCompanyResponse,
  PostEntitiesSendUpdateMessageBody,
  PostMetricsGetMetricsByIdentifiersBody,
  PostPowerOfAttorneyIsEloaAvailableBody,
  PostPowerOfAttorneyIsEloaAvailableResponse,
  PostSearchEngineEntityBody,
  PostSearchEngineEntityResponse,
  PostUsersGetByTaxIdBody,
  PostUsersGetByTaxIdResponse,
} from "./models";
import {
  GetActiveDdisResponse,
  GetApiEmailConfigsResponse,
  GetApiEmailConfigsTypeResponse,
  GetApiFranchiseResponse,
  GetApiGroupsResponse,
  GetMetricsGetAllResponse,
  GetMetricsGetByRolesResponse,
  GetOfferByIdentificationResponse,
  GetOfferResponse,
  PatchApiEmailConfigsTypeUpdateByIdResponse,
  PatchApiEmailConfigsUpdateByIdResponse,
  PatchEntitiesRelationshipByUseridByCompanyIdResponse,
  PostApiDesksRemoveDesksFromEntityResponse,
  PostApiEmailConfigsCreateResponse,
  PostApiEmailConfigsGetByIdResponse,
  PostApiEmailConfigsTypeCreateResponse,
  PostApiEmailConfigsTypeGetByIdResponse,
  PostApiFranchiseOdataResponse,
  PostApiUsersGetLoginInfoResponse,
  PostCustomerInvitesGetCompanyWorkflowsResponse,
  PostCustomerInvitesGetWorkflowDetailsResponse,
  PostCustomerInvitesGetWorkflowEventsResponse,
  PostCustomerInvitesUpdateWorkflowResponse,
  PostMetricsGetMetricsByIdentifiersResponse,
} from "./models/responses";
import { BankAccount } from "./models/types";
import { clerkODataService } from "./odata/clerk-odata.service";

export const clerkService = {
  admin: clerkAdminService,
  odata: clerkODataService,
  getUsersByTaxId(body: PostUsersGetByTaxIdBody) {
    return client.post<PostUsersGetByTaxIdResponse>(
      clerkEndpoints.users.getByTaxId,
      body,
    );
  },
  getUserCompleteSelf() {
    return client.get<GetUsersCompleteSelfResponse>(
      clerkEndpoints.users.completeSelf,
    );
  },

  getCustomerPlans(body: PostCustomerPlansGetFromCompanyBody) {
    return client.post<PostCustomerPlansGetFromCompanyResponse>(
      clerkEndpoints.customerPlans.getFromCompany,
      body,
    );
  },
  isEloaAvailable(body: PostPowerOfAttorneyIsEloaAvailableBody) {
    return client.post<PostPowerOfAttorneyIsEloaAvailableResponse>(
      clerkEndpoints.powerOfAttorney.isEloaAvailable,
      body,
    );
  },
  getAllUsersOdata(body: PostAdminOdataDesksAllUsersSelfBody) {
    return client.post<PostAdminOdataDesksAllUsersSelfResponse>(
      clerkEndpoints.admin.odata.desks.allUsers.self,
      body,
    );
  },
  entitySearch(body: PostSearchEngineEntityBody) {
    return client.post<PostSearchEngineEntityResponse>(
      clerkEndpoints.searchEngine.entity,
      body,
    );
  },
  getB2BUsersOdata(params: PostAdminOdataDesksB2BUsersSelfParams) {
    return client.post<PostAdminOdataDesksB2BUsersSelfResponse>(
      clerkEndpoints.admin.odata.desks.b2bUsers.self,
      params,
    );
  },
  getBankAccount() {
    return client.get<GetApiBankAccountsResponse>(
      clerkEndpoints.bankAccounts.getBankAccount,
    );
  },
  getBankAccounts() {
    return client.get<BankAccount[]>(
      clerkEndpoints.bankAccounts.getBankAccount,
    );
  },
  getStrategies() {
    return client.get<GetApiStrategiesResponse>(
      clerkEndpoints.strategies.endpoint,
    );
  },
  setStrategyEntity(body: PatchApiStrategiesBody) {
    return client.patch<PatchApiStrategiesResponse>(
      clerkEndpoints.strategies.endpoint,
      body,
    );
  },
  getStrategiesById(id: string) {
    return client.get<GetApiStrategiesByIdResponse>(
      clerkEndpoints.strategies.byId.endpoint(id),
    );
  },
  addStrategiesByGuid(id: string, guid: string) {
    return client.post<ApiStrategiesByGuidResponse>(
      clerkEndpoints.strategies.byId.byGuid(id, guid),
    );
  },
  deleteStrategiesByGuid(id: string, guid: string) {
    return client.delete<ApiStrategiesByGuidResponse>(
      clerkEndpoints.strategies.byId.byGuid(id, guid),
    );
  },
  getSystems() {
    return client.get<GetApiSystemsResponse>(clerkEndpoints.systems.endpoint);
  },
  addSystemByName(body: PostApiSystemsByNameBody) {
    return client.post<PostApiSystemsByNameResponse>(
      clerkEndpoints.systems.byName(body.data.name),
      body,
    );
  },
  getStrategyEntityById(id: number) {
    return client.get<GetApiStrategiesEntityByIdResponse>(
      clerkEndpoints.strategies.entity.byId(id),
    );
  },
  getDesks() {
    return client.get<GetApiDesksResponse>(clerkEndpoints.desks.endpoint);
  },
  upsertEntityDesks(body: PostApiDesksUpsertEntityBody) {
    return client.post<PostApiDesksUpsertEntityResponse>(
      clerkEndpoints.desks.upsertEntity,
      body,
    );
  },
  removeDesksFromEntity(body: PostApiDesksRemoveDesksFromEntityBody) {
    return client.post<PostApiDesksRemoveDesksFromEntityResponse>(
      clerkEndpoints.desks.removeDesksFromEntity,
      body,
    );
  },
  getEntityDesks(body: PostApiDesksGetEntityDesksBody) {
    return client.post<PostApiDesksGetEntityDesksResponse>(
      clerkEndpoints.desks.getEntityDesks,
      body,
    );
  },
  getGroups() {
    return client.get<GetApiGroupsResponse>(clerkEndpoints.groups.endpoint);
  },
  postSendUpdateMessage(body: PostEntitiesSendUpdateMessageBody) {
    return client.post(clerkEndpoints.entities.sendUpdatedMessage, body);
  },
  getPmeAccount(params: GetPmeAcountsParams) {
    return client.get<GetPmeAccountsResponse>(clerkEndpoints.pmeAccounts, {
      params,
    });
  },
  getAllMetrics() {
    return client.get<GetMetricsGetAllResponse>(clerkEndpoints.metrics.getAll);
  },
  getMetricsByIdentifiers(body: PostMetricsGetMetricsByIdentifiersBody) {
    return client.post<PostMetricsGetMetricsByIdentifiersResponse>(
      clerkEndpoints.metrics.getMetricsByIdentifiers,
      body,
    );
  },
  getMetricsByRoles() {
    return client.get<GetMetricsGetByRolesResponse>(
      clerkEndpoints.metrics.getByRoles,
    );
  },
  getWorflowByTaxId(body: PostCustomerInvitesGetCompanyWorkflowsBody) {
    return client.post<PostCustomerInvitesGetCompanyWorkflowsResponse>(
      clerkEndpoints.customerInvites.getCompanyWorkflows,
      body,
    );
  },
  getWorkflowDetails(body: PostCustomerInvitesGetCompanyWorkflowDetailsBody) {
    return client.post<PostCustomerInvitesGetWorkflowDetailsResponse>(
      clerkEndpoints.customerInvites.getWorkflowDetails,
      body,
    );
  },
  getWorkflowEvents(body: PostCustomerInvitesGetWorkflowEventsBody) {
    return client.post<PostCustomerInvitesGetWorkflowEventsResponse>(
      clerkEndpoints.customerInvites.getWorkflowEvents,
      body,
    );
  },
  updateWorkflow(body: PostCustomerInvitesUpdateWorkflowBody) {
    return client.post<PostCustomerInvitesUpdateWorkflowResponse>(
      clerkEndpoints.customerInvites.updateWorkflow,
      body,
    );
  },
  resendWorkflowNotification(
    body: PostCustomerInvitesResendWorkflowNotificationBody,
  ) {
    return client.post(
      clerkEndpoints.customerInvites.resendWorkflowNotification,
      body,
    );
  },
  updateUser(
    userId: string,
    companyId: string,
    body: PatchEntitiesRelationshipByUseridByCompanyIdBody,
  ) {
    return client.patch<PatchEntitiesRelationshipByUseridByCompanyIdResponse>(
      clerkEndpoints.entitiesRelationship.byUserId.byCompanyId(
        userId,
        companyId,
      ),
      body,
    );
  },
  updateUserInfo(body: PatchApiUsersUpdateByEmailBody) {
    return client.patch<PatchApiUsersUpdateByEmailResponse>(
      clerkEndpoints.users.updateByEmail,
      body,
    );
  },
  getDdis() {
    return client.get<GetActiveDdisResponse>(clerkEndpoints.ddis.getDdis);
  },
  showEmailConfigs() {
    return client.get<GetApiEmailConfigsResponse>(
      clerkEndpoints.emailConfigs.endpoint,
    );
  },
  getEmailConfigsById(body: PostApiEmailConfigsGetByIdBody) {
    return client.post<PostApiEmailConfigsGetByIdResponse>(
      clerkEndpoints.emailConfigs.getById,
      body,
    );
  },
  createEmailConfigs(body: PostApiEmailConfigsCreateBody) {
    return client.post<PostApiEmailConfigsCreateResponse>(
      clerkEndpoints.emailConfigs.create,
      body,
    );
  },
  updateEmailConfigsById(body: PatchApiEmailConfigsUpdateByIdBody) {
    return client.patch<PatchApiEmailConfigsUpdateByIdResponse>(
      clerkEndpoints.emailConfigs.updateById,
      body,
    );
  },
  deleteEmailConfigsById(body: DeleteApiEmailConfigsDeleteByIdBody) {
    return client.delete(clerkEndpoints.emailConfigs.deleteById, {
      data: body,
    });
  },
  showEmailConfigsType() {
    return client.get<GetApiEmailConfigsTypeResponse>(
      clerkEndpoints.emailConfigsType.endpoint,
    );
  },
  getEmailConfigsTypeById(body: PostApiEmailConfigsTypeGetByIdBody) {
    return client.post<PostApiEmailConfigsTypeGetByIdResponse>(
      clerkEndpoints.emailConfigsType.getById,
      body,
    );
  },
  createEmailConfigsType(body: PostApiEmailConfigsTypeCreateBody) {
    return client.post<PostApiEmailConfigsTypeCreateResponse>(
      clerkEndpoints.emailConfigsType.create,
      body,
    );
  },
  updateEmailConfigsTypeById(body: PatchApiEmailConfigsTypeUpdateByIdBody) {
    return client.patch<PatchApiEmailConfigsTypeUpdateByIdResponse>(
      clerkEndpoints.emailConfigsType.updateById,
      body,
    );
  },
  deleteEmailConfigsTypeById(body: DeleteApiEmailConfigsTypeDeleteByIdBody) {
    return client.delete(clerkEndpoints.emailConfigsType.deleteById, {
      data: body,
    });
  },
  getFranchises() {
    return client.get<GetApiFranchiseResponse>(
      clerkEndpoints.franchise.endpoint,
    );
  },
  addFranchise(body: PostApiFranchiseBody) {
    return client.post<GetApiFranchiseResponse>(
      clerkEndpoints.franchise.endpoint,
      body,
    );
  },
  updateFranchise(id: number, body: PostApiFranchiseBody) {
    return client.patch<GetApiFranchiseResponse>(
      clerkEndpoints.franchise.byId(id),
      body,
    );
  },
  deleteFranchise(id: number) {
    return client.delete(clerkEndpoints.franchise.byId(id));
  },
  getFranchiseClients(body: PostApiFranchiseOdataBody) {
    return client.post<PostApiFranchiseOdataResponse>(
      clerkEndpoints.franchise.odata,
      body,
    );
  },

  getOfferInfo() {
    return client.get<GetOfferResponse>(clerkEndpoints.products.endpoint);
  },

  getOfferActiveByIdentification(id: string) {
    return client.get<GetOfferByIdentificationResponse>(
      clerkEndpoints.products.byId(id),
    );
  },

  addProductByIdentification(
    id: number,
    body: PostApiProductByIdentificationBody,
  ) {
    return client.post<GetApiFranchiseResponse>(
      clerkEndpoints.products.createByIdentification(id),
      body,
    );
  },

  removeProductByIdentification(
    id: number,
    body: PostApiProductByIdentificationBody,
  ) {
    return client.delete<GetApiFranchiseResponse>(
      clerkEndpoints.products.deleteByIdentification(id),
      { data: body },
    );
  },
  getBankingAccounts() {
    return client.get(clerkEndpoints.getBankingClerk);
  },
  getLoginInfo(body: PostApiUsersGetLoginInfoBody) {
    return client.post<PostApiUsersGetLoginInfoResponse>(
      clerkEndpoints.users.getLoginInfo,
      body,
    );
  },
};
