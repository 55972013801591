import { DetailsFields } from "components/details-card";
import { CopyButton } from "components/copy-button";
import { StringList } from "./_compose/string-list.component";
import { DetailedWebhook } from "services/developers/models";
const resolveStatusText = (status: boolean) => {
    if(status) return "Desativado"
    return "Ativo"
}
const resolvePendingReprocessing = (status: boolean) => {
    if(status) return "Reprocessamento pendente"
    return "Sem reprocessamento pendente"
}
const resolveMessageType = (status: boolean) => {
    if(status) return "Envio não parseado"
    return "Envio parseado"
}
export const buildWebhookDetailsFields = (webhook: DetailedWebhook): DetailsFields => ({
  info: {
    items: [
      {
        label: "Nome",
        value: webhook.description,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "WebhookId",
        value: (
          <div className="d-flex align-items-center gap-2">
            {webhook.webhookId}
            <CopyButton
              action="detalhes_do_cliente:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={webhook.webhookId}
              toasterMessage="Identificação copiada."
            />
          </div>
        ),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Endpoint",
        value: (
          <div className="d-flex align-items-center gap-2">
            {webhook.endpoint}
            <CopyButton
              action="detalhes_do_cliente:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={webhook.endpoint}
              toasterMessage="Identificação copiada."
            />
          </div>
        ),
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  details: {
    title: "Detalhes",
    items: [
      {
        label: "Status",
        value: (
            <div className="d-flex align-items-center gap-2">
              {resolveStatusText(webhook.disabled)}
            </div>
          ),
        grid: { md: 4, xl: 2 },
      },
      {
        label: "Reprocessamento",
        value: (
            <div className="d-flex align-items-center gap-2">
              {resolvePendingReprocessing(webhook.hasReprocessingPendingJob)}
            </div>
          ),
        grid: { md: 4, xl: 2 },
      },
      {
        label: "Tipo de envio",
        value: (
            <div className="d-flex align-items-center gap-2">
              {resolveMessageType(webhook.sendRawMessage)}
            </div>
        ),
        grid: { md: 4, xl: 2 }
      }
    ],
  },
  lists: {
    title: "Listas",
    items: [
      {
        label: "Eventos",
        value: <StringList stringArray={webhook.events} />,
        grid: { md: 4, xl: 12 },
      },
    ],
  },
});
