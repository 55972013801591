import { ODataGridGeneratorConfig } from "components/data-grid";
import { LimitExternalIdentification } from "services/hubcreditmanager/models/types/limit/limit.types";

export const externalIdentificationsGrid = {
  datagrid: {
    noDataText: "Nenhuma identificação externa encontrada para o lead",
  },
  columns: [
    {
      dataField: "origin",
      caption: "Origem",
    },
    {
      dataField: "type",
      caption: "Tipo",
    },
    {
      dataField: "internalId",
      caption: "Id interno",
    },
    {
      dataField: "externalId",
      caption: "Id externo",
    },
  ],
} satisfies ODataGridGeneratorConfig<LimitExternalIdentification>;
