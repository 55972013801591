import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { BasicFields } from "./_compose/basic-fields";
import { ProfileSelector } from "./_compose/profile-selector";
import { useAddInternationalUser } from "./add-international-user.hook";

export const AddInternationalUser = () => {
  const { form, loading, submit } = useAddInternationalUser();

  return (
    <FormProvider {...form}>
      <FormTemplate
        pageTitle={<PageTitle title="Novo usuário internacional" />}
        actions={
          <LoadingButton loading={loading} onClick={submit}>
            Enviar
          </LoadingButton>
        }
      >
        <CardTemplate>
          <BasicFields />
          <ProfileSelector />
        </CardTemplate>
      </FormTemplate>
    </FormProvider>
  );
};
