import { yupResolver } from "@hookform/resolvers/yup";
import { OCol, ORFieldInput, OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { HubPartners } from "services/hubpartners/models/types/admin";
import { ParceirosProdutosFormRouteQueryParam } from "../../../../../../routes/parceiros.route-params";
import { EditProductFormFields, editProductSchema } from "./edit-product.form";

export const EditProduct = () => {
  const { value: group } =
    useParseFromSearchParams<ParceirosProdutosFormRouteQueryParam>("data");
  const [loading, setLoading] = useState(false);

  const form = useForm<EditProductFormFields>({
    resolver: yupResolver(editProductSchema),
  });

  const { handleSubmit, reset } = form;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.hubPartners.putProducsts(values);

      OToastManager.success("Produto editado com sucesso.");
    } catch (err) {
      if (isAxiosError<HubPartners.Admin.ErrorResponse>(err)) {
        err.response?.data?.failures?.forEach((failure) => {
          failure.message && OToastManager.danger(failure.message);
        });
      } else {
        OToastManager.danger("Não foi possível editar o produto.");
      }
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    group &&
      reset({
        name: group.name,
        description: group.description,
        code: group.code,
        uuid: group.uuid,
      });
  }, [group, reset]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title={group ? "Editar produto" : "Adicionar produto"} />
      }
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          {group ? "Editar" : "Adicionar"}
        </LoadingButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>
          <OCol>
            <ORFieldInput
              tag="text"
              label="Nome"
              name="name"
              id="name"
              required
            />
          </OCol>
          <OCol>
            <ORFieldInput
              tag="text"
              label="Descrição"
              name="description"
              id="description"
              required
            />
          </OCol>
          <OCol>
            <ORFieldInput
              tag="text"
              label="Código"
              name="code"
              id="code"
              required
            />
          </OCol>
        </FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
