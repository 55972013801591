import {
  OButton,
  OPillTab,
  OPillTabBody,
  OPillTabContent,
  OPillTabHeader,
  OPillTabs,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { PropertyFieldComponent } from "./_compose/property-field.component";
import { useProcessorConfigPropertiesPageContent } from "./processor-config-properties.hook";

export const ProcessorConfigPropertiesPage = () => {
  const { fields, form, handleSubmitProperties } =
    useProcessorConfigPropertiesPageContent();

  const monitoringContent = useMemo(() => {
    if (!fields?.length) return;

    const multiselectProperties = fields?.filter(
      (prop) => !!prop.multiselectType,
    );

    if (!multiselectProperties.length)
      return (
        <div className="d-flex gap-4 flex-wrap justify-content-between align-items-center">
          {fields.map((prop, index) => (
            <div
              className="w-48"
              key={`processor-config-property-${prop.processorPropertyId}`}
            >
              <PropertyFieldComponent
                fieldId={index}
                processorConfigProperty={prop}
              />
            </div>
          ))}
        </div>
      );

    return (
      <OPillTabs>
        <OPillTabHeader className="mb-4">
          <OPillTab key="list-tab">Comuns</OPillTab>
          <OPillTab key="non-list-tab">Multiselect</OPillTab>
        </OPillTabHeader>

        <OPillTabBody>
          <OPillTabContent key="list-content">
            <div className="d-flex gap-4 flex-wrap justify-content-between align-items-center">
              {fields.map((prop, index) => {
                if (!prop.multiselectType)
                  return (
                    <div
                      className="w-48"
                      key={`processor-config-property-${prop.processorPropertyId}`}
                    >
                      <PropertyFieldComponent
                        fieldId={index}
                        processorConfigProperty={prop}
                      />
                    </div>
                  );
                return null;
              })}
            </div>
          </OPillTabContent>

          <OPillTabContent key="non-list-content">
            <div className="d-flex gap-4 flex-wrap justify-content-between align-items-center">
              {fields.map((prop, index) => {
                if (prop.multiselectType)
                  return (
                    <div
                      className="w-100"
                      key={`processor-config-property-${prop.processorPropertyId}`}
                    >
                      <PropertyFieldComponent
                        fieldId={index}
                        processorConfigProperty={prop}
                      />
                    </div>
                  );
                return null;
              })}
            </div>
          </OPillTabContent>
        </OPillTabBody>
      </OPillTabs>
    );
  }, [fields]);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Configuração de Processador"
          description="Editar Propriedades"
        />
      }
      actions={
        <OButton onClick={() => handleSubmitProperties()}>Salvar</OButton>
      }
    >
      <CardTemplate>
        <FormProvider {...form}>{monitoringContent}</FormProvider>
      </CardTemplate>
    </FormTemplate>
  );
};
