import { OCard, OCardBody } from "@maestro/react";
import { DetailsCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { ContentTemplate } from "templates/content-template";
import { DetailsTemplate } from "templates/details-template";
import { tradeFields } from "../../../../components/trade";
import { ValidationErrorComponent } from "../../../../components/validation-error-component";
import {
  ConsumerUnitsGrid,
  ContactsGrid,
  NotifySignatories,
  TradeGuarantorsGrid,
} from "../../_compose";
import { useEnergyCustomer } from "./trade-customer.hook";
import { energyCustomerFields } from "./trade-customer.details";

export const CustomerTradeDetailsPage = () => {
  const { error, getEntity, hasError, loading, value, tradeId } =
    useEnergyCustomer();

  return (
    <DetailsTemplate
      pageTitle={<PageTitle title="Matriz" />}
      actions={
        value?.response.status === "PENDENTE_ASSINATURA" && (
          <NotifySignatories />
        )
      }
    >
      <div className="d-flex flex-column gap-4 position-relative">
        <ContentTemplate
          loading={loading}
          hasError={hasError}
          errorComponent={
            <OCard>
              <OCardBody>
                <ValidationErrorComponent
                  error={error}
                  title="Erro ao buscar o cliente"
                  callback={getEntity}
                />
              </OCardBody>
            </OCard>
          }
          value={value?.response}
          render={(trade) => (
            <>
              <DetailsCard
                fields={energyCustomerFields}
                value={trade.customer}
              />
              <DetailsCard fields={tradeFields} value={trade} />
              <ConsumerUnitsGrid />
              {tradeId && <TradeGuarantorsGrid tradeId={tradeId} />}
              {trade.customerId && (
                <ContactsGrid customerId={trade.customerId} />
              )}
            </>
          )}
        />
      </div>
    </DetailsTemplate>
  );
};
