import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getProcessorPropertyFieldId } from "../../../utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";

export const useProcessorConfigField = ({
  processorConfigProperty: { processorPropertyId, value },
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { setValue } = useFormContext();
  const {
    value: chidProcessorConfigs,
    callService: getChildProcessorConfigs,
    hasError: hasErrorChidProcessorConfigs,
    loading,
  } = useServiceCall(service.hubCreditManager.getChildProcessorConfigs);

  const processorPropertyFieldId = getProcessorPropertyFieldId(fieldId);

  useEffect(() => {
    hasErrorChidProcessorConfigs &&
      OToastManager.danger(
        "Não foi possivel buscar as opções de configurações de propriedade dos workflows filho. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorChidProcessorConfigs]);

  useEffect(() => {
    if (!chidProcessorConfigs) {
      getChildProcessorConfigs(processorPropertyId);
      return;
    }

    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [
    chidProcessorConfigs,
    value,
    setValue,
    getChildProcessorConfigs,
    processorPropertyId,
    processorPropertyFieldId,
  ]);

  return { chidProcessorConfigs, processorPropertyFieldId, loading };
};
