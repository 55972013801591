import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { NavigateFunction } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { BannerTemplate } from "services/hubcreditmanager/models/responses";

export const bannerTemplatesGrid = (
  navigate: NavigateFunction,
  handleDeleteTemplate: (values: BannerTemplate) => Promise<void>,
) => {
  return {
    datagrid: {
      noDataText: "Nenhum template encontrado.",
      dataSource: dataSourceCustomStoreGenerator(() => {
        return service.hubCreditManager.getBannerTemplates().catch(() => {
          throw new Error("Erro ao buscar templates.");
        });
      }),
      headerFilter: { visible: true, allowSearch: true },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        caption: "Ações",
        alignment: "center",
        cellRender: ({ data }) => {
          return (
            <DataGridAction
              actions={[
                {
                  icon: { category: "orq", icon: "orq-edit-pencil" },
                  label: "Editar",
                  role: roles.workflow.product.editCampaignTemplatePage.role,
                  onClick: () =>
                    navigate(
                      corporateRouter.routes.workflow.product.bannerManagement.bannerTemplates.edit.path(
                        {
                          id: Number(data.id),
                        },
                      ),
                    ),
                },
                {
                  icon: { category: "orq", icon: "orq-edit-trash" },
                  label: "Excluir",
                  role: "creditManager:BannerTemplate.Delete",
                  onClick: () => handleDeleteTemplate(data),
                },
              ]}
            />
          );
        },
      },
      {
        sortOrder: "asc",
        dataField: "id",
        caption: "Id",
      },
      {
        dataField: "identification",
        caption: "Identificação",
      },
      {
        dataField: "description",
        caption: "Descrição",
      },
      {
        dataField: "actionText",
        caption: "Botão de ação",
      },
      {
        dataField: "actionRef",
        caption: "Redirecionamento",
      },
      {
        dataField: "openInNewTab",
        caption: "Abre em nova aba?",
        cellRender: ({ data }) => (data.openInNewTab ? "Sim" : "Não"),
      },
      {
        dataField: "imageAlt",
        caption: "Texto alternativo",
      },
      {
        dataField: "imageFile.name",
        caption: "Nome do arquivo",
      },
    ],
  } satisfies ODataGridGeneratorConfig<
    NonNullable<
      Awaited<
        ReturnType<typeof service.hubCreditManager.getBannerTemplates>
      >["data"]
    >[number]
  >;
};
