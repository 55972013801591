import { OToastManager } from "@maestro/core";
import { SelectSearchMultipleOption } from "components/form";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { HubPartners } from "services/hubpartners/models/types/admin";
import { service } from "services/service";

export const useCategorizationFields = () => {
  const [partnershipTypes, setPartnershipTypes] = useState<
    HubPartners.Admin.PartnershipType[]
  >([]);
  const [originatorSubtypes, setOriginatorSubtypes] = useState<
    HubPartners.Admin.OriginatorSubtype[]
  >([]);
  const [products, setProducts] = useState<HubPartners.Admin.Product[]>([]);
  const [loadingPartnershipTypes, setLoadingPartnershipTypes] = useState(false);
  const [loadingOriginatorSubtypes, setLoadingOriginatorSubtypes] =
    useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);

  const form = useFormContext();
  const {
    formState: { errors },
    setValue,
  } = form;

  const partnershipTypeName = form.watch("partnershipTypeName");

  const partnershipTypeOptions = useMemo(
    () =>
      partnershipTypes?.map<SelectSearchOption<string>>((type) => ({
        label: type.code + " - " + type.name,
        value: type.code,
        onSelect: () => {
          setValue("partnershipTypeName", type.code + " - " + type.name);
          setValue("partnershipTypeCode", type.code);
        },
      })),
    [partnershipTypes, setValue],
  );

  const partnershipTypeError = (errors.partnershipTypeName ??
    null) as FieldError | null;

  const originatorSubtypeOptions = useMemo(
    () =>
      originatorSubtypes?.map<SelectSearchMultipleOption<string>>(
        (subtype) => ({
          label: subtype.code + " - " + subtype.name,
          value: subtype.code,
        }),
      ) ?? [],
    [originatorSubtypes],
  );

  const productOptions = useMemo(
    () =>
      products?.map<SelectSearchMultipleOption<string>>((product) => ({
        label: product.name,
        value: product.code,
      })) ?? [],
    [products],
  );

  const getPartnershipTypes = useCallback(async () => {
    setLoadingPartnershipTypes(true);
    try {
      const { data } = await service.hubPartners.getPartnershipTypes();

      setPartnershipTypes(
        data.data.filter(
          (type) => type.code.includes("FDR") || type.code.includes("PES"),
        ),
      );
    } catch {
      OToastManager.danger("Falha ao obter os tipos de parceria");
    } finally {
      setLoadingPartnershipTypes(false);
    }
  }, []);

  const getOriginatorSubtypes = useCallback(async () => {
    setLoadingOriginatorSubtypes(true);
    try {
      const { data } = await service.hubPartners.getOriginatorSubtypes(true);
      setOriginatorSubtypes(data.data);
    } catch {
      OToastManager.danger("Falha ao obter os subtipos de parceria");
    } finally {
      setLoadingOriginatorSubtypes(false);
    }
  }, []);

  const getProducts = useCallback(async () => {
    setLoadingProducts(true);
    try {
      const { data } = await service.hubPartners.getProducts();
      setProducts(data.data);
    } catch {
      OToastManager.danger("Falha ao obter os produtos");
    } finally {
      setLoadingProducts(false);
    }
  }, []);

  useEffect(() => {
    getPartnershipTypes();
  }, [getPartnershipTypes]);

  useEffect(() => {
    getOriginatorSubtypes();
  }, [getOriginatorSubtypes]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return {
    loadingOriginatorSubtypes,
    loadingPartnershipTypes,
    loadingProducts,
    originatorSubtypeOptions,
    partnershipTypeError,
    partnershipTypeOptions,
    productOptions,
    partnershipTypeName,
  };
};
