import { OBadge } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { HubPartners } from "services/hubpartners/models/types/admin";

export const batchCreateOriginatorsODataGrid =
  (): ODataGridGeneratorConfig<HubPartners.Admin.BatchCreateOriginatorsResponse> => ({
    datagrid: {
      noDataText: "Nenhum originador encontrado.",
      filterRow: {
        visible: true,
      },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao realizar o envio do arquivo.";
      },
    },
    columns: [
      {
        caption: "Nome",
        dataField: "name",
      },
      {
        caption: "CNPJ",
        dataField: "identification",
        cellRender: ({ data }) => masks.cpfCnpj(data.identification),
      },
      {
        caption: "Resultado do processamento",
        dataField: "statusType",
        cellRender: ({ data }) => (
          <OBadge rounded type={data.statusType}>
            {data.status}
          </OBadge>
        ),
      },
    ],
  });
