import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { validators } from "@maestro/utils";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { buildCustomerLink } from "utils/build-customer-link";
import { validationSchema } from "./add.form";
import { FormValuesType } from "./add.types";

export const useAddWithdraw = () => {
  const { t } = useTranslation("receivables", { keyPrefix: "drawee" });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const form = useForm<FormValuesType>({
    resolver: yupResolver(validationSchema(t)),
  });
  const { handleSubmit, watch, setValue } = form;

  const watchIdentificacao = watch("identificacao");

  const submit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      const payload = {
        ...values,
        companyStatus: "AUTORIZADO",
        companyType: "Sacado",
      };

      const {
        data: { identification },
      } = await service.quickfin.setCompanies(payload);

      OToastManager.success("Empresa criada com sucesso");

      navigate(
        buildCustomerLink(
          identification,
          corporateRouter.routes.recebiveis.customer.estrategiaComercial.path,
        ),
      );
    } catch (err) {
      OToastManager.danger("Erro para salvar os dados");
    } finally {
      setLoading(false);
    }
  });

  const getOfficialName = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await service.onboarding.searchLegalEntity({
        taxId: watchIdentificacao,
      });

      setValue("officialName", data.officialName);
    } catch (err) {
      OToastManager.danger("Erro na busca dos dados");
    } finally {
      setLoading(false);
    }
  }, [setValue, watchIdentificacao]);

  useEffect(() => {
    if (watchIdentificacao && validators.cpfCnpj(watchIdentificacao)) {
      getOfficialName();
    }
  }, [getOfficialName, watchIdentificacao]);

  return {
    form,
    submit,
    loading,
  };
};
