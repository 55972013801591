import { PostAdminReceivablesCotacaoResponse } from "services/quickfin/models";

export const mapQuoteDataToOperationDetails = (
  quoteData: PostAdminReceivablesCotacaoResponse,
) =>
  quoteData.map(
    (
      {
        recebivel: {
          dataVencimento,
          valorVencimento,
          dataDesembolso,
          cedenteCnpj,
          sacadoCnpj,
        },
        taxaTotal,
        cotacao,
      },
      idx,
    ) => ({
      receivableId: idx,
      dataVencimento,
      valorVencimento,
      dataDesembolso,
      cedenteIdentificacao: cedenteCnpj,
      sacadoIdentificacao: sacadoCnpj,
      valorDesembolso: cotacao,
      taxaTotal,
    }),
  );

export const mapQuoteDataOverrides = (
  quoteData: PostAdminReceivablesCotacaoResponse,
): Record<number, number> =>
  Object.fromEntries(quoteData.map(({ cotacao }, idx) => [idx, cotacao ?? 0]));
