import { DetailsFields } from "components/details-card";
import { HubPartners } from "services/hubpartners/models/types/admin";
import dayjs from "dayjs";

export const OriginatorFields = (
  originator: HubPartners.Admin.OriginatorDetails,
): DetailsFields => ({
  info: {
    title: "Detalhes",
    items: [
      {
        label: "Tipo do identificador",
        value: originator.identificationType,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Identificador",
        value: originator.identification,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Razão social",
        value: originator.officialName,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Nome fantasia",
        value: originator.tradingName,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Grupo econômico",
        value: originator.economicGroup?.name,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Identificador externo",
        value: originator.identifier,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "TagCode",
        value: originator.tagCode,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Instituição responsável",
        value: originator.mainOriginator?.name,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "CGE",
        value: originator.cge,
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
  categorization: {
    title: "Categorização",
    items: [
      {
        label: "Tipo de Parceria",
        value:
          originator.partnershipTypes
            .map((x) => x.code + " - " + x.name)
            .join(", ") || "-",
        grid: { sm: 6, lg: 4 },
      },
      {
        label: "Subtipos do Originador",
        value:
          originator.originatorSubTypes
            .map((x) => x.code + " - " + x.name)
            .join(", ") || "-",
        grid: { sm: 6, lg: 4 },
      },
      {
        label: "Produtos",
        value: originator.products?.map((x) => x.name).join(", ") || "-",
        grid: { sm: 6, lg: 4 },
      },
    ],
  },
  inication: {
    title: "Indicador",
    items: [
      {
        label: "Originador",
        value: originator.indicatorOriginator?.name,
        grid: { xs: 6, xl: 4 },
      },
      {
        label: "Operador",
        value: originator.indicatorOperator?.name,
        grid: { xs: 6, xl: 4 },
      },
    ],
  },
  audit: {
    title: "Informações de Auditoria",
    items: [
      {
        label: "Data de criação",
        value: dayjs(originator.createdDate).format("DD/MM/YYYY"),
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Criado por",
        value: originator.createdBy,
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Última atualização",
        value: dayjs(originator.updatedDate).format("DD/MM/YYYY") ?? "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Atualizado por",
        value: originator.updatedBy ?? "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
      {
        label: "Data de desativação",
        value: dayjs(originator.deactivatedDate).format("DD/MM/YYYY") ?? "-",
        grid: { sm: 6, md: 4, lg: 3 },
      },
    ],
  },
});
