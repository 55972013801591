import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { InternationalProfile } from "services/onboarding-relationships/models";
import { ProfileMasterDetail } from "./_compose";
import { Checkbox } from "./_compose/checkbox";

export const buildDataSource = (
  setProfiles: (profiles: InternationalProfile[]) => void,
) =>
  dataSourceCustomStoreGenerator<InternationalProfile>(
    () =>
      service.onboardingRelationships
        .getUserProfiles({
          flowIdentification: "INTERNATIONAL",
        })
        .then(({ data }) => {
          setProfiles(data.profiles);
          return data.profiles;
        })
        .catch(() => {
          const errorMessage = "Erro ao buscar as funções";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        key: "uuid",
      },
    },
  );

export const roleAssingmentsGrid: ODataGridGeneratorConfig<InternationalProfile> =
  {
    datagrid: {
      pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [20, 50, 100],
      },
      filterRow: {
        visible: true,
      },
      paging: {
        pageSize: 20,
      },
    },
    columns: [
      {
        cellRender: ({ data }) => <Checkbox profile={data} />,
        width: 40,
        alignment: "center",
      },
      {
        dataField: "nameLabel",
        caption: "Função",
      },
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "groupNameLabel",
        caption: "Categoria",
        groupIndex: 0,
        groupCellRender: ({ displayValue }) => displayValue,
      },
    ],
    masterDetail: {
      enabled: true,
      component: ProfileMasterDetail,
    },
  };
