import { ODataGridGenerator } from "components/data-grid/o-data-grid-generator.component";
import { PageTitle } from "components/page-title";
import dayjs from "dayjs";
import { useGridRef } from "hooks/grid-ref";
import { useMemo, useState } from "react";
import { GridTemplate } from "templates/grid-template";
import { buildDataSource, reconciliationsGrid } from "./reconciliations.grid";
import { DateFilter } from "./_compose";
import { ReconProduct } from "./reconciliations.types";

interface ReconciliationsProps {
  product: ReconProduct;
}

export const Reconciliations = ({ product }: ReconciliationsProps) => {
  const gridRef = useGridRef();
  const [datesInterval, setDatesInterval] = useState<[string, string]>([
    dayjs().startOf("month").format("YYYY/MM/DD"),
    dayjs().endOf("month").format("YYYY/MM/DD"),
  ]);

  const dataSource = useMemo(
    () => buildDataSource(...datesInterval, product),
    [datesInterval, product],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title={product} />}
      gridRef={gridRef}
      showRefreshButton
      actions={
        <DateFilter
          datesInterval={datesInterval}
          setDatesInterval={setDatesInterval}
        />
      }
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={reconciliationsGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
