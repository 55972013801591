import { useGridRef } from "hooks/grid-ref";
import { PageTitle } from "components/page-title";
import { ODataGridGenerator } from "components/data-grid";
import { GridTemplate } from "templates/grid-template";
import { useCustomer } from "contexts/customer";
import { useMemo } from "react";
import { buildWebhooksDataSource, WebhookListGrid} from "./webhooks-listagem.grid";
import { useParams } from "react-router-dom";
import { AppIdentificationRouteParams } from "pages/developers/routes/developers.route-params";

export const WebhookList = () => {
  const gridRef = useGridRef();
  const {
    customer: { identification },
  } = useCustomer();
  const { appId } = useParams<AppIdentificationRouteParams>();
  const dataSource = useMemo(
    () => buildWebhooksDataSource(appId, identification),
    [identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Lista de Webhooks" />}
      gridRef={gridRef}
      showClearFiltersButton
      showRefreshButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={WebhookListGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
