import { OCard, OCardBody, OCardHeader, ORow } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { useCallback, useEffect } from "react";
import { VariableDetails } from "../variable-details";

interface ObjectDetailsProps {
  payload: HubOnboardingRelationships.Admin.ContingencyPayload;
  setPayload: React.Dispatch<
    React.SetStateAction<HubOnboardingRelationships.Admin.ContingencyPayload>
  >;
  variableDetail: HubOnboardingRelationships.Admin.VariableDetail;
}

export const ObjectDetails = ({
  payload,
  setPayload,
  variableDetail,
}: ObjectDetailsProps) => {
  const { description: label, propertyIdentifier: property } = variableDetail;

  useEffect(() => {
    if (!payload[property]) {
      const newPayload = { ...payload };
      newPayload[property] = {};
      setPayload(newPayload);
    }
  }, [payload, property, setPayload]);

  const objectSetPayload = useCallback(
    (newValue: any) => {
      const newPayload = { ...payload };
      newPayload[property] = newValue;
      setPayload(newPayload);
    },
    [payload, property, setPayload],
  );

  return (
    <OCard>
      <OCardHeader>
        <PageTitle title={label} />
      </OCardHeader>
      <OCardBody>
        <ORow>
          {variableDetail.variables.map((variable) => (
            <VariableDetails
              key={variable.propertyIdentifier}
              variableDetail={
                {
                  ...variable,
                } as HubOnboardingRelationships.Admin.VariableDetail
              }
              payload={
                payload[
                  property
                ] as HubOnboardingRelationships.Admin.ContingencyPayload
              }
              setPayload={objectSetPayload}
            />
          ))}
        </ORow>
      </OCardBody>
    </OCard>
  );
};
