import {
  MasterDetailComponentProps,
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { GetApiAdminDesksOnboardingPartnerConfigResponse } from "services/onboarding/models/responses/get-api-admin-desks-onboarding-partner-config.response";

const identificationsGrid: ODataGridGeneratorConfig<
  GetApiAdminDesksOnboardingPartnerConfigResponse[number]["identifications"][number]
> = {
  datagrid: {
    noDataText: "Nenhum identificador",
    pager: {
      infoText: "Página {0} de {1} ({2} identificadores)",
    },
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    wordWrapEnabled: true,
  },
  columns: [
    {
      dataField: "queryParameter",
      caption: "Tipo",
    },
    {
      dataField: "value",
      caption: "Valor",
    },
  ],
};

type IdentificationsMasterDetailProps = MasterDetailComponentProps<
  GetApiAdminDesksOnboardingPartnerConfigResponse[number]
>;

export const IdentificationsMasterDetail = ({
  data: {
    data: { identifications },
  },
}: IdentificationsMasterDetailProps) => {
  return (
    <>
      <PageTitle title="Identificadores para encontrar a configuração" />
      <ODataGridGenerator
        grid={identificationsGrid}
        dataSource={identifications}
      />
    </>
  );
};
