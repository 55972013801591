import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { RouterButton } from "components/router-button";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { currencySymbol } from "utils/currency";
import { Collaterals } from "../../collaterals.types";

export const imovelGrid = (id: string) =>
  ({
    datagrid: {
      noDataText: "Nenhuma garantia encontrada.",
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
        alignment: "left",
      },
      {
        dataField: "amount",
        caption: "Valor",
        format: (value) => masks.currency(value, currencySymbol.BRL),
      },
      {
        dataField: "reviewPrice",
        caption: "Valor no laudo",
        format: (value) => masks.currency(value, currencySymbol.BRL),
      },
      {
        caption: "Porcentagem de garantia",
        dataField: "guaranteedPercentage",
        format: (value) => masks.percentage(value),
      },
      {
        dataField: "description",
        caption: "Descrição",
      },
      {
        cellRender: ({ data }) => data.properties?.[0]?.registration,
        caption: "Matrícula",
      },

      {
        dataField: "lockDisbursement",
        caption: "Trava de desembolso",
      },
      {
        caption: "Opcional",
        dataField: "hasOptional",
        cellRender: ({ data }) => (data.hasOptional ? "Sim" : "Não"),
      },
      {
        caption: "Ações",
        role: roles.emprestimos.product
          .limitManagerLimitsDetailsCollateralsDetailsImovelPage.role,
        cellRender: ({ data }) => (
          <RouterButton
            outline
            dataAction="emprestimos_garantia_imovel_grid:botao:editar"
            dataLabel="editar"
            href={corporateRouter.routes.emprestimos.product.limitManager.limits.details.collaterals.details.imovel.path(
              {
                collateralId: data.id,
                id,
              },
            )}
          >
            Editar
          </RouterButton>
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<Collaterals[number]>);
