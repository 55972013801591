import { PageTitle } from "components/page-title";
import { FavoriteWorkflowsTemplate } from "../_compose/template-workflows-favoritos";

export const FavoriteLoaWorkflows = () => {
  return (
    <>
      <PageTitle title="Procurações físicas" />
      <FavoriteWorkflowsTemplate type="LETTER_OF_ATTORNEY" />
    </>
  );
};
