import { ORFieldUploadInput } from "@maestro/react";
import { helpers } from "@maestro/utils";
import { useMemo } from "react";
import { getProcessorPropertyFieldId } from "../../../utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useFilePropertyField } from "./file-property-field.hook";

export const FilePropertyField = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { processorPropertyId, name, description } = processorConfigProperty;
  const { handleSubmitFile } = useFilePropertyField({
    processorConfigProperty,
    fieldId,
  });

  const processorPropertyFieldId = getProcessorPropertyFieldId(fieldId);

  const filePropertyField = useMemo(() => {
    return (
      <ORFieldUploadInput
        key={name + processorPropertyId}
        id={processorPropertyFieldId}
        name={processorPropertyFieldId}
        label={description}
        inputLabel="Selecionar documento"
        accept=".xls, .xlsx, .csv, .jpg, .jpeg, .bmp, .pdf, .png, .doc, .docx,"
        multiple={false}
        handleAddFile={(e, resolve) => handleSubmitFile(e.detail, resolve)}
        handleDownload={async (file) => {
          const base64 = await helpers.convertFileToBase64(file);
          helpers.downloadBase64(base64, file.name);
        }}
        handleRemove={(file, resolve) => {
          resolve(file);
        }}
      />
    );
  }, [
    name,
    processorPropertyId,
    processorPropertyFieldId,
    description,
    handleSubmitFile,
  ]);

  return <>{filePropertyField}</>;
};
