import { ORFieldInput } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useChangeUserEmailCard } from "./change-user-email-card.hook";

export const ChangeUserEmailCard = () => {
  const { form, onSubmit, loading } = useChangeUserEmailCard();

  return (
    <CardTemplate>
      <FormTemplate
        pageTitle={
          <PageTitle
            title="Transacional BTG Empresas"
            description="Atualize o e-mail transacional e também o e-mail de login para o e-mail informado"
          />
        }
        actions={
          <LoadingButton loading={loading} onClick={() => onSubmit()}>
            Salvar
          </LoadingButton>
        }
      >
        <FormProvider {...form}>
          <ORFieldInput
            tag="text"
            id="oldUserEmail"
            name="oldUserEmail"
            label="E-mail atual"
            disabled
          />
          <ORFieldInput
            tag="text"
            id="newUserEmail"
            name="newUserEmail"
            label="E-mail novo"
            disabled={loading}
          />
          <ORFieldInput
            tag="text"
            id="updatingReason"
            name="updatingReason"
            label="Motivo da atualização"
            disabled={loading}
          />
        </FormProvider>
      </FormTemplate>
    </CardTemplate>
  );
};
