import { OOptionMultiple, OSelectMultiple, OTypography } from "@maestro/react";
import { ColumnEditCellTemplateData } from "devextreme/ui/data_grid";
import { LimitContractTemplates } from "services/hubcreditmanager/models/types/limit/limit.types";

interface LoanContractsTemplatesMultiselectProps {
  editCellData: ColumnEditCellTemplateData<LimitContractTemplates>;
  options: {
    label: string;
    value: string;
  }[];
}

export const ContractTemplateMultiselect = ({
  editCellData: { value, setValue },
  options,
}: LoanContractsTemplatesMultiselectProps) => {
  return (
    <OSelectMultiple
      value={value ?? []}
      onInput={(e) => {
        const targetValue = (e.target as HTMLOSelectMultipleElement)?.value;

        if (targetValue?.join("") === value?.join("")) return;

        setValue(targetValue);
      }}
    >
      {options?.map((r) => (
        <OOptionMultiple value={r.value} key={`child-options-${r.value}`}>
          <OTypography>{r.label}</OTypography>
        </OOptionMultiple>
      ))}
    </OSelectMultiple>
  );
};
