import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getProcessorPropertyFieldId } from "../../../utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";

export const useWorkflowConfigField = ({
  processorConfigProperty: { processorPropertyId, value },
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { setValue } = useFormContext();
  const {
    value: chidWorkflowConfigs,
    callService: getChildWorkflowConfigs,
    hasError: hasErrorChidWorkflowConfigs,
    loading,
  } = useServiceCall(service.hubCreditManager.getChildWorkflowConfigs);

  const processorPropertyFieldId = getProcessorPropertyFieldId(fieldId);

  useEffect(() => {
    hasErrorChidWorkflowConfigs &&
      OToastManager.danger(
        "Não foi possivel buscar as opções de workflows filhos. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorChidWorkflowConfigs]);

  useEffect(() => {
    if (!chidWorkflowConfigs) {
      getChildWorkflowConfigs(processorPropertyId);
      return;
    }

    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [
    chidWorkflowConfigs,
    getChildWorkflowConfigs,
    processorPropertyFieldId,
    processorPropertyId,
    setValue,
    value,
  ]);

  return { chidWorkflowConfigs, processorPropertyFieldId, loading };
};
