import { TFunction } from "i18next";
import * as yup from "yup";
import { FormValuesType } from "./add.types";

export const validationSchema = (
  t: TFunction,
): yup.ObjectSchema<FormValuesType> =>
  yup.object({
    companyEntityType: yup.string().required(t("validation.requiredField")),
    identificacao: yup.string().required(t("validation.requiredField")),
    officialName: yup.string().required(t("validation.requiredField")),
    country: yup
      .string()
      .required(t("validation.requiredField"))
      .test(
        "country",
        t("validation.countryMessageFirst"),
        (value, context) => {
          return !(
            context.parent.companyEntityType === "Offshore" && value === "BR"
          );
        },
      )
      .test(
        "country",
        t("validation.countryMessageSecond"),
        (value, context) => {
          return !(
            context.parent.companyEntityType !== "Offshore" && value !== "BR"
          );
        },
      ),
  });
