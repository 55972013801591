import { service } from "services";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { OToastManager } from "@maestro/react";
import { corporateRouter } from "routes/corporate-router.context";
import { DataGridAction } from "components/datagrid-action";
import { WebhookInList, Resource } from "services/developers/models";
export const buildWebhooksDataSource = (appId: any, identification: string) =>
  dataSourceCustomStoreGenerator<Resource<WebhookInList>>(() =>
    service.developers.getWebhooksByApp(appId, identification)
    .then(({data}) => ({
        data: data.items ?? [],
        totalCount: data.items.length
    }))
    .catch(() => {
        const errorMessage = "Erro ao buscar webhook";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage)
    })
  );
const buildDetailsLink = (appId: string, webhookId: string) =>
  corporateRouter.routes.developers.customer.listagemApps.listagemWebhook.details.path({appId, webhookId})

export const WebhookListGrid: ODataGridGeneratorConfig<WebhookInList> = {
  datagrid: {
    noDataText: "Nenhum Webhooks encontrado",
    filterRow: { visible: true },
    headerFilter: { visible: true },
  },
  columns: [
    {
        caption: "Descrição",
        dataField: "description",
        alignment: "center",
        dataType: "string",
    },
    {
      caption: "Webhook Id",
      dataField: "webhookId",
      alignment: "center",
      dataType: "string",
    },
    {
      caption: "Ações",
      alignment: "center",
      allowExporting: false,
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "far", icon: "fa-link" },
              label: "Detalhes",
              route: buildDetailsLink(data.appId, data.webhookId),
            },
          ]}
        />
      ),
    },
  ],
};
