import { corporateRouter } from "routes/corporate-router.context";

export const redirectWorkflowDetailsMap = (
  type: HubOnboardingRelationships.Admin.RelationshipType,
  workflowUuid: string,
) =>
  ({
    GUARANTOR:
      corporateRouter.routes.cadastro.customer.guarantors.guarantorsDetails.path(
        {
          guarantorUuid: workflowUuid,
        },
      ),
    LETTER_OF_ATTORNEY:
      corporateRouter.routes.cadastro.customer.lettersOfAttorney.vintage.embedded.path(
        {
          uuid: workflowUuid,
        },
      ),
    ROLEASSIGNMENT:
      corporateRouter.routes.cadastro.customer.lettersOfAttorney.electronic
        .path,
  }[type]);
