import { ProcessorConfig } from "services/hubcreditmanager/models/types/workflow/canvas.types";
import { getValueFromMap } from "utils/get-value-from-map";
import { mapProcessorType } from "../../canvas.utils";

export const searchCharactersToIgnore = /[^0-9A-Z]+/gi;

export function replaceAccents(str: string) {
  if (!str) return;

  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(searchCharactersToIgnore, "");
}

const preprocessNodeData = (nodeData: ProcessorConfig) => {
  const processorType = nodeData.processor?.type
    ? getValueFromMap(mapProcessorType, nodeData.processor.type)
    : "";

  return replaceAccents(
    [nodeData.name, String(nodeData.id), processorType].join(""),
  );
};

export const matchesSearchCriteria = (
  nodeData: ProcessorConfig,
  value?: string,
) => {
  const contentCleanedValue = preprocessNodeData(nodeData);
  const regexMatch = contentCleanedValue?.match(
    RegExp(replaceAccents(String(value)) ?? "", "i"),
  );

  return regexMatch || String(nodeData.name) === value;
};
