import { OToastManager } from "@maestro/core";
import { validators } from "@maestro/utils";
import { SelectSearchMultipleOption } from "components/form";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { service } from "services";
import { InternationalUserForm } from "../../add-international-user.type";

export const useBasicFields = () => {
  const [disableEmail, setDisableEmail] = useState(false);
  const [phoneDefaultValue, setPhoneDefaultValue] = useState("");

  const { callService: getUserData, loading: getUserDataLoading } =
    useServiceCall(service.onboardingRelationships.getUserData);
  const { callService: getCountries, value } = useServiceCall(
    service.onboarding.getCountries,
  );

  const form = useFormContext<InternationalUserForm>();
  const {
    clearErrors,
    formState: { errors },
    register,
    setError,
    setValue,
    watch,
  } = form;
  const watchIdentification = watch("identification");

  const phoneError = (errors.phoneNumber ?? null) as FieldError | null;

  const onChangePhone = useCallback(
    (newPhone: string) => {
      setValue("phoneNumber", newPhone);
    },
    [setValue],
  );

  const setInitialData = useCallback(async () => {
    setPhoneDefaultValue("");
    if (validators.cpfCnpj(watchIdentification)) {
      const { success, response } = await getUserData({
        taxId: watchIdentification,
      });
      if (success) {
        setValue("name", response.data.name);
        setValue("email", response.data.email);
        setPhoneDefaultValue(response.data.phone);
        setValue("phoneNumber", response.data.phone);
        clearErrors("identification");

        setDisableEmail(!!response.data?.email);
      } else {
        setError("identification", {
          message: "Não foi possível encontrar o CPF",
        });
      }
    }
  }, [clearErrors, getUserData, setError, setValue, watchIdentification]);

  const getAllCountries = useCallback(async () => {
    const { success, response } = await getCountries();
    if (!success) {
      return OToastManager.danger("Não foi possível encontrar os países");
    }

    return response.data;
  }, [getCountries]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  useEffect(() => {
    getAllCountries();
  }, [getAllCountries]);

  const countries = useMemo(
    () =>
      value
        ?.filter((country) => country.code !== "BR")
        .map<SelectSearchMultipleOption<string>>((country) => ({
          customKey: country.code,
          label: `${country.code?.toUpperCase()} - ${country.name?.toUpperCase()}`,
          value: country.code,
        })) ?? [],
    [value],
  );

  return {
    countries,
    disableEmail,
    getUserDataLoading,
    onChangePhone,
    phoneDefaultValue,
    phoneError,
    register,
  };
};
