import { OLoader } from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useCreditLineField } from "./credit-line-field.hook";

export const CreditLineField = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { description } = processorConfigProperty;
  const { creditLines, loadingCreditLines, processorPropertyFieldId } =
    useCreditLineField({
      processorConfigProperty,
      fieldId,
    });

  const options = useMemo(
    () =>
      creditLines?.map(({ identification }) => {
        return {
          value: identification,
          label: identification,
        };
      }) ?? [],
    [creditLines],
  );

  const creditLineField = useMemo(
    () => (
      <div className="d-flex align-items-center">
        <SelectSearchField
          key={creditLines
            ?.map(({ identification }) => identification)
            .join("")}
          id={processorPropertyFieldId}
          name={processorPropertyFieldId}
          placeholder="Selecione"
          label={description}
          options={options}
        />
        {loadingCreditLines && <OLoader />}
      </div>
    ),
    [
      creditLines,
      processorPropertyFieldId,
      description,
      options,
      loadingCreditLines,
    ],
  );

  return <>{creditLineField}</>;
};
