import { ODivider, OTypography } from "@maestro/react";
import { MenuContainer } from "./menu.styles";
import { MenuConfig } from "./menu.types";
import { GoBack, MenuItem } from "./_compose";

interface MenuProps {
  config: MenuConfig;
}

export const Menu = ({ config }: MenuProps) => {
  return (
    <MenuContainer className="d-flex flex-column gap-2">
      {config.previous && (
        <>
          <GoBack
            link={config.previous.link}
            onClick={config.previous.onClick}
          />
          <ODivider size="xxs" className="mt-2 mb-4" />
          <OTypography type="dark" className="py-2" key={config.previous.label}>
            {config.previous.label}
          </OTypography>
        </>
      )}
      {config.items.map((item) => (
        <MenuItem
          key={item.link}
          active={item.active}
          label={item.label}
          link={item.link}
          hasSubItems={item.hasSubItems}
          onClick={item.onClick}
          i18nKey={item.i18nKey}
        />
      ))}
    </MenuContainer>
  );
};
