import { OIcon } from "@maestro/react";
import { useLinkNewTabHandler } from "hooks/link-new-tab-handler";
import { useTranslation } from "react-i18next";
import { useLinkClickHandler } from "react-router-dom";
import { MenuLink } from "./menu-item.styles";

interface MenuItemProps {
  label: string;
  active?: boolean;
  link: string;
  hasSubItems?: boolean;
  onClick?: () => void;
  i18nKey?: string;
}

export const MenuItem = ({
  active,
  hasSubItems,
  label,
  link,
  onClick,
  i18nKey,
}: MenuItemProps) => {
  const { t } = useTranslation();
  const handler = useLinkClickHandler(link);
  return (
    <MenuLink
      $active={active}
      href={link}
      onClick={(evt) => {
        if (onClick) {
          evt.preventDefault();
          evt.stopPropagation();
          onClick();
          return;
        }
        handler(evt);
      }}
      onAuxClick={useLinkNewTabHandler(link)}
    >
      {i18nKey ? t(i18nKey) : label}
      {hasSubItems && (
        <OIcon category="orq" icon="orq-chevron-right" size="md" />
      )}
    </MenuLink>
  );
};
