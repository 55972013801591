import * as yup from "yup";
import { checkFiles } from "./batch-create-originators.utils";

export const batchCreateOriginatorsFormFields = {
  originators: {
    name: "originators",
    id: "originators",
    dataAction: "b2b-originadores-cadastro-em-lote:upload:originators",
    dataLabel: "originators",
    inputLabel: "Selecione o arquivo CSV.",
    accept: ".csv",
    tip: "Serão aceitos documentos com no máximo 5mb, no formato .csv",
    validator: yup
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .array<any>()
      .required("Este campo é obrigatorio")
      .test("check-files", "Envie pelo menos 1 arquivo", checkFiles),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialValue: [] as any[],
  },
};

export const batchCreateOriginatorsValidationSchema = yup.object({
  originators: batchCreateOriginatorsFormFields.originators.validator,
});

export const batchCreateOriginatorsFormDefaultValues = {
  originators: batchCreateOriginatorsFormFields.originators.initialValue,
};
