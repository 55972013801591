import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager, OTypography } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import {
  LoanContractsConfigForm,
  LoanContractsConfigFormValues,
} from "../../../../../../components/loan-contracts-config/loan-contracts-config-form.component";
import { loanContractsConfigValidationFormSchema } from "../../../../../../components/loan-contracts-config/loan-contracts-config-form.schemas";
import {
  LoanContractsTemplatesForm,
  LoanContractsTemplatesFormValues,
} from "../../../../../../components/loan-contracts-templates/loan-contracts-templates-form.component";
import { loanContractsTemplatesValidationFormSchema } from "../../../../../../components/loan-contracts-templates/loan-contracts-templates-form.schemas";
import { EmprestimosProdutoContingenciaTemplatesDeContratoByLoanContractsIdEditarRouteQueryParams } from "../../../../../../routes/emprestimos.route-params";

type LoanEditContractsConfigFormValues = LoanContractsConfigFormValues &
  LoanContractsTemplatesFormValues;

export const LoanEditContractsConfigPage = () => {
  const [loading, setLoading] = useState(false);
  const { value, hasError } =
    useParseFromSearchParams<EmprestimosProdutoContingenciaTemplatesDeContratoByLoanContractsIdEditarRouteQueryParams>(
      "data",
    );

  const form = useForm<LoanEditContractsConfigFormValues>({
    resolver: yupResolver(
      loanContractsConfigValidationFormSchema.concat(
        loanContractsTemplatesValidationFormSchema,
      ),
    ),
  });

  const { reset, handleSubmit } = form;

  const editContractTemplateConfig = useCallback(
    async (values: LoanEditContractsConfigFormValues) => {
      try {
        setLoading(true);

        const contractTemplates = values.contractTemplates.map((c) => ({
          ...c,
          contractAdditionalSigners:
            c.contractAdditionalSigners?.map((ca) => ({
              ...ca,
              signerCompanyTaxId: ca.contractSigner.signerCompany.taxId,
              partyCompanyTaxId: ca.contractSigner.partyCompany.taxId,
            })) ?? [],
        }));

        await service.hubLoan.updateLoanContractsConfig({
          id: value?.id ?? 0,
          ...values,
          contractTemplates,
        });

        OToastManager.success(
          "Configuração de contratos de empréstimo editada com sucesso.",
        );
      } catch {
        OToastManager.danger(
          "Não foi possível editar configuração de contratos de empréstimo.",
        );
      } finally {
        setLoading(false);
      }
    },
    [value?.id],
  );

  useEffect(() => {
    value && reset(value);
  }, [reset, value]);

  return (
    <ContentTemplate
      hasError={hasError}
      loading={!value && !hasError}
      noValue
      render={() => (
        <FormTemplate
          pageTitle={
            <PageTitle title="Editar configurações de contrato de empréstimo" />
          }
          actions={
            <LoadingButton
              loading={loading}
              onClick={handleSubmit(editContractTemplateConfig)}
            >
              Editar
            </LoadingButton>
          }
        >
          <CardTemplate>
            <FormProvider {...form}>
              <LoanContractsConfigForm />

              <OTypography>Templates de contrato</OTypography>
              <LoanContractsTemplatesForm edit />
            </FormProvider>
          </CardTemplate>
        </FormTemplate>
      )}
    />
  );
};
