import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { GridTemplate } from "templates/grid-template";
import { NoAccountRegisteredTemplate } from "../../../../components/no-account-registered-template";
import { useCreditCardPaymentsContext } from "../../../../../../contexts/cliente/credit-card-payments.context";
import { installmentGrid } from "./installment-grid.grid";

export const InstallmentGrid = () => {
  const gridRef = useGridRef();
  const { selectedPayment } = useCreditCardPaymentsContext();

  return (
    <NoAccountRegisteredTemplate
      children={
        <GridTemplate
          pageTitle={
            <OTypography size="xl" type="dark">
              Parcelas do cartão de crédito
            </OTypography>
          }
          gridRef={gridRef}
          showRefreshButton
          showClearFiltersButton
        >
          <ODataGridGenerator
            gridRef={gridRef}
            grid={installmentGrid}
            dataSource={selectedPayment?.installments}
          />
        </GridTemplate>
      }
    />
  );
};
