import { yupResolver } from "@hookform/resolvers/yup";
import { IFileUpload, OToastManager, OUploadCustomEvent } from "@maestro/core";
import { isAxiosError } from "axios";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services/service";
import uniqueId from "lodash/uniqueId";
import { HubPartners } from "services/hubpartners/models/types/admin";
import {
  batchCreateOriginatorsFormDefaultValues,
  batchCreateOriginatorsValidationSchema,
} from "./batch-create-originators.form";

export const useBatchCreateOriginators = () => {
  const [start, setStart] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [file, setFile] = useState<FormData | undefined>();
  const [batchResult, setBatchResult] = useState<
    HubPartners.Admin.BatchCreateOriginatorsResponse[] | undefined
  >();

  const form = useForm({
    resolver: yupResolver(batchCreateOriginatorsValidationSchema),
    defaultValues: batchCreateOriginatorsFormDefaultValues,
    mode: "onBlur",
  });

  const { reset } = form;

  const handleUpload = async ({
    e,
    resolve,
  }: {
    e: OUploadCustomEvent<File>;
    resolve: (file: IFileUpload) => void;
  }) => {
    const uploadedFile = e.detail;

    const dataForm = new FormData();
    dataForm.append("formFile", uploadedFile);

    setFile(dataForm);

    resolve(Object.assign(uploadedFile, { key: uniqueId() }));
  };

  const handleReset = useCallback(() => {
    reset();
    setBatchResult(undefined);
    setFile(undefined);
  }, [reset]);

  const handleSubmit = async () => {
    try {
      if (!file) {
        return;
      }
      setHasError(false);
      setStart(true);

      const { data } = await service.hubPartners.batchCreateOriginators(file);

      reset();
      setBatchResult(data.data);

      OToastManager.success("Arquivo processado com sucesso!");
    } catch (err) {
      if (
        isAxiosError<
          | HubPartners.Admin.ErrorResponse
          | HubPartners.Admin.AlternateErrorResponse
        >(err)
      ) {
        if (err.response && err.response.data) {
          if ("failures" in err.response.data) {
            err.response.data.failures?.forEach((failure) => {
              failure.message && OToastManager.danger(failure.message);
            });
          } else if ("message" in err.response.data) {
            OToastManager.danger(
              err.response.data.message ??
                "Não foi realizar o cadastro em lote.",
            );
          }
        }
      } else {
        OToastManager.danger("Não foi realizar o cadastro em lote.");
      }
      handleReset();
      setHasError(true);
    } finally {
      setStart(false);
    }
  };

  return {
    form,
    start,
    handleUpload,
    batchResult,
    setStart,
    hasError,
    handleReset,
    handleSubmit,
    file,
  };
};
