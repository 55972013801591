export const defaultUserNotificationTypeData = [
  {
    label: "Default",
    value: "",
  },
  {
    label: "Email",
    value: "EMAIL",
  },
  {
    label: "WhatsApp",
    value: "WHATSAPP",
  },
];

export const backButtonActionTypes = [
  {
    label: "Padrão",
    value: "DEFAULT",
  },
  {
    label: "Eventos",
    value: "EVENT",
  },
];

export const summaryTypes = [
  {
    label: "Padrão",
    value: "DEFAULT",
  },
  {
    label: "Quantidade",
    value: "AMOUNT",
  },
];
