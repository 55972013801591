import { ODataGridGeneratorConfig } from "components/data-grid";
import { Collaterals } from "../../collaterals.types";

export const cardsGrid = {
  datagrid: {
    noDataText: "Nenhuma garantia encontrada.",
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
      alignment: "left",
    },
    {
      dataField: "identification",
      caption: "Identificação",
    },
    {
      dataField: "lockDisbursement",
      caption: "Trava de desembolso",
    },
    {
      dataField: "description",
      caption: "Descrição",
    },
    {
      caption: "Opcional",
      dataField: "hasOptional",
      cellRender: ({ data }) => (data.hasOptional ? "Sim" : "Não"),
    },
  ],
} satisfies ODataGridGeneratorConfig<Collaterals[number]>;
