import { ODataGridGeneratorConfig } from "components/data-grid";
import { masks } from "@maestro/utils";
import { InstallmentActionButtons } from "./installment-action-buttons/installment-action-buttons.component";
import { defaultPageSize } from "../../../credit-card-payments.type";

export const installmentGrid: ODataGridGeneratorConfig<BankingHubCards.CreditCardPaymentInstallment> =
  {
    datagrid: {
      noDataText: "Nenhuma parcela encontrada",
      headerFilter: {
        visible: true,
      },
      filterRow: {
        visible: true,
      },
      pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [5, 10, 20, 40, 100],
        showInfo: true,
      },
    },
    columns: [
      {
        dataField: "statementId",
        caption: "Id da fatura",
        allowSorting: true,
        allowHeaderFiltering: false,
        allowFiltering: false,
      },
      {
        dataField: "amount",
        caption: "Valor",
        cellRender: (option) => masks.currency(option.value, "R$", ","),
        allowSorting: true,
        allowHeaderFiltering: false,
        allowFiltering: false,
      },
      {
        dataField: "isPaid",
        caption: "Pago?",
        format: "boolean",
        allowSorting: false,
        allowHeaderFiltering: false,
        allowFiltering: false,
      },
      {
        caption: "Ações",
        cellRender: ({ data, component }) => {
          if (data.isPaid) return null;

          return (
            <InstallmentActionButtons
              installmentId={data.id}
              reload={() => component.getDataSource().reload()}
            />
          );
        },
      },
    ],
    paging: {
      enabled: true,
      defaultPageSize,
    },
  };
