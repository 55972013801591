import { RouterLink } from "components/router-link";
import { useTranslation } from "react-i18next";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { HeaderNavigation } from "./tools-menu.styles";

export const ToolsMenu = () => {
  const { t } = useTranslation("layout");

  return (
    <HeaderNavigation className="d-flex align-items-center gap-4">
      <RouterLink
        href={corporateRouter.routes.ferramentas.product.eligibility.path}
        role={roles.ferramentas.product.tab.role}
      >
        {t("header.tools")}
      </RouterLink>
    </HeaderNavigation>
  );
};
