import { ORow } from "@maestro/react";
import styled from "styled-components";

export const StyledRow = styled(ORow)`
  &:not(:last-child) {
    border-bottom: solid var(--theme-dark-10) 2px;
    padding-bottom: var(--font-md);
  }
  margin: var(--font-md) 0px;
`;
