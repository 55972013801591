import { OToastManager } from "@maestro/core";
import { OTypography } from "@maestro/react";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import {
  calculateNameAndTaxIdFilterExpression,
  NameAndTaxIdFormatted,
} from "components/name-and-tax-id-formatted";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { IdentificationsMasterDetail } from "./identifications.master-detail";

export const dataSource = dataSourceCustomStoreGenerator(() =>
  service.onboarding
    .getDeskOnboardingPartnerConfig()
    .then(({ data }) => data ?? [])
    .catch(() => {
      const validator = "Erro ao buscar as informações de conta";

      OToastManager.danger(validator);
      throw new Error(validator);
    }),
);

export const deskPartnerGrid: ODataGridGeneratorConfig<
  Awaited<
    ReturnType<typeof service.onboarding.getDeskOnboardingPartnerConfig>
  >["data"][number]
> = {
  datagrid: {
    noDataText: "Nenhuma informação de conta",
    headerFilter: { visible: true },
    filterRow: { visible: true },
  },
  columns: [
    {
      role: roles.ferramentas.product
        .onboardingWorkflowsConfigsPartnerConfigDetailsPage.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.ferramentas.product.onboarding.workflowsConfigs.deskPartnerConfig.details.path(
            {
              uuid: data.uuid,
            },
          )}
        />
      ),
    },
    {
      dataField: "flow",
      caption: "Fluxo",
    },
    {
      dataField: "partnerTypeCode",
      caption: "Tipo de parceiro",
    },
    {
      dataField: "entityType",
      caption: "Tipo de entidade",
    },
    {
      caption: "Instituição Responsável",
      calculateFilterExpression: calculateNameAndTaxIdFilterExpression(
        ["legalEntityResponsibleInstitutionOfficialName"],
        "legalEntityResponsibleInstitutionTaxId",
      ),
      cellRender: ({ data }) => (
        <NameAndTaxIdFormatted
          name={data.legalEntityResponsibleInstitutionOfficialName}
          taxId={data.legalEntityResponsibleInstitutionTaxId}
        />
      ),
    },
    {
      caption: "Configuração",
      cellRender: ({ data }) => (
        <div className="d-flex flex-column gap-2">
          <OTypography>{`${data.deskOnboardingConfigConfigIdentifier} - ${data.deskOnboardingConfigConfigName}`}</OTypography>
          <OTypography type="default">
            {data.deskOnboardingConfigConfigDescription}
          </OTypography>
        </div>
      ),
      calculateFilterExpression: (filterValue) => [
        ["deskOnboardingConfigConfigIdentifier", "contains", filterValue],
      ],
    },
  ],
  masterDetail: {
    enabled: true,
    component: IdentificationsMasterDetail,
  },
};
