export const getDescription = (
  variables: Record<string, string>,
  templateDescription?: string,
) => {
  return templateDescription
    ?.replace(
      "[[temaPrincipal]]",
      variables.temaPrincipal || "[tema principal]",
    )
    .replace("[[data]]", variables.data || "[data]")
    .replace("[[hora]]", variables.hora || "[hora]");
};
