import { ODataGridGeneratorConfig } from "components/data-grid";
import { ExternalIdentification } from "services/hubloan/models/responses/get-admin-external-ids-by-loan-id.response";

export const externalIdentificationsGrid = {
  datagrid: {
    noDataText: "Nenhuma identificação externa encontrada",
  },
  columns: [
    {
      dataField: "origin",
      caption: "Origem",
    },
    {
      dataField: "identification",
      caption: "Identificação",
    },
  ],
} satisfies ODataGridGeneratorConfig<ExternalIdentification>;
