import { OCol, ORFieldInputDateRange } from "@maestro/react";
import { useTranslation } from "react-i18next";

export const DateInputs = () => {
  const { t, i18n } = useTranslation("receivables", { keyPrefix: "noteNote" });
  const startDatePlaceholder = t("filter.startDatePlaceholder");
  const endDatePlaceholder = t("filter.endDatePlaceholder");
  return (
    <>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="tradeDate"
          name="tradeDate"
          label={t("filter.tradeDate")}
          placeholder={[startDatePlaceholder, endDatePlaceholder]}
          language={i18n.language}
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="disbursementDate"
          name="disbursementDate"
          label={t("filter.disbursementDate")}
          placeholder={[startDatePlaceholder, endDatePlaceholder]}
          language={i18n.language}
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="maturityDate"
          name="maturityDate"
          label={t("filter.maturityDate")}
          placeholder={[startDatePlaceholder, endDatePlaceholder]}
          language={i18n.language}
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="createdAt"
          name="createdAt"
          label={t("filter.createdAt")}
          placeholder={[startDatePlaceholder, endDatePlaceholder]}
          language={i18n.language}
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <ORFieldInputDateRange
          allowClear
          id="updatedAt"
          name="updatedAt"
          label={t("filter.updatedAt")}
          placeholder={[startDatePlaceholder, endDatePlaceholder]}
          language={i18n.language}
        />
      </OCol>
    </>
  );
};
