import { HubPartners } from "services/hubpartners/models/types/admin";
import * as yup from "yup";

export type EditOriginatorSubtypeFormFields =
  HubPartners.Admin.OriginatorSubtype;

export const editOriginatorSubtypeSchema: yup.ObjectSchema<EditOriginatorSubtypeFormFields> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    code: yup.string().required("Este campo é obrigatório"),
    uuid: yup.string().required("Este campo é obrigatório"),
    deskCode: yup.string(),
    deskName: yup.string(),
  });
