import { HubPartners } from "services/hubpartners/models/types/admin";
import * as yup from "yup";

export type EditDeskFormFields = HubPartners.Admin.Desk;

export const editDeskSchema: yup.ObjectSchema<EditDeskFormFields> = yup.object({
  name: yup.string().required("Este campo é obrigatório"),
  code: yup.string().required("Este campo é obrigatório"),
  uuid: yup.string().required("Este campo é obrigatório"),
});
