import {
  OCol,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { fidcAnalysisMap } from "../../../../../../../../utils/scf-configuration-maps";
import { generateUuidV4 } from "../../../../workflow-configuration-scf.utils";
import { BaseStepFields } from "./base-step-fields.component";

interface TomadorCreditoRiskAnalysisStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const TomadorCreditoRiskAnalysisStep = ({
  fieldName,
  index,
}: TomadorCreditoRiskAnalysisStepProps) => {
  const { getValues, setValue } = useFormContext();

  const fieldNameByIndex = `${fieldName}.${index}`;

  useEffect(() => {
    setValue(
      `${fieldNameByIndex}._t`,
      "FraudSysTomadorDeCreditoWorkflowConfigurationStep",
    );
    if (!getValues(`${fieldNameByIndex}.stepId`)) {
      setValue(`${fieldNameByIndex}.stepId`, `risk-${generateUuidV4()}`);
    }
    if (!getValues(`${fieldNameByIndex}.nextStepId`)) {
      setValue(`${fieldNameByIndex}.nextStepId`, null);
    }
  }, [fieldNameByIndex, getValues, setValue]);

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="TOMADOR_CREDITO_RISKANALYSIS"
      />
      <OCol xs={4}>
        <ORFieldInput
          tag="text"
          id={`${fieldNameByIndex}.segment`}
          name={`${fieldNameByIndex}.segment`}
          label="Segmento"
        />
      </OCol>
      <OCol xs={4}>
        <ORFieldInput
          tag="text"
          id={`${fieldNameByIndex}.coSegment`}
          name={`${fieldNameByIndex}.coSegment`}
          label="Co-segmento"
        />
      </OCol>
      <OCol xs={4}>
        <ORFieldSelect
          id={`${fieldNameByIndex}.riskAnalysisType`}
          name={`${fieldNameByIndex}.riskAnalysisType`}
          label="Nível de risco"
        >
          {Object.entries(fidcAnalysisMap).map(([value, label]) => (
            <OOption key={`onboarding-status-${value}`} value={value}>
              {label}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
    </ORow>
  );
};
