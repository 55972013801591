import * as yup from "yup";

export interface DeskConfigPipelineFormFields {
  deskOnboardingConfigIdentifier: string;
  pipelineUuid: string;
  order: number;
  pipelineType: string;
  pipelineArgument: unknown;
}

export const deskConfigPipelineFormValidationSchema: yup.ObjectSchema<DeskConfigPipelineFormFields> =
  yup.object({
    deskOnboardingConfigIdentifier: yup
      .string()
      .required("Este campo é obrigatório"),
    pipelineUuid: yup.string().required("Este campo é obrigatório"),
    order: yup
      .number()
      .typeError("Este campo é obrigatório")
      .required("Este campo é obrigatório"),
    pipelineType: yup.string().required("Este campo é obrigatório"),
    pipelineArgument: yup
      .mixed((input): input is any => !input || typeof input === "object")
      .transform((value) => {
        if (!value) return null;
        try {
          return JSON.parse(value);
        } catch {
          return "InvalidJson";
        }
      })
      .typeError("Formato inválido")
      .nullable()
      .optional(),
  });
