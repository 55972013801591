import { OToastManager } from "@maestro/core";
import { SelectSearchMultipleOption } from "components/form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { GetAdminProcessorConfigRejectionReasonsResponse } from "services/hubcreditmanager/models/responses/get-admin-processor-config-rejection-reasons.response";
import { getProcessorPropertyFieldId } from "../../../utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";

export const useMultiSelectField = ({
  processorConfigProperty: {
    processorPropertyId,
    processorConfigId,
    value,
    name,
    multiselectType,
  },
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { watch, setValue } = useFormContext();

  const processorPropertyFieldId = getProcessorPropertyFieldId(fieldId);

  const fieldWatch = watch(processorPropertyFieldId);
  const multipleFieldWatch = watch(
    `properties.${name}_${processorPropertyId}_value`,
  );

  const [rejectionReasons, setRejectionReasons] = useState<
    GetAdminProcessorConfigRejectionReasonsResponse[]
  >([]);

  const getRejectionReasons = useCallback(async () => {
    try {
      if (!multiselectType) return;
      const { data } =
        await service.hubCreditManager.getProcessorConfigRejectionReasons();

      setRejectionReasons(data.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (err) {
      OToastManager.danger("Erro para retornar as razões de rejeição.");
    }
  }, [multiselectType]);

  const rejectionReasonsOptions = useMemo(
    () =>
      rejectionReasons.map<SelectSearchMultipleOption<number>>(
        (rejectionReason) => ({
          label: rejectionReason.description,
          value: rejectionReason.id,
        }),
      ) ?? [],
    [rejectionReasons],
  );

  useEffect(() => {
    getRejectionReasons();
  }, [getRejectionReasons]);

  useEffect(() => {
    if (!value) return;
    setValue(processorPropertyFieldId, value);

    setValue(
      `properties.${name}_${processorPropertyId}_value`,
      value.split(",").map(Number),
    );
  }, [value, setValue, processorPropertyId, processorPropertyFieldId, name]);

  useEffect(() => {
    if (!multipleFieldWatch) return;

    setValue(processorPropertyFieldId, multipleFieldWatch.join(","));
  }, [multipleFieldWatch, processorPropertyFieldId, setValue]);

  return {
    fieldWatch,
    processorConfigId,
    rejectionReasonsOptions,
    rejectionReasons,
    processorPropertyFieldId,
  };
};
