import { OTooltip } from "@maestro/react";
import { ClearGridFiltersButton } from "components/clear-grid-filters-button";
import { ExportGridButton } from "components/export-grid-button";
import {
  FullscreenButton,
  FullscreenCallback,
  FullscreenContainer,
} from "components/fullscreen-container";
import { RefreshGridButton } from "components/refresh-grid-button";
import { TooltipContent } from "components/tooltip-icon";
import { useBehavior } from "contexts/behavior";
import { BehaviorRoles } from "contexts/behavior/types";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { GridTemplateProps } from "./grid.types";

export const GridTemplate = ({
  pageTitle,
  actions,
  children,
  gridRef,
  showClearFiltersButton,
  showExportButton,
  showRefreshButton,
}: GridTemplateProps) => {
  const fullscreenCallbackRef = useRef<FullscreenCallback | null>(null);
  const { t } = useTranslation("templates", { keyPrefix: "grid" });
  const { hasRoles } = useBehavior();

  return (
    <div>
      {pageTitle}

      <FullscreenContainer callbackRef={fullscreenCallbackRef}>
        <div className="d-flex justify-content-end align-items-center gap-3 mb-2">
          {actions}
          {showRefreshButton && (
            <OTooltip>
              <TooltipContent>{t("update")}</TooltipContent>
              <RefreshGridButton
                onClick={() =>
                  gridRef.current?.instance.getDataSource().reload()
                }
              />
            </OTooltip>
          )}
          {showExportButton && hasRoles([BehaviorRoles.GridExport_Show]) && (
            <OTooltip>
              <TooltipContent>{t("export")}</TooltipContent>
              <ExportGridButton gridRef={gridRef} />
            </OTooltip>
          )}
          {showClearFiltersButton && (
            <OTooltip>
              <TooltipContent>{t("clear")}</TooltipContent>
              <ClearGridFiltersButton
                onClick={() => gridRef.current?.instance.clearFilter()}
              />
            </OTooltip>
          )}
          <FullscreenButton callbackRef={fullscreenCallbackRef} />
        </div>

        {children}
      </FullscreenContainer>
    </div>
  );
};
