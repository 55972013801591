import common from "./common.json";
import { layout } from "./layout";
import { menu } from "./menu";
import { receivables } from "./receivables";
import { templates } from "./templates";

export const pt = {
  common,
  layout,
  menu,
  receivables,
  templates,
};
