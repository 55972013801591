import { client } from "../../client";
import { hubCreditManagerEndpoints } from "../hubcreditmanager.endpoints";
import {
  GetODataCustomerCertificateAdminParams,
  GetOdataEntityScoresAdminParams,
  GetODataERPConnectionsAdminParams,
  GetOdataLimitsAdminParams,
  GetOdataWorkflowLeadsAdminParams,
  GetOdataWorkflowLeadsBatchRequestsAdminParams,
} from "./models/requests";
import { GetOdataBatchFileTemplatesAdminParams } from "./models/requests/get-odata-batch-file-templates-admin.request";
import { GetOdataDenyListAdminParams } from "./models/requests/get-odata-deny-list-admin.request";
import { GetOdataLimitRequestsInactivesAdminParams } from "./models/requests/get-odata-limit-requests-inactives-admin.request";
import { GetOdataWorkflowLeadsBatchRequestRowsAdminParams } from "./models/requests/get-odata-workflow-leads-batch-request-rows-admin.request";
import {
  GetODataCustomerCertificateAdminResponse,
  GetOdataEntityScoresAdminResponse,
  GetODataERPConnectionAdminResponse,
  GetOdataLimitsAdminResponse,
  GetOdataPublishInvoicesRequestsResponse,
  GetOdataWorkflowLeadsAdminResponse,
  GetOdataWorkflowLeadsBatchRequestsAdminResponse,
} from "./models/responses";
import { GetOdataBatchFileTemplatesAdminResponse } from "./models/responses/get-odata-batch-file-templates-admin.respose";
import { GetOdataDenyListAdminResponse } from "./models/responses/get-odata-deny-list-admin.response";
import { GetOdataLimitsInactivesAdminResponse as GetOdataLimitRequestsInactivesAdminResponse } from "./models/responses/get-odata-limit-requests-inactives-admin.response";
import { GetOdataWorkflowLeadFinancedAssetsAdminResponse } from "./models/responses/get-odata-workflow-lead-financed-assets-admin.response";
import { GetOdataWorkflowLeadsBatchRequestRowsAdminResponse } from "./models/responses/get-odata-workflow-leads-batch-request-rows-admin.response";

export const hubCreditManagerODataService = {
  getLimitsAdmin(params?: GetOdataLimitsAdminParams) {
    return client.get<GetOdataLimitsAdminResponse>(
      hubCreditManagerEndpoints.odata.limitsAdmin,
      { params },
    );
  },
  getLimitRequestsInactivesAdmin(
    params?: GetOdataLimitRequestsInactivesAdminParams,
  ) {
    return client.get<GetOdataLimitRequestsInactivesAdminResponse>(
      hubCreditManagerEndpoints.odata.limitRequestsInactivesAdmin,
      { params },
    );
  },
  getWorkflowLeads(params?: GetOdataWorkflowLeadsAdminParams) {
    return client.get<GetOdataWorkflowLeadsAdminResponse>(
      hubCreditManagerEndpoints.odata.workflowLeadsAdmin,
      { params },
    );
  },
  getWorkflowLeadFinancedAssets(
    workflowLeadId: string | number,
    params?: GetOdataWorkflowLeadsBatchRequestRowsAdminParams,
  ) {
    return client.get<GetOdataWorkflowLeadFinancedAssetsAdminResponse>(
      hubCreditManagerEndpoints.odata.workflowLeadFinancedAssetsAdmin(
        workflowLeadId,
      ),
      { params },
    );
  },
  getWorkflowLeadsBatchRequests(
    params?: GetOdataWorkflowLeadsBatchRequestsAdminParams,
  ) {
    return client.get<GetOdataWorkflowLeadsBatchRequestsAdminResponse>(
      hubCreditManagerEndpoints.odata.workflowLeadsBatchRequestAdmin,
      { params },
    );
  },
  getWorkflowLeadsBatchRequestRows(
    batchRequestId: number,
    params?: GetOdataWorkflowLeadsBatchRequestRowsAdminParams,
  ) {
    return client.get<GetOdataWorkflowLeadsBatchRequestRowsAdminResponse>(
      hubCreditManagerEndpoints.odata.workflowLeadsBatchRequestRowAdmin(
        batchRequestId,
      ),
      { params },
    );
  },
  getBatchFileTemplates(params?: GetOdataBatchFileTemplatesAdminParams) {
    return client.get<GetOdataBatchFileTemplatesAdminResponse>(
      hubCreditManagerEndpoints.odata.batchFileTemplateAdmin,
      { params },
    );
  },
  getEntityScores(params?: GetOdataEntityScoresAdminParams) {
    return client.get<GetOdataEntityScoresAdminResponse>(
      hubCreditManagerEndpoints.odata.entityScoresAdmin,
      { params },
    );
  },
  getPublishInvoicesRequests(params?: GetOdataEntityScoresAdminParams) {
    return client.get<GetOdataPublishInvoicesRequestsResponse>(
      hubCreditManagerEndpoints.odata.publishInvoicesRequestsAdmin,
      { params },
    );
  },
  getDenyList(params?: GetOdataDenyListAdminParams) {
    return client.get<GetOdataDenyListAdminResponse>(
      hubCreditManagerEndpoints.odata.denyListAdmin,
      { params },
    );
  },
  getCustomerCertificates(params?: GetODataCustomerCertificateAdminParams) {
    return client.get<GetODataCustomerCertificateAdminResponse>(
      hubCreditManagerEndpoints.odata.customerCertificateAdmin,
      { params },
    );
  },
  getERPConnections(params?: GetODataERPConnectionsAdminParams) {
    return client.get<GetODataERPConnectionAdminResponse>(
      hubCreditManagerEndpoints.odata.erpConnectionsAdmin,
      { params },
    );
  },
};
