import { OIcon, OTooltip, OTypography } from "@maestro/react";
import { cases, masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { TooltipIcon } from "components/tooltip-icon";
import { LoginInfo } from "services/clerk/models/types";
import { format } from "utils/date";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  emailActivationBadgeMap,
  emailActivationTooltipMap,
} from "../../../utils";
import { CustomerUser } from "../clientes.type";
import {
  baseAccessTooltipMap,
  statusAccountMap,
  statusAccountTooltipMap,
  statusBiometryMap,
  statusBiometryTooltipMap,
} from "../utils";

export const formatUsersField = (user: CustomerUser, loginInfo?: LoginInfo) => {
  const fields: DetailsFields = {
    info: {
      items: [
        {
          label: "Nome",
          value: cases.title(user.name ?? ""),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "CPF",
          value: masks.cpf(user.cpf),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Data de Nascimento",
          value: format.date(user.birthDate, { timeZone: "UTC" }),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Telefone",
          value: masks.phone(user.phone),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "E-mail",
          value: user.email,
          grid: { md: 4, xl: 6 },
        },
      ],
    },
    details: {
      title: "Detalhes usuário",
      items: [
        {
          label: "Status do login",
          value: (
            <span className="d-flex gap-2">
              <OTypography>
                {statusAccountMap[user.accountStatus].label ||
                  user.accountStatus}
              </OTypography>
              {user.accountStatus && (
                <OTooltip floating maxWidth="350px">
                  <OTypography slot="tooltip-content">
                    {statusAccountTooltipMap[user.accountStatus] ||
                      user.accountStatus}
                  </OTypography>
                  <OIcon category="fal" icon="fa-info-circle" />
                </OTooltip>
              )}
            </span>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Último login em",
          value: format.dateTime(user.lastLogin),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Status biometria",
          value: (
            <span className="d-flex gap-2">
              <OTypography>
                {user.biometryStatus
                  ? statusBiometryMap[user.biometryStatus].label
                  : ""}
              </OTypography>
              {user.biometryStatus && (
                <OTooltip floating maxWidth="350px">
                  <OTypography slot="tooltip-content">
                    {statusBiometryTooltipMap[user.biometryStatus] ||
                      user.biometryStatus}
                  </OTypography>
                  <OIcon category="fal" icon="fa-info-circle" />
                </OTooltip>
              )}
            </span>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Ativação do e-mail",
          value: user.emailActivationStatus && (
            <span className="d-flex gap-2">
              <OTypography>
                {getValueFromMap(
                  emailActivationBadgeMap,
                  user.emailActivationStatus,
                )?.label ?? user.emailActivationStatus}
              </OTypography>
              <TooltipIcon
                content={
                  getValueFromMap(
                    emailActivationTooltipMap,
                    user.emailActivationStatus,
                  ) ?? user.emailActivationStatus
                }
              />
            </span>
          ),
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    loginDetails: {
      title: "Detalhes de login",
      items: [
        {
          label: "Base de acesso",
          value: (
            <span className="d-flex gap-2">
              <OTypography>{loginInfo?.idp}</OTypography>
              {loginInfo?.idp ? (
                <OTooltip floating maxWidth="350px">
                  <OTypography slot="tooltip-content">
                    {baseAccessTooltipMap[loginInfo?.idp] || loginInfo?.idp}
                  </OTypography>
                  <OIcon category="fal" icon="fa-info-circle" />
                </OTooltip>
              ) : (
                <OTypography>N/A</OTypography>
              )}
            </span>
          ),
          grid: { md: 4, xl: 3 },
        },
        {
          label: "E-mail para recuperação de senha",
          value: loginInfo ? loginInfo.email : "N/A",
          grid: { md: 4, xl: 3 },
        },
        {
          label: "E-mail confirmado?",
          value: loginInfo ? (loginInfo.emailVerified ? "Sim" : "Não") : "N/A",
          grid: { md: 4, xl: 3 },
        },
      ],
    },
    companyDetails: {
      title: "Detalhes na empresa",
      items: [
        {
          label: "É representante legal?",
          value: user.legalRepresentative ? "Sim" : "Não",
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  };
  return fields;
};
