import type { useRoles } from "hooks/roles";
import type { Routes } from "routes/routes.types";
import { navigateType } from "./navigate-type";
import { RoutesTreeNode } from "./routes-menu.types";

/**
 * Base menuNode depth:
 * - 0 - root
 * - 1 - products (cadastro, cambio, ...)
 * - 2 - product/customer
 * - 3 - actual pages
 */
export const baseDepth = 2;

/**
 * Sort `RoutesTreeNode` children by putting paths with params (colon) last.
 * This makes it so that exact path matches are prioritized over param matches
 * (which could be anything).
 */
const sortPathParamsLast = (arr: RoutesTreeNode[]) => {
  const newArr = [...arr];

  newArr.sort((a, b) => {
    const modifiedA = a.path.replace(/:/g, "\uFFFF");
    const modifiedB = b.path.replace(/:/g, "\uFFFF");

    return modifiedA.localeCompare(modifiedB);
  });

  return newArr;
};

/** Remove starting and trailing slashes and splits on slashes */
export const splitPathChunks = (path: string | undefined) =>
  path?.replace(/^\//, "").replace(/\/$/, "").split("/") ?? [];

const parseRoutesToTree = (
  routes: Routes,
  parent: RoutesTreeNode["parent"],
) => {
  return Object.values(routes).map((route) => {
    const node: RoutesTreeNode = {
      name: route.name,
      path: route.path,
      role: route.role,
      hasElement: route.element !== undefined,
      isNavigateElement:
        route.element !== undefined &&
        (route.element as JSX.Element)?.type === navigateType,
      hasSubItems: !!route.hasSubItems,
      showChildren: !!route.showChildren,
      hideGoBack: !!route.hideGoBack,
      children: [],
      depth: parent.depth + 1,
      parent,
      i18nKey: route.i18nKey,
    };

    node.children = parseRoutesToTree(route.children ?? {}, node);

    return node;
  });
};

export const buildRoutesTree = (routesObject: Routes) => {
  const tree = {
    name: "",
    path: "",
    hasElement: false,
    hasSubItems: false,
    children: [],
    depth: 0,
  } as unknown as RoutesTreeNode; // Root node doesn't have parent

  tree.children = parseRoutesToTree(routesObject, tree);

  return tree;
};

/**
 * Navigates the tree and returns the node matching the current path.
 * Uses `pathChunks`, which is an array resulting from `path.split("/")`.
 */
export const findCurrentNode = (tree: RoutesTreeNode, path: string) => {
  let node: RoutesTreeNode | undefined;

  sortPathParamsLast(tree.children).some((child) => {
    const match = path.match(
      new RegExp(child.path.replace(/\//g, "\\/").replace(/:[^/]+/g, "[^/]+")),
    );

    if (!match) return false;

    // full match
    if (match[0] === path) {
      node = child;
      return true; // early return from Array.prototype.some
    }

    // partial match, recursively find node in children
    node = findCurrentNode(child, path);
    if (node) return true;

    return false;
  });

  return node;
};

/**
 * From a matching node path and actual path, build a dictionary with the path params. Example:
 * ```ts
 * const nodePath = "/route/:param1/:param2";
 * const currentPath = "/route/1/test";
 * console.log(getCurrentPathParams(nodePath, currentPath))
 * // output: { param1: 1, param2: "test" }
 * ```
 */
export const getCurrentPathParams = (
  nodePath: string | undefined,
  currentPath: string,
) => {
  if (!nodePath?.match(/\/:\w+/)) return {};

  const params: Record<string, string> = {};

  const nodePathChunks = splitPathChunks(nodePath);
  const currentPathChunks = splitPathChunks(currentPath);

  for (let i = 0; i < nodePathChunks.length; i += 1) {
    if (nodePathChunks[i] !== currentPathChunks[i]) {
      params[nodePathChunks[i]] = currentPathChunks[i];
    }
  }

  return params;
};

/**
 * Fill params in a node path. Example:
 * ```ts
 * const nodePath = "/route/:param1/:param2";
 * const params = { param1: 1, param2: "test" };
 * console.log(fillPathParams(nodePath, params))
 * // output: /route/1/test
 * ```
 */
export const fillPathParams = (
  nodePath: string,
  params: Record<string, string>,
) => {
  let filledPath = nodePath;

  Object.entries(params).forEach(([param, value]) => {
    filledPath = filledPath.replace(param, value);
  });

  return filledPath;
};

/** Follow the tree upwards from the given node and returns the first node that `hasElement` */
export const findNearestParentWithElement = (
  node: RoutesTreeNode,
): RoutesTreeNode => {
  if (node.hasElement && !node.isNavigateElement) return node;
  if (node.depth === baseDepth) return node;
  return findNearestParentWithElement(node.parent);
};

/** Follow the tree upwards from the given node to test if the other is an ancestor */
export const isAncestor = (
  possibleAncestor: RoutesTreeNode,
  node: RoutesTreeNode,
): boolean => {
  if (!node.parent) return false;
  if (node.parent === possibleAncestor) return true;
  return isAncestor(possibleAncestor, node.parent);
};

export const mapNodeToMenuConfigItems = (
  node: RoutesTreeNode,
  currentNode: RoutesTreeNode | undefined,
  currentPathParams: Record<string, string>,
  setMenuNode: (node: RoutesTreeNode) => void,
) => ({
  label: node.name,
  link: fillPathParams(node.path, currentPathParams),
  active: !!currentNode?.path.match(
    // full match or partial match that ends in a "/" (case when dealing with sub-items)
    new RegExp("^" + node.path.replace(/\//, "\\/") + "(\\/|$)"),
  ),
  hasSubItems: node.hasSubItems,
  onClick: node.hasSubItems ? () => setMenuNode(node) : undefined,
  i18nKey: node.i18nKey,
});

/**
 * Decides whether to show menu entry based on roles. The rules are:
 * 1. If the entry has sub-items, show if the user has the role for any sub-item
 * 2. If not, show if the user has the role for the entry
 * 3. If the entry has no role, it always shows
 */
export const filterMenuByRoles = (
  node: RoutesTreeNode,
  hasRole: ReturnType<typeof useRoles>["hasRole"],
) => {
  if (node.hasSubItems) {
    return node.children.some((child) => hasRole(child.role));
  }
  return hasRole(node.role);
};

/**
 * Looks for the first element in the `customHistory` that
 * isn't a sibiling node or has a `<Navigate />` element
 */
export const getGoBackLink = (
  tree: RoutesTreeNode,
  currentNode: RoutesTreeNode,
  customHistory: string[],
) => {
  const historyIndex = customHistory.findIndex((historyItem) => {
    const historyNode = findCurrentNode(tree, historyItem.split("?")[0]);

    return (
      historyNode &&
      historyNode.parent !== currentNode.parent &&
      !isAncestor(currentNode, historyNode) &&
      !historyNode.isNavigateElement
    );
  });

  return {
    link: customHistory[historyIndex],
    newHistory: customHistory.slice(historyIndex + 1),
  };
};
