import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import {
  buildDataSource,
  favoriteWorkflowsDataGrid,
} from "./favorite-workflows-template.grid";

interface FavoriteWorkflowsTemplateProps {
  type: HubOnboardingRelationships.Admin.RelationshipType;
}

export const FavoriteWorkflowsTemplate = ({
  type,
}: FavoriteWorkflowsTemplateProps) => {
  const gridRef = useGridRef();

  const dataSource = useMemo(() => buildDataSource(type), [type]);

  const grid = useMemo(
    () => favoriteWorkflowsDataGrid(gridRef, type),
    [gridRef, type],
  );

  return (
    <GridTemplate gridRef={gridRef} showRefreshButton>
      <ODataGridGenerator
        dataSource={dataSource}
        gridRef={gridRef}
        grid={grid}
      />
    </GridTemplate>
  );
};
