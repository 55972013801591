import { OToastManager } from "@maestro/react";
import { useCallback, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GetAdminProcessorConfigPropertyResponse } from "services/hubcreditmanager/models/responses/get-admin-processor-config-properties.response";
import { logger } from "utils/logger";
import { WorkflowProdutoProcessorWorkflowConfigByProcessorId } from "../../../../../../../../routes/workflow.route-params";

export const useProcessorConfigPropertiesPageContent = () => {
  const { id, processorId } =
    useParams<WorkflowProdutoProcessorWorkflowConfigByProcessorId>();
  if (!id || !processorId) throw new Error("No id or no processorId");

  const form = useForm<{
    properties: GetAdminProcessorConfigPropertyResponse[];
  }>();

  const { control, getValues, reset } = form;

  const { fields } = useFieldArray({
    control,
    name: "properties",
  });

  const getProperties = useCallback(async () => {
    try {
      if (!processorId) return;
      const { data } =
        await service.hubCreditManager.getProcessorConfigDetailsProperties(
          processorId,
        );
      reset({
        properties: data?.sort(
          (a, b) => a.processorPropertyId - b.processorPropertyId,
        ),
      });
    } catch (err) {
      OToastManager.danger("Erro para retornar as propriedades.");
    }
  }, [processorId, reset]);

  const handleSubmitProperties = useCallback(async () => {
    try {
      const propertiesValues = getValues();
      const updatedProperties = propertiesValues.properties.map(
        ({ processorPropertyId, value }) => {
          const invalidValue = value === null || value === undefined;

          return {
            processorPropertyId,
            value: invalidValue ? null : String(value),
          };
        },
      );

      await service.hubCreditManager.updateProcessorConfigProperties({
        processorConfigId: Number(processorId),
        processorConfigProperties: updatedProperties,
      });

      OToastManager.success("Propriedades alteradas com sucesso.");
    } catch (err) {
      logger.error(err);
      OToastManager.danger("Não foi possível atualizar a propriedade.");
    }
  }, [getValues, processorId]);

  useEffect(() => {
    getProperties();
  }, [getProperties]);

  return {
    form,
    fields,
    handleSubmitProperties,
  };
};
