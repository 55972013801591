import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { buildDataSource, companyPartnersGrid } from "./company-partners.grid";

export const CompanyPartners = () => {
  const gridRef = useGridRef();
  const { customer } = useCustomer();

  const dataSource = useMemo(
    () => buildDataSource(customer.identification),
    [customer],
  );

  return (
    <>
      <PageTitle title="Parceiros" />
      <GridTemplate gridRef={gridRef} showClearFiltersButton showExportButton>
        <ODataGridGenerator
          dataSource={dataSource}
          grid={companyPartnersGrid}
          gridRef={gridRef}
        />
      </GridTemplate>
    </>
  );
};
