import { OCheckbox, OLabel, ORFieldCheckboxGroup } from "@maestro/react";
import { useMemo } from "react";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useBooleanPropertyField } from "./boolean-property-field.hook";

export const BooleanPropertyField = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { processorPropertyId, name, description } = processorConfigProperty;

  const { checkboxFieldId } = useBooleanPropertyField({
    processorConfigProperty,
    fieldId,
  });

  const genericPropertyField = useMemo(
    () => (
      <ORFieldCheckboxGroup
        key={`properties.${name}_${processorPropertyId}`}
        id={checkboxFieldId}
        name={checkboxFieldId}
      >
        <div className="d-flex align-items-center gap-2 mb-2">
          <OCheckbox
            size="xs"
            id={`single-parent-checkbox-${name}`}
            value="true"
          />
          <OLabel htmlFor={`single-parent-checkbox-${name}`}>
            {description}
          </OLabel>
        </div>
      </ORFieldCheckboxGroup>
    ),
    [name, processorPropertyId, checkboxFieldId, description],
  );

  return <>{genericPropertyField}</>;
};
