import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useGridRef } from "hooks/grid-ref";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { service } from "services";
import { BannerTemplate } from "services/hubcreditmanager/models/responses";
import { bannerTemplatesGrid } from "./banner-templates.grid";

export const useBannerTemplates = () => {
  const navigate = useNavigate();
  const gridRef = useGridRef();

  const { callService: callDeleteBannerTemplate } = useServiceCall(
    service.hubCreditManager.deleteBannerTemplate,
  );

  const handleDeleteTemplate = useCallback(
    async (bannerTemplate: BannerTemplate) => {
      const { id } = bannerTemplate;
      if (!id) return;

      const { success, error: e } = await callDeleteBannerTemplate(id);

      if (success) {
        OToastManager.success(`Template excluído com sucesso.`);

        !gridRef.current?.instance.getDataSource().isLoading() &&
          gridRef.current?.instance.getDataSource().reload();
      } else {
        isAxiosError(e) &&
          OToastManager.danger(e?.response?.data?.exceptionMessage);
      }
    },
    [callDeleteBannerTemplate, gridRef],
  );

  const grid = useMemo(
    () => bannerTemplatesGrid(navigate, handleDeleteTemplate),
    [handleDeleteTemplate, navigate],
  );

  return { grid, gridRef };
};
