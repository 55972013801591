import { AirplaneFormComponent } from "./_compose/airplane-form.component";
import { CACFormComponent } from "./_compose/cac-form.component";
import { CDBFormComponent } from "./_compose/cdb-form.component";
import { EquipmentFormComponent } from "./_compose/equipment-form.component";
import { InsuranceFormComponent } from "./_compose/insurance/insurance-form.component";
import { PromissoryNoteFormComponent } from "./_compose/promissory-note-form.component";

export const collateralOptions = [
  {
    value: "CAC",
    label: "CAC",
  },
  {
    value: "CDB",
    label: "CDB",
  },
  {
    value: "EQUIPAMENTO",
    label: "Equipamento",
  },
  {
    value: "NOTA_PROMISSORIA",
    label: "Nota Promissória",
  },
  {
    value: "PEAC",
    label: "PEAC",
  },
  {
    value: "SEGURO",
    label: "Seguro",
  },
  {
    value: "AERONAVE",
    label: "Aeronave",
  },
];

export const formTypeMapper = {
  CAC: <CACFormComponent />,
  SEGURO: <InsuranceFormComponent />,
  CDB: <CDBFormComponent />,
  EQUIPAMENTO: <EquipmentFormComponent />,
  AERONAVE: <AirplaneFormComponent />,
  NOTA_PROMISSORIA: <PromissoryNoteFormComponent />,
};
