import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
  OTypography,
} from "@maestro/react";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { service } from "services";
import { DeskPartnerFormValues } from "./desk-partner-form.form";
import { StyledBadge, StyledRow } from "./desk-partner-form.styles";

export const DeskPartnerForm = () => {
  const form = useFormContext<DeskPartnerFormValues>();
  const { control } = form;

  const fieldArraySteps = useFieldArray({
    control,
    name: "identifications",
  });

  const { append, remove, fields } = fieldArraySteps;

  const {
    callService: getDesksOnboardingConfigs,
    loading: loadingDesksConfigs,
    value: desksConfigs,
    hasError: hasErrorDesksConfigs,
  } = useServiceCall(service.onboarding.getDesksOnboardingConfigs);
  const {
    callService: getEnums,
    loading: loadingEnums,
    value: enums,
    hasError: hasErrorEnums,
  } = useServiceCall(service.onboarding.getDeskOnboardingPartnerConfigEnums);

  useEffect(() => {
    getDesksOnboardingConfigs();
    getEnums();
  }, [getDesksOnboardingConfigs, getEnums]);

  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Tipo de parceiro"
                tag="text"
                id="partnerTypeCode"
                name="partnerTypeCode"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldSelect
                id="entityType"
                name="entityType"
                placeholder="Selecione o tipo de entidade"
                label="Tipo de entidade"
              >
                <OOption value="PJ">PJ</OOption>
                <OOption value="PF">PF</OOption>
              </ORFieldSelect>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="CNPJ da Instituição Responsável"
                tag="cnpj"
                id="legalEntityResponsibleInstitutionTaxId"
                name="legalEntityResponsibleInstitutionTaxId"
              />
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="flow"
                name="flow"
                label="Fluxo"
                placeholder="Selecione"
                disabled={loadingEnums}
                key={enums?.flows?.length}
              >
                {enums?.flows?.map((flow) => (
                  <OOption key={flow} value={flow}>
                    {flow}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingEnums && <OLoader className="d-block" size="sm" />}
              {hasErrorEnums && (
                <OButton type="default" outline onClick={() => getEnums()}>
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="deskOnboardingConfigIdentifier"
                name="deskOnboardingConfigIdentifier"
                label="Configuração"
                placeholder="Selecione"
                disabled={loadingDesksConfigs}
                key={desksConfigs?.length}
              >
                {desksConfigs?.map((config) => (
                  <OOption
                    key={config.configIdentifier}
                    value={config.configIdentifier}
                  >
                    {`${config.configIdentifier} - ${config.configName} (${config.configDescription})`}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingDesksConfigs && <OLoader className="d-block" size="sm" />}
              {hasErrorDesksConfigs && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDesksOnboardingConfigs()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
          <div className="d-flex flex-column">
            <div style={{ marginBottom: "15px" }}>
              <OTypography size="lg" weight="400">
                Identificadores
              </OTypography>
            </div>
            <div className="d-flex flex-column" id="steps">
              <ORow className="mx-1">
                {fields.map((field, index) => (
                  <StyledRow key={field.id}>
                    <OCol xs={1} className="h-100" align="start">
                      <StyledBadge>{index + 1}</StyledBadge>
                    </OCol>
                    <OCol>
                      <ORow key={field.id} className="flex-fill">
                        <OCol xl={4}>
                          <ORFieldInput
                            id={`identifications.${index}.queryParameter`}
                            name={`identifications.${index}.queryParameter`}
                            tag="text"
                            label="Tipo"
                          />
                        </OCol>
                        <OCol xl={4}>
                          <ORFieldInput
                            id={`identifications.${index}.value`}
                            name={`identifications.${index}.value`}
                            tag="text"
                            label="Valor"
                          />
                        </OCol>
                      </ORow>
                    </OCol>
                    <OCol xs={1} align="start">
                      <OButton type="danger" onClick={() => remove(index)}>
                        <OIcon category="fa" icon="fa-trash" />
                      </OButton>
                    </OCol>
                  </StyledRow>
                ))}
              </ORow>
              <OButton
                type="tertiary"
                onClick={() =>
                  append(
                    {} as NonNullable<
                      DeskPartnerFormValues["identifications"]
                    >[number],
                  )
                }
                className="mt-3"
              >
                <OIcon category="fa" icon="fa-plus" />
              </OButton>
            </div>
          </div>
        </OCardBody>
      </OCard>
    </div>
  );
};
