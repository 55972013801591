import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import { FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams } from "../../../../../../../routes/ferramentas.route-params";
import {
  DeskConfigPipelineForm,
  DeskConfigPipelineFormFields,
  deskConfigPipelineFormValidationSchema,
} from "../_compose/desk-config-pipeline-form";

export const RegisterDeskConfigPipelinePage = () => {
  const { configIdentifier } =
    useParams<FerramentasProdutoOnboardingConfiguracaoDeMesasConfiguracaoByConfigIdentifierRouteParams>();
  if (!configIdentifier) throw new Error("No configIdentifier");

  const { callService: registerDeskConfigPipeline, loading } = useServiceCall(
    service.onboarding.registerPipelineOnDeskOnboardingConfig,
  );

  const form = useForm<DeskConfigPipelineFormFields>({
    resolver: yupResolver(deskConfigPipelineFormValidationSchema),
    defaultValues: {
      deskOnboardingConfigIdentifier: configIdentifier,
    },
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    const { success } = await registerDeskConfigPipeline({
      ...values,
    });

    if (!success) {
      return OToastManager.danger("Erro ao criar pipeline");
    }

    return OToastManager.success("Pipeline criada com sucesso");
  });

  return (
    <FormTemplate
      pageTitle={
        <PageTitle title={`Adicionar pipelines na mesa ${configIdentifier}`} />
      }
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <DeskConfigPipelineForm />
      </FormProvider>
    </FormTemplate>
  );
};
