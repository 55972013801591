import { OButton, OIcon, OTypography } from "@maestro/react";
import { CustomerSelector } from "components/customer-selector";
import { useBehavior } from "contexts/behavior";
import { BehaviorRoles } from "contexts/behavior/types";
import { useUnsafeUser } from "contexts/user";
import { useRoles } from "hooks/roles";
import { headerRoles } from "roles/header";
import { env, isNotProd } from "utils/environments";
import { HeaderContainer, StickyContainer } from "./header.styles";
import {
  ConfigurationModalButton,
  CustomerServiceUserMenu,
  EnvironmentLine,
  Logo,
  NavigationMenu,
  RedirectOldAdminButton,
  ToolsMenu,
  UserDropdown,
} from "./_compose";
import { LanguageSwitcher } from "./_compose/language-switcher";

export const Header = () => {
  const { hasRole } = useRoles();
  const { hasRoles } = useBehavior();
  const { user } = useUnsafeUser();

  return (
    <StickyContainer>
      {isNotProd() && <EnvironmentLine id="environment-line" />}
      <HeaderContainer
        id="header-container"
        className="row g-0 align-items-center"
      >
        <div className="col-5 d-flex align-items-center gap-4">
          {hasRoles([BehaviorRoles.HeaderLogo_Show]) && <Logo />}
          {hasRoles([BehaviorRoles.HeaderCustomerServiceUserMenu_Show]) && (
            <CustomerServiceUserMenu />
          )}

          {user && hasRoles([BehaviorRoles.HeaderRedirectToOldPortal_Show]) && (
            <RedirectOldAdminButton />
          )}

          {hasRoles([BehaviorRoles.HeaderMenu_Show]) && <NavigationMenu />}
          {hasRoles([BehaviorRoles.HeaderMenu_Show]) && <ToolsMenu />}
        </div>

        <div className="col-4">
          {user && hasRoles([BehaviorRoles.HeaderCustomerSelector_Show]) && (
            <CustomerSelector />
          )}
        </div>

        <div className="col-3 d-flex justify-content-end align-items-center gap-2">
          {user && hasRoles([BehaviorRoles.HeaderConfiguration_Show]) && (
            <ConfigurationModalButton />
          )}

          {hasRole(headerRoles.languageSwitcher.role) && <LanguageSwitcher />}

          {user && hasRoles([BehaviorRoles.HeaderWikiLink_Show]) && (
            <OButton
              dataAction="header:botao:wiki-link"
              dataLabel="wiki-link"
              type="dark"
              size="sm"
              rounded
              outline
              onClick={() => window.open(env.WIKI_LINK, "_blank")}
            >
              <OTypography tag="span" size="sm">
                <span className="d-flex gap-2">
                  <OIcon category="orq" icon="orq-offer-book" /> wiki
                </span>
              </OTypography>
            </OButton>
          )}

          {user && hasRoles([BehaviorRoles.HeaderUserMenu_Show]) && (
            <UserDropdown />
          )}
        </div>
      </HeaderContainer>
    </StickyContainer>
  );
};
