import { WorkflowContext } from "pages/workflow/components/hooks/use-workflow.context";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { getProcessorPropertyFieldId } from "../../../utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";

export const useBatchTemplatePropertyField = ({
  processorConfigProperty: { value },
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { setValue } = useFormContext();
  const {
    batchTemplates: { value: allBatchTemplates },
  } = useContext(WorkflowContext);

  const processorPropertyFieldId = getProcessorPropertyFieldId(fieldId);

  useEffect(() => {
    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [value, setValue, processorPropertyFieldId]);

  return { allBatchTemplates, processorPropertyFieldId };
};
