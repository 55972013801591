import { ODataGridGeneratorConfig } from "components/data-grid";
import { masks } from "@maestro/utils";
import { getValueFromMap } from "utils/get-value-from-map";
import { OImage, OTypography } from "@maestro/react";
import { payers } from "../../../../../utils/acquirers/payers";
import { cardIconMap } from "../../../../../utils";
import { ConciliationDetail } from "./extrato-liquidacao-posicao-details.types";

export const conciliacaoDetailsGrid: ODataGridGeneratorConfig<
  NonNullable<ConciliationDetail>[number]
> = {
  datagrid: {
    noDataText: "Nenhuma informação de conciliação.",
  },
  columns: [
    {
      dataField: "maturity_date",
      dataType: "date",
      sortOrder: "asc",
      caption: "Data de Vencimento",
    },
    {
      dataField: "credenciadora_tax_id",
      dataType: "string",
      caption: "CNPJ Credenciadora",
      calculateDisplayValue: (data) => masks.cnpj(data.credenciadora_tax_id),
      allowHeaderFiltering: false,
    },
    {
      caption: "Credenciadora",
      allowHeaderFiltering: false,
      cellRender: (props) => {
        const payer =
          payers[props.data.credenciadora_tax_id as keyof typeof payers] ??
          payers.default;
        return (
          <div className="d-flex flex-row gap-2 align-items-center">
            <OImage
              circle
              src={payer.image}
              alt="credenciadora"
              width="32px"
              height="32px"
            />
            <OTypography>{payer.name}</OTypography>
          </div>
        );
      },
      allowExporting: false,
    },
    {
      dataField: "bandeira",
      dataType: "string",
      caption: "Bandeira",
      alignment: "center",
      cellRender: ({ data }) =>
        getValueFromMap(cardIconMap, data.bandeira) || data.bandeira,
    },
    {
      dataField: "soma_valor_trades",
      caption: "Vencimento",
      alignment: "left",
      dataType: "number",
      calculateDisplayValue: (data) =>
        masks.currency(Number(data.soma_valor_trades), "R$"),
    },
    {
      dataField: "soma_valor_pagamento",
      caption: "Recebido da credenciadora",
      alignment: "left",
      dataType: "number",
      calculateDisplayValue: (data) =>
        masks.currency(Number(data.soma_valor_pagamento), "R$"),
    },
    {
      dataField: "diferenca",
      caption: "Diferença",
      alignment: "left",
      dataType: "number",
      calculateDisplayValue: (data) =>
        masks.currency(Number(data.diferenca), "R$"),
    },
  ],
};
