import {
  modalManager,
  OCol,
  OField,
  OLabel,
  OModal,
  OModalBody,
  OModalFooter,
  OModalHeader,
  ORadio,
  ORadioGroup,
  ORow,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGenerator } from "components/data-grid";
import { LoadingButton } from "components/loading-button";
import { useTranslation } from "react-i18next";
import { ReceivablesTitle } from "services/quickfin/models";
import { approvalsModalGrid } from "./approvals-modal.grid";
import { approvalConfirmationModalId } from "./approvals-modal.utils";

interface ApprovalsModalGridProps {
  approveReceivables: () => Promise<void>;
  hasReceivableWithHedgeIndication: boolean;
  haveHedge: string;
  loading: boolean;
  reducedReceivables: ReceivablesTitle[];
  resetHaveHedge: () => void;
  selectedReceivables: ReceivablesTitle[];
  setHaveHedge: React.Dispatch<React.SetStateAction<string>>;
  totalSum: number;
  totalDesembolso: number;
}

export const ApprovalsModal = ({
  approveReceivables,
  hasReceivableWithHedgeIndication,
  haveHedge,
  loading,
  reducedReceivables,
  resetHaveHedge,
  selectedReceivables,
  setHaveHedge,
  totalSum,
  totalDesembolso,
}: ApprovalsModalGridProps) => {
  const { t } = useTranslation("receivables", { keyPrefix: "approvals" });
  return (
    <OModal
      id={approvalConfirmationModalId}
      size="lg"
      position="center"
      onModalClose={() => resetHaveHedge()}
    >
      <OModalHeader closeButton>
        <OTypography tag="h2" size="lg" weight="normal">
          {t("modal.approve")}
        </OTypography>
      </OModalHeader>
      <OModalBody>
        <div className="d-flex flex-column gap-3">
          <ODataGridGenerator
            grid={approvalsModalGrid(t)}
            dataSource={reducedReceivables}
          />
          <ORow>
            <OCol xs={12} md={3}>
              <OField htmlFor="total-soma" label={t("modal.maturityAmount")}>
                <OTypography id="total-soma">
                  {masks.currency(totalSum)}
                </OTypography>
              </OField>
            </OCol>
            <OCol xs={12} md={3}>
              <OField
                htmlFor="total-desembolso"
                label={t("modal.disbursementAmount")}
              >
                <OTypography id="total-desembolso">
                  {masks.currency(totalDesembolso)}
                </OTypography>
              </OField>
            </OCol>
            <OCol xs={12} md={6}>
              <OField htmlFor="total-notas" label={t("modal.totalOfInvoices")}>
                <OTypography id="total-notas">
                  {selectedReceivables.length}
                </OTypography>
              </OField>
            </OCol>
            {hasReceivableWithHedgeIndication && (
              <OCol xs={12}>
                <OField htmlFor="tem-hedge" label={t("modal.hedgeMessage")}>
                  <ORadioGroup
                    dataAction="aprovacao_recebiveis_modal:radio:possui_indicacao_de_hedge"
                    dataLabel="possui_indicacao_de_hedge"
                    value={haveHedge}
                    onInput={(e: any) => setHaveHedge(e.target.value)}
                  >
                    <div className="d-flex gap-5">
                      <div className="d-flex gap-2">
                        <ORadio id="haveHedge-yes" value="yes" />
                        <OLabel htmlFor="haveHedge-yes">
                          {t("modal.hedgeMessageYes")}
                        </OLabel>
                      </div>
                      <div className="d-flex gap-2">
                        <ORadio id="haveHedge-no" value="no" />
                        <OLabel htmlFor="haveHedge-no">
                          {t("modal.hedgeMessageNo")}
                        </OLabel>
                      </div>
                    </div>
                  </ORadioGroup>
                </OField>
              </OCol>
            )}
          </ORow>
        </div>
      </OModalBody>
      <OModalFooter divider>
        <div className="d-flex justify-content-end gap-2">
          <LoadingButton
            loading={loading}
            dataAction="aprovacao_recebiveis_modal:botao:cancelar"
            dataLabel="cancelar"
            outline
            onClick={() => modalManager.hide(approvalConfirmationModalId)}
          >
            {t("modal.cancel")}
          </LoadingButton>
          <LoadingButton
            loading={loading}
            dataAction="aprovacao_recebiveis_modal:botao:confirmar"
            dataLabel="confirmar"
            type="tertiary"
            disabled={hasReceivableWithHedgeIndication && haveHedge !== ""}
            onClick={() => approveReceivables()}
          >
            {t("modal.confirm")}
          </LoadingButton>
        </div>
      </OModalFooter>
    </OModal>
  );
};
