import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORFieldUploadInput,
  ORow,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { WorkflowBannerById } from "../../../../../routes/workflow.route-params";
import { BannerPreview } from "../../_compose";
import { useBannerTemplateForm } from "./banner-templates-form.hook";

export const BannerTemplateFormPage = () => {
  const { upsertTemplate, onAddFile, form, loadingSubmit, imageUrl } =
    useBannerTemplateForm();

  const { handleSubmit } = form;

  const { id } = useParams<WorkflowBannerById>();

  return (
    <FormTemplate
      pageTitle={<PageTitle title={`${id ? "Editar" : "Adicionar"} banner`} />}
      actions={
        <LoadingButton
          loading={loadingSubmit}
          onClick={handleSubmit(upsertTemplate)}
        >
          {id ? "Editar" : "Adicionar"}
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <ContentTemplate
          noValue
          render={() => (
            <CardTemplate>
              <form spellCheck="false">
                <ORow>
                  <OCol sm={12}>
                    <ORFieldInput
                      id="description"
                      name="description"
                      tag="textarea"
                      label="Descrição"
                      labelSize="md"
                      placeholder="Informar"
                      tooltip="Variáveis devem ser envolvidas em duplos colchetes (ex: [[hora]])"
                      tooltipMaxWidth="200px"
                      required
                    />
                  </OCol>
                </ORow>
                <ORow>
                  <OCol sm={6}>
                    <ORFieldInput
                      id="identification"
                      name="identification"
                      tag="text"
                      label="Identificação da campanha"
                      labelSize="md"
                      placeholder="Informar"
                      tooltip="Identificador único"
                      tooltipMaxWidth="200px"
                      required
                    />
                  </OCol>
                  <OCol sm={6}>
                    <ORFieldCheckboxGroup id="openInNewTab" name="openInNewTab">
                      <div className="d-flex flex-row align-items-center gap-2">
                        <OCheckbox
                          size="xs"
                          id="openInNewTab-checkbox"
                          value="true"
                        />
                        <OLabel htmlFor="openInNewTab-checkbox">
                          Redirecionamento deve abrir em nova aba?
                        </OLabel>
                      </div>
                    </ORFieldCheckboxGroup>
                  </OCol>
                </ORow>
                <ORow>
                  <OCol sm={6}>
                    <ORFieldInput
                      id="actionText"
                      name="actionText"
                      tag="text"
                      label="Texto no botão de ação"
                      labelSize="md"
                      placeholder="Informar"
                      required
                    />
                  </OCol>
                  <OCol sm={6}>
                    <ORFieldInput
                      id="actionRef"
                      name="actionRef"
                      tag="text"
                      label="Redirecionamento do botão de ação"
                      labelSize="md"
                      placeholder="Informar"
                      required
                    />
                  </OCol>
                </ORow>
                <ORow>
                  <OCol sm={6}>
                    <ORFieldInput
                      id="imageAlt"
                      name="imageAlt"
                      tag="text"
                      label="Texto alternativo da imagem"
                      labelSize="md"
                      placeholder="Informar"
                      required
                    />
                  </OCol>
                </ORow>
                <div className="w-100 mb-2">
                  <ORFieldUploadInput
                    required
                    id="file"
                    name="file"
                    label="Imagem"
                    labelSize="md"
                    inputLabel="Selecionar imagem"
                    accept=".jpeg,.jpg,.png"
                    multiple={false}
                    onAddFile={onAddFile}
                  />
                </div>
              </form>
            </CardTemplate>
          )}
        />
        <div className="mt-4">
          <BannerPreview image={imageUrl} />
        </div>
      </FormProvider>
    </FormTemplate>
  );
};
