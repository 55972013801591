import { Behaviors } from "contexts/behavior/types";
import { TFunction } from "i18next";
import { MenuTabs } from "../tab-menu.types";
import { corbanMenuTabs } from "./corban-tabs";
import { menuTabs } from "./tabs";

export const tabMenuBehavior = (t: TFunction): Record<Behaviors, MenuTabs> => {
  return {
    [Behaviors.Admin]: menuTabs(t),
    [Behaviors.CustomerService]: menuTabs(t),
    [Behaviors.RTB]: menuTabs(t),
    [Behaviors.Corban]: corbanMenuTabs,
  };
};
