import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PostAdminUserBody } from "services/onboarding/models";
import { service } from "services/service";
import { useGetUser } from "../../../../../../hooks/use-get-user.hook";
import {
  ChangeUserEmailFormValues,
  changeUserEmailValidationSchema,
} from "./change-user-email-card.form";

export const useChangeUserEmailCard = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useGetUser();
  const form = useForm<ChangeUserEmailFormValues>({
    resolver: yupResolver(changeUserEmailValidationSchema),
  });
  const { handleSubmit, setValue } = form;

  const onSubmit = handleSubmit(async (values) => {
    if (!user) {
      return;
    }

    const payload: PostAdminUserBody = {
      newUserEmail: values.newUserEmail,
      oldUserEmail: values.oldUserEmail,
      updateReason: values.updatingReason,
      userTaxId: user.cpf ?? "",
    };

    setLoading(true);
    try {
      await service.onboarding.updateUserEmail(payload);
      OToastManager.success("E-mail do usuário alterado com sucesso!");
    } catch {
      OToastManager.danger("Não foi possível alterar o e-mail do usuário");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (user && user.email) {
      setValue("oldUserEmail", user.email);
    }
  }, [setValue, user]);

  return {
    form,
    onSubmit,
    loading,
    user,
  };
};
