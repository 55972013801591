export const appsRouteParams = {
  appId: "appId",
} as const;

export const ResourceRouteParams = {
  id: "id",
} as const;

export const usuariosRouteParams = {
  identification: "identification",
} as const;

export const webhookRouteParams = {
  webhookId: "webhookId",
} as const;

type AppParams = Record<keyof typeof appsRouteParams, string>;
type WebhookParams = Record<keyof typeof webhookRouteParams, string>; 

export type AppIdentificationRouteParams = Pick<AppParams, "appId">;
export type WebhookRouteParams = Pick<WebhookParams, "webhookId">;