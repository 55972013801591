import {
  OCol,
  OLoader,
  OOptionMultiple,
  ORFieldSelectMultiple,
} from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useCreditorIdField } from "pages/recebiveis/components/estrategia-form/fields/creditor-id-field.hook";
import { useTranslation } from "react-i18next";

export const CreditorIdsSelect = () => {
  const { t } = useTranslation("receivables", { keyPrefix: "noteNote" });
  const { creditors, getCreditors, loading, hasFetchError } =
    useCreditorIdField();

  return (
    <OCol xs={12} md={4}>
      <ORFieldSelectMultiple
        id="creditorIds"
        name="creditorIds"
        dataAction="titulos_nota_nota_novo:select:financiadores"
        dataLabel="financiadores"
        label={t("filter.financier")}
        disabled={hasFetchError || loading}
        key={creditors?.length}
      >
        {creditors?.map((creditor) => (
          <OOptionMultiple key={creditor.id} value={creditor.id.toString()}>
            {creditor.name}
          </OOptionMultiple>
        ))}
      </ORFieldSelectMultiple>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="financiadores:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getCreditors()}
        />
      )}
    </OCol>
  );
};
