import { OCol, ORow } from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { MultiSelectAll } from "../../../../../../../../components/multi-select-all";
import { scfDevelopersOnboardingStatusMap } from "../../../../../../../../utils/scf-configuration-maps";
import { generateUuidV4 } from "../../../../workflow-configuration-scf.utils";
import { BaseStepFields } from "./base-step-fields.component";

interface CedenteCompletoInternalCallbackStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const CedenteCompletoInternalCallbackStep = ({
  fieldName,
  index,
}: CedenteCompletoInternalCallbackStepProps) => {
  const { getValues, setValue, watch } = useFormContext();

  const fieldNameByIndex = `${fieldName}.${index}`;

  useEffect(() => {
    setValue(
      `${fieldNameByIndex}._t`,
      "InternalCallbackCedenteCompletoWorkflowConfigurationStep",
    );
    if (!getValues(`${fieldNameByIndex}.stepId`)) {
      setValue(
        `${fieldNameByIndex}.stepId`,
        `internal-callback-${generateUuidV4()}`,
      );
    }
    if (!getValues(`${fieldNameByIndex}.nextStepId`)) {
      setValue(`${fieldNameByIndex}.nextStepId`, null);
    }
  }, [fieldNameByIndex, getValues, setValue]);

  const watchAllowedWebhookStatusTypes = watch(
    `${fieldNameByIndex}.allowedWebhookStatusTypes`,
  );

  const changeAllowedWebhookStatusTypes = (values: string[]) =>
    setValue(`${fieldNameByIndex}.allowedWebhookStatusTypes`, values);

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="CEDENTE_COMPLETO_INTERNAL_CALLBACK"
        gridSize={4}
      />
      <OCol xs={4}>
        <MultiSelectAll
          id={`${fieldNameByIndex}.allowedWebhookStatusTypes`}
          items={Object.keys(scfDevelopersOnboardingStatusMap)}
          label="Status permitidos"
          mapping={scfDevelopersOnboardingStatusMap}
          selected={watchAllowedWebhookStatusTypes}
          setSelected={changeAllowedWebhookStatusTypes}
        />
      </OCol>
    </ORow>
  );
};
