import dayjs from "dayjs";
import {
  OCol,
  OLabel,
  ORFieldInput,
  ORFieldInputDate,
  ORow,
  OSwitch,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { CollateralFormValues } from "../add-collateral-form.schema";

export const AirplaneFormComponent = () => {
  const form = useFormContext<CollateralFormValues>();

  const { setValue, watch } = form;

  const formalizedWatcher = watch("formalized");

  return (
    <>
      <ORow>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="unitPrice"
            name="unitPrice"
            label="Valor unitário"
            tag="currency"
            symbol="R$"
          />
        </OCol>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="identification"
            name="identification"
            label="Chassi/Série"
            tag="text"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="manufacturer"
            name="manufacturer"
            label="Marca/Fabricante"
            tag="text"
          />
        </OCol>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="description"
            name="description"
            label="Modelo"
            tag="text"
          />
        </OCol>
      </ORow>
      <ORow>
        <OCol sm={12} md={6}>
          <ORFieldInputDate
            id="manufacturingYear"
            name="manufacturingYear"
            label="Ano"
            picker="year"
            size="md"
            disabledDate={(currentDate) =>
              dayjs().isBefore(currentDate.toISOString(), "year")
            }
          />
        </OCol>
        <OCol sm={12} md={6}>
          <ORFieldInput
            id="vehicleIdentificationNumber"
            name="vehicleIdentificationNumber"
            label="Matrícula"
            tag="text"
          />
        </OCol>
      </ORow>
      <div className="d-flex align-items-center mb-2 gap-2 w-25">
        <OSwitch
          id="formalized"
          size="xs"
          className="mr-2"
          value={!!formalizedWatcher}
          onInput={() => setValue("formalized", !formalizedWatcher)}
        />
        <OLabel htmlFor="formalized" size="md">
          Já assinado
        </OLabel>
      </div>
      {!!formalizedWatcher && (
        <ORow>
          <OCol md={6}>
            <ORFieldInput
              tag="text"
              id="formalizedContractNumber"
              name="formalizedContractNumber"
              label="Número do contrato da AF"
            />
          </OCol>
          <OCol md={6}>
            <ORFieldInput
              tag="text"
              id="timelineId"
              name="timelineId"
              label="Timeline Id"
            />
          </OCol>
        </ORow>
      )}
      <div />
    </>
  );
};
