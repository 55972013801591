import { useGridRef } from "hooks/grid-ref";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { InternationalProfile } from "services/onboarding-relationships/models";
import { mapProfileNames } from "./profile-selector.utils";
import { InternationalUserForm } from "../../add-international-user.type";

interface ProfileSelectorContext {
  blockedProfiles: Record<string, string[]>;
  clearProfiles: () => void;
  gridRef: ReturnType<typeof useGridRef>;
  selected: string | undefined;
  setProfiles: React.Dispatch<React.SetStateAction<InternationalProfile[]>>;
}

const profileSelectorContext = createContext({} as ProfileSelectorContext);

interface ProfileSelectorProviderProps {
  children: React.ReactNode;
}

export const ProfileSelectorProvider = ({
  children,
}: ProfileSelectorProviderProps) => {
  const [profiles, setProfiles] = useState<InternationalProfile[]>([]);
  const [blockedProfiles, setBlockedProfiles] = useState<
    Record<string, string[]>
  >({});

  const gridRef = useGridRef();

  const { setValue, watch } = useFormContext<InternationalUserForm>();

  const watchProfiles = watch("profiles");

  const clearProfiles = useCallback(() => setValue("profiles", []), [setValue]);

  // Recalculate `blockedProfiles` when `watchProfiles` changes
  useEffect(() => {
    const newBlockedProfiles = (watchProfiles ?? []).reduce(
      (blocked, selectedName) => {
        const selectedProfile = profiles.find(
          (profile) => profile.name === selectedName,
        );

        const newBlocked = { ...blocked };

        selectedProfile?.subProfiles.forEach((subProfile) => {
          newBlocked[subProfile.name] = [
            ...(newBlocked[subProfile.name] ?? []),
            selectedProfile.nameLabel ?? "",
          ];
        });
        return newBlocked;
      },
      {} as Record<string, string[]>,
    );

    setBlockedProfiles(newBlockedProfiles);
  }, [profiles, watchProfiles, setValue]);

  // Clears selected profiles when `blockedProfiles` changes
  useEffect(() => {
    const profilesToUncheck = (watchProfiles ?? []).filter(
      (name) => blockedProfiles[name],
    );
    if (profilesToUncheck.length) {
      setValue(
        "profiles",
        (watchProfiles ?? []).filter((p) => !profilesToUncheck.includes(p)),
      );
    }
  }, [blockedProfiles, watchProfiles, setValue]);

  // Selects grid rows corresponding to the selected profiles
  useEffect(() => {
    gridRef.current?.instance.selectRows(watchProfiles ?? [], false);
  }, [gridRef, watchProfiles]);

  /** Text containing the names of the selected profiles */
  const selected = mapProfileNames(watchProfiles, profiles);

  const value = useMemo(
    () => ({
      blockedProfiles,
      clearProfiles,
      gridRef,
      selected,
      setProfiles,
    }),
    [blockedProfiles, clearProfiles, gridRef, selected],
  );

  return (
    <profileSelectorContext.Provider value={value}>
      {children}
    </profileSelectorContext.Provider>
  );
};

export const useProfileSelector = () => useContext(profileSelectorContext);
