import { OCol, OField, OInputText, OOption, OSelect } from "@maestro/react";
import { useCallback } from "react";

interface VariableDetailsProps {
  payload: HubOnboardingRelationships.Admin.ContingencyPayload;
  setPayload: React.Dispatch<
    React.SetStateAction<HubOnboardingRelationships.Admin.ContingencyPayload>
  >;
  variableDetail: HubOnboardingRelationships.Admin.VariableDetail;
}

export const VariableDetails = ({
  payload,
  setPayload,
  variableDetail,
}: VariableDetailsProps) => {
  const {
    description: label,
    example: placeholder,
    possibleValues,
    propertyIdentifier: property,
  } = variableDetail;

  const onSubmit = useCallback(
    (newValue: string) => {
      const newPayload = { ...payload };
      newPayload[property] = newValue;
      setPayload(newPayload);
    },
    [payload, property, setPayload],
  );

  return (
    <OCol xs={12} lg={6}>
      <OField htmlFor={property} label={label}>
        {possibleValues.length > 0 ? (
          <OSelect
            id={property}
            className="w-100"
            defaultValue={placeholder}
            onInput={(e: any) => onSubmit(e.currentTarget.value)}
          >
            {possibleValues.map((value) => (
              <OOption key={value} value={value}>
                {value}
              </OOption>
            ))}
          </OSelect>
        ) : (
          <OInputText
            id={property}
            name={property}
            className="w-100"
            placeholder={placeholder}
            onInput={(e: any) => onSubmit(e.target.value)}
          />
        )}
      </OField>
    </OCol>
  );
};
