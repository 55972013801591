import { PageTitle } from "components/page-title";
import { FavoriteWorkflowsTemplate } from "../_compose/template-workflows-favoritos";

export const FavoriteGuarantorWorkflows = () => {
  return (
    <>
      <PageTitle title="Avalistas" />
      <FavoriteWorkflowsTemplate type="GUARANTOR" />
    </>
  );
};
