import {
  OBannerAction,
  OBannerBody,
  OBannerContent,
  OBannerInfo,
  OButton,
  OImage,
  OTypography,
} from "@maestro/react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BannerContentProps } from "./banner-content.types";
import { StyledOBadge, StyledOBanner } from "./banner-content.styles";

export const BannerContent = ({
  image,
  bannerTemplate,
  badge,
  description,
  title,
}: BannerContentProps) => {
  const navigate = useNavigate();
  const { watch } = useFormContext();

  const { actionRef, actionText, imageAlt, openInNewTab } =
    bannerTemplate ?? {};

  const watchDescription = description ?? watch("description");
  const watchActionText = actionText ?? watch("actionText");
  const watchActionRef = actionRef ?? watch("actionRef");
  const watchImageAlt = imageAlt ?? watch("openInNewTab");
  const watchOpenInNewTab = openInNewTab ?? watch("openInNewTab");

  return (
    <div className="d-flex flex-column gap-4">
      <OTypography size="lg">Aplicação do banner</OTypography>
      <StyledOBanner orientation="horizontal">
        <OBannerBody>
          <OBannerInfo>
            <StyledOBadge key={badge} type="light" size="sm" rounded>
              {badge || "[Produto]"}
            </StyledOBadge>
          </OBannerInfo>
          <OBannerContent
            key={watchDescription}
            title={title ?? ""}
            size="md"
            limitedSize
          >
            {watchDescription}
          </OBannerContent>
          <OBannerAction>
            <OButton
              outline
              onClick={() =>
                watchOpenInNewTab
                  ? window.open(watchActionRef, "_blank")
                  : navigate(watchActionRef)
              }
            >
              <OTypography type="dark">{watchActionText}</OTypography>
            </OButton>
          </OBannerAction>
        </OBannerBody>
        <OImage
          key={image}
          src={image}
          alt={watchImageAlt}
          width="auto"
          height="260px"
          objectFit="cover"
        />
      </StyledOBanner>
    </div>
  );
};
