import { OOptionMultiple, OSelectMultiple, OTypography } from "@maestro/react";
import { ColumnEditCellTemplateData } from "devextreme/ui/data_grid";
import { ContractTemplate } from "./loan-contracts-templates.types";

interface LoanContractsTemplatesMultiselectProps {
  editCellData: ColumnEditCellTemplateData<ContractTemplate>;
  options: {
    label: string;
    value: string;
  }[];
}

export const LoanContractsTemplatesMultiselect = ({
  editCellData: { value, setValue },
  options,
}: LoanContractsTemplatesMultiselectProps) => {
  return (
    <OSelectMultiple
      value={value ?? []}
      onInput={(e) => {
        const targetValue = (e.target as HTMLOSelectMultipleElement)?.value;

        if (targetValue?.join("") === value?.join("")) return;

        setValue(targetValue);
      }}
    >
      {options?.map((r) => (
        <OOptionMultiple
          value={r.value}
          key={`child-workflow-configs-option-${r.value}`}
        >
          <OTypography>{r.label}</OTypography>
        </OOptionMultiple>
      ))}
    </OSelectMultiple>
  );
};
