import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { TFunction } from "i18next";
import { ReceivablesTitle } from "services/quickfin/models";

export const approvalsModalGrid = (
  t: TFunction,
): ODataGridGeneratorConfig<ReceivablesTitle> => ({
  datagrid: {
    noDataText: t("grid.notFoundMessage"),
    pager: {
      allowedPageSizes: [10, 20, 30],
      showInfo: true,
      infoText: "Página {0} de {1} ({2} títulos)",
    },
  },
  columns: [
    {
      dataField: "TipoProduto",
      caption: t("modal.grid.productType"),
    },
    {
      dataField: "Sacado",
      caption: t("modal.grid.payer"),
    },
    {
      dataField: "Cedente",
      caption: t("modal.grid.payee"),
    },
    {
      dataField: "ValorVencimento",
      caption: t("modal.grid.maturityAmount"),
      cellRender: ({ data }) => masks.currency(data.ValorVencimento),
    },
    {
      dataField: "ValorDesembolso",
      caption: t("modal.grid.disbursementAmount"),
      cellRender: ({ data }) => masks.currency(data.ValorDesembolso),
    },
  ],
});
