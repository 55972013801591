import { useTranslation } from "react-i18next";
import { CompanySearchCanceling } from "./busca-empresa-cancelamento/company-search-canceling.component";

export const CancelingDrawnAndTransferor = () => {
  const { t } = useTranslation("receivables", { keyPrefix: "drawee.search" });
  return (
    <>
      <CompanySearchCanceling
        mode="Sacado"
        label={t("payer")}
        name="sacadoIdList"
        placeholder={t("placeholder")}
      />
      <CompanySearchCanceling
        mode="Cedente"
        label={t("payee")}
        name="cedenteIdList"
        placeholder={t("placeholder")}
      />
    </>
  );
};
