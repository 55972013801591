import { ORow } from "@maestro/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { generateUuidV4 } from "../../../../workflow-configuration-scf.utils";
import { BaseStepFields } from "./base-step-fields.component";

interface CedenteCompletoSyncClerkStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const CedenteCompletoSyncClerkStep = ({
  fieldName,
  index,
}: CedenteCompletoSyncClerkStepProps) => {
  const { getValues, setValue } = useFormContext();

  const fieldNameByIndex = `${fieldName}.${index}`;

  useEffect(() => {
    setValue(
      `${fieldNameByIndex}._t`,
      "SyncClerkCedenteCompletoWorkflowConfigurationStep",
    );
    if (!getValues(`${fieldNameByIndex}.stepId`)) {
      setValue(`${fieldNameByIndex}.stepId`, `clerk-${generateUuidV4()}`);
    }
    if (!getValues(`${fieldNameByIndex}.nextStepId`)) {
      setValue(`${fieldNameByIndex}.nextStepId`, null);
    }
  }, [fieldNameByIndex, getValues, setValue]);

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="CEDENTE_COMPLETO_CLERK"
      />
    </ORow>
  );
};
