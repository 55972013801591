import type { LoadOptions } from "devextreme/data";
import { CreditCardPayment } from "services/bankinghub/models/types/cards/cards";
import { defaultPageSize } from "../../credit-card-payments.type";

interface SortInput {
  selector: string;
  desc: false;
}

interface SortParams {
  order: "ASC" | "DESC" | undefined;
  sortBy: "CREATED_AT" | "TOTAL_AMOUNT" | undefined;
}

const MapperCreditCardPaymentFieldToSortBy: Record<
  string,
  "CREATED_AT" | "TOTAL_AMOUNT"
> = {
  createdAt: "CREATED_AT",
  totalAmount: "TOTAL_AMOUNT",
};

const assembleStatuses = (filters: any) => {
  const statuses: string[] = [];

  for (const filter of filters) {
    if (Array.isArray(filter)) {
      const [name, value] = filter;
      if (name === "status") {
        statuses.push(value);
      }
    }
  }

  return statuses.length ? statuses.join("&status=") : undefined;
};

const assembleSort = (sortInput: SortInput[] | undefined): SortParams => {
  if (!sortInput) {
    return {
      order: undefined,
      sortBy: undefined,
    };
  }
  return {
    order: sortInput[0].desc ? "DESC" : "ASC",
    sortBy: MapperCreditCardPaymentFieldToSortBy[sortInput[0].selector],
  };
};

export const assembleGetCreditCardPaymentsParams = (
  loadOptions: LoadOptions<CreditCardPayment>,
) => {
  const filters =
    loadOptions.filter == null
      ? []
      : !Array.isArray(loadOptions.filter[0])
      ? [loadOptions.filter]
      : loadOptions.filter;

  const statusesString = assembleStatuses(filters);

  const sortParams: SortParams = assembleSort(loadOptions.sort as SortInput[]);

  const { take, skip } = loadOptions;

  const itemsPerPage = take || defaultPageSize;
  const page = !!skip && !!take ? skip / take : 0;

  const assembledParams = {
    page: page % 1 !== 0 ? 0 : page,
    itemsPerPage,
    status: statusesString,
    order: sortParams.order,
    sortBy: sortParams.sortBy,
  };

  return assembledParams;
};
