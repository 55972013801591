import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useBatchTemplatePropertyField } from "./batch-template-field.hook";

export const BatchTemplatePropertyField = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { description } = processorConfigProperty;
  const { allBatchTemplates, processorPropertyFieldId } =
    useBatchTemplatePropertyField({
      processorConfigProperty,
      fieldId,
    });

  const options = useMemo(
    () =>
      allBatchTemplates?.map((config) => {
        return {
          value: config.identification,
          label: config?.name,
        };
      }) ?? [],
    [allBatchTemplates],
  );

  const formPropertyField = useMemo(() => {
    return (
      <SelectSearchField
        key={allBatchTemplates
          ?.map((template) => template.identification)
          .join("")}
        id={processorPropertyFieldId}
        name={processorPropertyFieldId}
        placeholder="Selecione"
        labelSize="md"
        options={options}
        label={description}
      />
    );
  }, [allBatchTemplates, processorPropertyFieldId, options, description]);

  return <>{formPropertyField}</>;
};
