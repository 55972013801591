import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { isAxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { addInternationalUserValidationSchema } from "./add-international-user.schema";
import { InternationalUserForm } from "./add-international-user.type";

export const useAddInternationalUser = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const form = useForm<InternationalUserForm>({
    resolver: yupResolver(addInternationalUserValidationSchema),
  });
  const { handleSubmit } = form;

  const submit = handleSubmit(async (values) => {
    setLoading(true);

    try {
      const payload = {
        user: {
          name: values.name,
          phoneNumber: values.phoneNumber,
          email: values.email,
          countryCode: values.countryCode,
          identification: values.identification,
        },
        profiles: values.profiles,
      };

      await service.onboardingRelationships.createInternacionalUser(payload);

      OToastManager.success("Usuário internacional adicionado com sucesso");
      navigate(corporateRouter.routes.usuarios.customer.path);
    } catch (err) {
      const message = isAxiosError(err)
        ? err?.response?.data?.failures[0]?.errorMessage
        : "Erro ao adicionar o usuário internacional";
      OToastManager.danger(message);
    } finally {
      setLoading(false);
    }
  });

  return {
    form,
    loading,
    submit,
  };
};
