import { useRoles } from "hooks/roles";
import { useServiceCall } from "hooks/service-call";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { workflowRoles } from "../../../../roles/workflow.roles";
import { useBanner } from "../banner-management.hook";

export const useBannerDetails = () => {
  const navigate = useNavigate();
  const { handleToggleIsActive, loading } = useBanner({});
  const { id } = useParams();
  const { hasRole } = useRoles();
  const form = useForm();

  const canEdit = hasRole(workflowRoles.product.editCampaignPage.role);

  const { callService: callServiceGetBannerById, value: data } = useServiceCall(
    service.hubCreditManager.getBannerById,
  );

  if (!id) throw Error("No id");

  const handleEditCampaignRedirect = useCallback(
    () =>
      navigate(
        corporateRouter.routes.workflow.product.bannerManagement.edit.path({
          id,
        }),
      ),
    [id, navigate],
  );

  useEffect(() => {
    if (loading) return;

    callServiceGetBannerById(id);
  }, [callServiceGetBannerById, loading, id]);

  return {
    navigate,
    handleToggleIsActive,
    handleEditCampaignRedirect,
    data,
    canEdit,
    form,
  };
};
