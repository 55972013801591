import { OToastManager } from "@maestro/core";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { service } from "services";

export const contractSignersGrid = {
  datagrid: {
    noDataText: "Nenhum signatário encontrado.",
    headerFilter: { visible: true, allowSearch: true },
    editing: {
      allowDeleting: true,
      confirmDelete: true,
    },
    filterRow: {
      visible: true,
    },
    dataSource: dataSourceCustomStoreGenerator(
      () => {
        return service.hubLoan.getAllContractSigners().catch(() => {
          throw new Error("Erro ao buscar signatários.");
        });
      },
      {
        customStoreOptions: {
          insert: async (values) => {
            try {
              await service.hubLoan.upsertContractSigner({
                ...values,
                signerCompanyTaxId: values?.signerCompany?.taxId,
                partyCompanyTaxId: values?.partyCompany?.taxId,
              });

              OToastManager.success("Signatário adicionado com sucesso.");
              return values;
            } catch {
              OToastManager.danger("Não foi possível adicionar o signatário.");
              throw new Error("Não foi possível adicionar o signatário.");
            }
          },
          remove: async (data) => {
            try {
              await service.hubLoan.removeContractSigner(data.id);
              OToastManager.success("Signatário removido com sucesso.");
            } catch {
              OToastManager.danger(
                "Um erro ocorreu ao tentar remover o signatário. Por favor, tente novamente mais tarde.",
              );
            }
          },
        },
      },
    ),
  },
  columns: [
    {
      dataField: "id",
      caption: "Id",
      allowEditing: false,
      sortOrder: "asc",
    },
    {
      dataField: "partyCompany.taxId",
      caption: "Empresa",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "partyCompany.name",
      caption: "Nome da empresa",
    },
    {
      dataField: "signerCompany.taxId",
      caption: "CPF do signatário",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "signerCompany.name",
      caption: "Nome do signatário",
    },
    {
      dataField: "email",
      caption: "E-mail do signatário",
      validationRules: [{ type: "required" }],
    },
    {
      type: "buttons",
      buttons: [
        {
          hint: "Salvar",
          icon: "save",
          name: "save",
        },
        {
          hint: "Cancelar",
          icon: "undo",
          name: "cancel",
        },
        {
          hint: "Deletar",
          icon: "trash",
          name: "delete",
        },
      ],
    },
  ],
} satisfies ODataGridGeneratorConfig<
  NonNullable<
    Awaited<ReturnType<typeof service.hubLoan.getAllContractSigners>>["data"]
  >[number]
>;
