import {
  OCol,
  OLoader,
  OOptionMultiple,
  ORFieldSelectMultiple,
} from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { useMarketplaceIdField } from "pages/recebiveis/components/estrategia-form/fields/marketplace-id-field.hook";
import { useTranslation } from "react-i18next";

export const MarketplaceIdsSelect = () => {
  const { t } = useTranslation("receivables", { keyPrefix: "noteNote" });
  const { marketplaces, getMarketplaces, loading, hasFetchError } =
    useMarketplaceIdField();

  return (
    <OCol xs={12} md={4}>
      <ORFieldSelectMultiple
        id="marketplaceIds"
        name="marketplaceIds"
        dataAction="titulos_nota_nota_novo:select:marketplaces"
        dataLabel="marketplaces"
        label={t("filter.marketplaces")}
        disabled={hasFetchError || loading}
        key={marketplaces?.length}
      >
        {marketplaces?.map((marketplace) => (
          <OOptionMultiple
            key={marketplace.id}
            value={marketplace.id?.toString() ?? ""}
          >
            {marketplace.subgroup
              ? `${marketplace.name} - ${marketplace.subgroup}`
              : marketplace.name}
          </OOptionMultiple>
        ))}
      </ORFieldSelectMultiple>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="marketplaces:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getMarketplaces()}
        />
      )}
    </OCol>
  );
};
