import styled from "styled-components";

export const FavoriteEmptyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: calc(1.2 * var(--font-md));

  transition: transform ease 0.2s;

  &:hover {
    transform: rotate(72deg);
  }
`;
