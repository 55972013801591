import { masks, validators } from "@maestro/utils";
import {
  ODataGridGeneratorConfig,
  removeKeyFromDataSourceItems,
} from "components/data-grid";
import { LimitContractTemplates } from "services/hubcreditmanager/models/types/limit/limit.types";

type AdditionalSigner =
  LimitContractTemplates["contractAdditionalSigners"][number];

export const contractAdditionalSignersFieldsGrid = (
  onChange?: (additionalSigners: AdditionalSigner[]) => void,
) => {
  return {
    datagrid: {
      noDataText: "Nenhum registro adicionado.",
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
      },
      onRowInserted: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
      onRowUpdated: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
      onRowRemoved: ({ component }) => {
        onChange?.(
          removeKeyFromDataSourceItems(
            component.getVisibleRows().map((row) => row.data),
          ),
        );
      },
    },
    columns: [
      {
        dataField: "party.taxId",
        caption: "CNPJ da Parte",
        allowEditing: true,
        format: (value) => masks.cnpj(value),
        validationRules: [
          { type: "required" },
          {
            type: "custom",
            message: "CNPJ inválido",
            validationCallback: (option) => {
              return validators.cnpj(option.value);
            },
          },
        ],
        setCellValue(newData, value) {
          // eslint-disable-next-line no-param-reassign
          newData.party = {
            ...newData.party,
            taxId: value?.replace(/\D/g, ""),
          };
        },
      },
      {
        dataField: "signer.taxId",
        caption: "CPF do signatário",
        allowEditing: true,
        format: (value) => masks.cpf(value),
        validationRules: [
          { type: "required" },
          {
            type: "custom",
            message: "CPF inválido",
            validationCallback: (option) => {
              return validators.cpf(option.value);
            },
          },
        ],
        setCellValue(newData, value) {
          // eslint-disable-next-line no-param-reassign
          newData.signer = {
            ...newData.signer,
            taxId: value?.replace(/\D/g, ""),
          };
        },
      },
      {
        dataField: "signer.name",
        caption: "Nome do signatário",
        allowEditing: true,
      },
      {
        dataField: "email",
        caption: "E-mail do signatário",
        allowEditing: true,
        validationRules: [{ type: "required" }, { type: "email" }],
      },
      {
        dataField: "nature",
        caption: "Natureza",
        lookup: {
          dataSource: ["AVALISTA", "INTERVENIENTE", "PROCURADOR", "TESTEMUNHA"],
        },
        validationRules: [{ type: "required" }],
      },
      {
        dataField: "signWithBiometry",
        caption: "Assina via biometria?",
        dataType: "boolean",
      },
      {
        dataField: "automaticSignature",
        caption: "Assinatura automática?",
        dataType: "boolean",
      },
    ],
  } satisfies ODataGridGeneratorConfig<AdditionalSigner>;
};
