import {
  CedenteCompletoComplementValidateStep,
  CedenteCompletoInternalCallbackStep,
  CedenteCompletoLessStep,
  CedenteCompletoSyncClerkStep,
  CedenteCompletoWebhookDevelopersStep,
  CedenteSimplesClerkStep,
  CedenteSimplesComplementValidateStep,
  CedenteSimplesLessStep,
  TomadorCreditoClerkStep,
  TomadorCreditoComplementValidateStep,
  TomadorCreditoLessStep,
  TomadorCreditoRiskAnalysisStep,
  TomadorCreditoWebhookDevelopersStep,
} from "./step-fields";

export const workflowConfigurationFormMapper = (
  fieldName: "configurations" | "syncConfigurations",
  index: number,
): Record<ScfDevelopers.WorkflowStepType, JSX.Element> => ({
  CEDENTE_SIMPLES_COMPLEMENT_VALIDATE: (
    <CedenteSimplesComplementValidateStep fieldName={fieldName} index={index} />
  ),
  CEDENTE_SIMPLES_LESS: (
    <CedenteSimplesLessStep fieldName={fieldName} index={index} />
  ),
  CEDENTE_SIMPLES_CLERK: (
    <CedenteSimplesClerkStep fieldName={fieldName} index={index} />
  ),
  CEDENTE_COMPLETO_COMPLEMENT_VALIDATE: (
    <CedenteCompletoComplementValidateStep
      fieldName={fieldName}
      index={index}
    />
  ),
  CEDENTE_COMPLETO_INTERNAL_CALLBACK: (
    <CedenteCompletoInternalCallbackStep fieldName={fieldName} index={index} />
  ),
  CEDENTE_COMPLETO_LESS: (
    <CedenteCompletoLessStep fieldName={fieldName} index={index} />
  ),
  CEDENTE_COMPLETO_CLERK: (
    <CedenteCompletoSyncClerkStep fieldName={fieldName} index={index} />
  ),
  CEDENTE_COMPLETO_WEBHOOK_DEVELOPERS: (
    <CedenteCompletoWebhookDevelopersStep fieldName={fieldName} index={index} />
  ),
  TOMADOR_CREDITO_COMPLEMENT_VALIDATE: (
    <TomadorCreditoComplementValidateStep fieldName={fieldName} index={index} />
  ),
  TOMADOR_CREDITO_RISKANALYSIS: (
    <TomadorCreditoRiskAnalysisStep fieldName={fieldName} index={index} />
  ),
  TOMADOR_CREDITO_LESS: (
    <TomadorCreditoLessStep fieldName={fieldName} index={index} />
  ),
  TOMADOR_CREDITO_CLERK: (
    <TomadorCreditoClerkStep fieldName={fieldName} index={index} />
  ),
  TOMADOR_CREDITO_WEBHOOK_DEVELOPERS: (
    <TomadorCreditoWebhookDevelopersStep fieldName={fieldName} index={index} />
  ),
});
