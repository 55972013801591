import { TFunction } from "i18next";
import { roles } from "roles/roles";
import { MenuTabs } from "../tab-menu.types";

export const menuTabs = (t: TFunction) => {
  return {
    customer: [
      {
        name: t("customer.register"),
        path: "/cadastro/cliente",
        role: roles.cadastro.customer.tab.role,
      },
      {
        name: t("customer.users"),
        path: "/usuarios/cliente",
        role: roles.usuarios.customer.tab.role,
      },
      {
        name: t("customer.acc"),
        path: "/acc/cliente",
        role: roles.acc.customer.tab.role,
      },
      {
        name: t("customer.cardsAnticipation"),
        path: "/antecipacao-de-cartoes/cliente",
        role: roles.antecipacaoDeCartoes.customer.tab.role,
      },
      {
        name: t("customer.providerAnticipation"),
        path: "/antecipacao-a-fornecedor/cliente",
        role: roles.antecipacaoAFornecedor.customer.tab.role,
      },
      {
        name: t("customer.partners"),
        path: "/finders/cliente",
        role: roles.b2b.customer.tab.role,
      },
      {
        name: t("customer.newPartners"),
        path: "/parceiros/cliente",
        role: roles.parceiros.customer.partnersTab.role,
      },
      {
        name: t("customer.exchange"),
        path: "/cambio/cliente",
        role: roles.cambio.customer.tab.role,
      },
      {
        // legacy
        name: t("customer.cards"),
        path: "/cartoes/cliente",
        role: roles.cartoes.customer.tab.role,
      },
      {
        name: t("customer.checkingAccount"),
        path: "/banking/cliente",
        role: roles.banking.customer.tab.role,
      },
      {
        name: t("customer.developers"),
        path: "/developers/cliente",
        role: roles.developers.product.tab.role,
      },
      {
        name: t("customer.duplicates"),
        path: "/duplicatas/cliente",
        role: roles.duplicatas.customer.tab.role,
      },
      {
        name: t("customer.loans"),
        path: "/emprestimos/cliente",
        role: roles.emprestimos.customer.tab.role,
      },
      {
        name: t("customer.energy"),
        path: "/energia/cliente",
        role: roles.energia.customer.tab.role,
      },
      {
        name: t("customer.payroll"),
        path: "/folha-de-pagamento/cliente",
        role: roles.fopa.customer.tab.role,
      },
      {
        name: t("customer.franchises"),
        path: "/franquias/cliente",
        role: roles.franquias.customer.tab.role,
      },
      {
        name: t("customer.receivables"),
        path: "/recebiveis/cliente",
        role: roles.recebiveis.customer.tab.role,
      },
      {
        name: t("customer.renegotiation"),
        path: "/renegociacoes/cliente",
        role: roles.renegociacao.customer.tab.role,
      },
      {
        name: t("customer.productCatalogue"),
        path: "/workflow-rmadmin/cliente",
        role: roles.workflowRmAdmin.customer.tab.role,
      },
    ],
    product: [
      {
        name: t("product.companies"),
        path: "/empresas/produto",
        role: roles.empresas.product.tab.role,
      },
      {
        name: t("product.users"),
        path: "/usuarios/produto",
        role: roles.usuarios.product.tab.role,
      },
      {
        name: t("product.acc"),
        path: "/acc/produto",
        role: roles.acc.product.tab.role,
      },
      {
        name: t("product.cardsAnticipation"),
        path: "/antecipacao-de-cartoes/produto",
        role: roles.antecipacaoDeCartoes.product.tab.role,
      },
      {
        name: t("product.partners"),
        path: "/finders/produto",
        role: roles.b2b.product.tab.role,
      },
      {
        name: t("product.partnersNew"),
        path: "/parceiros/produto",
        role: roles.parceiros.product.partnersTab.role,
      },
      {
        name: t("product.banking"),
        path: "/banking/produto",
        role: roles.banking.product.tab.role,
      },
      {
        name: t("product.billing"),
        path: "/billing/produto",
        role: roles.billing.product.tab.role,
      },
      {
        name: t("product.exchange"),
        path: "/cambio/produto",
        role: roles.cambio.product.tab.role,
      },
      {
        name: t("product.cards"),
        path: "/cartoes/produto",
        role: roles.cartoes.product.tab.role,
      },
      {
        name: t("product.charges"),
        path: "/cobrancas/produto",
        role: roles.cobrancas.product.tab.role,
      },
      {
        name: t("product.duplicates"),
        path: "/duplicatas/produto",
        role: roles.duplicatas.product.tab.role,
      },
      {
        name: t("product.developers"),
        path: "/developers/produto",
        role: roles.developers.product.tab.role,
      },
      {
        name: t("product.loans"),
        path: "/emprestimos/produto",
        role: roles.emprestimos.product.tab.role,
      },
      {
        name: t("product.energy"),
        path: "/energia/produto",
        role: roles.energia.product.tab.role,
      },
      {
        name: t("product.tools"),
        path: "/ferramentas/produto",
        role: roles.ferramentas.product.tab.role,
      },
      {
        name: t("product.fidc"),
        path: "/fidc/produto",
        role: roles.fidc.product.tab.role,
      },
      {
        name: t("product.franchises"),
        path: "/franquias/produto",
        role: roles.franquias.product.tab.role,
      },
      {
        name: t("product.receivables"),
        path: "/recebiveis/produto",
        role: roles.recebiveis.product.tab.role,
      },
      {
        name: t("product.leadsWorkflow"),
        path: "/workflow/produto",
        role: roles.workflow.product.tab.role,
      },
      {
        name: t("product.productsCatalogue"),
        path: "/workflow-rmadmin/produto",
        role: roles.workflowRmAdmin.product.tab.role,
      },
    ],
  } satisfies MenuTabs;
};
