import { ODataGridGenerator } from "components/data-grid";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { buildDataSource, energyTradesGrid } from "./trades-customer.grid";

export const EnergyCustomerTradesPage = () => {
  const { customer } = useCustomer();
  const gridRef = useGridRef();

  const dataSource = useMemo(
    () => buildDataSource(customer.identification),
    [customer.identification],
  );

  const gridRender = useMemo(
    () => energyTradesGrid(customer.identification),
    [customer.identification],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Propostas" />}
      gridRef={gridRef}
      showClearFiltersButton
      showExportButton
      showRefreshButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        dataSource={dataSource}
        grid={gridRender}
      />
    </GridTemplate>
  );
};
