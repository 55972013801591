import {
  OButton,
  OCol,
  OIcon,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { filePageTypeMap } from "../../../../edit-cedente-completo.utils";

interface FileFieldProps {
  disableFields: boolean;
  index: number;
  fieldName: `documents.${number}` | `representatives.${number}.document`;
  fileKey: string;
  removeFile: (index: number) => void;
}

export const FileField = ({
  disableFields,
  index,
  fieldName,
  fileKey,
  removeFile,
}: FileFieldProps) => {
  return (
    <ORow
      key={fileKey}
      className="d-flex flex-row align-items-center justify-content-between w-100"
    >
      <OCol xs={3}>
        <ORFieldInput
          tag="text"
          id={`${fieldName}.files.${index}.name`}
          name={`${fieldName}.files.${index}.name`}
          label="Nome do arquivo"
          disabled
        />
      </OCol>
      <OCol xs={3}>
        <ORFieldSelect
          id={`${fieldName}.files.${index}.pageType`}
          name={`${fieldName}.files.${index}.pageType`}
          label="Tipo do arquivo"
          disabled={disableFields}
        >
          {Object.entries(filePageTypeMap).map(([key, value]) => (
            <OOption key={key} value={key}>
              {value}
            </OOption>
          ))}
        </ORFieldSelect>
      </OCol>
      <OCol xs={3}>
        <ORFieldInput
          tag="text"
          id={`${fieldName}.files.${index}.extension`}
          name={`${fieldName}.files.${index}.extension`}
          label="Formato do arquivo"
          disabled
        />
      </OCol>
      <OCol xs={3} className="d-flex flex-row justify-content-end">
        <OButton
          type="danger"
          onClick={() => removeFile(index)}
          disabled={disableFields}
        >
          <OIcon category="orq" icon="orq-edit-trash" />
        </OButton>
      </OCol>
    </ORow>
  );
};
