import { PageTitle } from "components/page-title";
import { FavoriteWorkflowsTemplate } from "../_compose/template-workflows-favoritos";

export const FavoriteEloaWorkflows = () => {
  return (
    <>
      <PageTitle title="Procurações eletrônicas" />
      <FavoriteWorkflowsTemplate type="ROLEASSIGNMENT" />
    </>
  );
};
