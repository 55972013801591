import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LoginInfo } from "services/clerk/models/types";
import { PostSignupUpdateUserEmailBody } from "services/gatekeeper/models";
import { service } from "services/service";
import { useGetUser } from "../../../../../../hooks/use-get-user.hook";
import {
  ChangeLoginEmailFormValues,
  changeLoginEmailValidationSchema,
} from "./change-login-email-card.form";

export const useChangeLoginEmailCard = () => {
  const { user } = useGetUser();

  const [hasNoLogin, setHasNoLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState<LoginInfo>();
  const form = useForm<ChangeLoginEmailFormValues>({
    resolver: yupResolver(changeLoginEmailValidationSchema),
  });
  const { handleSubmit, setValue } = form;

  const onSubmit = handleSubmit(async (values) => {
    if (!user) {
      return;
    }

    const payload: PostSignupUpdateUserEmailBody = {
      idp: loginInfo?.idp ?? "btg:id",
      sub: user.cpf ?? "",
      email: values.newEmail,
      reason: values.reason,
    };

    setLoading(true);
    try {
      await service.gatekeeper.updateLoginEmail(payload);
      OToastManager.success("E-mail do usuário alterado com sucesso!");
    } catch {
      OToastManager.danger("Não foi possível alterar o e-mail do usuário");
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (loginInfo && loginInfo.email) {
      setValue("currentEmail", loginInfo.email);
    }
  }, [loginInfo, setValue, user]);

  const getLoginInfo = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      const payload = {
        identification: user.cpf ?? "",
      };
      const { data } = await service.clerk.getLoginInfo(payload);
      setLoginInfo(data);
    } catch {
      setHasNoLogin(true);
    }
  }, [user]);

  useEffect(() => {
    getLoginInfo();
  }, [getLoginInfo]);

  return {
    form,
    hasNoLogin,
    onSubmit,
    loading,
  };
};
