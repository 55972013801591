import { OCol, OLoader, ORFieldInput, ORow } from "@maestro/react";
import { InputDdiPhone } from "components/input-ddi-phone";
import { SelectSearchField } from "components/select-search";
import { useBasicFields } from "./basic-fields.hook";

export const BasicFields = () => {
  const {
    countries,
    disableEmail,
    getUserDataLoading,
    onChangePhone,
    phoneDefaultValue,
    phoneError,
    register,
  } = useBasicFields();

  return (
    <ORow gap={3}>
      <OCol xs={12} md={6} align="center">
        <ORFieldInput
          tag="text"
          id="identification"
          name="identification"
          label="Identificação/Login"
          placeholder="Preencher"
          required
        />
        {getUserDataLoading && <OLoader size="sm" />}
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          tag="text"
          id="name"
          name="name"
          label="Nome"
          placeholder="Preencher"
          required
        />
      </OCol>
      <OCol xs={12} md={6}>
        <ORFieldInput
          tag="text"
          id="email"
          name="email"
          label="E-mail"
          placeholder="Preencher"
          required
          disabled={disableEmail}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <SelectSearchField
          options={countries}
          id="countryCode"
          name="countryCode"
          label="Código do país"
          required
          placeholder="Selecionar"
        />
      </OCol>
      <OCol xs={12} md={6}>
        <InputDdiPhone
          {...register("phoneNumber")}
          id="phoneNumber"
          name="phoneNumber"
          label="Celular"
          placeholder="Preencher"
          required
          defaultValue={phoneDefaultValue}
          onChange={onChangePhone}
          error={!!phoneError}
          message={phoneError?.message}
        />
      </OCol>
    </ORow>
  );
};
