import { ODropdown, ODropdownList, OIcon, OImage } from "@maestro/react";
import Brazil from "assets/brazil.png";
import Spain from "assets/spain.png";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FlagButton, FlagItem } from "./language-switcher.styles";

const mappedIcon: Record<string, JSX.Element> = {
  pt: <OImage src={Brazil} alt="Brazil" width="30px" height="30px" circle />,
  es: <OImage src={Spain} alt="Spain" width="30px" height="30px" circle />,
};

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const icon = useMemo(
    () => mappedIcon[i18n.language || "pt"],
    [i18n.language],
  );

  return (
    <ODropdown>
      <FlagButton>
        <div>{icon}</div>
        <OIcon size="xl" category="orq" icon="orq-chevron-down" />
      </FlagButton>
      <ODropdownList>
        {Object.entries(mappedIcon).map(([lang, flag]) => (
          <FlagItem
            key={lang}
            active={i18n.language === lang}
            onClick={() => i18n.changeLanguage(lang)}
          >
            {flag}
          </FlagItem>
        ))}
      </ODropdownList>
    </ODropdown>
  );
};
