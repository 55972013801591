import { RedirectWithRoles } from "components/redirect-with-roles";
import { Routes } from "routes/routes.types";
import {
  B2BUsersPage,
  B2CUsersPage,
  GatekeeperBlockedUser,
  NotificationsEmailPage,
  NotificationsPushPage,
  UserActivations,
  UserCustomerDetails,
  UserDetailsPage,
  UserEmailManagement,
  UserNotifications,
  UserRelatedEntities,
  UserTokens,
  UsuariosCustomer,
} from "../pages";
import { ExpiringPowersCompanyUser } from "../pages/cliente/detalhes/expiracao-poderes";
import { AddUser } from "../pages/cliente/novo";
import { AddInternationalUser } from "../pages/cliente/novo-internacional";
import { UserRoleAssignments } from "../pages/cliente/perfis-de-acesso";
import { UserLettersOfAttorneyPage } from "../pages/cliente/procuracoes-fisicas";
import { AttachUser } from "../pages/cliente/vincular";
import { LoginActivity } from "../pages/produto/_pages/atividades-login";
import { ExpiringPowersUser } from "../pages/produto/_pages/detalhes/expiracao-poderes";
import { Tools } from "../pages/produto/_pages/ferramentas";
import { CreateAdmin } from "../pages/produto/novo-administrador";
import { usuariosRoles } from "../roles/usuarios.roles";
import { usuariosRouteParams } from "./usuarios.route-params";

const productCommonRoutes = (route: "b2b" | "b2c") => {
  return {
    details: {
      name: "Detalhes",
      path: `/usuarios/produto/${route}/:${usuariosRouteParams.identification}/detalhes`,
      element: <UserDetailsPage portal={route} />,
      role: usuariosRoles.product[`${route}DetailsPage`].role,
      children: {
        expiringPowersUser: {
          name: "Expiração de poderes",
          path: `/usuarios/produto/${route}/:${usuariosRouteParams.identification}/detalhes/expiracao-poderes`,
          element: <ExpiringPowersUser />,
          role: usuariosRoles.product.detailsExpiringPowersUserPage.role,
        },
      },
    },
    accessProfiles: {
      name: "Empresas relacionadas",
      path: `/usuarios/produto/${route}/:${usuariosRouteParams.identification}/empresas-relacionadas`,
      element: <UserRelatedEntities />,
      role: usuariosRoles.product[`${route}AccessProfilesPage`].role,
    },
    activations: {
      name: "Ativações",
      path: `/usuarios/produto/${route}/:${usuariosRouteParams.identification}/ativacoes`,
      element: <UserActivations />,
      role: usuariosRoles.product[`${route}ActivationsPage`].role,
    },
    deviceTokens: {
      name: "Tokens",
      path: `/usuarios/produto/${route}/:${usuariosRouteParams.identification}/tokens`,
      element: <UserTokens />,
      role: usuariosRoles.product[`${route}DeviceTokensPage`].role,
    },
    notifications: {
      name: "Notificações",
      path: `/usuarios/produto/${route}/:${usuariosRouteParams.identification}/notificacoes`,
      element: <UserNotifications />,
      role: usuariosRoles.product[`${route}NotificationsPage`].role,
    },
    emailMaintenance: {
      name: "Alteração de e-mail",
      path: `/usuarios/produto/${route}/:${usuariosRouteParams.identification}/manutencao-email`,
      element: <UserEmailManagement />,
      role: usuariosRoles.product[`${route}EmailMaintenancePage`].role,
    },
    blockUser: {
      name: "Logs de Bloqueios",
      path: `/usuarios/produto/${route}/:${usuariosRouteParams.identification}/bloqueios-usuarios`,
      element: <GatekeeperBlockedUser />,
      role: usuariosRoles.product[`${route}BlockedLogsPage`].role,
    },
    loginActivity: {
      name: "Atividades de Login",
      path: `/usuarios/produto/${route}/:${usuariosRouteParams.identification}/atividades-login`,
      element: <LoginActivity />,
      role: usuariosRoles.product[`${route}LoginActivitiesPage`].role,
    },
    tools: {
      name: "Ferramentas",
      path: `/usuarios/produto/${route}/:${usuariosRouteParams.identification}/ferramentas`,
      element: <Tools />,
      role: usuariosRoles.product[`${route}ToolsPage`].role,
    },
  } as const satisfies Routes;
};

export const usuariosRoutes = {
  customer: {
    name: "Usuários",
    path: "/usuarios/cliente",
    role: usuariosRoles.customer.tab.role,
    element: <UsuariosCustomer />,
    isFullScreen: true,
    children: {
      all: {
        name: "Usuários",
        path: `/usuarios/cliente/todos`,
        children: {
          add: {
            name: "Novo usuário",
            path: `/usuarios/cliente/todos/novo`,
            element: <AddUser />,
            role: usuariosRoles.customer.addPage.role,
          },
          addInternacional: {
            name: "Novo usuário internacional",
            path: `/usuarios/cliente/todos/novo-internacional`,
            element: <AddInternationalUser />,
            role: usuariosRoles.customer.addInterncionalPage.role,
          },
        },
      },
      attachUser: {
        name: "Usuários",
        path: "/usuarios/cliente/vincular",
        children: {
          byIdentificaton: {
            name: "Vincular usuário",
            path: `/usuarios/cliente/vincular/:${usuariosRouteParams.identification}`,
            role: usuariosRoles.customer.attachUser.role,
            element: <AttachUser />,
          },
        },
      },
      users: {
        name: "Usuários",
        path: `/usuarios/cliente/:${usuariosRouteParams.identification}`,
        showChildren: true,
        children: {
          details: {
            name: "Detalhes",
            path: `/usuarios/cliente/:${usuariosRouteParams.identification}/detalhes`,
            element: <UserCustomerDetails />,
            role: usuariosRoles.customer.detailsPage.role,
            children: {
              expiringPowersCompanyUser: {
                name: "Expiração de poderes",
                path: `/usuarios/cliente/:${usuariosRouteParams.identification}/detalhes/expiracao-poderes`,
                element: <ExpiringPowersCompanyUser />,
                role: usuariosRoles.customer
                  .detailsExpiringPowersCompanyUserPage.role,
              },
            },
          },
          roleAssignments: {
            name: "Perfis de acesso",
            path: `/usuarios/cliente/:${usuariosRouteParams.identification}/perfis-de-acesso`,
            element: <UserRoleAssignments />,
            role: usuariosRoles.customer.roleAssignmentsPage.role,
          },
          lettersOfAttorney: {
            name: "Procurações físicas",
            path: `/usuarios/cliente/:${usuariosRouteParams.identification}/procuracoes-fisicas`,
            element: <UserLettersOfAttorneyPage />,
            role: usuariosRoles.customer.roleAssignmentsPage.role,
          },
          notifications: {
            name: "Notificações",
            path: `/usuarios/cliente/:${usuariosRouteParams.identification}/notificacoes`,
            hasSubItems: true,
            children: {
              email: {
                name: "E-mail",
                path: `/usuarios/cliente/:${usuariosRouteParams.identification}/notificacoes/email`,
                element: <NotificationsEmailPage />,
                role: usuariosRoles.customer.notificationsEmailPage.role,
              },
              push: {
                name: "Push",
                path: `/usuarios/cliente/:${usuariosRouteParams.identification}/notificacoes/push`,
                element: <NotificationsPushPage />,
                role: usuariosRoles.customer.notificationsPushPage.role,
              },
            },
          },
        },
      },
    },
  },
  product: {
    name: "Usuários",
    path: "/usuarios/produto",
    element: <RedirectWithRoles />,
    children: {
      b2c: {
        name: "Todos os Usuários",
        path: "/usuarios/produto/b2c",
        element: <B2CUsersPage />,
        role: usuariosRoles.product.b2cPage.role,
        children: {
          createAdmin: {
            name: "Criar administrador",
            path: "/usuarios/produto/b2c/novo-administrador",
            element: <CreateAdmin />,
            role: usuariosRoles.product.b2cCreateAdminPage.role,
          },
          user: {
            name: "Usuário",
            path: `/usuarios/produto/b2c/:${usuariosRouteParams.identification}`,
            showChildren: true,
            children: {
              ...productCommonRoutes("b2c"),
            },
          },
        },
      },
      b2b: {
        name: "Usuários B2B",
        path: "/usuarios/produto/b2b",
        element: <B2BUsersPage />,
        role: usuariosRoles.product.b2bPage.role,
        children: {
          user: {
            name: "Usuário B2B",
            path: `/usuarios/produto/b2b/:${usuariosRouteParams.identification}`,
            showChildren: true,
            children: {
              ...productCommonRoutes("b2b"),
            },
          },
        },
      },
    },
  },
} as const satisfies Routes;
