import { OToastManager, modalManager } from "@maestro/core";
import { OTypography } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services/service";
import { getValueFromMap } from "utils/get-value-from-map";
import { showConfigurationsModalId } from "../../../../components/show-configurations-modal";
import {
  integrationDocumentsMap,
  scfDevelopersOnboardingStatusMap,
} from "../../../../utils/scf-configuration-maps";

const redirectDetailLink = (
  baseUrl: string,
  authClientId: string,
  configurationId: string,
) => {
  return `${baseUrl}?authClientId=${authClientId}&configurationId=${configurationId}`;
};

export const dataSource =
  dataSourceCustomStoreGenerator<ScfDevelopers.Workflow>(() =>
    service.scfDevelopers
      .getWorkflowConfigurations()
      .then(({ data }) => ({
        data,
        totalCount: data.length,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar pelos workflows";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const workflowConfigurationGrid = (
  setConfigurations: (
    configurations: ScfDevelopers.WorkflowConfiguration[],
  ) => void,
): ODataGridGeneratorConfig<ScfDevelopers.Workflow> => ({
  datagrid: {
    noDataText: "Nenhum workflow encontrado.",
    pager: {
      infoText: "Página {0} de {1} ({2} workflows)",
    },
  },
  columns: [
    {
      caption: "Detalhes",
      role: roles.ferramentas.product.onboardingWorkflowConfigScfEditPage.role,
      cellRender: ({ data }) => (
        <DetailsLink
          href={redirectDetailLink(
            corporateRouter.routes.ferramentas.product.onboarding.domainConfigScf.workflowConfiguration.edit.path(
              {
                integrationType: data.integrationType,
              },
            ),
            data.authClientId,
            data.configurationId ?? "",
          )}
        />
      ),
    },
    {
      dataField: "partnerName",
      caption: "Nome parceiro",
    },
    {
      dataField: "configurationName",
      caption: "Nome configuração",
    },
    {
      dataField: "authClientId",
      caption: "AuthClientId",
    },
    {
      dataField: "configurationId",
      caption: "ConfigurationId",
    },
    {
      dataField: "integrationType",
      caption: "Tipo de integração",
    },
    {
      dataField: "desks",
      caption: "Mesas",
    },
    {
      dataField: "mainDesk",
      caption: "Mesa principal",
    },
    {
      dataField: "isFidc",
      dataType: "boolean",
      caption: "É FIDC",
    },
    {
      dataField: "partnerId",
      caption: "PartnerId",
    },
    {
      dataField: "allowedReprocessStatuses",
      caption: "Status possíveis",
      cellRender: ({ data }) => (
        <OTypography>
          {data.allowedReprocessStatuses
            .map((status) =>
              getValueFromMap(scfDevelopersOnboardingStatusMap, status),
            )
            .join(", ")}
        </OTypography>
      ),
    },
    {
      dataField: "requiredOnboardingDocuments",
      caption: "Documentos necessários",
      cellRender: ({ data }) => (
        <OTypography>
          {data.requiredOnboardingDocuments
            .map((document) =>
              getValueFromMap(integrationDocumentsMap, document),
            )
            .join(", ")}
        </OTypography>
      ),
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => {
        return (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-unified-invoice" },
                label: "Exibir configurações",
                onClick: () => {
                  setConfigurations(data.configurations);
                  modalManager.show(showConfigurationsModalId);
                },
              },
              {
                icon: { category: "orq", icon: "orq-unified-invoice" },
                label: "Exibir configurações de sincronização",
                onClick: () => {
                  setConfigurations(data.syncConfigurations);
                  modalManager.show(showConfigurationsModalId);
                },
              },
            ]}
          />
        );
      },
    },
  ],
});
