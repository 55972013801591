import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLoader,
  OOption,
  ORFieldInput,
  ORFieldSelect,
  ORow,
} from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useDeskConfigPipelineForm } from "./desk-config-pipeline-form.hook";

export const DeskConfigPipelineForm = () => {
  const {
    desksConfigs,
    enums,
    loadingPipelines,
    hasErrorDesksConfigs,
    hasErrorEnums,
    hasErrorPipelines,
    getDeskOnboardingConfigPipelineEnums,
    getDesksOnboardingConfigs,
    getPipelineConfigurationList,
    loadingDesksConfigs,
    loadingEnums,
    pipelines,
  } = useDeskConfigPipelineForm();

  return (
    <OCard>
      <OCardBody>
        <ORow>
          <OCol xs={12} lg={6} align="center" className="gap-2">
            <ORFieldSelect
              id="deskOnboardingConfigIdentifier"
              name="deskOnboardingConfigIdentifier"
              label="Configuração de mesa"
              placeholder="Selecione"
              disabled={loadingDesksConfigs}
              key={desksConfigs?.length}
            >
              {desksConfigs?.map((config) => (
                <OOption
                  key={config.configIdentifier}
                  value={config.configIdentifier}
                >
                  {`${config.configIdentifier} - ${config.configName} (${config.configDescription})`}
                </OOption>
              ))}
            </ORFieldSelect>
            {loadingDesksConfigs && <OLoader className="d-block" size="sm" />}
            {hasErrorDesksConfigs && (
              <OButton
                type="default"
                outline
                onClick={() => getDesksOnboardingConfigs()}
              >
                <OIcon
                  category="fa"
                  icon="fa-repeat"
                  size="sm"
                  type="default"
                />
              </OButton>
            )}
          </OCol>
          <OCol xs={12} lg={6} align="center" className="gap-2">
            <SelectSearchField
              options={pipelines}
              id="pipelineUuid"
              name="pipelineUuid"
              label="Pipeline"
              placeholder="Selecione"
              disabled={loadingPipelines}
              key={pipelines?.length}
            />
            {loadingPipelines && <OLoader className="d-block" size="sm" />}
            {hasErrorPipelines && (
              <OButton
                type="default"
                outline
                onClick={() => getPipelineConfigurationList()}
              >
                <OIcon
                  category="fa"
                  icon="fa-repeat"
                  size="sm"
                  type="default"
                />
              </OButton>
            )}
          </OCol>
          <OCol xs={12} lg={6}>
            <ORFieldInput
              id="order"
              name="order"
              label="Ordem do pipeline"
              tag="number"
            />
          </OCol>
          <OCol xs={12} lg={6} align="center" className="gap-2">
            <ORFieldSelect
              id="pipelineType"
              name="pipelineType"
              label="Tipo do pipeline"
              placeholder="Selecione"
              disabled={loadingEnums}
              key={enums?.deskOnboardingConfigPipelineTypes?.length}
            >
              {enums?.deskOnboardingConfigPipelineTypes?.map((type) => (
                <OOption key={type} value={type}>
                  {type}
                </OOption>
              ))}
            </ORFieldSelect>
            {loadingEnums && <OLoader className="d-block" size="sm" />}
            {hasErrorEnums && (
              <OButton
                type="default"
                outline
                onClick={() => getDeskOnboardingConfigPipelineEnums()}
              >
                <OIcon
                  category="fa"
                  icon="fa-repeat"
                  size="sm"
                  type="default"
                />
              </OButton>
            )}
          </OCol>
          <OCol xs={12} lg={6}>
            <ORFieldInput
              id="pipelineArgument"
              name="pipelineArgument"
              label="Argumentos pipeline"
              tag="textarea"
            />
          </OCol>
        </ORow>
      </OCardBody>
    </OCard>
  );
};
