import { DetailsCard } from "components/details-card";
import { useTranslation } from "react-i18next";
import { NoteDetailsGenrator } from "./note-detail.details";
import { NoteDetailProps } from "./note-detail.types";

export const NoteDetail = ({ summary, loading }: NoteDetailProps) => {
  const { t } = useTranslation("receivables", { keyPrefix: "noteNote" });

  return (
    <DetailsCard
      fields={NoteDetailsGenrator(summary, t)}
      value={summary}
      loading={loading}
    />
  );
};
