import { SelectSearchField } from "components/select-search";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useFormPropertyField } from "./form-property-field.hook";

export const FormPropertyField = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { description } = processorConfigProperty;
  const { formList, processorPropertyFieldId } = useFormPropertyField({
    processorConfigProperty,
    fieldId,
  });

  const options = [
    { identification: "", name: "Nenhum" },
    ...(formList ?? []),
  ].map((config) => {
    return {
      value: config.identification,
      label: config?.name,
    };
  });

  return (
    <SelectSearchField
      key={formList?.map((form) => form.identification).join("")}
      id={processorPropertyFieldId}
      name={processorPropertyFieldId}
      placeholder="Selecione"
      label={description}
      options={options}
    />
  );
};
