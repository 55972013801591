import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { isNotProd } from "utils/environments";
import { logger } from "utils/logger";
import { resources } from "./locales";

const supportedLngs = ["pt", "es"];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      supportedLngs,
      fallbackLng: "pt",
      resources,
      interpolation: {
        escapeValue: false,
      },
      debug: isNotProd(),
    },
    (error: unknown) => {
      if (error) {
        logger.error("[I18n] error", error);
        return;
      }
      logger.info("[I18n] loaded");
    },
  );

// eslint-disable-next-line import/no-default-export
export default i18n;
