export const hasEmptyAttribute = (
  payload: HubOnboardingRelationships.Admin.ContingencyPayload,
): boolean => {
  const values = Object.values(payload);
  let hasEmpty = false;
  for (const value of values) {
    if (typeof value === "object") {
      hasEmpty = hasEmptyAttribute(value);
    }
    if (!value || value.length === 0) {
      hasEmpty = true;
      break;
    }
  }
  return hasEmpty;
};

export const hasMissingAttribute = (
  payload: HubOnboardingRelationships.Admin.ContingencyPayload,
  variableDetails:
    | HubOnboardingRelationships.Admin.VariableDetail[]
    | HubOnboardingRelationships.Admin.Variable[],
): boolean => {
  let hasEmpty = false;
  for (const variable of variableDetails) {
    const property = variable.propertyIdentifier;
    if (variable.type === "object") {
      hasEmpty = hasMissingAttribute(
        payload[
          property
        ] as HubOnboardingRelationships.Admin.ContingencyPayload,
        variable.variables,
      );
    }
    if (!payload[property]) {
      hasEmpty = true;
      break;
    }
  }
  return hasEmpty;
};
