import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { corporateRouter } from "routes/corporate-router.context";
import { SacadosCompaniesViewValueItem } from "services/quickfin/models";
import { buildCustomerLink } from "utils/build-customer-link";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";

export const grid = (
  t: TFunction,
): ODataGridGeneratorConfig<SacadosCompaniesViewValueItem> => ({
  datagrid: {
    noDataText: t("grid.dataNotFound"),
    remoteOperations: true,
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
  },
  columns: [
    {
      allowExporting: false,
      cellRender: ({ data: { Cnpj, Country } }) => {
        if (!Cnpj) return;
        const country = Country && Country !== "BR" ? Country : "";
        const identification = country + Cnpj;
        return (
          <DetailsLink
            href={buildCustomerLink(
              identification,
              corporateRouter.routes.recebiveis.customer.path,
            )}
          />
        );
      },
    },
    {
      dataField: "OfficialName",
      caption: t("grid.payer"),
    },
    {
      dataField: "Cnpj",
      caption: t("grid.payerIdentification"),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      format: (value) => masks.cpfCnpj(value),
    },
    {
      dataField: "CompanyEntityType",
      caption: t("grid.payerIdentificationType"),
      allowFiltering: false,
      allowSorting: false,
    },
    {
      dataField: "Country",
      caption: t("grid.payerCountry"),
      allowFiltering: false,
      allowSorting: false,
    },
    {
      dataField: "CreatedAt",
      caption: t("grid.createdAt"),
      dataType: "date",
      cellRender: ({ data }) =>
        dayjs(data.CreatedAt)
          .utc(true)
          .tz("America/Sao_Paulo")
          .format("DD/MM/YYYY, HH:mm"),
    },
    {
      dataField: "UpdatedAt",
      caption: t("grid.updatedAt"),
      dataType: "date",
      cellRender: ({ data }) =>
        dayjs(data.UpdatedAt)
          .utc(true)
          .tz("America/Sao_Paulo")
          .format("DD/MM/YYYY, HH:mm"),
    },
  ],
});
