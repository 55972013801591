import { PageTitle } from "components/page-title";
import { DetailsTemplate } from "templates/details-template";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PaymentInfo } from "./_compose/payment-info";
import { InstallmentGrid } from "./_compose/installment-grid";
import { useCreditCardPaymentsContext } from "../../../../contexts/cliente/credit-card-payments.context";

export const PaymentDetails = () => {
  const { creditCardPaymentId } = useParams();
  const { selectPayment } = useCreditCardPaymentsContext();

  useEffect(() => {
    if (creditCardPaymentId) {
      selectPayment(creditCardPaymentId);
    }
  }, [creditCardPaymentId, selectPayment]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes do pagamento" />}>
      <div className="d-flex flex-column gap-2">
        <PaymentInfo />
        <InstallmentGrid />
      </div>
    </DetailsTemplate>
  );
};
