import { ODataGridGeneratorConfig } from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { masks } from "@maestro/utils";
import {
  CreditCardPaymentStatus,
  MapperCreditCardPaymentStatus,
} from "services/bankinghub/models/types/cards";
import { corporateRouter } from "routes/corporate-router.context";
import { CreditCardPaymentActionButtons } from "./credit-card-payment-action-buttons";
import { defaultPageSize } from "../../credit-card-payments.type";

export const creditCardPaymentGrid: ODataGridGeneratorConfig<BankingHubCards.CreditCardPayment> =
  {
    datagrid: {
      noDataText:
        "Nenhum pagamento ou transferência por cartão de crédito encontrado",
      remoteOperations: {
        paging: true,
        filtering: true,
        sorting: true,
      },
      headerFilter: {
        visible: true,
      },
      filterRow: {
        visible: true,
      },
      pager: {
        showPageSizeSelector: true,
        allowedPageSizes: [5, 10, 20, 40, 100],
        showInfo: true,
      },
    },
    columns: [
      {
        caption: "Detalhes",
        cellRender: ({ data: { id } }) => {
          return (
            <DetailsLink
              href={corporateRouter.routes.cartoes.customer.creditCardPayments.paymentDetails.path(
                {
                  creditCardPaymentId: id,
                },
              )}
            />
          );
        },
        allowExporting: false,
      },
      {
        dataField: "createdAt",
        caption: "Data de criação",
        dataType: "datetime",
        format: "dd/MM/yyyy, HH:mm",
        allowSorting: true,
        allowHeaderFiltering: false,
        allowFiltering: false,
      },
      {
        dataField: "type",
        caption: "Tipo",
        allowSorting: false,
        allowHeaderFiltering: false,
        allowFiltering: false,
      },
      {
        dataField: "totalAmount",
        caption: "Valor total",
        cellRender: (option) => masks.currency(option.value, "R$", ","),
        allowSorting: true,
        allowHeaderFiltering: false,
        allowFiltering: false,
      },
      {
        dataField: "installmentAmount",
        caption: "Valor da parcela",
        cellRender: (option) => masks.currency(option.value, "R$", ","),
        allowSorting: false,
        allowHeaderFiltering: false,
        allowFiltering: false,
      },
      {
        dataField: "installmentQuantity",
        caption: "Número de parcelas",
        allowSorting: false,
        allowHeaderFiltering: false,
        allowFiltering: false,
      },
      {
        dataField: "status",
        caption: "Status",
        headerFilter: {
          dataSource: Object.values(CreditCardPaymentStatus).map((status) => ({
            text: MapperCreditCardPaymentStatus[status],
            value: ["status", status],
          })),
        },
        cellRender: ({ data }) => MapperCreditCardPaymentStatus[data.status],
        allowSorting: false,
        allowHeaderFiltering: true,
        allowFiltering: false,
      },
      {
        caption: "Ações",
        cellRender: ({ data, component }) => {
          if (data.status !== CreditCardPaymentStatus.Confirmed) return null;

          return (
            <CreditCardPaymentActionButtons
              installmentId={data.externalInstallmentId}
              totalAmount={data.totalAmount}
              reload={() => component.getDataSource().reload()}
            />
          );
        },
      },
    ],
    paging: {
      enabled: true,
      defaultPageSize,
    },
  };
