import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { cardIconMap } from "../../../../../utils";
import { CardReceivable } from "../../intubate-operations.types";
import { DisbursementAmountCell, RequestedAmountCell } from "./_compose";

export const buildQuoteListGrid = (
  setSelectedRows: (selectedRows: CardReceivable[]) => void,
): ODataGridGeneratorConfig<CardReceivable> => ({
  datagrid: {
    noDataText: "Nenhum recebível.",
    filterRow: {
      visible: true,
    },
    headerFilter: {
      visible: true,
    },
    remoteOperations: true,
    scrolling: {
      showScrollbar: "always",
      mode: "infinite",
      rowRenderingMode: "virtual",
    },
    paging: {
      pageSize: 20,
    },
    height: 900,
    onSelectionChanged: (evt) => setSelectedRows(evt.selectedRowsData),
  },
  columns: [
    {
      dataField: "ReceivableId",
      dataType: "string",
      caption: "ID do recebível",
      allowHeaderFiltering: false,
    },
    {
      dataField: "Sacado",
      dataType: "string",
      caption: "Sacado",
      allowHeaderFiltering: false,
    },
    {
      dataField: "SacadoIdentification",
      dataType: "string",
      caption: "CNPJ do Sacado",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "Cedente",
      dataType: "string",
      caption: "Cedente",
      allowHeaderFiltering: false,
    },
    {
      dataField: "CedenteIdentification",
      dataType: "string",
      caption: "CNPJ do Cedente",
      format: (value) => masks.cnpj(value),
      calculateFilterExpression: calculateTaxIdFilterExpression,
      allowHeaderFiltering: false,
    },
    {
      dataField: "Currency",
      dataType: "string",
      caption: "Moeda",
      alignment: "center",
    },
    {
      dataField: "Bandeira",
      dataType: "string",
      caption: "Bandeira",
      alignment: "center",
      cellRender: ({ data }) =>
        getValueFromMap(cardIconMap, data.Bandeira) || data.Bandeira,
    },
    {
      dataField: "DataVencimento",
      caption: "Data de Vencimento",
      dataType: "date",
      format: "shortDate",
      alignment: "center",
      allowHeaderFiltering: false,
      sortOrder: "asc",
    },
    {
      dataField: "ValorVencimento",
      caption: "Valor Vencimento",
      format: (value) => masks.currency(value, "R$"),
      allowHeaderFiltering: false,
    },
    {
      name: "ValorSolicitado",
      caption: "Valor Solicitado",
      cellRender: ({ data }) => <RequestedAmountCell data={data} />,
      allowHeaderFiltering: false,
    },
    {
      dataField: "TaxaTotal",
      caption: "Taxa Total",
      dataType: "number",
      format: { type: "percent", precision: 4 },
      allowHeaderFiltering: false,
    },
    {
      dataField: "ValorDesembolso",
      caption: "Valor De Desembolso",
      cellRender: ({ data }) => <DisbursementAmountCell data={data} />,
      allowFiltering: false,
      allowSorting: false,
    },
  ],
  selection: {
    mode: "multiple",
    showCheckBoxesMode: "always",
    selectAllMode: "allPages",
  },
});
