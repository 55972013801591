import { OButton, OCard, OTypography } from "@maestro/react";
import {
  PreviousStepButton,
  ResetStepButton,
  useStepsBuilder,
} from "components/steps-builder";
import { useMemo } from "react";
import { buildOperationSummary } from "../../../../../utils/operation-summary";
import { useIntubateOperations } from "../../intubate-operations.context";

export const Confirmation = () => {
  const { cardReceivables, submit, bankAccount } = useIntubateOperations();

  const { nextStep } = useStepsBuilder();

  const valorTotalBruto = useMemo(() => {
    return cardReceivables.reduce(
      (acc, curr) =>
        acc + (curr.ValorSolicitado ?? curr.ValorVencimento),
      0,
    );
  }, [cardReceivables]);

  const valorTotalLiquido = useMemo(() => {
    return cardReceivables.reduce(
      (acc, curr) =>
        acc + (curr.ValorDesembolsoSolicitado ?? curr.ValorDesembolso),
      0,
    );
  }, [cardReceivables]);

  const numberOfOperations = useMemo(
    () =>
      [
        ...new Set(
          cardReceivables.map(
            (receivable) =>
              `${receivable.CedenteIdentification}${receivable.SacadoIdentification}`,
          ),
        ),
      ].length,
    [cardReceivables],
  );

  const operationCredenciadoras = useMemo(
    () => [...new Set(cardReceivables.map((receivable) => receivable.Sacado))],
    [cardReceivables],
  );

  const operationSummary = buildOperationSummary(
    valorTotalBruto,
    valorTotalLiquido,
    cardReceivables.length,
    numberOfOperations,
    bankAccount,
  );

  const numberOfOperationsText =
    numberOfOperations > 1
      ? `Serão criadas ${numberOfOperations} operações.`
      : "Será criada 1 operação.";

  return (
    <div className="d-flex flex-column align-items-center gap-2">
      <OTypography>{numberOfOperationsText}</OTypography>
      <OTypography>Esse processo pode demorar alguns minutos.</OTypography>
      <OCard className="d-flex flex-row justify-content-around p-4 mt-2">
        {operationSummary.items.map((operation) => (
          <div className="d-flex flex-column" key={operation.label}>
            <div>{operation.label}</div>
            <div>{operation.value?.toString()}</div>
          </div>
        ))}
        <div className="d-flex flex-column">
          <div>Credenciadoras</div>
          <div className="d-flex flex-column">
            {operationCredenciadoras.map((credenciadora) => (
              <div key={credenciadora}>{credenciadora}</div>
            ))}
          </div>
        </div>
      </OCard>
      <div className="d-flex justify-content-end gap-3 mt-4 w-100">
        <ResetStepButton className="me-auto" />
        <PreviousStepButton />
        <OButton
          dataAction="entuba_step_confirmacao:botao:solicitar_antecipacao"
          dataLabel="solicitar_antecipacao"
          onClick={() => {
            submit();
            nextStep();
          }}
        >
          Criar operação
        </OButton>
      </div>
    </div>
  );
};
