import { masks } from "@maestro/utils";
import { DetailsFields } from "components/details-card";
import { TFunction } from "i18next";
import { PostAdminReceivablesSummaryResponse } from "services/quickfin/models";

export const NoteDetailsGenrator = (
  summary: PostAdminReceivablesSummaryResponse,
  t: TFunction,
) => {
  if (!summary) return {} as DetailsFields;

  return {
    summary: {
      items: [
        {
          label: t("note.totalOfReceivables"),
          value: summary.totalRecebiveis,
          grid: { md: 4, xl: 3 },
        },
        {
          label: t("note.totalOfMaturityAmount"),
          value: `${masks.currency(
            summary.totalValorVencimento,
            "R$",
          )} | ${masks.currency(
            summary.totalValorVencimentoUSD,
            "US$",
          )} | ${masks.currency(summary.totalValorVencimentoEUR, "€")}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: t("note.totalOfDisbursementAmount"),
          value: `${masks.currency(
            summary.totalValorDesembolso,
            "R$",
          )} | ${masks.currency(
            summary.totalValorDesembolsoUSD,
            "US$",
          )} | ${masks.currency(summary.totalValorDesembolsoEUR, "€")}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: t("note.totalOfRebate"),
          value: `${masks.currency(
            summary.totalRebate,
            "R$",
          )} | ${masks.currency(
            summary.totalRebateUSD,
            "US$",
          )} | ${masks.currency(summary.totalRebateEUR, "€")}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Total desembolsado",
          value: `${masks.currency(
            summary.totalDesembolsado,
            "R$",
          )} | ${masks.currency(
            summary.totalDesembolsadoUSD,
            "US$",
          )} | ${masks.currency(summary.totalDesembolsadoEUR, "€")}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: t("note.totalOfDelayed"),
          value: `${masks.currency(
            summary.totalAVencer,
            "R$",
          )} | ${masks.currency(
            summary.totalAVencerUSD,
            "US$",
          )} | ${masks.currency(summary.totalAVencerEUR, "€")}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Total em atraso",
          value: `${masks.currency(
            summary.totalEmAtraso,
            "R$",
          )} | ${masks.currency(
            summary.totalEmAtrasoUSD,
            "US$",
          )} | ${masks.currency(summary.totalEmAtrasoEUR, "€")}`,
          grid: { md: 4, xl: 3 },
        },
        {
          label: "Total liquidado",
          value: `${masks.currency(
            summary.totalLiquidado,
            "R$",
          )} | ${masks.currency(
            summary.totalLiquidadoUSD,
            "US$",
          )} | ${masks.currency(summary.totalLiquidadoEUR, "€")}`,
          grid: { md: 4, xl: 3 },
        },
      ],
    },
  } satisfies DetailsFields;
};
