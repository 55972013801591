import { client } from "../../client";
import { hubPartnersEndpoints } from "../hubpartners.endpoints";
import { GetCustomersODataResponse } from "./models/get-customers-o-data.response";
import { GetOperatorsODataResponse } from "./models/get-operators-o-data.response";
import { GetAccountsRelationshipODataResponse } from "./models/get-accounts-o-data.response";
import { GetOriginatorsODataResponse } from "./models/get-originators-o-data.response";
import { GetReferralsODataResponse } from "./models/get-referrals-o-data.response";

export const odataService = {
  client: {
    getAccountsRelationship() {
      return client.get<GetAccountsRelationshipODataResponse>(
        hubPartnersEndpoints.admin.odata.adminGetODataAccountRelationships,
      );
    },
    getEntitiesRelationship() {
      return client.get<GetCustomersODataResponse>(
        hubPartnersEndpoints.admin.odata.adminGetODataEntityRelationships,
      );
    },
    getReferralsRelationship() {
      return client.get<GetReferralsODataResponse>(
        hubPartnersEndpoints.admin.odata.adminGetODataReferralRelationships,
      );
    },
    getOperators() {
      return client.get<GetOperatorsODataResponse>(
        hubPartnersEndpoints.admin.odata.adminGetODataOperators,
      );
    },
    getOriginators() {
      return client.get<GetOriginatorsODataResponse>(
        hubPartnersEndpoints.admin.odata.adminGetODataOperators,
      );
    },
  },
  product: {
    getCustomersRelationship() {
      return client.get<GetCustomersODataResponse>(
        hubPartnersEndpoints.admin.odata.adminGetODataEntityRelationships,
      );
    },
    getOriginators() {
      return client.get<GetOriginatorsODataResponse>(
        hubPartnersEndpoints.admin.odata.adminGetODataOriginators,
      );
    },

    getOperators() {
      return client.get<GetOperatorsODataResponse>(
        hubPartnersEndpoints.admin.odata.adminGetODataOperators,
      );
    },
    getAccountsRelationship() {
      return client.get<GetAccountsRelationshipODataResponse>(
        hubPartnersEndpoints.admin.odata.adminGetODataAccountRelationships,
      );
    },
  },
};
