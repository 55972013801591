import {
  OButton,
  OCard,
  OCardBody,
  OCol,
  OIcon,
  OLabel,
  OLoader,
  OOption,
  OOptionMultiple,
  ORFieldInput,
  ORFieldRadioGroup,
  ORFieldSelect,
  ORFieldSelectMultiple,
  ORadio,
  ORow,
} from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { service } from "services";

export const DeskAccountConfigForm = () => {
  const {
    callService: getAccountInfo,
    loading: loadingAccountInfo,
    value: accountInfo,
    hasError: hasErrorAccountInfo,
  } = useServiceCall(service.onboarding.getDeskOnboardingAccountInfo);
  const {
    callService: getDesksOnboardingConfigs,
    loading: loadingDesksConfigs,
    value: desksConfigs,
    hasError: hasErrorDesksConfigs,
  } = useServiceCall(service.onboarding.getDesksOnboardingConfigs);
  const {
    callService: getSegmentGroups,
    loading: loadingSegmntGroups,
    value: segmentGroups,
    hasError: hasErrorSegmentGroups,
  } = useServiceCall(service.onboarding.getSegmentGroups);
  const {
    callService: getOfficers,
    loading: loadingOfficers,
    value: officers,
    hasError: hasErrorOfficers,
  } = useServiceCall(service.onboarding.getOfficers);
  const {
    callService: getContractFileConfiguration,
    loading: loadingContractFIleConfiguration,
    value: contractFIleConfiguration,
    hasError: hasErrorContractFIleConfiguration,
  } = useServiceCall(service.onboarding.getContractFileConfiguration);
  const {
    callService: getDesksOnboardingEnums,
    loading: loadingEnums,
    value: enums,
    hasError: hasErrorEnums,
  } = useServiceCall(service.onboarding.getDesksOnboardingEnums);

  useEffect(() => {
    getAccountInfo();
    getDesksOnboardingConfigs();
    getSegmentGroups();
    getOfficers();
    getContractFileConfiguration();
    getDesksOnboardingEnums();
  }, [
    getAccountInfo,
    getContractFileConfiguration,
    getDesksOnboardingConfigs,
    getOfficers,
    getSegmentGroups,
    getDesksOnboardingEnums,
  ]);
  return (
    <div className="d-flex flex-column gap-4">
      <OCard>
        <OCardBody>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="deskOnboardingAccountInfoUuid"
                name="deskOnboardingAccountInfoUuid"
                label="Informação da conta"
                placeholder="Selecione"
                disabled={loadingAccountInfo}
                key={accountInfo?.length}
              >
                {accountInfo?.map((account) => (
                  <OOption key={account.uuid} value={account.uuid}>
                    {`${account.agency} - ${account.accountType} (${account.accountDescription})`}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingAccountInfo && <OLoader className="d-block" size="sm" />}
              {hasErrorAccountInfo && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getAccountInfo()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="deskOnboardingConfigIdentifier"
                name="deskOnboardingConfigIdentifier"
                label="Configuração"
                placeholder="Selecione"
                disabled={loadingDesksConfigs}
                key={desksConfigs?.length}
              >
                {desksConfigs?.map((config) => (
                  <OOption
                    key={config.configIdentifier}
                    value={config.configIdentifier}
                  >
                    {`${config.configIdentifier} - ${config.configName} (${config.configDescription})`}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingDesksConfigs && <OLoader className="d-block" size="sm" />}
              {hasErrorDesksConfigs && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDesksOnboardingConfigs()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Optin template"
                tag="text"
                id="optinTemplate"
                name="optinTemplate"
              />
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Business Category"
                tag="text"
                id="businessCategory"
                name="businessCategory"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="segmentGroupId"
                name="segmentGroupId"
                label="Grupo de segmento"
                placeholder="Selecione"
                disabled={loadingSegmntGroups}
                key={segmentGroups?.length}
              >
                {segmentGroups?.map((segmentGroup) => (
                  <OOption key={segmentGroup.id} value={segmentGroup.id}>
                    {`${segmentGroup.id} - ${segmentGroup.segment.name} - ${segmentGroup.coSegment.name} (${segmentGroup.coSegment.code})`}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingSegmntGroups && <OLoader className="d-block" size="sm" />}
              {hasErrorSegmentGroups && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getSegmentGroups()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <SelectSearchField
                options={
                  officers?.map((officer) => ({
                    label: `${officer.cge} - ${officer.name}`,
                    value: officer.id,
                  })) ?? []
                }
                id="officerId"
                name="officerId"
                label="Officer"
                placeholder="Selecione"
              />
              {loadingOfficers && <OLoader className="d-block" size="sm" />}
              {hasErrorOfficers && (
                <OButton type="default" outline onClick={() => getOfficers()}>
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>

          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <SelectSearchField
                id="newSegmentGroupId"
                name="newSegmentGroupId"
                label="Novo grupo de segmento"
                placeholder="Selecione"
                disabled={loadingSegmntGroups}
                key={segmentGroups?.length}
                options={
                  segmentGroups?.map((segmentGroup) => ({
                    label: `${segmentGroup.id} - ${segmentGroup.segment.name} - ${segmentGroup.coSegment.name} (${segmentGroup.coSegment.code})`,
                    value: segmentGroup.id,
                  })) ?? []
                }
              />
              {loadingSegmntGroups && <OLoader className="d-block" size="sm" />}
              {hasErrorSegmentGroups && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getSegmentGroups()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldInput
                label="PossibleAccountsToOpenQuantity"
                tag="number"
                id="possibleAccountsToOpenQuantity"
                name="possibleAccountsToOpenQuantity"
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldInput
                label="Cge padrão (introducingCompany)"
                tag="number"
                id="defaultIntroducingCompanyCge"
                name="defaultIntroducingCompanyCge"
              />
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="usageType"
                name="usageType"
                label="UsageType"
                placeholder="Selecione"
                disabled={loadingEnums}
                key={enums?.deskAccountConfigUsageTypes?.length}
              >
                {enums?.deskAccountConfigUsageTypes?.map((usageType) => (
                  <OOption key={usageType} value={usageType}>
                    {usageType}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingEnums && <OLoader className="d-block" size="sm" />}
              {hasErrorEnums && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDesksOnboardingEnums()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="managedPortfolio"
                name="managedPortfolio"
                label="Managed portfolio?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldOverrideDeskConfig"
                name="shouldOverrideDeskConfig"
                label="Should override DeskConfig?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="isPrimary"
                name="isPrimary"
                label="Is Primary?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldUseOnAdmin"
                name="shouldUseOnAdmin"
                label="Deve mostrar no Admin?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="obligatoryHasRelationships"
                name="obligatoryHasRelationships"
                label="ObligatoryHasRelationships?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
            <OCol xs={12} lg={6}>
              <ORFieldRadioGroup
                id="shouldBeAvailableOnBtgEmpresas"
                name="shouldBeAvailableOnBtgEmpresas"
                label="Deve ficar disponivel no Btg Empresas?"
              >
                <div className="d-flex gap-4">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="yes" value="true" />
                    <OLabel htmlFor="yes">Sim</OLabel>
                  </div>
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <ORadio id="no" value="false" />
                    <OLabel htmlFor="no">Não</OLabel>
                  </div>
                </div>
              </ORFieldRadioGroup>
            </OCol>
          </ORow>
          <ORow>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelectMultiple
                id="contractFileConfigIds"
                name="contractFileConfigIds"
                label="Configuração de arquivo para contrato"
                placeholder="Selecione"
                disabled={loadingContractFIleConfiguration}
                key={contractFIleConfiguration?.length}
              >
                {contractFIleConfiguration?.map((config) => (
                  <OOptionMultiple key={config.uuid} value={config.uuid}>
                    {config.fileName}
                  </OOptionMultiple>
                ))}
              </ORFieldSelectMultiple>
              {loadingContractFIleConfiguration && (
                <OLoader className="d-block" size="sm" />
              )}
              {hasErrorContractFIleConfiguration && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getContractFileConfiguration()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
            <OCol xs={12} lg={6} align="center" className="gap-2">
              <ORFieldSelect
                id="isActivatedOn"
                name="isActivatedOn"
                label="Deve ser ativada onde?"
                placeholder="Selecione"
                disabled={loadingEnums}
                key={enums?.termsSignPlaces?.length}
              >
                {enums?.termsSignPlaces?.map((termsSignPlace) => (
                  <OOption key={termsSignPlace} value={termsSignPlace}>
                    {termsSignPlace}
                  </OOption>
                ))}
              </ORFieldSelect>
              {loadingEnums && <OLoader className="d-block" size="sm" />}
              {hasErrorEnums && (
                <OButton
                  type="default"
                  outline
                  onClick={() => getDesksOnboardingEnums()}
                >
                  <OIcon
                    category="fa"
                    icon="fa-repeat"
                    size="sm"
                    type="default"
                  />
                </OButton>
              )}
            </OCol>
          </ORow>
        </OCardBody>
      </OCard>
    </div>
  );
};
