import { OTooltip } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import { roles } from "roles/roles";
import { calculateTaxIdFilterExpression } from "utils/calculate-tax-id-filter-expression";
import { getValueFromMap } from "utils/get-value-from-map";
import { FavoriteWorkflowButton } from "../../../components/favorite-workflow-button";
import {
  biometryStatusesMap,
  buildContingenciesLink,
  buildDetailsLink,
  buildEditLink,
  guarantorTypesMap,
  portalStatusesMap,
  resentInvite,
} from "./guarantors.utils";

export const buildGrid: ODataGridGeneratorConfig<HubOnboardingRelationships.Guarantor.DetailedGuarantor> =
  {
    datagrid: {
      noDataText: "Nenhum avalista",
      pager: {
        allowedPageSizes: [10, 20, 30],
        showPageSizeSelector: true,
        infoText: "Página {0} de {1} ({2} avalistas)",
      },
      filterRow: {
        visible: true,
      },
    },
    columns: [
      {
        role: roles.cadastro.customer.guarantorsGuarantorsDetailsPage.role,
        cellRender: ({ data }) =>
          data.uuid ? (
            <DetailsLink href={buildDetailsLink(data.uuid)} />
          ) : undefined,
        allowExporting: false,
      },
      {
        caption: "Favorito",
        dataField: "isFavorite",
        allowExporting: false,
        allowFiltering: false,
        cellRender: ({ data }) => (
          <FavoriteWorkflowButton
            type="GUARANTOR"
            wasFavorite={data.isFavorite}
            workflowUuid={data.uuid}
          />
        ),
      },
      {
        dataField: "id",
        caption: "ID",
        allowExporting: false,
      },
      {
        dataField: "name",
        caption: "Nome",
      },
      {
        dataField: "email",
        caption: "E-mail",
      },
      {
        dataField: "phoneNumber",
        caption: "Telefone",
        customizeText: ({ valueText }) =>
          valueText ? masks.phone(valueText) : "Não informado",
      },
      {
        dataField: "taxId",
        caption: "CPF",
        customizeText: ({ valueText }) => masks.cpf(valueText),
        calculateFilterExpression: calculateTaxIdFilterExpression,
      },
      {
        dataField: "portalStatus",
        caption: "Dados Gerais",
        cellRender: ({ data }) =>
          getValueFromMap(portalStatusesMap, data.portalStatus),
        lookup: {
          dataSource: Object.entries(portalStatusesMap).map(([k, v]) => ({
            value: k,
            label: v,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        dataField: "detailedStatus",
        caption: "Status Detalhado",
        role: roles.cadastro.customer.guarantorsGuarantorsDetailedStatusColumn
          .role,
        allowExporting: false,
      },
      {
        dataField: "type",
        caption: "Tipo",
        cellRender: ({ data }) => getValueFromMap(guarantorTypesMap, data.type),
        lookup: {
          dataSource: Object.entries(guarantorTypesMap).map(([k, v]) => ({
            value: k,
            label: v,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        dataField: "biometryStatusType",
        caption: "Biometria",
        cellRender: ({ data }) =>
          getValueFromMap(biometryStatusesMap, data.biometryStatusType),
        lookup: {
          dataSource: Object.entries(biometryStatusesMap).map(([k, v]) => ({
            value: k,
            label: v,
          })),
          displayExpr: "label",
          valueExpr: "value",
        },
      },
      {
        dataField: "errors",
        caption: "Ocorrências",
        alignment: "center",
        width: 110,
        cellRender: ({ data }) => (
          <OTooltip floating position="top-left">
            <span slot="tooltip-content">Clique em detalhes para ver mais</span>
            {data.errors?.length}
          </OTooltip>
        ),
        allowExporting: false,
      },
      {
        dataField: "documents",
        caption: "Documentos",
        alignment: "center",
        width: 110,
        cellRender: ({ data }) => (
          <OTooltip floating position="top-left">
            <span slot="tooltip-content">Clique em detalhes para ver mais</span>
            {data.documents?.length}
          </OTooltip>
        ),
        allowExporting: false,
      },
      {
        caption: "Ações",
        allowExporting: false,
        role: roles.cadastro.customer.guarantorsGuarantorsEmbeddedPage.role,
        cellRender: ({ data }) => (
          <DataGridAction
            actions={[
              {
                icon: { category: "orq", icon: "orq-it" },
                label: "Contingências",
                route: data.uuid ? buildContingenciesLink(data.uuid) : "",
                role: roles.cadastro.customer.guarantorsContingenciesPage.role,
                visible: !!data.uuid,
                disabled: !data.uuid,
              },
              {
                label: "Editar",
                icon: { category: "orq", icon: "orq-edit-pencil" },
                route: data.uuid ? buildEditLink(data.uuid) : "",
                visible: !!data.uuid && data.canEditGuarantor,
                disabled: !data.uuid,
              },
              {
                label: "Reenviar onboarding",
                icon: { category: "orq", icon: "orq-email" },
                onClick: () => resentInvite(data.uuid ?? ""),
                visible: data.portalStatus === "REPROVADO",
              },
            ]}
          />
        ),
      },
    ],
    paging: {
      defaultPageSize: 10,
    },
  };
