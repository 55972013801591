import { OCol, ORow, OToastManager } from "@maestro/react";
import {
  SelectSearchMultiple,
  SelectSearchMultipleOption,
} from "components/form";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services/service";
import { generateUuidV4 } from "../../../../workflow-configuration-scf.utils";
import { BaseStepFields } from "./base-step-fields.component";

interface CedenteCompletoWebhookDevelopersStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const CedenteCompletoWebhookDevelopersStep = ({
  fieldName,
  index,
}: CedenteCompletoWebhookDevelopersStepProps) => {
  const [partnerOptions, setPartnerOptions] = useState<
    SelectSearchMultipleOption<string>[]
  >([]);

  const { getValues, setValue } = useFormContext();

  const fieldNameByIndex = `${fieldName}.${index}`;

  useEffect(() => {
    setValue(
      `${fieldNameByIndex}._t`,
      "WebhookViaDevelopersCedenteCompletoWorkflowConfigurationStep",
    );
    if (!getValues(`${fieldNameByIndex}.stepId`)) {
      setValue(`${fieldNameByIndex}.stepId`, `webhook-${generateUuidV4()}`);
    }
    if (!getValues(`${fieldNameByIndex}.nextStepId`)) {
      setValue(`${fieldNameByIndex}.nextStepId`, null);
    }
  }, [fieldNameByIndex, getValues, setValue]);

  const fetchPartners = useCallback(async () => {
    try {
      const { data } = await service.scfDevelopers.fetchPartnersList();
      setPartnerOptions(
        data.map<SelectSearchMultipleOption<string>>((partner) => ({
          label: partner.name,
          value: partner.authClientId,
        })) ?? [],
      );
    } catch {
      OToastManager.danger("Erro ao obter parceiros");
    }
  }, []);

  useEffect(() => {
    fetchPartners();
  }, [fetchPartners]);

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="CEDENTE_COMPLETO_WEBHOOK_DEVELOPERS"
        gridSize={4}
      />
      <OCol xs={4}>
        <SelectSearchMultiple
          label="Webhook para Parceiros adicionais"
          id={`${fieldNameByIndex}.additionalPartnerAuthClientIds`}
          name={`${fieldNameByIndex}.additionalPartnerAuthClientIds`}
          placeholder="Selecione os parceiros"
          options={partnerOptions}
          valuesLabel="Parceiros selecionados:"
          maxOptions={50}
        />
      </OCol>
    </ORow>
  );
};
