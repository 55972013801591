export const currencyOptions = ["BRL", "USD", "EUR"];
export const currencyQuoteTypeOptions = ["PTAX", "SPOT"];
export const indexCodeOptions = ["PRE", "CDIE"];
export const fundingTypeOptions = ["BTG", "BNDES"];
export const collateralTypesOptions = [
  "CAC",
  "IMOVEL",
  "PAINEIS_SOLARES",
  "PEAC",
  "RECEBIVEIS_CARTOES",
  "SACAS_GRAO",
  "SEGURO",
  "EQUIPAMENTO",
  "AERONAVE",
];
