import { env } from "utils/environments";

export const gatekeeperEndpoints = {
  allowOperation: {
    getSignatureCombinations: `${env.API_GATEKEEPER}/allow_operation/get_signature_combinations`,
  },
  impersonate: `${env.API_GATEKEEPER}/impersonate`,
  impersonateSalesforce: `${env.API_GATEKEEPER}/impersonate/salesforce`,
  internalCustomerService: {
    generate: `${env.API_GATEKEEPER}/internal_customer_service/generate`,
  },
  invalidate: {
    salesforce: {
      byCpf: `${env.API_GATEKEEPER}/invalidate/salesforce/by_cpf`,
    },
  },
  odata: {
    companies: `${env.API_GATEKEEPER}/odata/companies`,
    users: `${env.API_GATEKEEPER}/odata/users`,
    logs: `${env.API_GATEKEEPER}/odata/logs`,
  },
  profiles: {
    endpoint: `${env.API_GATEKEEPER}/profiles`,
    byProfileName: (profileName: string) =>
      `${env.API_GATEKEEPER}/profiles/${profileName}`,
    all: `${env.API_GATEKEEPER}/profiles/all`,
    getActs: `${env.API_GATEKEEPER}/profiles/get_acts`,
    getDetails: `${env.API_GATEKEEPER}/profiles/get_details`,
    addActs: `${env.API_GATEKEEPER}/profiles/add_acts`,
    removeActs: `${env.API_GATEKEEPER}/profiles/remove_acts`,
    updateSubProfiles: `${env.API_GATEKEEPER}/profiles/update_sub_profiles`,
    addRoles: `${env.API_GATEKEEPER}/profiles/add_roles`,
    removeRoles: `${env.API_GATEKEEPER}/profiles/remove_roles`,
    details: {
      create: `${env.API_GATEKEEPER}/profiles/details/create`,
      update: `${env.API_GATEKEEPER}/profiles/details/update`,
      delete: `${env.API_GATEKEEPER}/profiles/details/delete`,
      listGroups: `${env.API_GATEKEEPER}/profiles/details/list_groups`,
      getFromProfile: `${env.API_GATEKEEPER}/profiles/details/get_from_profile`,
    },
    updateProfile: (profileName: string) =>
      `${env.API_GATEKEEPER}/profiles/${profileName}`,
  },
  profileGroup: {
    listAll: `${env.API_GATEKEEPER}/profile_groups/list_all`,
  },
  profileManagement: {
    getSelfRequests: `${env.API_GATEKEEPER}/profile_management/get_self_requests`,
    newRequest: `${env.API_GATEKEEPER}/profile_management/new_request`,
    getRequestDetails: `${env.API_GATEKEEPER}/profile_management/get_request_details`,
    newChange: `${env.API_GATEKEEPER}/profile_management/new_change`,
    updateChange: `${env.API_GATEKEEPER}/profile_management/update_change`,
    deleteChange: `${env.API_GATEKEEPER}/profile_management/delete_change`,
    cancelRequest: `${env.API_GATEKEEPER}/profile_management/cancel_request`,
    sendRequest: `${env.API_GATEKEEPER}/profile_management/send_request`,
    getChangeDetail: `${env.API_GATEKEEPER}/profile_management/get_change_detail`,
    getAvailableRolesForChange: `${env.API_GATEKEEPER}/profile_management/get_available_roles_for_change`,
    getAvailableProfilesForChange: `${env.API_GATEKEEPER}/profile_management/get_available_profiles_for_change`,
    getAvailableSubProfilesForChange: `${env.API_GATEKEEPER}/profile_management/get_available_sub_profiles_for_change`,
    admin: {
      requestsOdata: `${env.API_GATEKEEPER}/profile_management/admin/requests_odata`,
      updateChange: `${env.API_GATEKEEPER}/profile_management/admin/update_change`,
      updateChanges: `${env.API_GATEKEEPER}/profile_management/admin/update_changes`,
      resolveRequest: `${env.API_GATEKEEPER}/profile_management/admin/resolve_request`,
      getRequestDetails: `${env.API_GATEKEEPER}/profile_management/admin/get_request_details`,
      publishRequestAnalysis: `${env.API_GATEKEEPER}/profile_management/admin/publish_request_analysis`,
    },
  },
  signup: {
    updateUserEmail: `${env.API_GATEKEEPER}/signup/update_user_email`,
  },
  roles: {
    endpoint: `${env.API_GATEKEEPER}/roles`,
    /**
     * Use `gatekeeperEndpoints.users.updateFromEmail`
     * @deprecated
     */
    updateById: `${env.API_GATEKEEPER}/roles/update_by_id`,
    byRoleName: (roleName: string) => `${env.API_GATEKEEPER}/roles/${roleName}`,
  },
  getActs: `${env.API_GATEKEEPER}/get_acts`,
  systems: {
    endpoint: `${env.API_GATEKEEPER}/systems`,
    all: `${env.API_GATEKEEPER}/systems/all`,
    bySystemName: (systemName: string) =>
      `${env.API_GATEKEEPER}/systems/${systemName}`,
  },
  sessionToken: {
    endpoint_by_tax_id: `${env.API_GATEKEEPER}/session-token/get_by_tax_id`,
    invalidate: {
      bySessionTokenId: (sessionTokenId: string) =>
        `${env.API_GATEKEEPER}/session-token/invalidate/${sessionTokenId}`,
    },
  },
  users: {
    endpoint: `${env.API_GATEKEEPER}/users`,
    getFromCpf: `${env.API_GATEKEEPER}/users/get_from_cpf`,
    getUserByEmail: `${env.API_GATEKEEPER}/users/get_user_by_email`,
    byEmail: {
      activate: (email: string) =>
        `${env.API_GATEKEEPER}/users/${email}/activate`,
      deactivate: (email: string) =>
        `${env.API_GATEKEEPER}/users/${email}/deactivate`,
      endpoint: (email: string) => `${env.API_GATEKEEPER}/users/${email}`,
    },
    email: {
      profiles: {
        add: `${env.API_GATEKEEPER}/users/email/profiles/add`,
        remove: `${env.API_GATEKEEPER}/users/email/profiles/remove`,
      },
    },
    updateFromEmail: `${env.API_GATEKEEPER}/users/update_from_email`,
    timePenalty: {
      status: `${env.API_GATEKEEPER}/users/time_penalty/status_by_cpf`,
      remove: `${env.API_GATEKEEPER}/users/time_penalty/bypass`,
    },
  },
  companies: {
    endpoint: `${env.API_GATEKEEPER}/companies`,
    createByIdentification: {
      users: {
        profiles: {
          add: `${env.API_GATEKEEPER}/companies/create_by_identification/users/profiles/add`,
        },
      },
    },
    deleteByIdentification: {
      users: {
        profiles: {
          remove: `${env.API_GATEKEEPER}/companies/delete_by_identification/users/profiles/remove`,
        },
      },
    },
    byIdentification: {
      users: {
        profiles: {
          /**
           * Use `gatekeeperEndpoints.companies.createByIdentification.users.profiles.add`
           * @deprecated
           */
          add: (identification: string) =>
            `${env.API_GATEKEEPER}/companies/${identification}/users/profiles/add`,
          /**
           * Use `gatekeeperEndpoints.companies.deleteByIdentification.users.profiles.remove`
           * @deprecated
           */
          remove: (identification: string) =>
            `${env.API_GATEKEEPER}/companies/${identification}/users/profiles/remove`,
        },
      },
    },
  },
  ssoUtils: {
    rmDecrypt: `${env.API_GATEKEEPER}/sso-utils/rm-d`,
  },
  advisors: {
    index: `${env.API_GATEKEEPER}/advisors`,
  },
  entities: {
    advisoredEntities: (identificationTuple: `cpf:${string}` | "self") =>
      `${env.API_GATEKEEPER}/entities/${identificationTuple}/advisored_entities`,
  },
};
