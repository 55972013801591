import { HubPartners } from "services/hubpartners/models/types/admin";
import * as yup from "yup";

export type UpdateOriginatorInfoFormValues = Pick<
  HubPartners.Admin.NewOriginator,
  "identification" | "identificationType" | "officialName" | "tradingName"
>;

export const updateOriginatorInfoValidationSchema: yup.ObjectSchema<UpdateOriginatorInfoFormValues> =
  yup.object({
    identification: yup.string().required("Este campo é obrigatório"),
    identificationType: yup
      .mixed<HubPartners.Admin.IdentificationType>()
      .required("Este campo é obrigatório"),
    officialName: yup.string().required("Este campo é obrigatório"),
    tradingName: yup.string(),
  });

export const updateOriginatorInfoDefaultValues: UpdateOriginatorInfoFormValues =
  {
    identification: "",
    identificationType: "CPF",
    officialName: "",
    tradingName: "",
  };
