import { StatusBadgeProps } from "components/status-badge";
import {
  AddressType,
  CardVariant,
  CardType,
  CardModality,
  WalletProvisionRequestStatus,
  WalletType,
  CreditCardPaymentStatus,
  RevertCreditCardPaymentType,
  CreditCardPaymentType,
} from "./enums";

export const MapperCardVariant: Record<CardVariant, string> = {
  [CardVariant.Black]: "Black",
  [CardVariant.Platinum]: "Platinum",
  [CardVariant.Business]: "Business",
  [CardVariant.Gold]: "Business",
  [CardVariant.BusinessDebit]: "Business",
};

export const MapperCardType: Record<CardType, string> = {
  [CardType.Physical]: "Físico",
  [CardType.Virtual]: "Virtual",
  [CardType.Plastic]: "Físico",
};

export const MapperCardModality: Record<CardModality, string> = {
  [CardModality.Credit]: "Crédito",
  [CardModality.Debit]: "Débito",
  [CardModality.Multiple]: "Múltiplo (Crédito e Débito)",
};

export const MapperAddressType: Record<AddressType, string> = {
  [AddressType.RESIDENTIAL]: "Residencial",
  [AddressType.COMMERCIAL]: "Comercial",
  [AddressType.OTHERS]: "Outros",
};

export const MapperWalletStatusToStatusBadgeProps: Record<
  WalletProvisionRequestStatus,
  StatusBadgeProps
> = {
  [WalletProvisionRequestStatus.Created]: {
    children: "Disponível",
    type: "info",
  },
  [WalletProvisionRequestStatus.Approved]: {
    children: "Aprovada",
    type: "success",
  },
  [WalletProvisionRequestStatus.Rejected]: {
    children: "Rejeitada",
    type: "danger",
  },
  [WalletProvisionRequestStatus.Expired]: {
    children: "Expirada",
    type: "warning",
  },
  [WalletProvisionRequestStatus.Deleted]: {
    children: "Apagada",
    type: "warning",
  },
};

export const MapperWalletTypeToStatusBadgeProps: Record<
  WalletType,
  StatusBadgeProps
> = {
  [WalletType.Apple]: {
    children: "Apple Wallet",
    type: "info",
  },
  [WalletType.Samsung]: {
    children: "Samsung Wallet",
    type: "info",
  },
  [WalletType.Google]: {
    children: "Google Wallet",
    type: "info",
  },
};

export const MapperCreditCardPaymentStatus: Record<
  CreditCardPaymentStatus,
  string
> = {
  [CreditCardPaymentStatus.Created]: "Criado",
  [CreditCardPaymentStatus.Confirmed]: "Confirmado",
  [CreditCardPaymentStatus.Failed]: "Falhou",
  [CreditCardPaymentStatus.Reverted]: "Revertido",
  [CreditCardPaymentStatus.Canceled]: "Cancelado",
};

export const MapperRevertCreditCardPaymentType: Record<
  RevertCreditCardPaymentType,
  string
> = {
  [RevertCreditCardPaymentType.ClientOrderReturn]:
    "Devolução a pedido do cliente",
  [RevertCreditCardPaymentType.SystemicError]: "Devolução sistêmica",
  [RevertCreditCardPaymentType.FraudCancelling]: "Cancelamento por fraude",
  [RevertCreditCardPaymentType.NoReturn]: "Sem devolução",
};

export const MapperCreditCardPaymentType: Record<
  CreditCardPaymentType,
  string
> = {
  [CreditCardPaymentType.Pix]: "Pix",
  [CreditCardPaymentType.Bankslip]: "Boleto",
};
