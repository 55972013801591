import * as yup from "yup";

interface BaseWorkflowConfigurationFormField {
  stepId: string;
  stepType: ScfDevelopers.WorkflowStepType;
  nextStepId: string | null;
  isStepActive: string[];
  _t: string;
}

interface BCheckComplementCedenteSimplesWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE";
}

interface LessCedenteSimplesWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_SIMPLES_LESS";
  partyGroupTypeIds: number[];
  segment: string;
  accountType: string | null;
  mainOfficerCGE: number;
  mainOfficerName: string | null;
  skipValidationAlreadyIfExistsPartyWithRelationship: string[];
}

interface SyncClerkCedenteSimplesWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_SIMPLES_CLERK";
}

interface BCheckComplementorTomadorDeCreditoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "TOMADOR_CREDITO_COMPLEMENT_VALIDATE";
}

interface BCheckComplementorCedenteCompletoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_COMPLETO_COMPLEMENT_VALIDATE";
}

interface InternalCallbackCedenteCompletoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_COMPLETO_INTERNAL_CALLBACK";
  allowedWebhookStatusTypes: string[];
}

interface LessCedenteCompletoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_COMPLETO_LESS";
  partyGroupTypeIds: number[];
  segment: string;
  accountType: string | null;
  mainOfficerCGE: number;
  mainOfficerName: string | null;
  skipValidationAlreadyIfExistsPartyWithRelationship: string[];
}

interface SyncClerkCedenteCompletoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_COMPLETO_CLERK";
}

interface WebhookViaDevelopersCedenteCompletoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "CEDENTE_COMPLETO_WEBHOOK_DEVELOPERS";
  additionalPartnerAuthClientIds: string[];
}

interface FraudSysTomadorDeCreditoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "TOMADOR_CREDITO_RISKANALYSIS";
  segment: string;
  coSegment: string;
  riskAnalysisType: string;
}

interface LessTomadorDeCreditoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "TOMADOR_CREDITO_LESS";
  partyGroupTypeIds: number[];
  segment: string;
  accountType: string | null;
  mainOfficerCGE: number;
  mainOfficerName: string | null;
  skipValidationAlreadyIfExistsPartyWithRelationship: string[];
}

interface SyncClerkTomadorDeCreditoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "TOMADOR_CREDITO_CLERK";
}

interface WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationFormField
  extends BaseWorkflowConfigurationFormField {
  stepType: "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS";
}

export type CedenteSimplesConfigurationFormField =
  | BCheckComplementCedenteSimplesWorkflowConfigurationFormField
  | LessCedenteSimplesWorkflowConfigurationFormField
  | SyncClerkCedenteSimplesWorkflowConfigurationFormField;

export type CedenteCompletoConfigurationFormField =
  | BCheckComplementorCedenteCompletoWorkflowConfigurationFormField
  | InternalCallbackCedenteCompletoWorkflowConfigurationFormField
  | LessCedenteCompletoWorkflowConfigurationFormField
  | SyncClerkCedenteCompletoWorkflowConfigurationFormField
  | WebhookViaDevelopersCedenteCompletoWorkflowConfigurationFormField;

export type TomardorCreditoConfigurationFormField =
  | BCheckComplementorTomadorDeCreditoWorkflowConfigurationFormField
  | FraudSysTomadorDeCreditoWorkflowConfigurationFormField
  | LessTomadorDeCreditoWorkflowConfigurationFormField
  | SyncClerkTomadorDeCreditoWorkflowConfigurationFormField
  | WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationFormField;

export type WorkflowConfigurationFormField =
  | CedenteSimplesConfigurationFormField
  | CedenteCompletoConfigurationFormField
  | TomardorCreditoConfigurationFormField;

export interface WorkflowScfFormFields {
  allowedReprocessStatuses: string[];
  authClientId: string;
  configurationId?: string;
  configurationName: string;
  configurationDisplayName: string;
  configurations: WorkflowConfigurationFormField[];
  desks: number[];
  integrationType: ScfDevelopers.IntegrationType;
  isFidc: string[];
  mainDesk: number;
  partnerId: number;
  partnerName: string;
  requiredOnboardingDocuments: ScfDevelopers.PayerDocumentType[];
  syncConfigurations: WorkflowConfigurationFormField[];
  requestRequiredFields: string[];
}

const hasWhiteSpace = (str: string) => {
  return /\s/g.test(str);
};

const hasHash = (str: string) => {
  return /#/g.test(str);
};

const hasAccent = (str: string) => {
  return /[áéíóúâêîôûãõàç]+/gi.test(str);
};

const validateStringWithoutHash = yup
  .string()
  .required("Campo obrigatório")
  .test(
    "invalidWhitespaceChar",
    "Não deve haver espaço",
    (value) => !hasWhiteSpace(value),
  )
  .test(
    "invalidHashChar",
    "Caractere inválido: '#'",
    (value) => !hasHash(value),
  )
  .test(
    "InvalidAccentChar",
    "Caractere não deve possuir acento",
    (value) => !hasAccent(value),
  );

const baseWorkflowConfigurationValidationSchema: yup.ObjectSchema<BaseWorkflowConfigurationFormField> =
  yup.object({
    stepId: yup.string().required(),
    stepType: yup.mixed<ScfDevelopers.WorkflowStepType>().defined(),
    nextStepId: yup.string().required().nullable(),
    isStepActive: yup.array(yup.string().required()).default([]),
    _t: yup.string().required("Este campo é obrigatório"),
  });

const bCheckComplementCedenteSimplesWorkflowConfigurationValidationSchema: yup.ObjectSchema<BCheckComplementCedenteSimplesWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_SIMPLES_COMPLEMENT_VALIDATE">().required(),
  });

const lessCedenteSimplesWorkflowConfigurationValidationSchema: yup.ObjectSchema<LessCedenteSimplesWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_SIMPLES_LESS">().required(),
    partyGroupTypeIds: yup
      .array()
      .required("Este campo é obrigatório")
      .default([]),
    segment: yup.string().required("Este campo é obrigatório"),
    accountType: yup.string().nullable().defined(),
    mainOfficerCGE: yup
      .number()
      .typeError("Deve ser um Id numérico")
      .required("Este campo é obrigatório"),
    mainOfficerName: yup.string().nullable().defined(),
    skipValidationAlreadyIfExistsPartyWithRelationship: yup
      .array(yup.string().required())
      .default([]),
  });

const syncClerkCedenteSimplesWorkflowConfigurationValidationSchema: yup.ObjectSchema<SyncClerkCedenteSimplesWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_SIMPLES_CLERK">().required(),
  });

const bCheckComplementorCedenteCompletoWorkflowConfigurationValidationSchema: yup.ObjectSchema<BCheckComplementorCedenteCompletoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_COMPLETO_COMPLEMENT_VALIDATE">().required(),
  });

const internalCallbackCedenteCompletoWorkflowConfigurationValidationSchema: yup.ObjectSchema<InternalCallbackCedenteCompletoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_COMPLETO_INTERNAL_CALLBACK">().required(),
    allowedWebhookStatusTypes: yup
      .array()
      .required("Este campo é obrigatório")
      .default([]),
  });

const lessCedenteCompletoWorkflowConfiguration: yup.ObjectSchema<LessCedenteCompletoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_COMPLETO_LESS">().required(),
    partyGroupTypeIds: yup
      .array()
      .required("Este campo é obrigatório")
      .default([]),
    segment: yup.string().required("Este campo é obrigatório"),
    accountType: yup.string().nullable().defined(),
    mainOfficerCGE: yup
      .number()
      .typeError("Deve ser um Id numérico")
      .required("Este campo é obrigatório"),
    mainOfficerName: yup.string().nullable().defined(),
    skipValidationAlreadyIfExistsPartyWithRelationship: yup
      .array(yup.string().required())
      .default([]),
  });

const syncClerkCedenteCompletoWorkflowConfigurationValidationSchema: yup.ObjectSchema<SyncClerkCedenteCompletoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_COMPLETO_CLERK">().required(),
  });

const webhookViaDevelopersCedenteCompletoWorkflowConfigurationValidationSchema: yup.ObjectSchema<WebhookViaDevelopersCedenteCompletoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"CEDENTE_COMPLETO_WEBHOOK_DEVELOPERS">().required(),
    additionalPartnerAuthClientIds: yup
      .array()
      .required("Este campo é obrigatório")
      .default([]),
  });

const bCheckComplementorTomadorDeCreditoWorkflowConfigurationValidationSchema: yup.ObjectSchema<BCheckComplementorTomadorDeCreditoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"TOMADOR_CREDITO_COMPLEMENT_VALIDATE">().required(),
  });

const fraudSysTomadorDeCreditoWorkflowConfigurationValidationSchema: yup.ObjectSchema<FraudSysTomadorDeCreditoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"TOMADOR_CREDITO_RISKANALYSIS">().required(),
    segment: yup.string().required("Este campo é obrigatório"),
    coSegment: yup.string().required("Este campo é obrigatório"),
    riskAnalysisType: yup.string().required("Este campo é obrigatório"),
  });

const lessTomadorDeCreditoWorkflowConfigurationValidationSchema: yup.ObjectSchema<LessTomadorDeCreditoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"TOMADOR_CREDITO_LESS">().required(),
    partyGroupTypeIds: yup
      .array()
      .required("Este campo é obrigatório")
      .default([]),
    segment: yup.string().required("Este campo é obrigatório"),
    accountType: yup.string().nullable().defined(),
    mainOfficerCGE: yup
      .number()
      .typeError("Deve ser um Id numérico")
      .required("Este campo é obrigatório"),
    mainOfficerName: yup.string().nullable().defined(),
    skipValidationAlreadyIfExistsPartyWithRelationship: yup
      .array(yup.string().required())
      .default([]),
  });

const syncClerkTomadorDeCreditoWorkflowConfigurationValidationSchema: yup.ObjectSchema<SyncClerkTomadorDeCreditoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"TOMADOR_CREDITO_CLERK">().required(),
  });

const webhookViaDevelopersTomadorDeCreditoWorkflowConfigurationValidationSchema: yup.ObjectSchema<WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationFormField> =
  baseWorkflowConfigurationValidationSchema.shape({
    stepType: yup.string<"TOMADOR_CREDITO_WEBHOOK_DEVELOPERS">().required(),
  });

const workflowConfigurationSchemaMapper = yup.lazy(
  (value: WorkflowConfigurationFormField) => {
    if (value.stepType === "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE") {
      return bCheckComplementCedenteSimplesWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "CEDENTE_SIMPLES_LESS") {
      return lessCedenteSimplesWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "CEDENTE_SIMPLES_CLERK") {
      return syncClerkCedenteSimplesWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "CEDENTE_COMPLETO_COMPLEMENT_VALIDATE") {
      return bCheckComplementorCedenteCompletoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "CEDENTE_COMPLETO_INTERNAL_CALLBACK") {
      return internalCallbackCedenteCompletoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "CEDENTE_COMPLETO_LESS") {
      return lessCedenteCompletoWorkflowConfiguration;
    }
    if (value.stepType === "CEDENTE_COMPLETO_CLERK") {
      return syncClerkCedenteCompletoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "CEDENTE_COMPLETO_WEBHOOK_DEVELOPERS") {
      return webhookViaDevelopersCedenteCompletoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "TOMADOR_CREDITO_COMPLEMENT_VALIDATE") {
      return bCheckComplementorTomadorDeCreditoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "TOMADOR_CREDITO_RISKANALYSIS") {
      return fraudSysTomadorDeCreditoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "TOMADOR_CREDITO_LESS") {
      return lessTomadorDeCreditoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "TOMADOR_CREDITO_CLERK") {
      return syncClerkTomadorDeCreditoWorkflowConfigurationValidationSchema;
    }
    if (value.stepType === "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS") {
      return webhookViaDevelopersTomadorDeCreditoWorkflowConfigurationValidationSchema;
    }
    return yup.mixed<WorkflowConfigurationFormField>().required();
  },
);

const configurationsWorkflowConfigurationValidationSchema = yup
  .array()
  .of(workflowConfigurationSchemaMapper)
  .default([]);

export const workflowScfValidationSchema: yup.ObjectSchema<WorkflowScfFormFields> =
  yup.object({
    allowedReprocessStatuses: yup.array(yup.string().required()).default([]),
    authClientId: yup.string().required("Campo obrigatório"),
    configurationId: yup.string(),
    configurationName: validateStringWithoutHash,
    configurationDisplayName: yup.string().required("Campo obrigatório"),
    configurations: configurationsWorkflowConfigurationValidationSchema,
    desks: yup
      .array(
        yup.number().typeError("Mesas devem possuir ids numéricos").required(),
      )
      .default([])
      .min(1, "Deve inserir ao menos uma mesa"),
    integrationType: yup.mixed<ScfDevelopers.IntegrationType>().required(),
    isFidc: yup.array(yup.string().required()).default([]),
    mainDesk: yup
      .number()
      .typeError("Mesa deve possuir id numérico")
      .required("Selecione uma mesa")
      .test("choosesADesk", "Deve ser uma das mesas", (value, context) => {
        return value && context.parent.desks?.includes(value);
      }),
    partnerId: yup
      .number()
      .typeError("Digite um valor numérico")
      .required("Campo obrigatório"),
    partnerName: validateStringWithoutHash,
    requiredOnboardingDocuments: yup
      .array(yup.mixed<ScfDevelopers.PayerDocumentType>().required())
      .default([]),
    requestRequiredFields: yup
      .array()
      .transform((value) => (Array.isArray(value) ? value : []))
      .optional()
      .default([]),
    syncConfigurations: configurationsWorkflowConfigurationValidationSchema,
  });

const bCheckComplementCedenteSimplesWorkflowConfigurationDefaultValues: BCheckComplementCedenteSimplesWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "BCheckComplementCedenteSimplesWorkflowConfigurationStep",
  };

const lessCedenteSimplesWorkflowConfigurationDefaultValues: LessCedenteSimplesWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_SIMPLES_LESS",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "LessCedenteSimplesWorkflowConfigurationStep",
    partyGroupTypeIds: [],
    segment: "",
    accountType: "",
    mainOfficerCGE: 0,
    mainOfficerName: "",
    skipValidationAlreadyIfExistsPartyWithRelationship: [],
  };

const syncClerkCedenteSimplesWorkflowConfigurationDefaultValues: SyncClerkCedenteSimplesWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_SIMPLES_CLERK",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "SyncClerkCedenteSimplesWorkflowConfigurationStep",
  };

const bCheckComplementCedenteCompletoWorkflowConfigurationDefaultValues: BCheckComplementorCedenteCompletoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_COMPLETO_COMPLEMENT_VALIDATE",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "BCheckComplementorCedenteCompletoWorkflowConfigurationStep",
  };

const internalCallbackCedenteCompletoWorkflowConfigurationDefaultValues: InternalCallbackCedenteCompletoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_COMPLETO_INTERNAL_CALLBACK",
    nextStepId: null,
    isStepActive: ["true"],
    allowedWebhookStatusTypes: [],
    _t: "InternalCallbackCedenteCompletoWorkflowConfigurationStep",
  };

const lessCedenteCompletoWorkflowConfigurationDefaultValues: LessCedenteCompletoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_COMPLETO_LESS",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "LessCedenteCompletoWorkflowConfigurationStep",
    partyGroupTypeIds: [],
    segment: "",
    accountType: "",
    mainOfficerCGE: 0,
    mainOfficerName: "",
    skipValidationAlreadyIfExistsPartyWithRelationship: [],
  };

const syncClerkCedenteCompletoWorkflowConfigurationDefaultValues: SyncClerkCedenteCompletoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_COMPLETO_CLERK",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "SyncClerkCedenteCompletoWorkflowConfigurationStep",
  };

const webhookViaDevelopersCedenteCompletoWorkflowConfigurationDefaultValues: WebhookViaDevelopersCedenteCompletoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "CEDENTE_COMPLETO_WEBHOOK_DEVELOPERS",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "WebhookViaDevelopersCedenteCompletoWorkflowConfigurationStep",
    additionalPartnerAuthClientIds: [],
  };

const bCheckComplementorTomadorDeCreditoWorkflowConfigurationDefaultValues: BCheckComplementorTomadorDeCreditoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "TOMADOR_CREDITO_COMPLEMENT_VALIDATE",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "BCheckComplementorTomadorDeCreditoWorkflowConfigurationStep",
  };

const fraudSysTomadorDeCreditoWorkflowConfigurationDefaultValues: FraudSysTomadorDeCreditoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "TOMADOR_CREDITO_RISKANALYSIS",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "FraudSysTomadorDeCreditoWorkflowConfigurationStep",
    segment: "",
    coSegment: "",
    riskAnalysisType: "",
  };

const lessTomadorDeCreditoWorkflowConfigurationDefaultValues: LessTomadorDeCreditoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "TOMADOR_CREDITO_LESS",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "LessTomadorDeCreditoWorkflowConfigurationStep",
    partyGroupTypeIds: [],
    segment: "",
    accountType: "",
    mainOfficerCGE: 0,
    mainOfficerName: "",
    skipValidationAlreadyIfExistsPartyWithRelationship: [],
  };

const syncClerkTomadorDeCreditoWorkflowConfigurationDefaultValues: SyncClerkTomadorDeCreditoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "TOMADOR_CREDITO_CLERK",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "SyncClerkTomadorDeCreditoWorkflowConfigurationStep",
  };

const webhookViaDevelopersTomadorDeCreditoWorkflowConfigurationDefaultValues: WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationFormField =
  {
    stepId: "",
    stepType: "TOMADOR_CREDITO_WEBHOOK_DEVELOPERS",
    nextStepId: null,
    isStepActive: ["true"],
    _t: "WebhookViaDevelopersTomadorDeCreditoWorkflowConfigurationStep",
  };

export const workflowConfigurationDefaultValues: Record<
  ScfDevelopers.WorkflowStepType,
  WorkflowConfigurationFormField
> = {
  CEDENTE_SIMPLES_COMPLEMENT_VALIDATE:
    bCheckComplementCedenteSimplesWorkflowConfigurationDefaultValues,
  CEDENTE_SIMPLES_LESS: lessCedenteSimplesWorkflowConfigurationDefaultValues,
  CEDENTE_SIMPLES_CLERK:
    syncClerkCedenteSimplesWorkflowConfigurationDefaultValues,
  CEDENTE_COMPLETO_COMPLEMENT_VALIDATE:
    bCheckComplementCedenteCompletoWorkflowConfigurationDefaultValues,
  CEDENTE_COMPLETO_INTERNAL_CALLBACK:
    internalCallbackCedenteCompletoWorkflowConfigurationDefaultValues,
  CEDENTE_COMPLETO_LESS: lessCedenteCompletoWorkflowConfigurationDefaultValues,
  CEDENTE_COMPLETO_CLERK:
    syncClerkCedenteCompletoWorkflowConfigurationDefaultValues,
  CEDENTE_COMPLETO_WEBHOOK_DEVELOPERS:
    webhookViaDevelopersCedenteCompletoWorkflowConfigurationDefaultValues,
  TOMADOR_CREDITO_COMPLEMENT_VALIDATE:
    bCheckComplementorTomadorDeCreditoWorkflowConfigurationDefaultValues,
  TOMADOR_CREDITO_RISKANALYSIS:
    fraudSysTomadorDeCreditoWorkflowConfigurationDefaultValues,
  TOMADOR_CREDITO_LESS: lessTomadorDeCreditoWorkflowConfigurationDefaultValues,
  TOMADOR_CREDITO_CLERK:
    syncClerkTomadorDeCreditoWorkflowConfigurationDefaultValues,
  TOMADOR_CREDITO_WEBHOOK_DEVELOPERS:
    webhookViaDevelopersTomadorDeCreditoWorkflowConfigurationDefaultValues,
};
