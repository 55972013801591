import { OIcon, OToastManager } from "@maestro/react";
import { useCallback, useMemo, useState } from "react";
import { service } from "services/service";
import { FavoriteEmptyButton } from "./favorite-workflow-button.styles";

interface FavoriteWorkflowButtonProps {
  type: HubOnboardingRelationships.Admin.RelationshipType;
  wasFavorite: boolean;
  workflowUuid: string;
}

export const FavoriteWorkflowButton = ({
  type,
  wasFavorite,
  workflowUuid,
}: FavoriteWorkflowButtonProps) => {
  const [isFavorite, setIsFavorite] = useState(wasFavorite);

  const category = useMemo(() => (isFavorite ? "fas" : "fal"), [isFavorite]);

  const addToFavorites = useCallback(async () => {
    try {
      const payload = {
        type,
        onboardingUuid: workflowUuid,
      };
      await service.onboardingRelationships.addFavoriteWorkflow(payload);
    } catch {
      OToastManager.danger("Ocorreu um erro ao favoritar o workflow");
    }
  }, [type, workflowUuid]);

  const removeFromFavorites = useCallback(async () => {
    try {
      const payload = {
        type,
        onboardingUuid: workflowUuid,
      };
      await service.onboardingRelationships.removeFavoriteWorkflow(payload);
    } catch {
      OToastManager.danger("Ocorreu um erro ao remover workflow dos favoritos");
    }
  }, [type, workflowUuid]);

  return (
    <FavoriteEmptyButton
      onClickCapture={() => {
        if (isFavorite) {
          removeFromFavorites();
        } else {
          addToFavorites();
        }
        setIsFavorite(!isFavorite);
      }}
      className="ms-auto"
    >
      <OIcon category={category} icon="fa-star" type="dark" size="sm" />
    </FavoriteEmptyButton>
  );
};
