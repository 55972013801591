import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { service } from "services";
import { HubPartners } from "services/hubpartners/models/types/admin";
import { AddLinkFormFields, addLinkSchema } from "./add-link.form";

export const useAddLink = () => {
  const [books, setBooks] = useState<HubPartners.Admin.Book[]>([]);
  const [catalogs, setCatalogs] = useState<HubPartners.Admin.Catalog[]>([]);
  const [products, setProducts] = useState<HubPartners.Admin.Product[]>([]);
  const [loading, setLoading] = useState(false);

  const form = useForm<AddLinkFormFields>({
    resolver: yupResolver(addLinkSchema),
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.hubPartners.postLinks(values);

      OToastManager.success("Vínculo adicionado com sucesso.");
    } catch (err) {
      if (isAxiosError<HubPartners.Admin.ErrorResponse>(err)) {
        err.response?.data?.failures?.forEach((failure) => {
          failure.message && OToastManager.danger(failure.message);
        });
      } else {
        OToastManager.danger("Não foi possível cadastrar um vínculo.");
      }
    } finally {
      setLoading(false);
    }
  });

  const fetchBooks = useCallback(async () => {
    try {
      const { data } = await service.hubPartners.getBooks();
      setBooks(data.data);
    } catch {
      OToastManager.danger("Não foi possível obter os possíveis books");
    }
  }, []);

  const fetchCatalogs = useCallback(async () => {
    try {
      const { data } = await service.hubPartners.getCatalogs();
      setCatalogs(data.data);
    } catch {
      OToastManager.danger("Não foi possível obter os possíveis catálogos");
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    try {
      const { data } = await service.hubPartners.getProducts();
      setProducts(data.data);
    } catch {
      OToastManager.danger("Não foi possível obter os possíveis catálogos");
    }
  }, []);

  useEffect(() => {
    fetchBooks();
  }, [fetchBooks]);

  useEffect(() => {
    fetchCatalogs();
  }, [fetchCatalogs]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return {
    books,
    catalogs,
    form,
    loading,
    onSubmit,
    products,
  };
};
