export const PhoneTypesList: Record<ScfDevelopers.PhoneType, string> = {
  PESSOAL: "Pessoal",
  COMERCIAL: "Comercial",
  RESIDENCIAL: "Residencial",
  OUTROS: "Outros",
};

export const EmailTypesList: Record<ScfDevelopers.EmailType, string> = {
  PARTICULAR: "Particular",
  COMERCIAL: "Comercial",
  OUTROS: "Outros",
};

export const filePageTypeMap: Record<ScfDevelopers.FilePageType, string> = {
  COMPLETO: "Completo",
  FRENTE: "Frente",
  VERSO: "Verso",
  MULTI_PAGINAS: "Multi páginas",
};

export const documentTypeMap: Record<ScfDevelopers.PayerDocumentType, string> =
  {
    CONTRATO_SOCIAL: "Contrato social",
    ESTATUTO_SOCIAL: "Estatuto social",
    REQUERIMENTO_EMPRESARIO: "Requerimento empresário",
    PROCURACAO: "Procuração",
    ATA_ELEICAO_DIRETORIA: "Ata eleição diretoria",
    QUADRO_SOCIETARIO: "Quadro societário",
    DOCUMENTO_FINANCEIRO: "Documento financeiro",
    CCMEI: "CCMEI",
    COMPROVANTE_DE_RESIDENCIA: "Comprovante de residência",
    INDEFINIDO: "Indefinido",
    IDENTIFICACAO: "Identificação",
    ATA_ELEICAO: "Ata eleição",
    FICHA_CADASTRAL: "Ficha cadastral",
    DECLARACAO_ITIOF: "Declaração ITIOF",
    DECLARACAO_ITIR: "Declaração ITIR",
    KNOW_YOUR_CUSTOMER: "Know your customer",
    BALANCO_DRE: "Balanço DRE",
    DRE_DEFIS: "DRE DEFIS",
    DECLARACAO_PREVISAO_FATURAMENTO: "Declaração revisão faturamento",
    DECLARACAO_ANUAL_SIMPLES_NACIONAL: "Declaração anual simples nacional",
    CERTIFICADO_CONDICACAO_MEI: "Certificado condicação MEI",
    CERTIDAO_DE_CASAMENTO: "Certidão de casamento",
    BIOMETRIA: "Biometria",
  };

export const documentSubtypeByDocumentMap: Record<
  ScfDevelopers.PayerDocumentType,
  ScfDevelopers.PayerDocumentSubType[] | undefined
> = {
  IDENTIFICACAO: ["CNH", "RG", "RNE", "PASSAPORTE"],
  BIOMETRIA: ["SELFIE"],
  CONTRATO_SOCIAL: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  ESTATUTO_SOCIAL: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  REQUERIMENTO_EMPRESARIO: [
    "INDEFINIDO",
    "CNH",
    "RG",
    "RNE",
    "SELFIE",
    "PASSAPORTE",
  ],
  PROCURACAO: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  ATA_ELEICAO_DIRETORIA: [
    "INDEFINIDO",
    "CNH",
    "RG",
    "RNE",
    "SELFIE",
    "PASSAPORTE",
  ],
  QUADRO_SOCIETARIO: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  DOCUMENTO_FINANCEIRO: [
    "INDEFINIDO",
    "CNH",
    "RG",
    "RNE",
    "SELFIE",
    "PASSAPORTE",
  ],
  CCMEI: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  COMPROVANTE_DE_RESIDENCIA: [
    "INDEFINIDO",
    "CNH",
    "RG",
    "RNE",
    "SELFIE",
    "PASSAPORTE",
  ],
  INDEFINIDO: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  ATA_ELEICAO: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  FICHA_CADASTRAL: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  DECLARACAO_ITIOF: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  DECLARACAO_ITIR: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  KNOW_YOUR_CUSTOMER: [
    "INDEFINIDO",
    "CNH",
    "RG",
    "RNE",
    "SELFIE",
    "PASSAPORTE",
  ],
  BALANCO_DRE: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  DRE_DEFIS: ["INDEFINIDO", "CNH", "RG", "RNE", "SELFIE", "PASSAPORTE"],
  DECLARACAO_PREVISAO_FATURAMENTO: [
    "INDEFINIDO",
    "CNH",
    "RG",
    "RNE",
    "SELFIE",
    "PASSAPORTE",
  ],
  DECLARACAO_ANUAL_SIMPLES_NACIONAL: [
    "INDEFINIDO",
    "CNH",
    "RG",
    "RNE",
    "SELFIE",
    "PASSAPORTE",
  ],
  CERTIFICADO_CONDICACAO_MEI: [
    "INDEFINIDO",
    "CNH",
    "RG",
    "RNE",
    "SELFIE",
    "PASSAPORTE",
  ],
  CERTIDAO_DE_CASAMENTO: [
    "INDEFINIDO",
    "CNH",
    "RG",
    "RNE",
    "SELFIE",
    "PASSAPORTE",
  ],
};

export const DocumentSubtypeMap: Record<
  ScfDevelopers.PayerDocumentSubType,
  string
> = {
  CNH: "CNH",
  RG: "RG",
  RNE: "RNE",
  SELFIE: "Selfie",
  PASSAPORTE: "Passaporte",
  INDEFINIDO: "Indefinido",
};
