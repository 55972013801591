import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ProcessorConfigPropertyProps } from "./property-field.types";
import { fields } from "./property-field.utils";
import { getProcessorPropertyFieldId } from "../utils";

export const PropertyFieldComponent = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(
      getProcessorPropertyFieldId(fieldId),
      processorConfigProperty.value,
    );
  }, [fieldId, processorConfigProperty, setValue]);

  const fieldRender = useMemo(() => {
    const { type, multiselectType } = processorConfigProperty;
    const fieldType = Object.keys(fields).includes(type)
      ? type
      : !multiselectType
      ? "DEFAULT"
      : "MULTI_SELECT";

    return fields[fieldType]({
      processorConfigProperty: { ...processorConfigProperty },
      fieldId,
    });
  }, [fieldId, processorConfigProperty]);

  return fieldRender;
};
