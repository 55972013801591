export enum CardStatus {
  Normal = "NORMAL",
  Blocked = "BLOCKED",
  Canceled = "CANCELED",
  Created = "CREATED",
  Damaged = "DAMAGED",
  Expired = "EXPIRED",
  Fraud = "FRAUD",
  Lost = "LOST",
  Robbed = "ROBBED",
  Requested = "REQUESTED",
  Sleeping = "SLEEPING",
  BlockedInternal = "BLOCKED_INTERNAL",
  BlockedByPaymentDefault = "BLOCKED_BY_PAYMENT_DEFAULT",
  Destroyed = "DESTROYED",
}

export enum CardVariant {
  Black = "BLACK",
  Platinum = "PLATINUM",
  Business = "BUSINESS",
  Gold = "GOLD",
  BusinessDebit = "BUSINESS_DEBIT",
}

export enum CardModality {
  Debit = "DEBIT",
  Credit = "CREDIT",
  Multiple = "MULTIPLE",
}

export enum CardType {
  Physical = "PHYSICAL",
  Plastic = "PLASTIC",
  Virtual = "VIRTUAL",
}

export enum CardBlockReason {
  Robbed = "ROBBED",
  Damaged = "DAMAGED",
  Fraud = "FRAUD",
  Lost = "LOST",
}

export enum AccountProgramModality {
  Debit = "DEBIT",
  Credit = "CREDIT",
  Multiple = "MULTIPLE",
}

export enum AccountStatus {
  Created = "CREATED",
  Requested = "REQUESTED",
}

export enum OwnerGender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum AddressType {
  RESIDENTIAL = "RESIDENTIAL",
  COMMERCIAL = "COMMERCIAL",
  OTHERS = "OTHERS",
}

export enum ProposalStatus {
  Hired = "HIRED",
  Available = "AVAILABLE",
}

export enum OfferStatus {
  Created = "CREATED",
  Hired = "HIRED",
  Expired = "EXPIRED",
  Canceled = "CANCELED",
  PendingSignature = "PENDING_SIGNATURE",
  PendingGuarantorsOnboard = "PENDING_GUARANTORS_ONBOARD",
}

export enum TaxesOrigin {
  Offer = "OFFER",
  Account = "ACCOUNT",
}

export enum TaxesVariantType {
  BUSINESS = 1,
  GOLD = 1,
  PLATINUM = 2,
  BLACK = 4,
}

export enum OfferGuarantorBiometryStatusType {
  Approved = "APROVADO",
  Pending = "PENDENTE",
}

export enum OfferGuarantorPortalStatus {
  EmAnalise = "EM_ANALISE",
  EmAnaliseKyc = "EM_ANALISE_KYC",
  Pendente = "PENDENTE",
  PendenteAjuste = "PENDENTE_AJUSTE",
  PendenteEnvio = "PENDENTE_ENVIO",
  PententeAdmin = "PENDENTE_ADMIN",
  PendenteAssinaturaDocumentos = "PENDENTE_ASSINATURA_DOCUMENTOS",
  PendenteBiometria = "PENDENTE_BIOMETRIA",
  Reprovado = "REPROVADO",
  Concluido = "CONCLUIDO",
  Indefinido = "INDEFINIDO",
  AguardandoNaFilaDeEnvio = "AGUARDANDO_NA_FILA_DE_ENVIO",
}

export enum OfferGuarantorDocumentType {
  ProofOfAddress = "COMPROVANTE_DE_RESIDENCIA",
  WeddingCertificate = "CERTIDAO_DE_CASAMENTO",
}

export enum WalletType {
  Samsung = "SAMSUNG",
  Apple = "APPLE",
  Google = "GOOGLE",
}

export enum WalletProvisionRequestStatus {
  Created = "CREATED",
  Approved = "APPROVED",
  Rejected = "REJECTED",
  Expired = "EXPIRED",
  Deleted = "DELETED",
}

export enum CreditCardPaymentStatus {
  Created = "CREATED",
  Confirmed = "CONFIRMED",
  Failed = "FAILED",
  Reverted = "REVERTED",
  Canceled = "CANCELED",
}

export enum CreditCardPaymentType {
  Pix = "PIX",
  Bankslip = "BANKSLIP",
}

export enum RevertCreditCardPaymentType {
  ClientOrderReturn = "CLIENT_ORDER_RETURN",
  SystemicError = "SYSTEMIC_ERROR",
  FraudCancelling = "FRAUD_CANCELLING",
  NoReturn = "NO_RETURN",
}

export enum RevertCreditCardPaymentChargeBackAmountType {
  Total = "TOTAL",
  Partial = "PARTIAL",
}
