import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getProcessorPropertyFieldId } from "../../../utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";

export const useCreditLineField = ({
  processorConfigProperty: { value },
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { setValue } = useFormContext();
  const {
    value: creditLines,
    callService: getCreditLines,
    loading: loadingCreditLines,
    hasError: hasErrorCreditLines,
  } = useServiceCall(service.hubCreditManager.getCreditLines);

  const processorPropertyFieldId = getProcessorPropertyFieldId(fieldId);

  useEffect(() => {
    hasErrorCreditLines &&
      OToastManager.danger(
        "Não foi possivel buscar as linhas de crédito. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorCreditLines]);

  useEffect(() => {
    if (!creditLines) {
      getCreditLines();
      return;
    }

    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [creditLines, value, getCreditLines, setValue, processorPropertyFieldId]);

  return { creditLines, loadingCreditLines, processorPropertyFieldId };
};
