import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { getProcessorPropertyFieldId } from "../../../utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";

export const useBooleanPropertyField = ({
  processorConfigProperty: { value },
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { setValue, watch } = useFormContext();

  const processorPropertyFieldId = getProcessorPropertyFieldId(fieldId);
  const checkboxFieldId = `checkbox_${fieldId}`;

  const checkboxFieldValue = watch(checkboxFieldId);

  useEffect(() => {
    setValue(checkboxFieldId, value === "true" ? ["true"] : []);
  }, [checkboxFieldId, setValue, value]);

  useEffect(() => {
    setValue(processorPropertyFieldId, checkboxFieldValue);
  }, [checkboxFieldValue, processorPropertyFieldId, setValue]);

  return { processorPropertyFieldId, checkboxFieldValue, checkboxFieldId };
};
