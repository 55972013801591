import { OTypography } from "@maestro/react";
import { SelectSearchMultiple } from "components/form";
import { useMemo } from "react";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useMultiSelectField } from "./multi-select-field.hook";

export const MultiSelectField = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { processorPropertyId, name } = processorConfigProperty;

  const { rejectionReasonsOptions } = useMultiSelectField({
    processorConfigProperty,
    fieldId,
  });

  const genericPropertyField = useMemo(
    () => (
      <div className="d-flex flex-column gap-4 w-100">
        <OTypography size="lg">
          {processorConfigProperty.description}
        </OTypography>
        <div className="d-flex flex-row gap-4">
          <SelectSearchMultiple
            id={`properties.${name}_${processorPropertyId}`}
            name={`properties.${name}_${processorPropertyId}_value`}
            placeholder="Selecione"
            options={rejectionReasonsOptions ?? []}
          />
        </div>
      </div>
    ),
    [
      processorConfigProperty.description,
      name,
      processorPropertyId,
      rejectionReasonsOptions,
    ],
  );

  return <>{genericPropertyField}</>;
};
