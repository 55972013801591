import { OToastManager } from "@maestro/core";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { DataGrid } from "devextreme-react";
import { service } from "services/service";
import { buildCustomerLink } from "utils/build-customer-link";
import { FavoriteWorkflowButton } from "../favorite-workflow-button";
import { redirectWorkflowDetailsMap } from "./favorite-workflows-template.utils";

export const buildDataSource = (
  type: HubOnboardingRelationships.Admin.RelationshipType,
) =>
  dataSourceCustomStoreGenerator<HubOnboardingRelationships.Admin.FavoritedWorkflow>(
    () =>
      service.onboardingRelationships
        .getFavoriteWorkflows({
          type,
        })
        .then(({ data }) => data)
        .catch(() => {
          const errorMessage = "Erro ao obter workflows favoritos";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
    {
      customStoreOptions: {
        update: async (original, updated) => {
          try {
            const payload = {
              onboardingUuid: original.uuid,
              receiveEmail: updated.receiveEmail ?? original.receiveEmail,
              receiveTeams: updated.receiveTeams ?? original.receiveTeams,
              priority: updated.priority ?? original.priority,
            };

            await service.onboardingRelationships.updateFavoriteWorkflow(
              payload,
            );
            OToastManager.success("Workflow atualizado com sucesso");
          } catch {
            OToastManager.danger("Falha ao atualizar workflow.");
          }
        },
      },
    },
  );

export const favoriteWorkflowsDataGrid = (
  gridRef: React.RefObject<DataGrid>,
  type: HubOnboardingRelationships.Admin.RelationshipType,
): ODataGridGeneratorConfig<HubOnboardingRelationships.Admin.FavoritedWorkflow> => ({
  datagrid: {
    noDataText: "Nenhum workflow favoritado.",
    filterRow: {
      visible: true,
    },
    editing: {
      allowUpdating: true,
      mode: "row",
      selectTextOnEditStart: true,
    },
  },
  columns: [
    {
      caption: "Detalhes",
      allowEditing: false,
      cellRender: ({ data }) => (
        <DetailsLink
          href={buildCustomerLink(
            data.taxId,
            redirectWorkflowDetailsMap(type, data.workflowUuid),
          )}
        />
      ),
    },
    {
      caption: "Favorito",
      allowEditing: false,
      cellRender: ({ data }) => (
        <FavoriteWorkflowButton
          gridRef={gridRef}
          type={type}
          workflowUuid={data.workflowUuid}
        />
      ),
    },
    {
      dataField: "taxId",
      caption: "Cpf/Cnpj",
      allowEditing: false,
      cellRender: ({ data }) => masks.cpfCnpj(data.taxId),
    },
    {
      dataField: "workflowUuid",
      caption: "WorkflowUuid",
      allowEditing: false,
    },
    {
      dataField: "messageFlowKey",
      caption: "MessageFlowKey",
      allowEditing: false,
    },
    {
      dataField: "lessWorkflowId",
      caption: "LessWorkflowId",
      allowEditing: false,
    },
    {
      dataField: "status",
      caption: "Status",
      allowEditing: false,
    },
    {
      dataField: "receiveEmail",
      dataType: "boolean",
      caption: "Email",
    },
    {
      dataField: "receiveTeams",
      dataType: "boolean",
      caption: "Teams",
    },
    {
      dataField: "priority",
      dataType: "boolean",
      caption: "Prioridade",
    },
    {
      dataField: "usersTaxId",
      caption: "Usuários",
      allowEditing: false,
      cellRender: ({ data }) => data.usersTaxId.join(", "),
    },
  ],
});
