import { OToastManager } from "@maestro/react";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { service } from "services";
import { HubPartners } from "services/hubpartners/models/types/admin";

export const dataSource =
  dataSourceCustomStoreGenerator<HubPartners.Admin.Link>(() =>
    service.hubPartners
      .getProductLinks()
      .then(({ data }) => data)
      .catch(() => {
        const errorMessage = "Erro ao buscar vínculo";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      }),
  );

export const linkGrid: ODataGridGeneratorConfig<HubPartners.Admin.Link> = {
  datagrid: {
    noDataText: "Nenhum vínculo encontrado.",
    filterRow: {
      visible: true,
    },
  },
  columns: [
    {
      dataField: "product",
      caption: "Produto",
      calculateFilterExpression: (filterValue) => [
        ["product.name", "contains", filterValue],
        "or",
        ["product.code", "contains", filterValue],
      ],
      cellRender: ({ data }) => `${data.product.code} - ${data.product.name}`,
    },
    {
      dataField: "book",
      caption: "Book",
      calculateFilterExpression: (filterValue) => [
        ["book.name", "contains", filterValue],
        "or",
        ["book.code", "contains", filterValue.replace(/[^\w]/g, "")],
      ],
      cellRender: ({ data }) => `${data.book.code} - ${data.book.name}`,
    },
    {
      dataField: "catalog",
      caption: "Catálogo",
      calculateFilterExpression: (filterValue) => [
        ["catalog.name", "contains", filterValue],
        "or",
        ["catalog.code", "contains", filterValue.replace(/[^\w]/g, "")],
      ],
      cellRender: ({ data }) => `${data.catalog.code} - ${data.catalog.name}`,
    },
  ],
};
