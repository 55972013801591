import { OLoader } from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useWorkflowConfigField } from "./workflow-config-field.hook";

export const WorkflowConfigField = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { description } = processorConfigProperty;
  const { chidWorkflowConfigs, loading, processorPropertyFieldId } =
    useWorkflowConfigField({
      processorConfigProperty,
      fieldId,
    });

  const options = useMemo(
    () =>
      chidWorkflowConfigs?.map((config) => {
        return {
          value: config.identification,
          label: config?.name,
        };
      }) ?? [],
    [chidWorkflowConfigs],
  );

  const workflowConfigField = useMemo(() => {
    return (
      <div className="d-flex align-items-center">
        <SelectSearchField
          key={chidWorkflowConfigs
            ?.map((config) => config.identification)
            .join("")}
          id={processorPropertyFieldId}
          name={processorPropertyFieldId}
          placeholder="Selecione"
          label={description}
          options={options}
        />
        {loading && <OLoader />}
      </div>
    );
  }, [
    chidWorkflowConfigs,
    processorPropertyFieldId,
    description,
    options,
    loading,
  ]);

  return <>{workflowConfigField}</>;
};
