import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useConditionPropertyField } from "./condition-property-field.hook";

export const ConditionPropertyField = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { description } = processorConfigProperty;
  const { allConditions, processorPropertyFieldId } = useConditionPropertyField(
    {
      processorConfigProperty,
      fieldId,
    },
  );

  const options = [
    { conditionName: "", description: "Nenhum" },
    ...(allConditions ?? []),
  ]?.map((config) => {
    return {
      value: config.conditionName,
      label: config?.description,
    };
  });

  const formPropertyField = useMemo(() => {
    if (options)
      return (
        <SelectSearchField
          key={allConditions?.map((cond) => cond.conditionName).join("")}
          id={processorPropertyFieldId}
          name={processorPropertyFieldId}
          placeholder="Selecione"
          labelSize="md"
          label={description}
          options={options}
        />
      );
  }, [options, allConditions, processorPropertyFieldId, description]);

  return <>{formPropertyField}</>;
};
