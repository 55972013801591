import dayjs from "dayjs";
import { DetailsFields } from "components/details-card";
import {
  MapperCreditCardPaymentStatus,
  MapperCreditCardPaymentType,
} from "services/bankinghub/models/types/cards";
import { masks } from "@maestro/utils";

export const buildPaymentInfoFields = ({
  payment,
}: {
  payment?: BankingHubCards.CreditCardPayment;
}): DetailsFields => ({
  info: {
    title: "Dados do pagamento",
    items: [
      {
        label: "Data de criação",
        value: dayjs(payment?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Valor total",
        value: masks.currency(payment?.totalAmount, "R$", ","),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Número de parcelas",
        value: payment?.installmentQuantity,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Valor da parcela",
        value: masks.currency(payment?.installmentAmount, "R$", ","),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Tipo",
        value: payment?.type && MapperCreditCardPaymentType[payment.type],
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Status",
        value: payment?.status && MapperCreditCardPaymentStatus[payment.status],
        grid: { md: 4, xl: 3 },
      },
    ],
  },
  rates: {
    title: "Tarifas",
    items: [
      {
        label: "Taxa de juros",
        value: `${payment?.interestRate}% ao mês`,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Juros",
        value: masks.currency(payment?.interestAmount, "R$", ","),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Taxa IOF",
        value: `${payment?.iofRate}% ao mês`,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "IOF",
        value: masks.currency(payment?.iofAmount, "R$", ","),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Total com juros",
        value: masks.currency(payment?.totalAmountWithRates, "R$", ","),
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Taxa de custo efetivo total (CET)",
        value: `${payment?.totalEffectiveCostRate}% ao ano`,
        grid: { md: 4, xl: 3 },
      },
      {
        label: "Custo efetivo total (CET)",
        value: masks.currency(payment?.totalEffectiveCost, "R$", ","),
        grid: { md: 4, xl: 3 },
      },
    ],
  },
});
