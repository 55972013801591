import { useBehavior } from "contexts/behavior";
import { useTranslation } from "react-i18next";
import { tabMenuBehavior } from "./configs/tab-menu-behavior";

export const useMenuTabs = () => {
  const { behavior } = useBehavior();
  const { t } = useTranslation("layout", { keyPrefix: "tabMenu" });

  return tabMenuBehavior(t)[behavior.id];
};
