import { useCustomer } from "contexts/customer";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoginInfo } from "services/clerk/models/types";
import { service } from "services/service";
import { useFindUserFromCustomer } from "../../../hooks";
import { UsuariosClienteByIdentificationRouteParams } from "../../../routes/usuarios.route-params";

export const useUserCustomerDetails = () => {
  const { identification } =
    useParams<UsuariosClienteByIdentificationRouteParams>();
  if (!identification) throw new Error("No identification");

  const [loginInfo, setLoginInfo] = useState<LoginInfo>();

  const {
    customer: { identification: customerIdentification },
  } = useCustomer();

  const user = useFindUserFromCustomer(identification);

  const navigate = useNavigate();

  const getLoginInfo = useCallback(async () => {
    if (!user) {
      return;
    }

    const payload = {
      identification: user.cpf,
    };
    const { data } = await service.clerk.getLoginInfo(payload);
    setLoginInfo(data);
  }, [user]);

  useEffect(() => {
    getLoginInfo();
  }, [getLoginInfo]);

  return {
    customerIdentification,
    identification,
    loginInfo,
    navigate,
    user,
  };
};
