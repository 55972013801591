import { OCol, ORow } from "@maestro/react";
import { PageTitle } from "components/page-title";
import { ChangeLoginEmailCard, ChangeUserEmailCard } from "./_compose";

export const UserEmailManagement = () => {
  return (
    <>
      <PageTitle title="Alteração de e-mail" />
      <ORow>
        <OCol lg={6} xs={12}>
          <ChangeUserEmailCard />
        </OCol>
        <OCol lg={6} xs={12}>
          <ChangeLoginEmailCard />
        </OCol>
      </ORow>
    </>
  );
};
