import { OIcon, OToastManager } from "@maestro/react";
import { DataGrid } from "devextreme-react";
import { useCallback, useMemo, useState } from "react";
import { service } from "services/service";
import { FavoriteEmptyButton } from "./favorite-workflow-button.styles";

interface FavoriteWorkflowButtonProps {
  gridRef: React.RefObject<DataGrid>;
  type: HubOnboardingRelationships.Admin.RelationshipType;
  workflowUuid: string;
}

export const FavoriteWorkflowButton = ({
  gridRef,
  type,
  workflowUuid,
}: FavoriteWorkflowButtonProps) => {
  const [isFavorite, setIsFavorite] = useState(true);

  const category = useMemo(() => (isFavorite ? "fas" : "fal"), [isFavorite]);

  const removeFromFavorites = useCallback(async () => {
    try {
      const payload = {
        type,
        onboardingUuid: workflowUuid,
      };
      await service.onboardingRelationships.removeFavoriteWorkflow(payload);
      setIsFavorite(false);
      gridRef.current?.instance.getDataSource().reload();
    } catch {
      OToastManager.danger("Ocorreu um erro ao remover workflow dos favoritos");
    }
  }, [gridRef, type, workflowUuid]);

  return (
    <FavoriteEmptyButton
      onClickCapture={() => removeFromFavorites()}
      className="ms-auto"
    >
      <OIcon category={category} icon="fa-star" type="dark" size="sm" />
    </FavoriteEmptyButton>
  );
};
