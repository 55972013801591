import { Roles } from "roles/roles.types";

export const workflowRoles = {
  product: {
    tab: {
      role: "admin:WorkflowPageProduct.View",
      description: "Visualização da tab 'Workflow de Leads' na visão produto",
    },
    dashboardPage: {
      role: "admin:WorkflowDashboardPageProduct.View",
      description: "Visualização da página 'Dashboard' na visão produto",
    },
    workflowsPage: {
      role: "admin:WorkflowWorkflowsPageProduct.View",
      description: "Visualização da página 'Workflows' na visão produto",
    },
    workflowsConfigPage: {
      role: "admin:WorkflowWorkflowsConfigPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows' na visão produto",
    },
    workflowsConfigAddPage: {
      role: "admin:WorkflowWorkflowsConfigAddPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Adicionar' na visão produto",
    },
    workflowsConfigDetailsEditPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsEditPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar' na visão produto",
    },
    workflowsConfigDetailsEditMainDataPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsEditMainDataPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Dados principais' na visão produto",
    },
    workflowsConfigDetailsEditConfigurableGridColumnsPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsEditConfigurableGridColumnsPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Colunas configuráveis' na visão produto",
    },
    workflowsConfigDetailsWorkflowConfigHierarchyPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsWorkflowConfigHierarchyPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Hierarquia' na visão produto",
    },
    workflowsConfigDetailsWorkflowConfigViewsPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsWorkflowConfigViewsPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Visualizações' na visão produto",
    },
    workflowsConfigDetailsAddWorkflowConfigViewPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsAddWorkflowConfigViewPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Visualizações - Adicionar' na visão produto",
    },
    workflowsConfigDetailsOffersPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsOffersPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Ofertas' na visão produto",
    },
    workflowsConfigDetailsOffersAddPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsOffersAddPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Ofertas - Adicionar' na visão produto",
    },

    workflowsConfigDetailsOffersEditPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsOffersEditPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Ofertas - Editar' na visão produto",
    },
    workflowsConfigDetailsCanvasPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsCanvasPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Fluxo de configuração' na visão produto",
    },
    workflowsConfigProcessorsPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsCanvasPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Processadores' na visão produto",
    },
    workflowConfigProcessorDetailsBasicDataPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsProcessorBasicDataPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Processadores - Detalhes - Dados Básicos' na visão produto",
    },
    workflowConfigProcessorDetailsMonitoringPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsProcessorMonitoringPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Processadores - Detalhes - Monitoramento' na visão produto",
    },
    workflowConfigProcessorDetailsChannelsPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsProcessorChannelsPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Processadores - Detalhes - Canais' na visão produto",
    },
    workflowConfigProcessorDetailsSettingsPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsProcessorSettingsPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Processadores - Detalhes - Configurações' na visão produto",
    },
    workflowConfigProcessorDetailsUserActionsPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsProcessorUserActionsPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Processadores - Detalhes - Ações Usuário' na visão produto",
    },
    workflowConfigProcessorDetailsPropertiesPage: {
      role: "admin:WorkflowWorkflowsConfigDetailsProcessorPropertiesPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Processadores - Detalhes - Propriedades' na visão produto",
    },
    processorsPage: {
      role: "admin:WorkflowProcessorsPageProduct.View",
      description: "Visualização da página 'Processadores' na visão produto",
    },
    processorsAddPage: {
      role: "admin:WorkflowProcessorsAddPageProduct.View",
      description:
        "Visualização da página 'Processadores - Adicionar' na visão produto",
    },
    processorsEditPage: {
      role: "admin:WorkflowProcessorsEditPageProduct.View",
      description:
        "Visualização da página 'Processadores - Editar' na visão produto",
    },
    conditionsPage: {
      role: "admin:WorkflowConditionsPageProduct.View",
      description: "Visualização da página 'Condições' na visão produto",
    },
    conditionsAddPage: {
      role: "admin:WorkflowConditionsAddPageProduct.View",
      description:
        "Visualização da página 'Condições - Adicionar' na visão produto",
    },
    conditionsDetailsEditPage: {
      role: "admin:WorkflowConditionsDetailsEditPageProduct.View",
      description:
        "Visualização da página 'Condições - Condição - Editar' na visão produto",
    },
    conditionsDetailsTestPage: {
      role: "admin:WorkflowConditionsDetailsTestPageProduct.View",
      description:
        "Visualização da página 'Condições - Condição - Testar' na visão produto",
    },
    leadsDetailsLeadsPage: {
      role: "admin:WorkflowLeadsBatchRequestsDetailsLeadsPageProduct.View",
      description:
        "Visualização da página 'Lotes - Lotes enviados - Leads' na visão produto",
    },
    leadsPage: {
      role: "admin:WorkflowLeadsPageProduct.View",
      description: "Visualização da página 'Leads' na visão produto",
    },
    leadsDetailsSummaryPage: {
      role: "admin:WorkflowLeadsDetailsSummaryPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Detalhes' na visão produto",
    },
    leadsDetailsProposalsPage: {
      role: "admin:WorkflowLeadsDetailsProposalsPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Detalhes - Propostas' na visão produto",
    },
    leadsDetailsProposalsDetailsPage: {
      role: "admin:WorkflowLeadsDetailsProposalsDetailsPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Detalhes - Propostas - Detalhes' na visão produto",
    },
    leadsDetailsCreditLinesPage: {
      role: "admin:WorkflowLeadsDetailsCreditLinesPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Detalhes - Linhas de crédito' na visão produto",
    },
    leadsDetailsExternalIdentificationsPage: {
      role: "admin:WorkflowLeadsDetailsExternalIdentificationsPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Detalhes - Identificações externas' na visão produto",
    },
    leadsDetailsConsumerUnitsPage: {
      role: "admin:WorkflowLeadsDetailsConsumerUnitsPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Unidades consumidoras' na visão produto",
    },
    leadsDetailsConsumerUnitsDetailsPage: {
      role: "admin:WorkflowLeadsDetailsConsumerUnitsDetailsPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Unidades consumidoras - Detalhes' na visão produto",
    },
    leadsDetailsDocumentsPage: {
      role: "admin:WorkflowLeadsDetailsDocumentsPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Documentos' na visão produto",
    },
    leadsDetailsDocumentsPendencyPage: {
      role: "admin:WorkflowLeadsDetailsDocumentsPendencyPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Documentos - Pendenciar' na visão produto",
    },
    leadsDetailsPendenciesPage: {
      role: "admin:WorkflowLeadsDetailsPendenciesPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Pendências' na visão produto",
    },
    leadsDetailsPendenciesAddPage: {
      role: "admin:WorkflowLeadsDetailsPendenciesAddPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Pendências - Adicionar' na visão produto",
    },
    leadsDetailsContextsPage: {
      role: "admin:WorkflowLeadsDetailsContextsPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Contextos' na visão produto",
    },
    leadsDetailsContextsUpdateStatusPage: {
      role: "admin:WorkflowLeadsDetailsContextsUpdateStatusPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Contextos - Atualizar status' na visão produto",
    },
    leadsDetailsContextsAddPage: {
      role: "admin:WorkflowLeadsDetailsContextsAddPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Contextos - Adicionar' na visão produto",
    },
    leadsDetailsUserActionRequestsPage: {
      role: "admin:WorkflowLeadsDetailsUserActionRequestsPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Solicitações' na visão produto",
    },
    leadsDetailsContingenciesPage: {
      role: "admin:WorkflowLeadsDetailsContingenciesPageProduct.View",
      description:
        "Visualização da página 'Leads - Lead - Contingências' na visão produto",
    },
    configurationsPage: {
      role: "admin:WorkflowConfigurationsPageProduct.View",
      description: "Visualização da página 'Configurações' na visão produto",
    },
    configurationsGridsPage: {
      role: "admin:WorkflowConfigurationsGridsPageProduct.View",
      description:
        "Visualização da página 'Configurações - Grids' na visão produto",
    },
    configurationsAddGridPage: {
      role: "admin:WorkflowConfigurationsAddGridPageProduct.View",
      description:
        "Visualização da página 'Configurações - Grids - Adicionar' na visão produto",
    },
    configurationsEditGridPage: {
      role: "admin:WorkflowConfigurationsEditGridPageProduct.View",
      description:
        "Visualização da página 'Configurações - Grids - Editar' na visão produto",
    },
    configurationsBigNumbersPage: {
      role: "admin:WorkflowConfigurationsBigNumbersPageProduct.View",
      description:
        "Visualização da página 'Configurações - Big Numbers' na visão produto",
    },
    configurationsAddBigNumberPage: {
      role: "admin:WorkflowConfigurationsAddBigNumberPageProduct.View",
      description:
        "Visualização da página 'Configurações - Big Numbers - Adicionar' na visão produto",
    },
    configurationsUpdateBigNumberPage: {
      role: "admin:WorkflowConfigurationsUpdateBigNumberPageProduct.View",
      description:
        "Visualização da página 'Configurações - Big Numbers - Editar' na visão produto",
    },
    configurationsConfigurableColumnsPage: {
      role: "admin:WorkflowConfigurationsConfigurableColumnsPageProduct.View",
      description:
        "Visualização da página 'Configurações - Colunas configuráveis' na visão produto",
    },
    configurationsCreateConfigurableColumnsPage: {
      role: "admin:WorkflowConfigurationsCreateConfigurableColumnsPageProduct.View",
      description:
        "Visualização da página 'Configurações - Colunas configuráveis - Adicionar' na visão produto",
    },
    configurationsUpdateConfigurableColumnsPage: {
      role: "admin:WorkflowConfigurationsUpdateConfigurableColumnsPageProduct.View",
      description:
        "Visualização da página 'Configurações - Colunas configuráveis - Editar' na visão produto",
    },
    configurationsDeleteConfigurableColumnsPage: {
      role: "admin:WorkflowConfigurationsDeleteConfigurableColumnsPageProduct.View",
      description:
        "Visualização da página 'Configurações - Colunas configuráveis - Deletar' na visão produto",
    },
    configurationsStatusPage: {
      role: "admin:WorkflowConfigurationsStatusPageProduct.View",
      description:
        "Visualização da página 'Configurações - Status' na visão produto",
    },
    configurationsAddStatusPage: {
      role: "admin:WorkflowConfigurationsAddStatusPageProduct.View",
      description:
        "Visualização da página 'Configurações - Status - Adicionar' na visão produto",
    },
    configurationsUpdateStatusPage: {
      role: "admin:WorkflowConfigurationsUpdateStatusPageProduct.View",
      description:
        "Visualização da página 'Configurações - Status - Editar' na visão produto",
    },
    configurationsMinimumCriteriaPage: {
      role: "admin:WorkflowConfigurationsMinimumCriteriaPageProduct.View",
      description:
        "Visualização da página 'Configurações - Critérios Mínimos' na visão produto",
    },
    configurationsCreateMinimumCriteriaPage: {
      role: "admin:WorkflowConfigurationsCreateMinimumCriteriaPageProduct.View",
      description:
        "Visualização da página 'Configurações - Critérios Mínimos - Adicionar' na visão produto",
    },
    configurationsEditMinimumCriteriaPage: {
      role: "admin:WorkflowsConfigurationEditMinimumCriteriaPageProduct.View",
      description:
        "Visualização da página 'Configurações de Workflows - Configurações de Workflows - Editar - Critérios Mínimos' na visão produto",
    },
    configurationsCommonDomainPage: {
      role: "admin:WorkflowConfigurationsCommonDomainPageProduct.View",
      description:
        "Visualização da página 'Configurações - Common Domain' na visão produto",
    },
    configurationsCreateCommonDomainPage: {
      role: "admin:WorkflowConfigurationsCreateCommonDomainPageProduct.View",
      description:
        "Visualização da página 'Configurações - Common Domain - Adicionar' na visão produto",
    },
    configurationsUpdateCommonDomainPage: {
      role: "admin:WorkflowsConfigurationEditCommonDomainPageProduct.View",
      description:
        "Visualização da página 'Configurações - Common Domain - Editar' na visão produto",
    },
    campaignsPage: {
      role: "admin:WorkflowCampaignsManagementPageProduct.View",
      description:
        "Visualização da página 'Gerenciamento de campanhas' na visão produto",
    },
    editCampaignPage: {
      role: "admin:WorkflowEditCampaignsManagementPageProduct.View",
      description:
        "Visualização da página 'Gerenciamento de campanhas - Editar' na visão produto",
    },
    campaignTemplatesPage: {
      role: "admin:WorkflowCampaignManagementTemplatesPageProduct.View",
      description:
        "Visualização da página 'Gerenciamento de campanhas - Templates de campanha' na visão produto",
    },
    editCampaignTemplatePage: {
      role: "admin:WorkflowCampaignManagementEditTemplatePageProduct.View",
      description:
        "Visualização da página 'Gerenciamento de campanhas - Templates de campanha - Editar' na visão produto",
    },
    createCampaignTemplatePage: {
      role: "admin:WorkflowCampaignManagementCreateTemplatePageProduct.View",
      description:
        "Visualização da página 'Gerenciamento de campanhas - Templates de campanha - Criar' na visão produto",
    },
    originatorScorePage: {
      role: "admin:WorkflowOriginatorScorePageProduct.View",
      description:
        "Visualização da página 'Score de originadores' na visão produto",
    },
    originatorScoreMonitorPage: {
      role: "admin:WorkflowOriginatorScoreMonitorPageProduct.View",
      description:
        "Visualização da página 'Score de originadores - Monitor' na visão produto",
    },
    originatorScoreModulePage: {
      role: "admin:WorkflowOriginatorScoreModulePageProduct.View",
      description:
        "Visualização da página 'Score de originadores - Módulos' na visão produto",
    },
    originatorAddScoreModulePage: {
      role: "admin:WorkflowOriginatorAddScoreModulePageProduct.View",
      description:
        "Visualização da página 'Score de originadores - Adicionar módulo' na visão produto",
    },
    originatorEditScoreModulePage: {
      role: "admin:WorkflowOriginatorEditScoreModulePageProduct.View",
      description:
        "Visualização da página 'Score de originadores - Editar módulo' na visão produto",
    },
    originatorScoreBenefitPage: {
      role: "admin:WorkflowOriginatorScoreBenefitPageProduct.View",
      description:
        "Visualização da página 'Score de originadores - Benefícios' na visão produto",
    },
    originatorAddScoreBenefitPage: {
      role: "admin:WorkflowOriginatorAddScoreBenefitPageProduct.View",
      description:
        "Visualização da página 'Score de originadores - Adicionar benefício' na visão produto",
    },
    originatorEditScoreBenefitPage: {
      role: "admin:WorkflowOriginatorEditScoreBenefitPageProduct.View",
      description:
        "Visualização da página 'Score de originadores - Editar benefício' na visão produto",
    },
    originatorScoreLevelPage: {
      role: "admin:WorkflowOriginatorScoreLevelPageProduct.View",
      description:
        "Visualização da página 'Score de originadores - Níveis' na visão produto",
    },
    resalesPage: {
      role: "admin:WorkflowResalesPageProduct.View",
      description:
        "Visualização da página 'Revendas/Parceiros' na visão produto",
    },
    resalesAddPage: {
      role: "admin:WorkflowResalesAddPageProduct.View",
      description:
        "Visualização da página 'Revendas/Parceiros - Adicionar' na visão produto",
    },
    formsManagerFormsPage: {
      role: "admin:WorkflowFormsManagerFormsPageProduct.View",
      description:
        "Visualização da página 'Gestor de formulários - Formulários' na visão produto",
    },
    formsManagerFormsDetailsPage: {
      role: "admin:WorkflowFormsManagerFormsDetailsPageProduct.View",
      description:
        "Visualização da página 'Gestor de formulários - Formulários - Detalhes' na visão produto",
    },
    formsManagerComponentsPage: {
      role: "admin:WorkflowFormsManagerComponentsPageProduct.View",
      description:
        "Visualização da página 'Gestor de formulários - Componentes' na visão produto",
    },
    formsManagerComponentsAddPage: {
      role: "admin:WorkflowFormsManagerComponentsAddPageProduct.View",
      description:
        "Visualização da página 'Gestor de formulários - Componentes - Adicionar' na visão produto",
    },
    formsManagerComponentsEditPage: {
      role: "admin:WorkflowFormsManagerComponentsEditPageProduct.View",
      description:
        "Visualização da página 'Gestor de formulários - Componentes - Editar' na visão produto",
    },
    batchRequestsPage: {
      role: "admin:WorkflowLeadsBatchRequestsPageProduct.View",
      description:
        "Visualização da página 'Lotes - Lotes enviados' na visão produto",
    },
    batchTemplatePage: {
      role: "admin:WorkflowLeadsBatchTemplatesPageProduct.View",
      description:
        "Visualização da página 'Lotes - Templates' na visão produto",
    },
    batchTemplatesAddPage: {
      role: "admin:WorkflowLeadsBatchTemplatesAddPageProduct.View",
      description:
        "Visualização da página 'Lotes - Templates - Adicionar' na visão produto",
    },
    batchTemplatesDetailsSummaryPage: {
      role: "admin:WorkflowLeadsBatchTemplatesDetailsPageProduct.View",
      description:
        "Visualização da página 'Lotes - Templates - Detalhes' na visão produto",
    },
    batchTemplatesColumnsPage: {
      role: "admin:WorkflowLeadsBatchTemplatesColumnsPageProduct.View",
      description:
        "Visualização da página 'Lotes - Templates - Colunas' na visão produto",
    },
    batchTemplatesColumnsConditionsPage: {
      role: "admin:WorkflowLeadsBatchTemplatesColumnsConditionsPageProduct.View",
      description:
        "Visualização da página 'Lotes - Templates - Colunas - Condições' na visão produto",
    },
    batchTemplatesConfigurationsPage: {
      role: "admin:WorkflowLeadsBatchTemplatesConfigurationsPageProduct.View",
      description:
        "Visualização da página 'Lotes - Templates - Configurações' na visão produto",
    },
    batchTemplatesAddConfigurableColumnPage: {
      role: "admin:WorkflowLeadsBatchTemplatesConfigurableColumnPageProduct.View",
      description:
        "Visualização da página 'Lotes - Templates - Configurações - Adicionar coluna' na visão produto",
    },
    batchRequestsDetailsSummaryPage: {
      role: "admin:WorkflowLeadsBatchRequestsDetailsPageProduct.View",
      description:
        "Visualização da página 'Lotes - Lotes Enviados - Detalhes' na visão produto",
    },
    batchRequestsLeadsPage: {
      role: "admin:WorkflowLeadsBatchRequestsLeadsPageProduct.View",
      description:
        "Visualização da página 'Lotes - Lotes Enviados - Leads' na visão produto",
    },
    batchRequestsContingencyPage: {
      role: "admin:WorkflowLeadsBatchRequestsDetailsContingencyPageProduct.View",
      description:
        "Visualização da página 'Lotes - Lotes enviados - Contingencias' na visão produto",
    },
    batchRequestsContingencyEditPage: {
      role: "admin:WorkflowLeadsBatchRequestsDetailsContingencyEditPageProduct.View",
      description:
        "Visualização da página 'Lotes - Lotes enviados - Contingencias - Editar' na visão produto",
    },
    batchConfigurationsAddColumnPage: {
      role: "admin:BatchConfigurationsAddColumnPageProduct.View",
      description:
        "Visualização da página 'Lotes - Templates - Detalhes - Configuração - Adicionar - Coluna' na visão produto",
    },
    exportRequestsPage: {
      role: "admin:ExportRequestsPageProduct.View",
      description: "Visualização da página 'Exportações' na visão produto",
    },
    legalEntityDenialPage: {
      role: "admin:LegalEntityDenialPage.View",
      description:
        "Visualização da página 'Lista de bloqueios' na visão produto",
    },
    legalEntityDenialAddPage: {
      role: "admin:LegalEntityDenialAddPage.View",
      description:
        "Visualização da página 'Lista de bloqueios - Adicionar' na visão produto",
    },
    legalEntityDenialEditPage: {
      role: "admin:LegalEntityDenialEditPage.View",
      description:
        "Visualização da página 'Lista de bloqueios - Editar' na visão produto",
    },
  },
} satisfies Roles;
