import {
  OCol,
  OLoader,
  OOptionMultiple,
  ORFieldSelectMultiple,
} from "@maestro/react";
import { RefreshGridButton } from "components/refresh-grid-button";
import { usePortfolioIdField } from "pages/recebiveis/components/estrategia-form/fields/portfolio-id-field.hook";
import { useTranslation } from "react-i18next";

export const PortfolioIdsSelect = () => {
  const { t } = useTranslation("receivables", { keyPrefix: "noteNote" });
  const { portfolios, getPortfolios, loading, hasFetchError } =
    usePortfolioIdField();

  return (
    <OCol xs={12} md={4}>
      <ORFieldSelectMultiple
        id="portfolioIds"
        name="portfolioIds"
        dataAction="titulos_nota_nota_novo:select:carteiras"
        dataLabel="carteiras"
        label={t("filter.portfolioIds")}
        disabled={hasFetchError || loading}
        key={portfolios?.length}
      >
        {portfolios?.map((portfolio) => (
          <OOptionMultiple key={portfolio.id} value={portfolio.id.toString()}>
            {portfolio.name}
          </OOptionMultiple>
        ))}
      </ORFieldSelectMultiple>
      {loading && <OLoader size="sm" />}
      {hasFetchError && !loading && (
        <RefreshGridButton
          dataAction="carteiras:botao:tentar_novamente"
          dataLabel="tentar_novamente"
          onClick={() => getPortfolios()}
        />
      )}
    </OCol>
  );
};
