import { OCol } from "@maestro/react";
import { useTranslation } from "react-i18next";
import { InputMultiple } from "../../../../../../../../components/input-multiple/input-multiple.component";

export const TagInputs = () => {
  const { t } = useTranslation("receivables", { keyPrefix: "noteNote" });
  return (
    <>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="receivableIdentification"
          name="receivableIdentification"
          dataAction="filtro_recebiveis:texto:identificacao_do_recebivel"
          dataLabel="identificacao_do_recebivel"
          label={t("filter.receivableIdentification")}
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="receivableId"
          name="receivableId"
          dataAction="filtro_recebiveis:texto:receivable_id"
          dataLabel="receivable_id"
          label={t("filter.receivableId")}
          inputType="number"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="tradeId"
          name="tradeId"
          dataAction="filtro_recebiveis:texto:trade_id"
          dataLabel="trade_id"
          label={t("filter.tradeId")}
          inputType="number"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="borderoId"
          name="borderoId"
          dataAction="filtro_recebiveis:texto:id_do_lote"
          dataLabel="id_do_lote"
          label={t("filter.borderoId")}
          inputType="number"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="operationId"
          name="operationId"
          dataAction="filtro_recebiveis:texto:id_da_operacao"
          dataLabel="id_da_operacao"
          label={t("filter.operationId")}
          inputType="number"
        />
      </OCol>
      <OCol xs={12} md={6} xl={4}>
        <InputMultiple
          id="contractNumbers"
          name="contractNumbers"
          dataAction="filtro_recebiveis:texto:numero_do_contrato"
          dataLabel="numero_do_contrato"
          label={t("filter.contractNumbers")}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <InputMultiple
          id="externalIds"
          name="externalIds"
          dataAction="filtro_recebiveis:texto:identificador_externo"
          dataLabel="identificador_externo"
          label={t("filter.externalIds")}
        />
      </OCol>
      <OCol xs={12} md={6}>
        <InputMultiple
          id="strategyGuids"
          name="strategyGuids"
          dataAction="filtro_recebiveis:texto:guid_estrategia"
          dataLabel="guid_estrategia"
          label={t("filter.strategyGuids")}
        />
      </OCol>
    </>
  );
};
