import { client } from "../client";
import { hubPartnersEndpoints } from "./hubpartners.endpoints";
import {
  GetBooks,
  GetCatalogs,
  GetEconomicGroupOriginatorsResponse,
  GetEconomicGroupsResponse,
  GetOriginatorsByTaxIdDetailsResponse,
  GetOriginatorsMainResponse,
  GetOriginatorsSearchResponse,
  GetOriginatorSubtypeResponse,
  GetPartnershipTypeResponse,
  GetProductLinks,
  GetProductsResponse,
  PostEntitiesGetRelationshipsResponse,
} from "./models";
import {
  GetOriginatorsByTaxIdDetailsParams,
  PostBooksRequest,
  PostCatalogsRequest,
  PostEconomicGroupRequest,
  PostEntitiesGetRelationshipsBody,
  PostOperatorsBody,
  PostOriginatorsBody,
  PostOriginatorsSubtypeRequest,
  PostPartnershipTypeRequest,
  PostProductLinkRequest,
  PostProductsRequest,
  PutBooksRequest,
  PutEconomicGroupRequest,
  PutOriginatorByIdentifierBody,
  PutOriginatorByIdentifierCategorizationBody,
  PutOriginatorByIdentifierIndicatorBody,
  PutPartnershipTypeRequest,
  PutProductLinkRequest,
  PutProductsRequest,
} from "./models/requests";
import { PostDeskRequest } from "./models/requests/post-desk.request";
import { PutCatalogsRequest } from "./models/requests/put-catalogs.request";
import { PutDeskRequest } from "./models/requests/put-desk.request";
import { PutEntityRequestBody } from "./models/requests/put-entity.request";
import { PutOriginatorsSubtypeRequest } from "./models/requests/put-originators-subtype.request";
import { GetDeskResponse } from "./models/responses/get-desk.response";
import { GetReferrersSearchResponse } from "./models/responses/get-referrers-search.response";
import { PostBatchCreateOriginatorsResponse } from "./models/responses/post-batch-create-originators.response";
import { SearchCommonDomainResponse } from "./models/responses/search-common-domain.response";
import { odataService } from "./odata/hubpartners-odata.service";
import { portalService } from "./portal/hubpartners-portal.service";

export const hubPartnersService = {
  odata: odataService,
  portal: portalService,
  getDesks: () =>
    client.get<GetDeskResponse>(hubPartnersEndpoints.admin.desk.endpoint),
  getOriginatorSubtypes: (hideRestricted = false) =>
    client.get<GetOriginatorSubtypeResponse>(
      hubPartnersEndpoints.admin.originatorSubtype.endpoint,
      {
        params: {
          hideRestricted,
        },
      },
    ),
  getPartnershipTypes: () =>
    client.get<GetPartnershipTypeResponse>(
      hubPartnersEndpoints.admin.partnershipType.endpoint,
    ),
  getEconomicGroupOriginators: (originatorIdentification: string) =>
    client.post<GetEconomicGroupOriginatorsResponse>(
      hubPartnersEndpoints.admin.economicGroup.originators
        .byOriginatorIdentification.endpoint,
      {
        originatorIdentification,
      },
    ),
  getProducts: () =>
    client.get<GetProductsResponse>(
      hubPartnersEndpoints.admin.products.endpoint,
    ),
  getProductLinks: () =>
    client.get<GetProductLinks>(
      hubPartnersEndpoints.admin.productLink.endpoint,
    ),
  getBooks: () =>
    client.get<GetBooks>(hubPartnersEndpoints.admin.books.endpoint),
  getCatalogs: () =>
    client.get<GetCatalogs>(hubPartnersEndpoints.admin.catalogs.endpoint),

  getOriginators: () =>
    client.get<GetOriginatorsSearchResponse>(
      hubPartnersEndpoints.admin.originators.search,
    ),

  getReferrersOperatorProfiles: (search: string) =>
    client.post<GetReferrersSearchResponse>(
      hubPartnersEndpoints.admin.referrers.endpoint,
      {
        search,
      },
    ),

  postProducts(body: PostProductsRequest) {
    return client.post(hubPartnersEndpoints.admin.products.endpoint, body);
  },

  postBooks(body: PostBooksRequest) {
    return client.post(hubPartnersEndpoints.admin.books.endpoint, body);
  },
  postCatalogs(body: PostCatalogsRequest) {
    return client.post(hubPartnersEndpoints.admin.catalogs.endpoint, body);
  },
  postLinks(body: PostProductLinkRequest) {
    return client.post(hubPartnersEndpoints.admin.productLink.endpoint, body);
  },
  postPartnershipType(body: PostPartnershipTypeRequest) {
    return client.post(
      hubPartnersEndpoints.admin.partnershipType.endpoint,
      body,
    );
  },
  postOriginatorsSubtype(body: PostOriginatorsSubtypeRequest) {
    return client.post(
      hubPartnersEndpoints.admin.originatorSubtype.endpoint,
      body,
    );
  },
  postDesk(body: PostDeskRequest) {
    return client.post(hubPartnersEndpoints.admin.desk.endpoint, body);
  },
  registerOriginator(body: PostOriginatorsBody) {
    return client.post(hubPartnersEndpoints.admin.originators.endpoint, body);
  },
  batchCreateOriginators(form: FormData) {
    return client.post<PostBatchCreateOriginatorsResponse>(
      hubPartnersEndpoints.admin.originators.batch,
      form,
    );
  },
  putPartnershipType(data: PutPartnershipTypeRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.partnershipType.endpoint}/${uuid}`,
      body,
    );
  },
  putOriginatorsSubtype(data: PutOriginatorsSubtypeRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.originatorSubtype.endpoint}/${uuid}`,
      body,
    );
  },
  putDesk(data: PutDeskRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.desk.endpoint}/${uuid}`,
      body,
    );
  },
  putProducsts(data: PutProductsRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.products.endpoint}/${uuid}`,
      body,
    );
  },
  putBooks(data: PutBooksRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.books.endpoint}/${uuid}`,
      body,
    );
  },
  putCatalogs(data: PutCatalogsRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.catalogs.endpoint}/${uuid}`,
      body,
    );
  },
  putLink(data: PutProductLinkRequest) {
    const { uuid, ...body } = data;
    return client.put(
      `${hubPartnersEndpoints.admin.productLink.endpoint}/${uuid}`,
      body,
    );
  },
  putEntity(data: PutEntityRequestBody) {
    return client.put(hubPartnersEndpoints.admin.entities.endpoint, data);
  },
  getMainOriginators() {
    return client.get<GetOriginatorsMainResponse>(
      hubPartnersEndpoints.admin.originators.main,
    );
  },
  originatorDetails(taxId: GetOriginatorsByTaxIdDetailsParams) {
    return client.post<GetOriginatorsByTaxIdDetailsResponse>(
      hubPartnersEndpoints.admin.originators.details.endpoint,
      {
        identification: taxId,
      },
    );
  },
  updateOriginatorDetails(
    identifier: string,
    body: PutOriginatorByIdentifierBody,
  ) {
    return client.put(
      hubPartnersEndpoints.admin.originators.byIdentifier.endpoint(identifier),
      body,
    );
  },
  updateOriginatorCategorization(
    identifier: string,
    body: PutOriginatorByIdentifierCategorizationBody,
  ) {
    return client.put(
      hubPartnersEndpoints.admin.originators.byIdentifier.categorization(
        identifier,
      ),
      body,
    );
  },
  updateOriginatorIndicator(
    identifier: string,
    body: PutOriginatorByIdentifierIndicatorBody,
  ) {
    return client.put(
      hubPartnersEndpoints.admin.originators.byIdentifier.indicator(identifier),
      body,
    );
  },
  createOperator(body: PostOperatorsBody) {
    return client.post(hubPartnersEndpoints.operatorProfiles.endpoint, body);
  },
  fetchAllEconomicGroups() {
    return client.get<GetEconomicGroupsResponse>(
      hubPartnersEndpoints.admin.economicGroup.endpoint,
    );
  },
  createEconomicGroup(body: PostEconomicGroupRequest) {
    return client.post(hubPartnersEndpoints.admin.economicGroup.endpoint, body);
  },
  updateEconomicGroup(body: PutEconomicGroupRequest) {
    return client.put(hubPartnersEndpoints.admin.economicGroup.endpoint, body);
  },
  searchCommonDomain(search: string) {
    return client.get<SearchCommonDomainResponse>(
      hubPartnersEndpoints.admin.commonDomain.search,
      {
        params: {
          search,
        },
      },
    );
  },
  getActivePartners(body: PostEntitiesGetRelationshipsBody) {
    return client.post<PostEntitiesGetRelationshipsResponse>(
      hubPartnersEndpoints.admin.entities.getRelationships,
      body,
    );
  },
};
