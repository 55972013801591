import { masks } from "@maestro/utils";
import { CopyButton } from "components/copy-button";
import { DetailsFields } from "components/details-card";
import { EntityOnboardingStatusBadge } from "../../../../components/entity-onboarding-status-badge";

export const energyCustomerFields = (
  customer: HubEnergy.RelatedEntityResponse,
): DetailsFields => ({
  customer: {
    items: [
      {
        label: "Nome",
        value: customer.name,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Razão social",
        value: customer.officialName,
        grid: { md: 6, lg: 4 },
      },
      {
        label: customer.taxIdType,
        value: (
          <div className="d-flex align-items-center gap-2">
            {masks.cpfCnpj(customer.taxId)}{" "}
            <CopyButton
              action="detalhes:botao:copiar-identificacao"
              label="copiar-identificacao"
              target={customer.taxId}
              toasterMessage="Identificação copiada."
            />
          </div>
        ),
        grid: { md: 6, lg: 4 },
      },
      {
        label: "CGE",
        value: customer.cge,
        grid: { md: 6, lg: 4 },
      },
      {
        label: "Status do onboarding",
        value: (
          <EntityOnboardingStatusBadge
            isEnergyOnboardingCompleted={customer.isEnergyOnboardingCompleted}
          />
        ),
        grid: { md: 6, lg: 4 },
      },
    ],
  },
});
