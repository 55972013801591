import { OButton, OToastManager } from "@maestro/react";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGenerator,
} from "components/data-grid";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { DataGrid } from "devextreme-react/data-grid";
import { useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { GridTemplate } from "templates/grid-template";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import { contractTemplatesGrid } from "./contract-templates.grid";

export const ContractTemplatesPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();

  const dataGridRef = useRef<DataGrid>(null);

  if (!id) throw new Error("No id");

  const dataSource = useMemo(
    () =>
      dataSourceCustomStoreGenerator(
        () =>
          service.hubCreditManager
            .getContractTemplates(id)
            .then(({ data }) => {
              return data ?? [];
            })
            .catch(() => {
              throw new Error("Erro ao buscar templates de contrato");
            }),
        {
          customStoreOptions: {
            insert: async (data) => {
              try {
                await service.hubCreditManager.createContractTemplate({
                  limitRequestId: +id,
                  contractType: data.contractType,
                  irisAct: data.irisAct,
                  signatureOrigin: data.signatureOrigin,
                  templateKey: data.templateKey,
                  contractSigners: data.contractSigners,
                  contractAdditionalSigners:
                    data.contractAdditionalSigners?.map((ca) => ({
                      ...ca,
                      signerTaxId: ca.signer.taxId,
                      partyTaxId: ca.party.taxId,
                    })) ?? [],
                });

                OToastManager.success(
                  "Template de contrato adicionado com sucesso.",
                );

                return data;
              } catch {
                OToastManager.danger(
                  "Não foi possível salvar template de contrato.",
                );

                throw new Error(
                  "Não foi possível salvar template de contrato.",
                );
              }
            },
            update: async (original, updated) => {
              try {
                const data: typeof updated = { ...original, ...updated };

                await service.hubCreditManager.updateContractTemplate({
                  limitRequestId: +id,
                  id: data.id,
                  contractType: data.contractType,
                  irisAct: data.irisAct,
                  signatureOrigin: data.signatureOrigin,
                  templateKey: data.templateKey,
                  contractSigners: data.contractSigners,
                  contractAdditionalSigners: data.contractAdditionalSigners.map(
                    (ca) => ({
                      ...ca,
                      signerTaxId: ca.signer.taxId,
                      partyTaxId: ca.party.taxId,
                    }),
                  ),
                });

                OToastManager.success(
                  "Template de contrato atualizado com sucesso.",
                );
              } catch {
                OToastManager.danger(
                  "Não foi possível salvar template de contrato.",
                );
              }
            },
            remove: async (data) => {
              try {
                await service.hubCreditManager.deleteContractTemplate(data.id);
                OToastManager.success(
                  "Template de contrato deletada com sucesso.",
                );
              } catch {
                OToastManager.danger("Não foi possível template de contrato.");
              }
            },
          },
        },
      ),
    [id],
  );

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Templates de contrato" />}
      actions={
        <>
          <OButton
            type="tertiary"
            dataAction="emprestimos_getor_limites_templates_de_contrato:botao:adicionar"
            dataLabel="adicionar"
            onClick={() => {
              dataGridRef.current?.instance.addRow();
            }}
            outline
          >
            Adicionar
          </OButton>
          <RefreshGridButton
            onClick={() => {
              !dataSource?.isLoading() && dataSource?.reload();
            }}
          />
        </>
      }
    >
      <ODataGridGenerator
        gridRef={dataGridRef}
        grid={contractTemplatesGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
