import { ORFieldCheckboxGroup, OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useMemo } from "react";
import { GridTemplate } from "templates/grid-template";
import { Actions } from "./_compose";
import {
  ProfileSelectorProvider,
  useProfileSelector,
} from "./profile-selector.context";
import { buildDataSource, roleAssingmentsGrid } from "./profile-selector.grid";

const ProfileSelectorComponent = () => {
  const { gridRef, selected, setProfiles } = useProfileSelector();

  const dataSource = useMemo(() => buildDataSource(setProfiles), [setProfiles]);

  return (
    <div className="mt-2">
      <OTypography size="lg">
        Selecione as funções para este usuário
      </OTypography>
      <OTypography key={JSON.stringify(selected)}>
        Selecionados: {selected || "---"}
      </OTypography>
      <GridTemplate
        actions={<Actions />}
        gridRef={gridRef}
        showClearFiltersButton
        showRefreshButton
      >
        <ORFieldCheckboxGroup name="profiles" id="profiles">
          <ODataGridGenerator
            gridRef={gridRef}
            grid={roleAssingmentsGrid}
            dataSource={dataSource}
          />
        </ORFieldCheckboxGroup>
      </GridTemplate>
    </div>
  );
};

export const ProfileSelector = () => (
  <ProfileSelectorProvider>
    <ProfileSelectorComponent />
  </ProfileSelectorProvider>
);
