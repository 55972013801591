import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface CreditCardPaymentsContext {
  payments: BankingHubCards.CreditCardPayment[];
  fetchPayments: (payments: BankingHubCards.CreditCardPayment[]) => void;
  selectedPayment: BankingHubCards.CreditCardPayment | undefined;
  selectPayment: (id: string) => void;
}

const CreditCardPaymentsContextDefaultData: CreditCardPaymentsContext = {
  payments: [],
  fetchPayments: () => {},
  selectedPayment: undefined,
  selectPayment: () => {},
};

const creditCardPaymentsContext = createContext<CreditCardPaymentsContext>(
  CreditCardPaymentsContextDefaultData,
);

export const CreditCardPaymentsProvider = ({ children }: PropsWithChildren) => {
  const [payments, setPayments] = useState<BankingHubCards.CreditCardPayment[]>(
    [],
  );
  const [selectedPayment, setSelectedPayment] =
    useState<BankingHubCards.CreditCardPayment>();

  const selectPayment = useCallback(
    (id: string) => {
      const paymentFromId = payments.find((payment) => payment.id === id);
      setSelectedPayment(paymentFromId);
    },
    [payments],
  );

  const value = useMemo(
    () => ({
      payments,
      fetchPayments: setPayments,
      selectedPayment,
      selectPayment,
    }),
    [payments, setPayments, selectedPayment, selectPayment],
  );

  return (
    <creditCardPaymentsContext.Provider value={value}>
      {children}
    </creditCardPaymentsContext.Provider>
  );
};

export const useCreditCardPaymentsContext = () =>
  useContext(creditCardPaymentsContext);
