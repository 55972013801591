import { OIcon, ORFieldInput, OTooltip } from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { CardTemplate } from "templates/card-template";
import { FormTemplate } from "templates/form-template";
import { useChangeLoginEmailCard } from "./change-login-email-card.hook";

export const ChangeLoginEmailCard = () => {
  const { form, hasNoLogin, onSubmit, loading } = useChangeLoginEmailCard();

  return (
    <CardTemplate>
      <div
        className={`d-flex justify-content-${
          hasNoLogin ? "between" : "start"
        } align-items-center w-100`}
      >
        <PageTitle
          title="Login"
          description="Atualize apenas o e-mail de login para o usuário"
        />
        {hasNoLogin && (
          <OTooltip maxWidth="300px" position="top-left">
            <span slot="tooltip-content">Usuário não possui login</span>
            <OIcon size="lg" category="orq" icon="orq-info" />
          </OTooltip>
        )}
      </div>
      <FormTemplate
        actions={
          <LoadingButton
            loading={loading}
            onClick={() => onSubmit()}
            disabled={hasNoLogin}
          >
            Salvar
          </LoadingButton>
        }
      >
        <FormProvider {...form}>
          <ORFieldInput
            tag="text"
            id="currentEmail"
            name="currentEmail"
            label="E-mail atual"
            disabled
          />
          <ORFieldInput
            tag="text"
            id="newEmail"
            name="newEmail"
            label="E-mail novo"
            disabled={loading || hasNoLogin}
          />
          <ORFieldInput
            tag="text"
            id="reason"
            name="reason"
            label="Motivo da atualização"
            disabled={loading || hasNoLogin}
          />
        </FormProvider>
      </FormTemplate>
    </CardTemplate>
  );
};
