import { SelectSearchFieldOption } from "components/select-search/field/select-search-field.types";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { service } from "services";

export const useDeskConfigPipelineForm = () => {
  const {
    callService: getPipelineConfigurationList,
    loading: loadingPipelines,
    value: allPipelines,
    hasError: hasErrorPipelines,
  } = useServiceCall(service.onboarding.getPipelineConfigurationList);

  const {
    callService: getDesksOnboardingConfigs,
    loading: loadingDesksConfigs,
    value: desksConfigs,
    hasError: hasErrorDesksConfigs,
  } = useServiceCall(service.onboarding.getDesksOnboardingConfigs);

  const {
    callService: getDeskOnboardingConfigPipelineEnums,
    loading: loadingEnums,
    value: enums,
    hasError: hasErrorEnums,
  } = useServiceCall(service.onboarding.getDeskOnboardingConfigPipelineEnums);

  const pipelines = useMemo(
    () =>
      allPipelines?.map<SelectSearchFieldOption<string>>(
        ({ pipelineName, uuid }) => ({
          label: pipelineName,
          value: uuid,
        }),
      ) ?? [],
    [allPipelines],
  );

  useEffect(() => {
    getPipelineConfigurationList();
  }, [getPipelineConfigurationList]);

  useEffect(() => {
    getDesksOnboardingConfigs();
  }, [getDesksOnboardingConfigs]);

  useEffect(() => {
    getDeskOnboardingConfigPipelineEnums();
  }, [getDeskOnboardingConfigPipelineEnums]);

  return {
    desksConfigs,
    enums,
    loadingPipelines,
    hasErrorDesksConfigs,
    hasErrorEnums,
    hasErrorPipelines,
    getDeskOnboardingConfigPipelineEnums,
    getDesksOnboardingConfigs,
    getPipelineConfigurationList,
    loadingDesksConfigs,
    loadingEnums,
    pipelines,
  };
};
