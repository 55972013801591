import { OToastManager } from "@maestro/core";
import { OTypography } from "@maestro/react";
import { masks } from "@maestro/utils";
import {
  dataSourceCustomStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { HubPartners } from "services/hubpartners/models/types/admin";
import { service } from "services/service";

export const buildDataSource = (identification: string) =>
  dataSourceCustomStoreGenerator<HubPartners.Admin.ActiveRelationship>(() => {
    const payload = {
      identification,
    };
    return service.hubPartners
      .getActivePartners(payload)
      .then(({ data }) => ({
        data: data.data,
      }))
      .catch(() => {
        const errorMessage = "Erro ao buscar parceiros";
        OToastManager.danger(errorMessage);
        throw new Error(errorMessage);
      });
  });

export const companyPartnersGrid: ODataGridGeneratorConfig<HubPartners.Admin.ActiveRelationship> =
  {
    datagrid: {
      noDataText: "Nenhum parceiro encontrado.",
      filterRow: {
        visible: true,
      },
      onDataErrorOccurred: (e) => {
        if (e.error) e.error.message = "Erro ao buscar parceiros.";
      },
    },
    columns: [
      {
        caption: "Vínculo ativo",
        dataType: "boolean",
        dataField: "isActive",
      },
      {
        caption: "Instituição responsável",
        dataField: "mainOriginator",
      },
      {
        caption: "Originador",
        dataField: "originatorName",
        cellRender: ({ data }) => (
          <div className="d-flex flex-column justify-content-center gap-1">
            <OTypography>{data.originatorName}</OTypography>
            <OTypography type="default">
              {masks.cpfCnpj(data.originatorIdentification)}
            </OTypography>
          </div>
        ),
      },
      {
        caption: "Operador",
        dataField: "operatorName",
        cellRender: ({ data }) => (
          <div className="d-flex flex-column justify-content-center gap-1">
            <OTypography>{data.operatorName}</OTypography>
            <OTypography type="default">
              {masks.cpfCnpj(data.operatorIdentification)}
            </OTypography>
          </div>
        ),
      },
      {
        caption: "Conta",
        dataField: "number",
        cellRender: ({ data }) => data.number ?? "-",
      },
      {
        caption: "Agência",
        dataField: "branch",
        cellRender: ({ data }) => data.branch ?? "-",
      },
      {
        caption: "Tipo de parceria",
        dataField: "partnershipTypeName",
      },
      {
        caption: "Tipo de relacionamneto",
        dataField: "subtypes",
        cellRender: ({ data }) =>
          data.subtypes
            .map((subtype) => `${subtype.code} - ${subtype.deskName}`)
            .join(", "),
      },
      {
        caption: "Data de vínculo",
        dataField: "createdDate",
        dataType: "datetime",
      },
      {
        caption: "Data de desativação",
        dataField: "deactivatedDate",
        dataType: "datetime",
      },
    ],
  };
