import { modalManager } from "@maestro/react";
import { masks } from "@maestro/utils";
import { ODataGridGeneratorConfig } from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import dayjs from "dayjs";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { Collaterals } from "../../collaterals.types";
import { RemoveCollateralConfirmationModal } from "../../remove-collateral-confirmation-modal.component";

export const cacGrid = (id: string) =>
  ({
    datagrid: {
      noDataText: "Nenhuma garantia encontrada.",
    },
    columns: [
      {
        dataField: "id",
        caption: "Id",
        alignment: "left",
      },
      {
        dataField: "formalizedContractNumber",
        caption: "Contrato referente",
        customizeText: ({ value }) => value || "Não informado",
      },
      {
        dataField: "amount",
        caption: "Valor",
        alignment: "left",
        format: (value) => masks.currency(value, "R$", "."),
      },
      {
        dataField: "expireDate",
        caption: "Data de vencimento",
        customizeText: ({ value }) =>
          value ? dayjs(value).format("DD/MM/YYYY") : "Não informado",
      },
      {
        dataField: "formalized",
        caption: "Formalizado",
        alignment: "left",
        cellRender: ({ data }) => (data.formalized ? "Sim" : "Não"),
      },
      {
        caption: "Ações",
        cellRender: ({ data }) => (
          <>
            <DataGridAction
              actions={[
                {
                  label: "Editar",
                  icon: { category: "orq", icon: "orq-edit-pencil" },
                  role: roles.emprestimos.product
                    .limitManagerLimitsDetailsCollateralsUpdatePage.role,
                  route:
                    corporateRouter.routes.emprestimos.product.limitManager.limits.details.collaterals.update.path(
                      {
                        collateralId: data.id,
                        id,
                      },
                    ),
                },
                {
                  label: "Remover",
                  icon: { category: "orq", icon: "orq-cancel" },
                  onClick: () =>
                    modalManager.show(`remove-collateral-modal-${data.id}`),
                },
              ]}
            />
            <RemoveCollateralConfirmationModal collateralId={data.id} />
          </>
        ),
      },
    ],
  } satisfies ODataGridGeneratorConfig<Collaterals[number]>);
