import { OToastManager } from "@maestro/core";
import {
  ODataGridGeneratorConfig,
  dataSourceCustomStoreGenerator,
} from "components/data-grid";
import { DataGridAction } from "components/datagrid-action";
import { DetailsLink } from "components/standard-link";
import { StatusBadge } from "components/status-badge";
import { DataGrid } from "devextreme-react";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { getValueFromMap } from "utils/get-value-from-map";
import { FavoriteWorkflowButton } from "../../../../components/favorite-workflow-button";
import { statusMap } from "./letters-of-attorney.utils";

export const buildDataSource = () =>
  dataSourceCustomStoreGenerator<HubOnboardingRelationships.Admin.Loaworkflow>(
    () =>
      service.onboardingRelationships
        .getLoaWorkflows()
        .then(({ data }) => data)
        .catch(() => {
          const errorMessage = "Erro ao buscar as procurações";
          OToastManager.danger(errorMessage);
          throw new Error(errorMessage);
        }),
  );

export const lettersOfAttorneyGrid = (
  gridRef: React.RefObject<DataGrid>,
): ODataGridGeneratorConfig<HubOnboardingRelationships.Admin.Loaworkflow> => ({
  datagrid: {
    noDataText: "Nenhuma procuração",
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: { showPageSizeSelector: true },
  },
  columns: [
    {
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.cadastro.customer.lettersOfAttorney.vintage.embedded.path(
            { uuid: data.uuid },
          )}
        />
      ),
      role: roles.cadastro.customer.lettersOfAttorneyVintageEmbeddedPage.role,
    },
    {
      caption: "Favorito",
      dataField: "isFavorite",
      allowHeaderFiltering: false,
      allowFiltering: false,
      cellRender: ({ data }) => (
        <FavoriteWorkflowButton
          type="LETTER_OF_ATTORNEY"
          wasFavorite={data.isFavorite}
          workflowUuid={data.uuid}
        />
      ),
    },
    {
      dataField: "uuid",
      caption: "UUID",
      allowHeaderFiltering: false,
      role: roles.cadastro.customer.lettersOfAttorneyVintageUuidColumn.role,
    },
    {
      caption: "Documento",
      calculateCellValue: ({ documents }) =>
        documents.find((doc) => doc.type === "PROCURACAO")?.files?.[0]?.name,
      cellRender: ({ displayValue }) =>
        displayValue ?? (
          <span style={{ color: "var(--theme-danger)" }}>Nenhum documento</span>
        ),
      allowFiltering: true,
      allowHeaderFiltering: false,
      allowSorting: true,
    },
    {
      dataField: "createdDate",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Data de criação",
      allowHeaderFiltering: false,
    },
    {
      dataField: "userRequester",
      caption: "Enviado por",
      allowHeaderFiltering: false,
      cellRender: ({ data }) => data.userRequester?.name ?? "",
    },
    {
      dataField: "sendToAnalysis",
      dataType: "datetime",
      format: "shortDateShortTime",
      caption: "Enviado em",
      allowHeaderFiltering: false,
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ({ data }) => {
        const mappedStatus = getValueFromMap(statusMap, data.status);

        return mappedStatus ? (
          <StatusBadge type={mappedStatus.type}>
            {mappedStatus.label}
          </StatusBadge>
        ) : (
          data.status
        );
      },
    },
    {
      dataField: "detailedStatus",
      caption: "Status detalhado",
      role: roles.cadastro.customer.lettersOfAttorneyVintageDetailedStatusColumn
        .role,
      allowHeaderFiltering: false,
    },
    {
      caption: "Ações",
      cellRender: ({ data }) => (
        <DataGridAction
          actions={[
            {
              icon: { category: "orq", icon: "orq-it" },
              label: "Contingências",
              role: roles.cadastro.customer
                .lettersOfAttorneyVintageContingencies.role,
              route:
                corporateRouter.routes.cadastro.customer.lettersOfAttorney.vintage.embedded.contingencies.path(
                  { uuid: data.uuid },
                ),
            },
            {
              icon: { category: "orq", icon: "orq-edit-trash" },
              label: "Excluir",
              onClick: async () => {
                try {
                  const { uuid: representativeUuid } = data;
                  await service.onboardingRelationships.removeWorkflow({
                    uuid: representativeUuid,
                  });
                  OToastManager.success("Procuração removida com sucesso");

                  gridRef.current?.instance.getDataSource().reload();
                } catch {
                  OToastManager.danger("Erro ao remover Procuração");
                }
              },
            },
          ]}
        />
      ),
    },
  ],
});
