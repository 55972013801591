import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { service } from "services";
import { getValidationMessages } from "../../../../utils";

export const useNotifySignatories = () => {
  const {
    callService: sendPendingSignatureNotification,
    loading: loadingNotify,
    error: errorNotify,
  } = useServiceCall(service.hubEnergy.sendPendingSignatureNotification);
  const { tradeId } = useParams();

  const notifiySignatories = useCallback(async () => {
    const { success } = await sendPendingSignatureNotification({
      tradeId: Number(tradeId),
    });

    if (success) {
      OToastManager.success("Notificações enviadas com sucesso");
    } else {
      const errorMessage =
        getValidationMessages(errorNotify)?.[0]?.errorMessage ??
        "Falha ao enviar notificações";

      OToastManager.danger(errorMessage);
    }
  }, [errorNotify, sendPendingSignatureNotification, tradeId]);

  return {
    loadingNotify,
    notifiySignatories,
  };
};
