import { yupResolver } from "@hookform/resolvers/yup";
import { OToastManager } from "@maestro/core";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useServiceCall } from "hooks/service-call";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { FormTemplate } from "templates/form-template";
import {
  DeskPartnerForm,
  deskPartnerFormValidationSchema,
  DeskPartnerFormValues,
} from "../_compose";

export const RegisterDeskPartnerPage = () => {
  const form = useForm<DeskPartnerFormValues>({
    resolver: yupResolver(deskPartnerFormValidationSchema),
  });
  const { handleSubmit } = form;

  const { callService: registerDeskPartner, loading } = useServiceCall(
    service.onboarding.registerDeskOnboardingPartnerConfig,
  );

  const submit = handleSubmit(async (values) => {
    const { success } = await registerDeskPartner(values);

    if (!success) {
      return OToastManager.danger("Erro ao criar configuração com parceiro");
    }

    return OToastManager.success(
      "Configuração com parceiro criada com sucesso",
    );
  });

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Adicionar configuração com parceiro" />}
      actions={
        <LoadingButton loading={loading} onClick={submit}>
          Salvar
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <DeskPartnerForm />
      </FormProvider>
    </FormTemplate>
  );
};
