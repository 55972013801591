import {
  OCheckbox,
  OCol,
  OLabel,
  ORFieldCheckboxGroup,
  ORFieldInput,
} from "@maestro/react";

interface BaseStepFieldsProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
  stepName: ScfDevelopers.WorkflowStepType;
  gridSize?: 4 | 6;
}

export const BaseStepFields = ({
  fieldName,
  index,
  stepName,
  gridSize = 6,
}: BaseStepFieldsProps) => {
  const fieldNameByIndex = `${fieldName}.${index}`;

  return (
    <>
      <OCol xs={gridSize}>
        <ORFieldInput
          tag="text"
          dataAction={`partner_configuration_scf:input:${fieldName}_${index}_step_${stepName}`}
          dataLabel={`${fieldName}_${index}_step_${stepName}`}
          id={`${fieldNameByIndex}.stepType`}
          name={`${fieldNameByIndex}.stepType`}
          label="Tipo de step"
          disabled
        />
      </OCol>
      <OCol xs={gridSize} align="center">
        <ORFieldCheckboxGroup
          dataAction={`partner_configuration_scf:checkbox:${fieldName}_${index}_step_esta_ativo`}
          dataLabel={`${fieldName}_${index}_step_esta_ativo`}
          id={`${fieldNameByIndex}.isStepActive`}
          name={`${fieldNameByIndex}.isStepActive`}
        >
          <div className="d-flex align-items-center gap-2 mb-2">
            <OCheckbox
              size="xs"
              id={`${fieldNameByIndex}.isStepActiveCheckbox`}
              value="true"
            />
            <OLabel
              htmlFor={`${fieldNameByIndex}.isStepActiveCheckbox`}
              id={`${fieldNameByIndex}.isStepActiveLabel`}
            >
              Step está ativo
            </OLabel>
          </div>
        </ORFieldCheckboxGroup>
      </OCol>
    </>
  );
};
