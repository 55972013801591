import { ORFieldInput } from "@maestro/react";
import { PropTypesMap } from "@maestro/react/dist/types/atoms/form/field-input/types";
import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { getValueFromMap } from "utils/get-value-from-map";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useGenericPropertyField } from "./generic-property-field.hook";

export const processorPropertyTypeMap: Record<string, keyof PropTypesMap> = {
  TEXT: "text",
  TEXTAREA: "textarea",
  NUMBER: "number",
  INTEGER: "number",
};

export const GenericPropertyField = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const {
    processorPropertyId,
    name,
    allowedValues,
    description,
    type,
    validateAsList,
  } = processorConfigProperty;

  const { processorPropertyFieldId } = useGenericPropertyField({
    processorConfigProperty,
    fieldId,
  });

  const options = useMemo(() => {
    const opts =
      allowedValues?.map((config) => ({ value: config, label: config })) ?? [];

    opts.unshift({ value: "", label: "NENHUM" });

    return opts;
  }, [allowedValues]);

  const genericPropertyField = useMemo(
    () => (
      <>
        {!allowedValues?.length ? (
          <ORFieldInput
            key={`properties.${name}_${processorPropertyId}`}
            id={processorPropertyFieldId}
            name={processorPropertyFieldId}
            tag={getValueFromMap(processorPropertyTypeMap, type) ?? "text"}
            label={description}
            labelSize="md"
          />
        ) : (
          <SelectSearchField
            key={allowedValues?.map((option) => option).join("")}
            id={processorPropertyFieldId}
            name={processorPropertyFieldId}
            label={description}
            labelSize="md"
            placeholder="Selecione"
            tooltip={
              validateAsList
                ? "Validação por lista (valores separados por vírgula)"
                : ""
            }
            options={options}
            tooltipMaxWidth="100px"
            tooltipPosition="right-top"
          />
        )}
      </>
    ),
    [
      allowedValues,
      name,
      processorPropertyId,
      processorPropertyFieldId,
      type,
      description,
      validateAsList,
      options,
    ],
  );

  return <>{genericPropertyField}</>;
};
