import { OToastManager } from "@maestro/core";
import { useCallback, useState } from "react";
import { service } from "services";
import { DetailedWebhook } from "services/developers/models";

export const useWebhookDetails = () => {
  const [webhook, setWebhook] = useState<DetailedWebhook>();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const getWebhook = useCallback(async (appId: string, tenantId: string, webhookId: string) => {
    try {
      setLoading(true);
      const {
        data: { items },
      } = await service.developers.getWebhookDetails(appId, tenantId, webhookId);
      setWebhook(items[0]);
      setHasError(false);
    } catch (err) {
      OToastManager.danger("Não foi possível buscar o aplicativo");
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    webhook,
    loading,
    hasError,
    getWebhook,
  };
};
