import { OIcon } from "@maestro/react";
import { Panel } from "reactflow";
import { useCanvaSearchBar } from "./canva-search-bar.hook";
import {
  SearchBarDiv,
  StyledBadge,
  StyledDiv,
  StyledIcon,
  StyledInput,
  StyledText,
} from "./canva-search-bar.styles";
import { CanvaSearchBarProps } from "./canva-search-bar.types";

export const CanvaSearchBar = ({ nodes }: CanvaSearchBarProps) => {
  const {
    searchValue,
    filteredNodes,
    matchedNodeIndex,
    selected,
    activeArrows,
    setSelected,
    handleSearchChange,
    setMatchedNodeIndex,
    handleKeyDown,
  } = useCanvaSearchBar({
    nodes,
  });

  return (
    <Panel position="top-right">
      <div className="d-flex flex-row">
        <StyledBadge type="dark" onClick={() => setSelected(!selected)} circle>
          <OIcon category="orq" icon="orq-tools-search" size="lg" />
        </StyledBadge>

        <StyledDiv>
          <SearchBarDiv
            active={selected}
            className="d-flex flex-row justify-content-around align-items-center"
          >
            <StyledInput
              id="nodeSearch"
              name="nodeSearch"
              inputType="text"
              placeholder="Buscar"
              value={searchValue}
              onInput={(event: React.ChangeEvent<HTMLOInputTextElement>) =>
                handleSearchChange(event)
              }
              onKeyDown={(event) => handleKeyDown(event)}
              aspect="outline"
            />
            <StyledText
              type={
                !filteredNodes?.length && searchValue ? "danger-80" : "light"
              }
              key={`${filteredNodes?.length}-${matchedNodeIndex}-${searchValue}`}
            >
              {searchValue
                ? filteredNodes?.length
                  ? `${matchedNodeIndex + 1} de ${filteredNodes?.length}`
                  : "Nenhum resultado"
                : "Nenhum resultado"}
            </StyledText>

            <StyledIcon
              category="orq"
              icon="orq-arrow-up"
              size="lg"
              active={activeArrows}
              type={activeArrows ? "light" : "default"}
              onClick={() =>
                filteredNodes &&
                setMatchedNodeIndex((prevValue) =>
                  matchedNodeIndex + 1 < filteredNodes?.length
                    ? prevValue + 1
                    : 0,
                )
              }
            />

            <StyledIcon
              category="orq"
              icon="orq-arrow-down"
              size="lg"
              type={activeArrows ? "light" : "default"}
              active={activeArrows}
              onClick={() =>
                filteredNodes &&
                setMatchedNodeIndex(
                  (prevValue) =>
                    (matchedNodeIndex ? prevValue : filteredNodes.length) - 1,
                )
              }
            />

            <StyledIcon
              category="orq"
              icon="orq-close"
              size="lg"
              active
              type="light"
              onClick={() => setSelected(false)}
            />
          </SearchBarDiv>
        </StyledDiv>
      </div>
    </Panel>
  );
};
