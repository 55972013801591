import {
  OButton,
  OCard,
  OCardBody,
  ONotification,
  ORFieldUploadInput,
  OTypography,
} from "@maestro/react";
import { PageTitle } from "components/page-title";
import { FormProvider } from "react-hook-form";
import { FormTemplate } from "templates/form-template";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { LoadingButton } from "components/loading-button";
import { Timer } from "../../../../components/timer";
import { useBatchCreateOriginators } from "./batch-create-originators.hook";
import { batchCreateOriginatorsFormFields } from "./batch-create-originators.form";
import { batchCreateOriginatorsODataGrid } from "./batch-create-originators.grid";

export const BatchCreateOriginators = () => {
  const {
    form,
    batchResult,
    handleUpload,
    handleSubmit,
    start,
    handleReset,
    file,
  } = useBatchCreateOriginators();
  const gridRef = useGridRef();

  const grid = useMemo(() => batchCreateOriginatorsODataGrid(), []);

  return (
    <FormTemplate
      pageTitle={
        <PageTitle
          title="Cadastro em Lote"
          description="Faça o envio do arquivo CSV para realizar o cadastro em lote dos originadores deste arquivo."
        />
      }
      actions={
        <LoadingButton
          hidden={!!batchResult}
          disabled={!file}
          loading={start}
          onClick={handleSubmit}
        >
          Enviar arquivo
        </LoadingButton>
      }
    >
      <FormProvider {...form}>
        <div className="d-flex flex-column gap-3">
          <OCard rounded>
            <OCardBody className="d-flex flex-column w-100 justify-content-center align-items-center">
              {!batchResult ? (
                <ORFieldUploadInput
                  {...batchCreateOriginatorsFormFields.originators}
                  size="md"
                  handleAddFile={async (e, resolve) => {
                    handleUpload({ e, resolve });
                  }}
                />
              ) : (
                <OButton size="md" onClick={handleReset}>
                  Novo cadastro em lote
                </OButton>
              )}
            </OCardBody>
          </OCard>

          {!batchResult && <Timer initTime={new Date()} start={start} />}
          {!batchResult && (
            <ONotification type="warning">
              <OTypography>
                O <b>processamento</b> do arquivo{" "}
                <b>pode durar vários minutos</b>
                , não saia desta tela caso desejar visualizar o resultado do
                processamento de cada originador do arquivo. <br />{" "}
                <b>Caso sair</b>, o cadastro dos originadores{" "}
                <b>irá continuar</b>, porém se ocorrer um problema com algum
                originador você <b>não terá acesso</b> ao resultado do
                processamento e ao motivo do problema. <br />
                Sugerimos que abra uma aba específica para o envio e outra para
                continuar utilizado as demais funcionalidades do portal.
              </OTypography>
            </ONotification>
          )}
          {batchResult && (
            <ODataGridGenerator
              dataSource={batchResult}
              gridRef={gridRef}
              grid={grid}
            />
          )}
        </div>
      </FormProvider>
    </FormTemplate>
  );
};
