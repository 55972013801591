import { OLoader } from "@maestro/react";
import { SelectSearchField } from "components/select-search";
import { useMemo } from "react";
import { ProcessorConfigPropertyProps } from "../../property-field.types";
import { useProcessorConfigField } from "./processor-config-field.hook";

export const ProcessorConfigField = ({
  processorConfigProperty,
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { description } = processorConfigProperty;
  const { chidProcessorConfigs, loading, processorPropertyFieldId } =
    useProcessorConfigField({
      processorConfigProperty,
      fieldId,
    });

  const options = useMemo(
    () =>
      chidProcessorConfigs?.map((config) => {
        return {
          value: String(config?.id),
          label: config?.name,
        };
      }) ?? [],
    [chidProcessorConfigs],
  );

  const processorConfigField = useMemo(
    () => (
      <div className="d-flex align-items-center">
        <SelectSearchField
          key={chidProcessorConfigs?.map((config) => config.id).join("")}
          id={processorPropertyFieldId}
          name={processorPropertyFieldId}
          placeholder="Selecione"
          label={description}
          options={options}
        />
        {loading && <OLoader />}
      </div>
    ),
    [
      chidProcessorConfigs,
      processorPropertyFieldId,
      description,
      options,
      loading,
    ],
  );

  return <>{processorConfigField}</>;
};
