import { OToastManager } from "@maestro/core";
import {
  OCheckbox,
  OCol,
  OField,
  OInputText,
  ORFieldCheckboxGroup,
  ORFieldInput,
  ORow,
} from "@maestro/react";
import { SelectSearch } from "components/select-search";
import { SelectSearchOption } from "components/select-search/select-search.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { service } from "services/service";
import { InputMultiple } from "../../../../../../../../../recebiveis/components/input-multiple/input-multiple.component";
import { generateUuidV4 } from "../../../../workflow-configuration-scf.utils";
import { JustifiedLabel } from "../configuration-form.style";
import { BaseStepFields } from "./base-step-fields.component";

interface CedenteSimplesLessStepProps {
  fieldName: "configurations" | "syncConfigurations";
  index: number;
}

export const CedenteSimplesLessStep = ({
  fieldName,
  index,
}: CedenteSimplesLessStepProps) => {
  const [loading, setLoading] = useState(false);
  const [officerOptions, setOfficerOptions] = useState<
    SelectSearchOption<string>[]
  >([]);

  const {
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const fieldNameByIndex = `${fieldName}.${index}`;

  const officerErrors = (errors[`${fieldNameByIndex}.mainOfficerName`] ??
    null) as FieldError | null;

  const officerValue = useMemo(() => {
    const foundOperator = officerOptions?.find(
      (operator) =>
        operator.label === getValues(`${fieldNameByIndex}.mainOfficerName`),
    );
    return foundOperator?.label ?? "";
  }, [fieldNameByIndex, getValues, officerOptions]);

  useEffect(() => {
    setValue(
      `${fieldNameByIndex}._t`,
      "LessCedenteSimplesWorkflowConfigurationStep",
    );
    if (!getValues(`${fieldNameByIndex}.stepId`)) {
      setValue(`${fieldNameByIndex}.stepId`, `less-${generateUuidV4()}`);
    }
    if (!getValues(`${fieldNameByIndex}.nextStepId`)) {
      setValue(`${fieldNameByIndex}.nextStepId`, null);
    }
  }, [fieldNameByIndex, getValues, setValue]);

  const fetchOfficers = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await service.scfDevelopers.fetchOfficers();
      setOfficerOptions(
        data.map<SelectSearchOption<string>>((officer) => ({
          label: officer.name,
          value: officer.cge,
          onSelect: () => {
            setValue(`${fieldNameByIndex}.mainOfficerName`, officer.name);
            setValue(`${fieldNameByIndex}.mainOfficerCGE`, officer.cge);
          },
        })) ?? [],
      );
    } catch {
      OToastManager.danger("Erro ao obter officers");
    } finally {
      setLoading(false);
    }
  }, [fieldNameByIndex, setValue]);

  useEffect(() => {
    fetchOfficers();
  }, [fetchOfficers]);

  return (
    <ORow className="w-100">
      <BaseStepFields
        fieldName={fieldName}
        index={index}
        stepName="CEDENTE_SIMPLES_LESS"
        gridSize={4}
      />
      <OCol xs={4}>
        <ORFieldInput
          tag="text"
          id={`${fieldNameByIndex}.segment`}
          name={`${fieldNameByIndex}.segment`}
          label="Segmento"
        />
      </OCol>
      <OCol xs={4}>
        <ORFieldInput
          tag="text"
          id={`${fieldNameByIndex}.accountType`}
          name={`${fieldNameByIndex}.accountType`}
          label="Co-segmento"
        />
      </OCol>
      <OCol xs={4}>
        <OField
          label="Nome do officer"
          htmlFor={`${fieldNameByIndex}.mainOfficerName`}
          error={!!officerErrors}
          message={officerErrors?.message ?? ""}
        >
          <SelectSearch
            id={`${fieldNameByIndex}.mainOfficerName`}
            name={`${fieldNameByIndex}.mainOfficerName`}
            placeholder="Busque o nome do officer"
            options={officerOptions}
            value={officerValue}
            disabled={loading}
            className="w-100"
            maxOptions={50}
            eager={false}
          />
        </OField>
      </OCol>
      <OCol xs={4}>
        <ORFieldInput
          tag="number"
          id={`${fieldNameByIndex}.mainOfficerCGE`}
          name={`${fieldNameByIndex}.mainOfficerCGE`}
          label="CGE do officer"
          disabled
        />
      </OCol>
      <OCol xs={4}>
        <InputMultiple
          id={`${fieldNameByIndex}.partyGroupTypeIds`}
          name={`${fieldNameByIndex}.partyGroupTypeIds`}
          label="PartyGroupTypeIds"
        />
      </OCol>
      <OCol xs={4} align="center">
        <ORFieldCheckboxGroup
          id={`${fieldNameByIndex}.skipValidationAlreadyIfExistsPartyWithRelationship`}
          name={`${fieldNameByIndex}.skipValidationAlreadyIfExistsPartyWithRelationship`}
        >
          <div className="d-flex align-items-center gap-3 mb-2">
            <OCheckbox
              size="xs"
              id={`${fieldNameByIndex}.skipValidationAlreadyIfExistsPartyWithRelationshipCheckbox`}
              value="true"
            />
            <JustifiedLabel
              htmlFor={`${fieldNameByIndex}.skipValidationAlreadyIfExistsPartyWithRelationshipCheckbox`}
              id={`${fieldNameByIndex}.skipValidationAlreadyIfExistsPartyWithRelationshipLabel`}
            >
              Pular validação caso exista entidade com relacionamento
            </JustifiedLabel>
          </div>
        </ORFieldCheckboxGroup>
      </OCol>
      <OCol xs={4}>
        <OField htmlFor={`${fieldNameByIndex}.lessKey`} label="Chave no LESS">
          <OInputText
            id={`${fieldNameByIndex}.lessKey`}
            name={`${fieldNameByIndex}.lessKey`}
            disabled
            value={`${getValues("partnerName")}#${getValues(
              "configurationName",
            )}`}
          />
        </OField>
      </OCol>
    </ORow>
  );
};
