import {
  OCard,
  OCardBody,
  OCardFooter,
  OCardHeader,
  ORow,
  OToastManager,
} from "@maestro/react";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useCallback, useState } from "react";
import { service } from "services/service";
import { ObjectDetails } from "../object-details";
import { VariableDetails } from "../variable-details";
import {
  hasEmptyAttribute,
  hasMissingAttribute,
} from "./contingency-script.utils";

interface ContingencyScriptProps {
  contingency: HubOnboardingRelationships.Admin.Contingency;
}

export const ContingencyScript = ({ contingency }: ContingencyScriptProps) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] =
    useState<HubOnboardingRelationships.Admin.ContingencyPayload>({});

  const submit = useCallback(async () => {
    const hasMissingField = hasMissingAttribute(
      payload,
      contingency.variableDetails,
    );
    const hasEmptyField = hasEmptyAttribute(payload);
    if (hasMissingField || hasEmptyField) {
      OToastManager.danger("Preencha todos os campos");
      return;
    }

    const payloadToSubmit = { ...payload };

    for (const [key, value] of Object.entries(payloadToSubmit)) {
      payloadToSubmit[key] =
        typeof value === "string"
          ? value
          : JSON.stringify(payloadToSubmit[key]);
    }

    setLoading(true);
    try {
      await service.onboardingRelationships.runContingency({
        identifier: contingency.identifier ?? "",
        data: payloadToSubmit,
      });
    } catch {
      OToastManager.danger("Ocorreu um erro. Tente novamente mais tarde");
    } finally {
      setLoading(false);
    }
  }, [contingency, payload]);

  return (
    <OCard className="my-2">
      <OCardHeader>
        <PageTitle title={contingency.description} />
      </OCardHeader>
      <OCardBody>
        <ORow>
          {contingency.variableDetails.length > 0 &&
            contingency.variableDetails.map((variableDetail) =>
              variableDetail.variables.length > 0 ? (
                <ObjectDetails
                  key={variableDetail.propertyIdentifier}
                  variableDetail={variableDetail}
                  payload={payload}
                  setPayload={setPayload}
                />
              ) : (
                <VariableDetails
                  key={variableDetail.propertyIdentifier}
                  variableDetail={variableDetail}
                  payload={payload}
                  setPayload={setPayload}
                />
              ),
            )}
        </ORow>
      </OCardBody>
      <OCardFooter className="d-flex justify-content-end align-items-center gap-2 mt-4">
        <LoadingButton loading={loading} onClick={submit}>
          Enviar
        </LoadingButton>
      </OCardFooter>
    </OCard>
  );
};
