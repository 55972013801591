import { HubPartners } from "services/hubpartners/models/types/admin";
import * as yup from "yup";

export type EditProductFormFields = HubPartners.Admin.Product;

export const editProductSchema: yup.ObjectSchema<EditProductFormFields> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    description: yup.string().required("Este campo é obrigatório"),
    code: yup.string().required("Este campo é obrigatório"),
    uuid: yup.string().required("Este campo é obrigatório"),
  });
