import {
  OBreadcrumb,
  OBreadcrumbItem,
  OIcon,
  OLoader,
  OTypography,
} from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { Cards } from "./_compose/cards/cards.component";
import { FilterModal } from "./_compose/filter-modal";
import { useBanner } from "./banner-management.hook";
import { StyledHeader } from "./banner-management.styles";

export const BannerManagementPage = () => {
  const { grid, loading, gridRef, data } = useBanner({});

  return (
    <div className="d-flex flex-column gap-4 h-100">
      <div>
        {loading && <OLoader absolute backdrop />}

        <StyledHeader className="w-100">
          <OBreadcrumb>
            <OBreadcrumbItem href="#">
              <span className="d-flex gap-2">
                <OIcon category="fal" icon="fa-home-alt" size="md" />
                Gerenciamento de campanhas
              </span>
            </OBreadcrumbItem>
          </OBreadcrumb>

          <OTypography size="xxl" type="light" className="mt-3">
            Gerenciamento de campanhas
          </OTypography>
          <OTypography type="light-80">
            Crie, edite e exclua campanhas com banners.
          </OTypography>
        </StyledHeader>

        <Cards />
      </div>

      <div className="d-flex flex-row justify-content-between">
        <OTypography type="dark" size="xxl">
          Campanhas
        </OTypography>

        <FilterModal data={data} gridRefInstance={gridRef.current?.instance} />
      </div>

      <ODataGridGenerator gridRef={gridRef} grid={grid} />
    </div>
  );
};
