import { HubPartners } from "services/hubpartners/models/types/admin";
import * as yup from "yup";

export type UpdateOriginatorCategorizationsFormFields = Pick<
  HubPartners.Admin.NewOriginator,
  "partnershipTypeCode" | "originatorSubTypesCodes" | "productsCodes"
> & {
  partnershipTypeName: string;
};

export const updateOriginatorCategorizationsSchema: yup.ObjectSchema<UpdateOriginatorCategorizationsFormFields> =
  yup.object({
    partnershipTypeName: yup.string().required("Este campo é obrigatório"),
    partnershipTypeCode: yup.string().required("Este campo é obrigatório"),
    originatorSubTypesCodes: yup
      .array()
      .of(yup.string().required("Este campo é obrigatório"))
      .default([])
      .min(1, "Selecione ao menos um subtipo."),
    productsCodes: yup
      .array()
      .of(yup.string().required("Este campo é obrigatório"))
      .default([])
      .min(1, "Selecione ao menos um produto."),
  });

export const updateOriginatorCategorizationsDefaultValues: UpdateOriginatorCategorizationsFormFields =
  {
    partnershipTypeName: "",
    partnershipTypeCode: "",
    originatorSubTypesCodes: [],
    productsCodes: [],
  };
