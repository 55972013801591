import { DetailsCard } from "components/details-card";
import { useCreditCardPaymentsContext } from "../../../../../../contexts/cliente/credit-card-payments.context";
import { buildPaymentInfoFields } from "./payment-info.details";

export const PaymentInfo = () => {
  const { selectedPayment } = useCreditCardPaymentsContext();

  return (
    <DetailsCard
      fields={buildPaymentInfoFields}
      value={{ payment: selectedPayment }}
    />
  );
};
