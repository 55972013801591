import { OButton, OCol, OField, OIcon, OOption, OSelect } from "@maestro/react";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { getValueFromMap } from "utils/get-value-from-map";
import {
  workflowConfigurationDefaultValues,
  WorkflowConfigurationFormField,
  WorkflowScfFormFields,
} from "../../../workflow-configuration-scf.form";
import { workflowStepTypeMap } from "../../../workflow-configuration-scf.utils";
import { StyledIndex, StyledRow } from "./configuration-form.style";
import { workflowConfigurationFormMapper } from "./configuration-form.utils";

interface ConfigurationFormProps {
  fieldName: "configurations" | "syncConfigurations";
  title: string;
}

export const ConfigurationsForm = ({
  fieldName,
  title,
}: ConfigurationFormProps) => {
  const [stepType, setStepType] = useState<ScfDevelopers.WorkflowStepType>(
    "CEDENTE_SIMPLES_COMPLEMENT_VALIDATE",
  );

  const form = useFormContext<WorkflowScfFormFields>();
  const { control, watch } = form;

  const watchIntegrationType = watch("integrationType");

  const { append, fields, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  return (
    <OField label={title} htmlFor="extra-fields">
      <div className="d-flex flex-column" id="extra-fields">
        <div className="d-flex flex-row w-50 gap-3 mb-3">
          <OSelect
            value={stepType}
            onInput={(evt) =>
              setStepType(
                (evt.currentTarget as HTMLOSelectElement)
                  .value as ScfDevelopers.WorkflowStepType,
              )
            }
          >
            {watchIntegrationType &&
              Object.entries(workflowStepTypeMap[watchIntegrationType]).map(
                ([value, label]) => (
                  <OOption key={`${fieldName}-${value}`} value={value}>
                    {label}
                  </OOption>
                ),
              )}
          </OSelect>
          <OButton
            onClick={() =>
              append(
                workflowConfigurationDefaultValues[
                  stepType
                ] as WorkflowConfigurationFormField,
              )
            }
          >
            <OIcon category="fa" icon="fa-plus" />
          </OButton>
        </div>
        {fields.map((field, index) => (
          <StyledRow key={field.id}>
            <OCol xs={1} className="h-100">
              <StyledIndex>{index + 1}</StyledIndex>
            </OCol>
            <OCol xs={10} align="center">
              {getValueFromMap(
                workflowConfigurationFormMapper(fieldName, index),
                field.stepType,
              )}
            </OCol>
            <OCol xs={1} align="center">
              <OButton onClick={() => remove(index)}>
                <OIcon category="fa" icon="fa-trash" />
              </OButton>
            </OCol>
          </StyledRow>
        ))}
      </div>
    </OField>
  );
};
