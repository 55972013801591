import { OToastManager } from "@maestro/core";
import { useServiceCall } from "hooks/service-call";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { service } from "services";
import { getProcessorPropertyFieldId } from "../../../utils";
import { ProcessorConfigPropertyProps } from "../../property-field.types";

export const useFormPropertyField = ({
  processorConfigProperty: { value },
  fieldId,
}: ProcessorConfigPropertyProps) => {
  const { setValue } = useFormContext();
  const {
    value: formList,
    callService: getFormList,
    hasError: hasErrorFormList,
  } = useServiceCall(service.hubCreditManager.getFormsList);

  const processorPropertyFieldId = useMemo(
    () => getProcessorPropertyFieldId(fieldId),
    [fieldId],
  );

  useEffect(() => {
    hasErrorFormList &&
      OToastManager.danger(
        "Não foi buscar as opções de formulário. Por favor, tente novamente mais tarde.",
      );
  }, [hasErrorFormList]);

  useEffect(() => {
    if (!formList?.length) {
      getFormList();
      return;
    }

    if (!value) return;
    setValue(processorPropertyFieldId, value);
  }, [
    formList?.length,
    getFormList,
    processorPropertyFieldId,
    setValue,
    value,
  ]);

  return { formList, processorPropertyFieldId };
};
