import { DetailsCard } from "components/details-card";
import { PageTitle } from "components/page-title";
import { useCustomer } from "contexts/customer";
import { DetailsTemplate } from "templates/details-template";
import { ActivePartners, Cards, NotificationArea } from "./_compose";
import { buildCompanyDetailsFields } from "./company-details.details";

export const CompanyDetails = () => {
  const { customer } = useCustomer();

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes da empresa" />}>
      <div className="d-flex flex-column gap-4">
        <NotificationArea />
        <DetailsCard fields={buildCompanyDetailsFields} value={customer} />
        <ActivePartners />
        <Cards />
      </div>
    </DetailsTemplate>
  );
};
