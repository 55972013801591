import { HubPartners } from "services/hubpartners/models/types/admin";
import * as yup from "yup";

export type AddCatalogFormFields = Omit<HubPartners.Admin.Catalog, "uuid">;

export const addCatalogSchema: yup.ObjectSchema<AddCatalogFormFields> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    code: yup.string().required("Este campo é obrigatório"),
  });
