import { HubPartners } from "services/hubpartners/models/types/admin";
import * as yup from "yup";

export const newOriginatorFormSchema: yup.ObjectSchema<
  Omit<HubPartners.Admin.NewOriginator, "shouldCreateOperators"> & {
    shouldCreateOperators: string[];
  }
> = yup.object({
  identificationType: yup.mixed<HubPartners.Admin.IdentificationType>(),
  identification: yup.string().required("Este campo é obrigatório"),
  officialName: yup.string().required("Este campo é obrigatório"),
  tradingName: yup.string(),
  partnershipTypeCode: yup.string().required("Este campo é obrigatório"),
  originatorSubTypesCodes: yup
    .array()
    .of(yup.string().required("Este campo é obrigatório"))
    .default([])
    .min(1, "Selecione ao menos um subtipo."),
  productsCodes: yup
    .array()
    .of(yup.string().required("Este campo é obrigatório"))
    .default([])
    .min(1, "Selecione ao menos um produto."),
  hasIndicator: yup
    .array()
    .of(yup.string().required("Este campo é obrigatório"))
    .default([]),
  shouldCreateOperators: yup
    .array()
    .of(yup.string().required("Este campo é obrigatório"))
    .default([]),
  referrerOperatorProfileIdentifier: yup
    .string()
    .test(
      "requiredWhenIndicated",
      "Selecionar originador que indicou",
      (value, context) =>
        (!!value && context.parent.hasIndicator.includes("check")) ||
        (!value && !context.parent.hasIndicator.includes("check")),
    ),
});
