import { DesksOnboardingPartnerConfigIdentification } from "services/onboarding/models/responses/get-api-admin-desks-onboarding-partner-config.response";
import * as yup from "yup";

export interface DeskPartnerFormValues {
  partnerTypeCode: string;
  deskOnboardingConfigIdentifier: string;
  legalEntityResponsibleInstitutionTaxId: string;
  flow: string;
  entityType: string;
  identifications?: DesksOnboardingPartnerConfigIdentification[];
}

export const identificationsValidationSchema = yup.object({
  queryParameter: yup.string().required("Este campo é obrigatório"),
  value: yup.string().required("Este campo é obrigatório"),
});

export const deskPartnerFormValidationSchema: yup.ObjectSchema<DeskPartnerFormValues> =
  yup.object({
    partnerTypeCode: yup.string().required("Este campo é obrigatório"),
    entityType: yup.string().required("Este campo é obrigatório"),
    deskOnboardingConfigIdentifier: yup
      .string()
      .required("Este campo é obrigatório"),
    legalEntityResponsibleInstitutionTaxId: yup
      .string()
      .required("Este campo é obrigatório"),
    flow: yup.string().required("Este campo é obrigatório"),
    identifications: yup.array().of(identificationsValidationSchema).optional(),
  });
