import {
  OButton,
  OCard,
  OCardBody,
  OCardFooter,
  OCardHeader,
  OCol,
  OLoader,
  ORow,
  OTypography,
} from "@maestro/react";
import { masks } from "@maestro/utils";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { useActivePartners } from "./active-partners.hook";
import { StyledRow } from "./active-partners.style";

export const ActivePartners = () => {
  const { hasRole, loading, navigate, relationships } = useActivePartners();

  if (!hasRole(roles.cadastro.customer.detailsOriginDetailField.role)) {
    return <>null</>;
  }

  return (
    <OCard>
      <OCardHeader divider>
        <OTypography type="default">Parceiros</OTypography>
      </OCardHeader>
      <OCardBody>
        {loading ? (
          <OLoader />
        ) : relationships && relationships.length > 0 ? (
          <div>
            <ORow>
              <OCol xs={3}>
                <OTypography weight="500" size="sm" type="default">
                  Instituição responsável
                </OTypography>
              </OCol>
              <OCol xs={3}>
                <OTypography weight="500" size="sm" type="default">
                  Originador
                </OTypography>
              </OCol>
              <OCol xs={3}>
                <OTypography weight="500" size="sm" type="default">
                  Operador
                </OTypography>
              </OCol>
              <OCol xs={3}>
                <OTypography weight="500" size="sm" type="default">
                  Agência e Conta
                </OTypography>
              </OCol>
            </ORow>
            {relationships &&
              relationships.map((entity) => (
                <StyledRow key={entity.uuid}>
                  <OCol xs={3}>
                    <div className="d-flex align-items-center">
                      <OTypography>{entity.mainOriginator}</OTypography>
                    </div>
                  </OCol>
                  <OCol xs={3}>
                    <div className="d-flex flex-column justify-content-center gap-1">
                      <OTypography>{entity.originatorName}</OTypography>
                      <OTypography type="default">
                        {masks.cpfCnpj(entity.originatorIdentification)}
                      </OTypography>
                    </div>
                  </OCol>
                  <OCol xs={3}>
                    <div className="d-flex flex-column justify-content-center gap-1">
                      <OTypography>{entity.operatorName}</OTypography>
                      <OTypography type="default">
                        {masks.cpfCnpj(entity.operatorIdentification)}
                      </OTypography>
                    </div>
                  </OCol>
                  <OCol xs={3}>
                    <div className="d-flex align-items-center">
                      <OTypography>{`${entity.branch ?? ""} - ${
                        entity.number ?? ""
                      }`}</OTypography>
                    </div>
                  </OCol>
                </StyledRow>
              ))}
          </div>
        ) : (
          <div>
            <OTypography>Não possui relacionamentos ativos</OTypography>
          </div>
        )}
      </OCardBody>
      <OCardFooter className="d-flex justify-content-center">
        <OButton
          onClick={() =>
            navigate(corporateRouter.routes.cadastro.customer.partners.path)
          }
          role={roles.cadastro.customer.partnersPage.role}
        >
          Ver Detalhes
        </OButton>
      </OCardFooter>
    </OCard>
  );
};
