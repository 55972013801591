import { OCol, OLoader, ORFieldInput, ORow } from "@maestro/react";
import { useAddressFields } from "./address-fields.hook";

interface AddressFieldsProps {
  addressKey: string;
  fieldName: "addresses" | `representatives.${number}.addresses`;
  index: number;
}

export const AddressFields = ({
  addressKey,
  fieldName,
  index,
}: AddressFieldsProps) => {
  const { disableFields, hasFoundAddress, loadingAddress } = useAddressFields(
    fieldName,
    index,
  );

  if (loadingAddress) {
    return (
      <div className="d-flex w-100 justify-content-center">
        <OLoader />
      </div>
    );
  }

  return (
    <ORow key={addressKey}>
      <OCol xl={6}>
        <ORFieldInput
          tag="phone"
          id={`${fieldName}.${index}.phoneNumber`}
          name={`${fieldName}.${index}.phoneNumber`}
          label="Telefone"
          disabled={disableFields}
        />
      </OCol>
      <OCol xl={6}>
        <ORFieldInput
          tag="text"
          id={`${fieldName}.${index}.contactName`}
          name={`${fieldName}.${index}.contactName`}
          label="Nome do contato"
          disabled={disableFields}
        />
      </OCol>
      <OCol xl={2}>
        <ORFieldInput
          tag="cep"
          id={`${fieldName}.${index}.zipCode`}
          name={`${fieldName}.${index}.zipCode`}
          label="CEP"
          disabled={disableFields}
        />
      </OCol>
      <OCol xl={4}>
        <ORFieldInput
          tag="text"
          id={`${fieldName}.${index}.street`}
          name={`${fieldName}.${index}.street`}
          label="Endereço"
          disabled={hasFoundAddress || disableFields}
        />
      </OCol>
      <OCol xl={2}>
        <ORFieldInput
          tag="text"
          id={`${fieldName}.${index}.number`}
          name={`${fieldName}.${index}.number`}
          label="Número"
          disabled={disableFields}
        />
      </OCol>
      <OCol xl={4}>
        <ORFieldInput
          tag="text"
          id={`${fieldName}.${index}.complement`}
          name={`${fieldName}.${index}.complement`}
          label="Complemento"
          disabled={disableFields}
        />
      </OCol>
      <OCol xl={3}>
        <ORFieldInput
          tag="text"
          id={`${fieldName}.${index}.neighborhood`}
          name={`${fieldName}.${index}.neighborhood`}
          label="Bairro"
          disabled={hasFoundAddress || disableFields}
        />
      </OCol>
      <OCol xl={3}>
        <ORFieldInput
          tag="text"
          id={`${fieldName}.${index}.cityName`}
          name={`${fieldName}.${index}.cityName`}
          label="Cidade"
          disabled={hasFoundAddress || disableFields}
        />
      </OCol>
      <OCol xl={3}>
        <ORFieldInput
          tag="text"
          id={`${fieldName}.${index}.stateCode`}
          name={`${fieldName}.${index}.stateCode`}
          label="Estado"
          disabled={hasFoundAddress || disableFields}
        />
      </OCol>
      <OCol xl={3}>
        <ORFieldInput
          tag="text"
          id={`${fieldName}.${index}.countryCode`}
          name={`${fieldName}.${index}.countryCode`}
          label="País"
          disabled={hasFoundAddress || disableFields}
        />
      </OCol>
    </ORow>
  );
};
