import {
  dataSourceODataStoreGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { DetailsLink } from "components/standard-link";
import { corporateRouter } from "routes/corporate-router.context";
import { hubEnergyEndpoints } from "services/hubenergy/hubenergy.endpoints";
import { getValueFromMap } from "utils/get-value-from-map";
import { gridStorage } from "utils/storage";
import { MappedStatusBadge } from "../../components/mapped-status-badge";
import { ProposalCell } from "../../components/proposal-cell";
import { approvedAmountTypeMap } from "../../components/trade/trade-fields.utils";
import {
  configureHeaderFilterODataGroupBy,
  disableHeaderFilterPagination,
  odataDateFilterWorkaround,
  tradeProductTypeMap,
} from "../../utils";
import { energyTradeStatusMap } from "../../utils/status-maps/trade.status";

export const buildDataSource = (
  filterValue?: string,
  paramsCallback?: (params: Record<string, string | undefined>) => void,
) =>
  dataSourceODataStoreGenerator<HubEnergy.TradeODataResponse>(
    hubEnergyEndpoints.odata.trades,
    {
      odataStoreOptions: {
        beforeSend: ({ params }) => {
          const filters: string[] = [];

          !!filterValue && filters.push(`customerTaxId eq '${filterValue}'`);

          if (params.$filter) filters.push(params.$filter);

          if (filters.length)
            // eslint-disable-next-line no-param-reassign
            params.$filter = filters.map((f) => `(${f})`).join(" and ");

          odataDateFilterWorkaround(params);
          paramsCallback?.(params);
        },
      },
    },
  );

export const energyTradesGrid = (
  filterValue?: string,
): ODataGridGeneratorConfig<HubEnergy.TradeODataResponse> => ({
  datagrid: {
    noDataText: "Nenhuma proposta encontrada.",
    headerFilter: { visible: true },
    filterRow: { visible: true },
    pager: { showPageSizeSelector: true },
    stateStoring: gridStorage("customerTradesGrid"),
    columnAutoWidth: false,
    syncLookupFilterValues: false,
  },
  columns: [
    {
      dataField: "id",
      dataType: "number",
      cellRender: ({ data }) => (
        <DetailsLink
          href={corporateRouter.routes.energia.customer.trades.byId.details.path(
            {
              tradeId: data.id,
            },
          )}
        />
      ),
      allowFiltering: true,
      allowHeaderFiltering: false,
      allowSorting: true,

      alignment: "left",
      allowExporting: false,
      width: "auto",
    },
    {
      dataType: "string",
      caption: "Proposta",
      cellRender: ({ data }) => (
        <ProposalCell
          approvedAmount={data.approvedAmount}
          approvedAmountType={data.approvedAmountType}
        />
      ),
      calculateCellValue: (data) =>
        `${data.approvedAmountType} - ${data.approvedAmount}`,
      headerFilter:
        configureHeaderFilterODataGroupBy<HubEnergy.TradeODataResponse>()(
          ["approvedAmountType"] as const,
          (adjustParams) => buildDataSource(filterValue, adjustParams),
          (approvedAmountType) =>
            getValueFromMap(approvedAmountTypeMap, approvedAmountType) ??
            approvedAmountType,
        ),
      allowFiltering: true,
      allowSorting: true,
      allowExporting: false,
    },
    {
      dataField: "productType",
      caption: "Produto",
      dataType: "string",
      alignment: "center",
      headerFilter:
        configureHeaderFilterODataGroupBy<HubEnergy.TradeODataResponse>()(
          ["productType"] as const,
          (adjustParams) => buildDataSource(filterValue, adjustParams),
          (productType) =>
            getValueFromMap(tradeProductTypeMap, productType) ?? productType,
        ),
      lookup: {
        dataSource: Object.entries(tradeProductTypeMap).map(
          ([key, value]: [string, string]) => ({
            text: value,
            value: key,
          }),
        ),
        displayExpr: "text",
        valueExpr: "value",
      },
    },
    {
      dataField: "status",
      caption: "Status",
      dataType: "string",
      alignment: "center",
      cellRender: ({ data }) => (
        <MappedStatusBadge map={energyTradeStatusMap} status={data.status} />
      ),
      headerFilter:
        configureHeaderFilterODataGroupBy<HubEnergy.TradeODataResponse>()(
          ["status"] as const,
          (adjustParams) => buildDataSource(filterValue, adjustParams),
          (status) =>
            getValueFromMap(energyTradeStatusMap, status)?.text ?? status,
        ),
      lookup: {
        dataSource: Object.entries(energyTradeStatusMap).map((item) => ({
          text: item[1].text,
          value: item[0],
        })),
        displayExpr: "text",
        valueExpr: "value",
      },
      filterType: "exclude",
      defaultFilterValues: ["EXPIRADO", "REJEITADO"],
    },
    {
      dataField: "proposalDate",
      dataType: "date",
      format: "shortDateShortTime",
      caption: "Data da proposta",
      headerFilter: { dataSource: disableHeaderFilterPagination },
    },
    {
      dataField: "supplyStartDate",
      dataType: "date",
      caption: "Início do contrato",
      headerFilter: { dataSource: disableHeaderFilterPagination },
    },
    {
      dataField: "supplyEndDate",
      dataType: "date",
      caption: "Fim do contrato",
      headerFilter: { dataSource: disableHeaderFilterPagination },
    },
  ],
});
