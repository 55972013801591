import { InternationalProfile } from "services/onboarding-relationships/models";

export const mapProfileNames = (
  names: string[] | undefined,
  profiles: InternationalProfile[] | undefined,
) =>
  names
    ?.map(
      (name) => profiles?.find((profile) => profile.name === name)?.nameLabel,
    )
    ?.join(", ");
