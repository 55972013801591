import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { AvailableDetail } from "services/onboarding-relationships/models";

const grid: ODataGridGeneratorConfig<AvailableDetail> = {
  datagrid: {
    filterRow: { visible: true },
    headerFilter: { visible: true },
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [20, 50, 100],
    },
  },
  columns: [
    {
      dataField: "groupName",
      caption: "Categoria",
      sortIndex: 0,
    },
    {
      dataField: "description",
      caption: "Descrição",
      allowHeaderFiltering: false,
      cellRender: ({ data }) => (
        <span style={{ whiteSpace: "pre-wrap" }}>{data.description}</span>
      ),
    },
  ],
  paging: {
    pageSize: 20,
  },
};

interface AccessDetailsGridProps {
  accessDetails: AvailableDetail[];
}

export const AccessDetailsGrid = ({
  accessDetails,
}: AccessDetailsGridProps) => {
  return <ODataGridGenerator grid={grid} dataSource={accessDetails} />;
};
