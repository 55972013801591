/* eslint-disable import-rules/imports-in-modules */
import { DetailsTemplate } from "templates/details-template";
import { PageTitle } from "components/page-title";
import { DetailsCard } from "components/details-card";
import { useParams } from "react-router-dom";
import { AppIdentificationRouteParams, WebhookRouteParams } from "pages/developers/routes/developers.route-params";
import { useCustomer } from "contexts/customer";
import { useEffect } from "react";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { useWebhookDetails } from "./webhooks-detalhes.hook";
import { buildWebhookDetailsFields } from "./webhooks-detalhes.details";

export const WebhookDetails = () => {
  const { appId } = useParams<AppIdentificationRouteParams>();
  const { webhookId } = useParams<WebhookRouteParams>();

  if (!appId || !webhookId) throw new Error("No identification");
  
  const {
    customer: { identification },
  } = useCustomer();
  const { getWebhook, webhook, loading, hasError } = useWebhookDetails();

  useEffect(() => {
    getWebhook(appId, identification, webhookId);
  }, [appId, identification, getWebhook]);

  return (
    <DetailsTemplate pageTitle={<PageTitle title="Detalhes do webhook" />}>
      <DetailsCard
        loading={loading}
        hasError={hasError}
        errorComponent={
          <ErrorComponent
            messageTitle="Erro ao buscar os detalhes do webhook"
            messageParagraph="Clique no botão para tentar novamente."
          >
            <TryAgainButton onClick={() => getWebhook(appId, identification, webhookId)} />
          </ErrorComponent>
        }
        fields={buildWebhookDetailsFields}
        value={webhook}
      />
    </DetailsTemplate>
  );
};
