import { ButtonWithConfirmation } from "components/button-with-confirmation";
import { useNotifySignatories } from "./notify-signatories.hook";

export const NotifySignatories = () => {
  const { loadingNotify, notifiySignatories } = useNotifySignatories();

  return (
    <ButtonWithConfirmation
      confirmationModal={{
        title: "Deseja notificar os assinantes de contratos desse trade?",
        loading: loadingNotify,
      }}
      disabled={loadingNotify}
      onConfirm={notifiySignatories}
    >
      Notificar assinantes
    </ButtonWithConfirmation>
  );
};
