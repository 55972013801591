import dayjs from "dayjs";

export const cedentCompletoFilterModalId = "cedente-completo-filter-modal-id";

export const formatDate = (date: string | undefined) => {
  if (!date) {
    return undefined;
  }

  const splitDate = date.split("/");
  const reversedDate = splitDate.reverse().join("/");
  return dayjs(reversedDate).toISOString();
};
