import { OButton, OTypography, modalManager } from "@maestro/react";
import {
  ODataGridGenerator,
  ODataGridGeneratorConfig,
} from "components/data-grid";
import { ErrorComponent, TryAgainButton } from "components/empty-state";
import { PageTitle } from "components/page-title";
import { RefreshGridButton } from "components/refresh-grid-button";
import { RouterButton } from "components/router-button";
import { useServiceCall } from "hooks/service-call";
import React, { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { roles } from "roles/roles";
import { corporateRouter } from "routes/corporate-router.context";
import { service } from "services";
import { ContentTemplate } from "templates/content-template";
import { GridTemplate } from "templates/grid-template";
import { LimitCollateral } from "services/hubcreditmanager/models/types/limit/limit.types";
import { EmprestimosProdutoGestorLimitesById } from "../../../../../../routes/emprestimos.route-params";
import {
  airplaneGrid,
  cacGrid,
  cardsGrid,
  equipmentGrid,
  guaranteeFtsLimitsGrid,
  imovelGrid,
  insuranceGrid,
  promissoryNoteGrid,
  sacasGrid,
  solarGrid,
} from "./_compose/grids";
import { RemoveCollateralConfirmationModal } from "./remove-collateral-confirmation-modal.component";

export const CollateralsPage = () => {
  const { id } = useParams<EmprestimosProdutoGestorLimitesById>();
  if (!id) throw new Error("No id parameter");

  const {
    callService: getCollaterals,
    value: collaterals,
    hasError: hasErrorCollaterals,
    loading: loadingCollaterals,
  } = useServiceCall(service.hubCreditManager.getCollaterals);

  useEffect(() => {
    getCollaterals(id);
  }, [getCollaterals, id]);

  const collateralTypes = useMemo(
    () => [
      {
        type: "CDB",
        label: "Garantias CDB",
        grid: guaranteeFtsLimitsGrid(+id),
      },
      { type: "EQUIPAMENTO", label: "Equipamentos", grid: equipmentGrid(id) },
      { type: "AERONAVE", label: "Aeronaves", grid: airplaneGrid(id) },
      { type: "PAINEIS_SOLARES", label: "Painéis Solares", grid: solarGrid },
      { type: "CAC", label: "CAC", grid: cacGrid(id) },
      {
        type: "NOTA_PROMISSORIA",
        label: "Nota Promissória",
        grid: promissoryNoteGrid(id),
      },
      { type: "SEGURO", label: "Seguros", grid: insuranceGrid(id) },
      {
        type: "RECEBIVEIS_CARTOES",
        label: "Vendas em Cartão",
        grid: cardsGrid,
      },
      { type: "SACAS_GRAO", label: "Safra", grid: sacasGrid(id) },
      { type: "IMOVEL", label: "Imóvel", grid: imovelGrid(id) },
    ],
    [id],
  );

  const peacCollaterals = collaterals?.filter(
    (c) => c.collateralType === "PEAC",
  );

  const getCollateralsByType = useCallback(
    (type: string) =>
      collaterals?.filter((collateral) => collateral.collateralType === type),
    [collaterals],
  );

  const renderCollateralSections = useMemo(() => {
    return collateralTypes.map(({ type, label, grid }) => {
      const collateralsByType = getCollateralsByType(type);

      if (!collateralsByType?.length) return null;

      return (
        <React.Fragment key={type}>
          <OTypography className="my-4" tag="h3" size="lg" type="dark">
            {label}
          </OTypography>
          <ODataGridGenerator
            grid={grid as ODataGridGeneratorConfig<LimitCollateral>}
            dataSource={collateralsByType}
          />
        </React.Fragment>
      );
    });
  }, [collateralTypes, getCollateralsByType]);

  useEffect(() => {
    modalManager.on("remove-collateral-modal", "modalClose", () =>
      getCollaterals(id),
    );
  }, [getCollaterals, id]);

  return (
    <GridTemplate
      pageTitle={<PageTitle title="Garantias" />}
      actions={
        <>
          <RouterButton
            type="tertiary"
            role={
              roles.emprestimos.product
                .limitManagerLimitsDetailsCollateralsAddPage.role
            }
            href={corporateRouter.routes.emprestimos.product.limitManager.limits.details.collaterals.add.path(
              { id },
            )}
            outline
          >
            Adicionar nova garantia
          </RouterButton>
          {!!peacCollaterals?.length && (
            <OButton
              type="danger"
              onClick={() =>
                modalManager.show(
                  `remove-collateral-modal-${peacCollaterals[0].id}`,
                )
              }
              outline
            >
              Remover PEAC
            </OButton>
          )}
          <RefreshGridButton
            onClick={() => {
              getCollaterals(id);
            }}
          />
        </>
      }
    >
      <ContentTemplate
        loading={loadingCollaterals}
        hasError={hasErrorCollaterals}
        value={collaterals}
        errorComponent={
          <ErrorComponent
            messageTitle="Não foi possível carregar informações."
            messageParagraph="Tente novamente mais tarde."
          >
            <TryAgainButton onClick={() => getCollaterals(id)} />
          </ErrorComponent>
        }
        render={() => (
          <>
            {!!peacCollaterals?.length && (
              <>
                <RemoveCollateralConfirmationModal
                  collateralId={peacCollaterals[0].id}
                />
                <OTypography className="my-4" tag="h3" size="lg" type="dark">
                  PEAC
                </OTypography>
              </>
            )}

            {renderCollateralSections}
          </>
        )}
      />
    </GridTemplate>
  );
};
