import { RouterLink } from "components/router-link";
import { useProductsHomeRoute } from "hooks/products-home-route";
import { useTranslation } from "react-i18next";
import { HeaderNavigation } from "./navigation-menu.styles";

export const NavigationMenu = () => {
  const productsRoute = useProductsHomeRoute();
  const { t } = useTranslation("layout");

  return (
    <HeaderNavigation>
      {productsRoute && (
        <RouterLink href={productsRoute}>
          {t("header.productsAndServices")}
        </RouterLink>
      )}
    </HeaderNavigation>
  );
};
