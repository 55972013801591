import { HubPartners } from "services/hubpartners/models/types/admin";
import * as yup from "yup";

export type EditCatalogFormFields = HubPartners.Admin.Catalog;

export const editCatalogSchema: yup.ObjectSchema<EditCatalogFormFields> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    code: yup.string().required("Este campo é obrigatório"),
    uuid: yup.string().required("Este campo é obrigatório"),
  });
