import { env } from "utils/environments";

export const developersEndpoints = {
  apps: {
    pending: `${env.API_DEVELOPERS}/admin/apps/history?verificationStatus=TESTING`,
    verifyApp: (appId: string, version: number | string, tenantId: string) =>
      `${env.API_DEVELOPERS}/admin/apps/${appId}/versions/${version}/approve?tenantId=${tenantId}`,
    rejectApp: (appId: string, version: number | string, tenantId: string) =>
      `${env.API_DEVELOPERS}/admin/apps/${appId}/versions/${version}/reject?tenantId=${tenantId}`,
    filteredApp: (filter: string) =>
      `${env.API_DEVELOPERS}/admin/apps${filter}`,
    detailsApp: (appId: string, tenantId: string) =>
      `${env.API_DEVELOPERS}/admin/apps/${appId}?tenantId=${tenantId}`,
    filteredResource: (filter: string) =>
      `${env.API_DEVELOPERS}/admin/resources${filter}`,
    filteredScope: (filter: string) =>
      `${env.API_DEVELOPERS}/admin/scopes${filter}`,
    revokeConsentScope: (id: string) =>
      `${env.API_DEVELOPERS}/admin/scopes/${id}`,
    revokeConsentResource: (id: string) =>
      `${env.API_DEVELOPERS}/admin/resources/${id}`,
  },
  webhooks: {
    webhooksByApp: (appId: string, tenantId: string) =>
      `${env.API_DEVELOPERS}/admin/${tenantId}/apps/${appId}/webhooks`,
    webhooksByWebhookId: (appId: string, tenantId: string, webhookId: string) =>
      `${env.API_DEVELOPERS}/admin/${tenantId}/apps/${appId}/webhooks/${webhookId}`
  }
};
