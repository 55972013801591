import {
  OButton,
  OFilter,
  OFilterBody,
  OFilterFooter,
  OFilterHeader,
  OFilterItem,
  OFilterModal,
  OInputDate,
  OInputText,
  OOption,
  OSelect,
  OTypography,
} from "@maestro/react";
import { useRef } from "react";
import { cedentCompletoFilterModalId, formatDate } from "./filter-modal.utils";
import { scfDevelopersOnboardingStatusMap } from "../cedente-completo-errors.utils";

interface CedenteCompletoFilterModalProps {
  setFilter: (filter: ScfDevelopers.CedenteCompletoFilter) => void;
}

export const CedenteCompletoFilterModal = ({
  setFilter,
}: CedenteCompletoFilterModalProps) => {
  const filterModalRef = useRef<HTMLOFilterModalElement>(null);

  const handleFilterItems = async () => {
    const params = await filterModalRef.current?.filter();

    if (!params) {
      return;
    }

    params.createdDate = formatDate(params.createdDate);
    params.updatedDate = formatDate(params.updatedDate);

    setFilter(params);

    filterModalRef.current?.closeFilter();
  };

  const handleClearFilterItems = async () => {
    await filterModalRef.current?.clearFilter();
    setFilter({});
    filterModalRef.current?.closeFilter();
  };

  return (
    <OFilterModal ref={filterModalRef} id={cedentCompletoFilterModalId}>
      <OFilterHeader className="pt-1">
        <OTypography tag="h2">Filtros</OTypography>
      </OFilterHeader>

      <OFilterBody>
        <OFilter>
          <OFilterItem>
            <OTypography slot="title">Status</OTypography>
            <OSelect data-filterparam="status">
              {Object.entries(scfDevelopersOnboardingStatusMap).map(
                ([key, label]) => (
                  <OOption key={key} id={key} value={key}>
                    {label}
                  </OOption>
                ),
              )}
            </OSelect>
          </OFilterItem>
          <OFilterItem>
            <OTypography slot="title">AuthClient Id</OTypography>
            <OInputText
              name="authClientId_field"
              id="authClientId_field"
              data-filterparam="authClientId"
            />
          </OFilterItem>
          <OFilterItem>
            <OTypography slot="title">Configuration Id</OTypography>
            <OInputText
              name="configurationId_field"
              id="configurationId_field"
              data-filterparam="configurationId"
            />
          </OFilterItem>
          <OFilterItem>
            <OTypography slot="title">Data de criação</OTypography>
            <OInputDate data-filterparam="createdDate" />
          </OFilterItem>
          <OFilterItem>
            <OTypography slot="title">Data de atualização</OTypography>
            <OInputDate data-filterparam="updatedDate" />
          </OFilterItem>
        </OFilter>
      </OFilterBody>

      <OFilterFooter>
        <div className="d-flex justify-content-end">
          <OButton
            type="primary"
            onClick={handleClearFilterItems}
            bordered={false}
            outline
          >
            Limpar
          </OButton>
          <OButton onClick={handleFilterItems}>Filtrar</OButton>
        </div>
      </OFilterFooter>
    </OFilterModal>
  );
};
