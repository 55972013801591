import { HubPartners } from "services/hubpartners/models/types/admin";
import * as yup from "yup";

export type AddOriginatorSubtypeFormFields = Omit<
  HubPartners.Admin.OriginatorSubtype,
  "uuid"
>;

export const addOriginatorSubtypeSchema: yup.ObjectSchema<AddOriginatorSubtypeFormFields> =
  yup.object({
    name: yup.string().required("Este campo é obrigatório"),
    code: yup.string().required("Este campo é obrigatório"),
    deskCode: yup.string(),
    deskName: yup.string(),
  });
