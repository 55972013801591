import { yupResolver } from "@hookform/resolvers/yup";
import { OCol, ORFieldInput, ORow, OToastManager } from "@maestro/react";
import { isAxiosError } from "axios";
import { DefaultErrorComponent } from "components/empty-state";
import { LoadingButton } from "components/loading-button";
import { PageTitle } from "components/page-title";
import { useParseFromSearchParams } from "hooks/parse-from-search-params";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { service } from "services";
import { PutOriginatorsSubtypeRequest } from "services/hubpartners/models/requests/put-originators-subtype.request";
import { CardTemplate } from "templates/card-template";
import { ContentTemplate } from "templates/content-template";
import { FormTemplate } from "templates/form-template";
import { ORFieldSelectSearch } from "components/select-search";
import { HubPartners } from "services/hubpartners/models/types/admin";
import { useDesks } from "../../../../../hooks";
import {
  EditOriginatorSubtypeFormFields,
  editOriginatorSubtypeSchema,
} from "./edit-originator-subtype.form";

export const EditOriginatorSubtype = () => {
  const { value: originatorSubtype } =
    useParseFromSearchParams<PutOriginatorsSubtypeRequest>("data");
  const [loading, setLoading] = useState(false);

  const { allDesks, getDesks, getDesksHasError, getDesksLoading } = useDesks();

  const form = useForm<EditOriginatorSubtypeFormFields>({
    resolver: yupResolver(editOriginatorSubtypeSchema),
  });
  const { handleSubmit, reset } = form;

  const watchDeskName = form.watch("deskName");

  const onSubmit = handleSubmit(async (values) => {
    try {
      setLoading(true);

      await service.hubPartners.putOriginatorsSubtype(values);

      OToastManager.success("Subtipo de originador editado com sucesso.");
    } catch (err) {
      if (isAxiosError<HubPartners.Admin.ErrorResponse>(err)) {
        err.response?.data?.failures?.forEach((failure) => {
          failure.message && OToastManager.danger(failure.message);
        });
      } else {
        OToastManager.danger(
          "Não foi possível editar o subtipo de originador.",
        );
      }
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (originatorSubtype) {
      reset({
        deskName: originatorSubtype.deskName,
        deskCode: originatorSubtype.deskCode,
        name: originatorSubtype.name,
        code: originatorSubtype.code,
        uuid: originatorSubtype.uuid,
      });
    }
  }, [originatorSubtype, reset]);

  return (
    <FormTemplate
      pageTitle={<PageTitle title="Editar subtipo de originador" />}
      actions={
        <LoadingButton loading={loading} onClick={onSubmit}>
          Editar
        </LoadingButton>
      }
    >
      <CardTemplate>
        <ContentTemplate
          loading={getDesksLoading}
          hasError={getDesksHasError}
          value={!!allDesks}
          errorComponent={
            <DefaultErrorComponent
              title="Não foi possível buscar as mesas"
              callback={getDesks}
            />
          }
          render={() => (
            <FormProvider {...form}>
              <ORow>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    label="Código"
                    name="code"
                    id="code"
                    required
                  />
                </OCol>
                <OCol>
                  <ORFieldInput
                    tag="text"
                    label="Nome"
                    name="name"
                    id="name"
                    required
                  />
                </OCol>
              </ORow>
              <ORow>
                <OCol>
                  <ORFieldSelectSearch
                    label="Mesa"
                    name="deskName"
                    id="deskName"
                    value={watchDeskName}
                    options={allDesks?.map((desk) => ({
                      value: desk.code,
                      label: desk.code + " - " + desk.name,
                      onSelect: () => {
                        form.setValue(
                          "deskName",
                          desk.code + " - " + desk.name,
                        );
                        form.setValue("deskCode", desk.code);
                      },
                    }))}
                  />
                </OCol>
              </ORow>
            </FormProvider>
          )}
        />
      </CardTemplate>
    </FormTemplate>
  );
};
