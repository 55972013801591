import { OTypography } from "@maestro/react";
import { ODataGridGenerator } from "components/data-grid";
import { useGridRef } from "hooks/grid-ref";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridTemplate } from "templates/grid-template";
import {
  buildConsumerUnitsGrid,
  buildDataSource,
} from "../../../../components/consumer-units-grid";

const dataGrid = buildConsumerUnitsGrid(true);

export const ConsumerUnitsGrid = () => {
  const { tradeId } = useParams();

  const gridRef = useGridRef();

  const dataSource = useMemo(() => buildDataSource(tradeId), [tradeId]);

  return (
    <GridTemplate
      pageTitle={
        <OTypography type="dark" size="lg">
          Unidades consumidoras
        </OTypography>
      }
      gridRef={gridRef}
      showRefreshButton
      showClearFiltersButton
      showExportButton
    >
      <ODataGridGenerator
        gridRef={gridRef}
        grid={dataGrid}
        dataSource={dataSource}
      />
    </GridTemplate>
  );
};
