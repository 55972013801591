import {
  OBreadcrumb,
  OBreadcrumbItem,
  OButton,
  OIcon,
  OTypography,
} from "@maestro/react";
import { FormProvider } from "react-hook-form";
import { corporateRouter } from "routes/corporate-router.context";
import { CardTemplate } from "templates/card-template";
import { BannerPreview } from "../_compose/banner-preview/banner-preview.component";
import { InfoList } from "../form/_compose/summary/_compose";
import { useBannerDetails } from "./details.hook";
import { StyledButton } from "./details.style";
import { getBannerDetailsInfo, getCampaignDetailsInfo } from "./details.utils";

export const BannerManagementDetailsPage = () => {
  const {
    data,
    canEdit,
    form,
    navigate,
    handleToggleIsActive,
    handleEditCampaignRedirect,
  } = useBannerDetails();

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex flex-row justify-content-between align-items-end w-100 mb-2">
        <div>
          <OBreadcrumb>
            <OBreadcrumbItem
              onClick={() =>
                navigate(
                  corporateRouter.routes.workflow.product.bannerManagement.path,
                )
              }
              href="#"
            >
              <span className="d-flex gap-2">
                <OIcon category="fal" icon="fa-home-alt" size="md" />
                Gerenciamento de campanhas
              </span>
            </OBreadcrumbItem>
            <OBreadcrumbItem href="#">Detalhes</OBreadcrumbItem>
          </OBreadcrumb>

          <OTypography size="xxl" type="dark" className="my-3">
            Detalhes da campanha
          </OTypography>

          <OTypography type="dark-80">
            Confira os detalhes da campanha e do banner.
          </OTypography>
        </div>

        {canEdit && (
          <OButton outline onClick={() => handleEditCampaignRedirect()}>
            <div className="d-flex flex-row justify-content-between gap-2">
              <OTypography type="dark">Editar campanha</OTypography>
              <OIcon type="dark" category="orq" icon="orq-edit-pencil" />
            </div>
          </OButton>
        )}
      </div>

      {data && (
        <CardTemplate>
          <div className="d-flex flex-column gap-4">
            {!!getBannerDetailsInfo(data).length && (
              <InfoList
                items={getBannerDetailsInfo(data)}
                title="Criação do banner"
                divider="horizontal"
                colSize={4}
              />
            )}
            <InfoList
              items={getCampaignDetailsInfo(data)}
              title="Configuração da campanha"
              divider="horizontal"
              colSize={4}
            />
          </div>
        </CardTemplate>
      )}

      {data && (
        <FormProvider {...form}>
          <BannerPreview data={data} />
        </FormProvider>
      )}

      <div className="d-flex justify-content-between gap-2">
        <OButton type="dark" outline onClick={() => navigate(-1)}>
          Voltar
        </OButton>

        {canEdit && (
          <StyledButton
            outline
            bordered={false}
            onClick={() => data && handleToggleIsActive(data)}
            type="dark"
          >
            {data?.isActive ? "Desativar campanha" : "Ativar campanha"}
          </StyledButton>
        )}
      </div>
    </div>
  );
};
