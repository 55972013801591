import { OCard, OCardBody, OTypography } from "@maestro/react";
import { useEffect, useState } from "react";

interface TimerProps {
  start: boolean;
  initTime?: Date;
}

export const Timer = ({ start, initTime }: TimerProps) => {
  const [time, setTime] = useState("00:00:00");

  const showTimer = (ms: number) => {
    const second = Math.floor((ms / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((ms / 1000 / 60) % 60)
      .toString()
      .padStart(2, "0");
    const hour = Math.floor((ms / 1000 / 60 / 60) % 60)
      .toString()
      .padStart(2, "0");
    setTime(hour + ":" + minute + ":" + second);
  };

  const clearTime = () => {
    setTime("00:00:00");
  };

  useEffect(() => {
    clearTime();
  }, [initTime]);

  useEffect(() => {
    if (!start || !initTime) {
      return;
    }
    const id = setInterval(() => {
      const left = new Date().getTime() - new Date(initTime).getTime();
      showTimer(left);
      if (left <= 0) {
        setTime("00:00:00");
        clearInterval(id);
      }
    }, 1);
    return () => clearInterval(id);
  }, [initTime, start]);

  return (
    <OCard>
      <OCardBody className="d-flex flex-column w-100 justify-content-center align-items-center">
        <OTypography size="lg" type="dark">
          Tempo decorrido
        </OTypography>
        <OTypography size="xxxl" key={time}>
          {time}
        </OTypography>
      </OCardBody>
    </OCard>
  );
};
