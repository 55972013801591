import { useBehavior } from "contexts/behavior";
import { Behaviors } from "contexts/behavior/types/behavior-ids";
import { clearCustomer, useUnsafeCustomer } from "contexts/customer";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { DropdownList, StyledInput } from "./compose";
import { CustomerSelectorProvider, useCustomerSelector } from "./context";
import { FavoritesProvider } from "./context/favorites.context";
import { FullOptionsProvider } from "./context/full-options.context";
import { SearchEntityProvider } from "./context/search-entity.context";
import { UserCustomersProvider } from "./context/user-customers.context";
import { FullWidthODropdown } from "./customer-selector.styles";
import { displayValueFromCustomer } from "./utils";

const CustomerSelectorComponent = () => {
  const { state, customer } = useUnsafeCustomer();
  const { behavior } = useBehavior();
  const { t } = useTranslation("common");
  const searchPlaceholder =
    behavior.id === Behaviors.Corban
      ? t("customerSelector")
      : t("customerAndCompanySelector");

  const loadingCustomer = state === "LOADING";

  const dropdownRef = useRef<HTMLODropdownElement>(null);

  const { mode, displayValue, loading, setValue, setDisplayValue } =
    useCustomerSelector();

  const placeholder =
    mode === "fetch" ? searchPlaceholder : "Buscar uma empresa";

  return (
    <FullWidthODropdown ref={dropdownRef} className="w-100">
      <StyledInput
        id="buscar-empresa"
        name="buscar-empresa"
        dataAction="header:input:buscar"
        dataLabel="buscar"
        placeholder={placeholder}
        autocomplete="off"
        value={displayValue ?? undefined}
        handleClear={() => {
          clearCustomer();
          setValue(undefined);
        }}
        onInput={(e) => {
          setValue((e.target as HTMLInputElement).value);
        }}
        onFocus={() => {
          // clear input on focus to speed up typing
          if (state === "SELECTED") setDisplayValue(undefined);
        }}
        onBlur={(evt) => {
          // setIsEmpty(false);
          // only reset value if clicking outside dropdown
          if (
            !dropdownRef.current?.contains(evt.relatedTarget) &&
            state === "SELECTED"
          ) {
            setDisplayValue(displayValueFromCustomer(customer));
          }
        }}
        loading={loadingCustomer || loading}
        active={!!customer}
      />

      <DropdownList />
    </FullWidthODropdown>
  );
};

export const CustomerSelector = () => (
  <FavoritesProvider>
    <SearchEntityProvider>
      <UserCustomersProvider>
        <FullOptionsProvider>
          <CustomerSelectorProvider>
            <CustomerSelectorComponent />
          </CustomerSelectorProvider>
        </FullOptionsProvider>
      </UserCustomersProvider>
    </SearchEntityProvider>
  </FavoritesProvider>
);
